import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import moment from 'moment';
import useAsyncEffect from 'use-async-effect';
import _ from 'lodash';

import Loader from 'components/Loader';
import {
  CAMOGRAM_STATUS_APPROVED,
  ANNOTATION_TYPE_HIGH_RES,
} from 'common/constants';
import fetchStoreCamogramsApi from 'utils/api/fetchStoreCamogramsApi';
import fetchStoreHighResCamogramsApi from 'utils/api/newEditor/fetchStoreHighResCamogramsApi';
import { fetchNewEditorCamerasApi } from 'utils/api';
import { configureCameraImages, configureCameraLabel, exist } from 'utils';
import { configureCamerasInCamogramList } from './helpers';
import StartTaskBasedEditorButton from './components/StartTaskBasedEditorButton';
import fetchJobsApi from 'utils/api/taskBasedEditor/fetchJobsApi';
import { CamogramJobBase, TaskType } from 'types/taskBased';
import { EditorDataSource } from 'types';
import TaskBasedCamogramItem from './components/TaskBasedCamogramItem';
import { configureCameraImageIdFromProviderKey } from 'utils/providerKey';
import { releaseQcJobsApi } from 'utils/api/taskBasedEditor/releaseGroupJobsApi';
import ErrorDisplayer from 'components/ErrorDisplayer';
import { formatDatetime } from 'utils/formatDatetime';

const StoreName = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: solid 1px #cdcdcd;
  padding-bottom: 1.5rem;

  > * {
    margin-right: 1.5rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  > * {
    margin-right: 1rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 3rem;

  @media (max-width: 800px) {
    thead {
      display: none;
    }
  }
`;

const Tbody = styled.tbody`
  border-top: solid 1px #cdcdcd;
`;

const Row = styled.tr`
  background: #fff;
  margin: 0;
  line-height: 1.5;
  border-bottom: solid 1px #cdcdcd;

  &:hover {
    background: #eee;
  }

  @media (max-width: 800px) {
    border: 20px solid white;
    border-width: 5px 0 5px;
    display: block;
    padding: 5px;
  }
`;

const Cell = styled.td`
  padding: 8px 12px;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  word-wrap: break-word;
  max-width: 400px;

  span {
    vertical-align: middle;
  }

  @media (max-width: 800px) {
    display: block;
    text-align: left;
    padding: 4px 8px;
  }
`;

const getDataSource = camogram => camogram.data_source;

const FinalizedCamogramItem = ({ camogram, onClick }) => {
  const editorType = getDataSource(camogram);
  const camerasLabel = camogram.providerKeys
    .map(configureCameraLabel)
    .join(',');
  return (
    <Row>
      <Cell>
        {formatDatetime(camogram.rockset_created_at || camogram.created_at)}
      </Cell>
      <Cell>{camogram.started_by}</Cell>
      <Cell>{formatDatetime(camogram.approved_at)}</Cell>
      <Cell>{camogram.approved_by}</Cell>
      <Cell>{camerasLabel}</Cell>
      <Cell>{editorType}</Cell>
      <Cell>
        <Button onClick={onClick} variant="contained" size="small">
          <Launch style={{ paddingRight: '6px' }} /> Open
        </Button>
      </Cell>
    </Row>
  );
};

const configureCameraListLabel = providerKeyWithInteger => {
  const {
    shelfId,
    camogramSectionId,
    cameraInteger,
    cameraId,
  } = providerKeyWithInteger;
  const cameraLabelId = exist(cameraInteger) ? cameraInteger : cameraId;

  if (exist(shelfId) && exist(camogramSectionId)) {
    return `${cameraLabelId} (${shelfId}, Section ${camogramSectionId})`;
  }
  return cameraLabelId;
};

const configureTaskBasedCamograms = (
  rawJobs: CamogramJobBase[],
  cameras: any
) => {
  const normalized = rawJobs
    .map(job => ({
      ...job,
      cameraId: job.data.cameraId,
      groupId: job.groupId,
      timestamp: job.data.timestamp,
    }))
    .filter(job => job.groupId !== undefined);
  const grouped = _.groupBy(normalized, 'groupId');
  const result = Object.entries(grouped)
    .map(([groupId, jobs]) => {
      const providerKeys = _.reduce(
        jobs,
        (acc, { cameraId, providerKey }) => {
          const _cameraId = providerKey.cameraId ?? cameraId;
          return { ...acc, [_cameraId]: providerKey };
        },
        {}
      );
      const providerKeysWithInteger = Object.values(
        configureCameraImages(providerKeys, cameras)
      );
      const created_at = jobs[0].createdAt;
      const started_by = jobs[0].createdBy;
      const isDone = jobs.every(
        ({ currentTaskName }) => currentTaskName === ''
      );

      const qcProviderKeys = jobs
        .filter(
          ({ currentTaskName, hasProgressedToReviewAnnotations }) =>
            hasProgressedToReviewAnnotations &&
            currentTaskName !== TaskType.Completed
        )
        .map(({ providerKey: jobProviderKey }) => {
          const providerKeyWithInteger = providerKeysWithInteger.find(
            providerKey =>
              configureCameraImageIdFromProviderKey(providerKey) ===
              configureCameraImageIdFromProviderKey(jobProviderKey)
          );
          return { ...jobProviderKey, ...providerKeyWithInteger };
        });
      const wipProviderKeys = jobs
        .filter(
          ({ hasProgressedToReviewAnnotations }) =>
            !hasProgressedToReviewAnnotations
        )
        .map(({ providerKey: jobProviderKey }) => {
          const providerKeyWithInteger = providerKeysWithInteger.find(
            providerKey =>
              configureCameraImageIdFromProviderKey(providerKey) ===
              configureCameraImageIdFromProviderKey(jobProviderKey)
          );
          return { ...jobProviderKey, ...providerKeyWithInteger };
        });
      return {
        groupId,
        created_at,
        isDone,
        started_by,
        jobs,
        qcProviderKeys,
        wipProviderKeys,
      };
    })
    .filter(job => !job.isDone)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return result;
};

const CamogramPicker = () => {
  const { storeId } = useParams();
  const store = useSelector(state => state.stores[storeId]);
  const [camograms, setCamograms] = useState([]);
  const [taskBasedCamograms, setTaskBasedCamograms] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const annotationTypes = store?.camogram_annotation_type || [];

  const shouldDisplayTaskBasedButton = annotationTypes.includes(
    ANNOTATION_TYPE_HIGH_RES
  );

  const fetchCamograms = async () => {
    if (store === undefined) return;
    setLoading(true);
    const createdAfter = moment().subtract(7, 'days').toISOString();
    const fetchJobsFunc = shouldDisplayTaskBasedButton
      ? fetchJobsApi({ storeId, createdAfter })
      : [];
    try {
      const [
        regularCamogramsResponse,
        highResCamogramsResponse,
        cameras,
        jobs,
      ] = await Promise.all([
        fetchStoreCamogramsApi({ storeId }),
        fetchStoreHighResCamogramsApi({ storeId }),
        fetchNewEditorCamerasApi(storeId),
        fetchJobsFunc,
      ]);
      const mergedCamograms = [
        ...regularCamogramsResponse,
        // Deduplicate high res camograms by created_at timestamp
        ..._.uniqBy(highResCamogramsResponse, 'created_at'),
      ].sort(
        (a, b) =>
          new Date(b.rockset_created_at || b.created_at) -
          new Date(a.rockset_created_at || a.created_at)
      );
      const withCameraInteger = mergedCamograms.map(camogram => {
        const providerKeys = camogram.provider_keys
          ? _.mapValues(
              _.groupBy(
                camogram.provider_keys,
                provider_key => provider_key.cam_id
              ),
              val => val[0]
            )
          : {};
        const cameraIntegers = Object.values(
          configureCameraImages(providerKeys, cameras)
        ).map(providerKey => providerKey.cameraInteger);
        return { ...camogram, cameraIntegers };
      });

      setCamograms(withCameraInteger);

      const taskBasedCamograms = configureTaskBasedCamograms(jobs, cameras);
      setTaskBasedCamograms(taskBasedCamograms);
    } catch (error) {
      alert('Failed to fetch stores:\n' + error.message);
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect(fetchCamograms, [
    setLoading,
    storeId,
    setCamograms,
    shouldDisplayTaskBasedButton,
    store,
  ]);
  const openCamogramView = camogram => () => {
    const editorType = getDataSource(camogram);
    if (editorType === EditorDataSource.Omni) {
      history.push(
        `/stores/${storeId}/omni-camograms/${
          camogram.rockset_created_at || camogram.created_at
        }`
      );
    } else if (editorType === EditorDataSource.StaticHighRes) {
      history.push(
        `/stores/${storeId}/highres-camograms/${
          camogram.rockset_created_at || camogram.created_at
        }`
      );
    } else {
      history.push(
        `/stores/${storeId}/camograms/${
          camogram.rockset_created_at || camogram.created_at
        }`
      );
    }
  };

  const [finalizedCamograms, inProgressCamograms] = _.partition(
    camograms,
    camogram => camogram.status === CAMOGRAM_STATUS_APPROVED
  );

  const wipTaskBasedCamograms = taskBasedCamograms.filter(
    ({ wipProviderKeys }) => wipProviderKeys.length > 0
  );
  const qcTaskBasedCamograms = taskBasedCamograms.filter(
    ({ qcProviderKeys }) => qcProviderKeys.length > 0
  );

  const onClickTaskBasedQcButton = (groupId: string) => async () => {
    await releaseQcJobsApi({ storeId, groupId });
    history.push(`/stores/${storeId}/task-based-review-annotations/${groupId}`);
  };

  const onClickTaskBasedContinueButton = (groupId: string) => () => {
    history.push(`/stores/${storeId}/task-based-editor/${groupId}`);
  };

  const onClickHome = () => {
    history.push('/');
  };
  if (store === undefined)
    return (
      <ErrorDisplayer
        error={`There is no store with the id ${storeId}. The store id may have changed. Please go back to the homepage and check.`}
        actions={
          <Button color="primary" onClick={onClickHome}>
            CamogramViz Home
          </Button>
        }
      />
    );
  return (
    <div>
      <NameContainer>
        <div>
          <StoreName>{store.name}</StoreName>
          <div>{store.id}</div>
        </div>
        <ButtonsContainer>
          {shouldDisplayTaskBasedButton && (
            <StartTaskBasedEditorButton
              storeId={storeId}
              camograms={finalizedCamograms}
              annotationTypes={annotationTypes}
            />
          )}
        </ButtonsContainer>
      </NameContainer>
      <>
        {shouldDisplayTaskBasedButton && (
          <>
            <h2>Task Based Camograms in Progress</h2>
            <Loader loading={loading} text="Loading Camograms">
              {wipTaskBasedCamograms.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <Cell as="th">Started On</Cell>
                      <Cell as="th">Group ID</Cell>
                      <Cell as="th">Started By</Cell>
                      <Cell as="th">WIP Cameras</Cell>
                      <Cell />
                      <Cell />
                    </tr>
                  </thead>
                  <Tbody>
                    {wipTaskBasedCamograms.map((camogram, index) => (
                      <TaskBasedCamogramItem
                        key={index}
                        storeId={storeId}
                        camogram={camogram}
                        fetchCamograms={fetchCamograms}
                        cameras={camogram.wipProviderKeys.map(
                          configureCameraListLabel
                        )}
                        buttonTitle="Continue TaskBased"
                        onClickContinueButton={onClickTaskBasedContinueButton(
                          camogram.groupId
                        )}
                      />
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <div>There are no task based camograms in progress</div>
              )}
            </Loader>
            <h2>Task Based Camograms in QC</h2>
            <Loader loading={loading} text="Loading Camograms">
              {qcTaskBasedCamograms.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <Cell as="th">Started On</Cell>
                      <Cell as="th">Group ID</Cell>
                      <Cell as="th">Started By</Cell>
                      <Cell as="th">QC Cameras</Cell>
                      <Cell />
                      <Cell />
                    </tr>
                  </thead>
                  <Tbody>
                    {qcTaskBasedCamograms.map((camogram, index) => (
                      <TaskBasedCamogramItem
                        key={index}
                        storeId={storeId}
                        camogram={camogram}
                        fetchCamograms={fetchCamograms}
                        cameras={camogram.qcProviderKeys.map(
                          configureCameraListLabel
                        )}
                        buttonTitle="Continue QC"
                        onClickContinueButton={onClickTaskBasedQcButton(
                          camogram.groupId
                        )}
                      />
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <div>There are no task based camograms in QC</div>
              )}
            </Loader>
          </>
        )}
      </>
      <h2>Finalized Camograms</h2>
      <Loader loading={loading} text="Loading Camograms">
        <Table>
          <thead>
            <tr>
              <Cell as="th">Started On</Cell>
              <Cell as="th">Started By</Cell>
              <Cell as="th">Finalized On</Cell>
              <Cell as="th">Finalized By</Cell>
              <Cell as="th">Cameras</Cell>
              <Cell as="th">Type</Cell>
              <Cell />
            </tr>
          </thead>
          <Tbody>
            {finalizedCamograms.map((camogram, index) => (
              <FinalizedCamogramItem
                key={index}
                storeId={storeId}
                camogram={camogram}
                onClick={openCamogramView(camogram)}
              />
            ))}
          </Tbody>
        </Table>
      </Loader>
    </div>
  );
};

export default CamogramPicker;
