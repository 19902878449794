import { Button, CircularProgress, Dialog } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Backdrop from './Backdrop';
import useAsyncCall from 'hooks/useAsyncCall';
import Loader from 'components/Loader';
import createBulkJobsApi from 'utils/api/taskBasedEditor/createBulkJobsApi';
import {
  ANNOTATION_TYPE_HIGH_RES,
  ANNOTATION_TYPE_OMNI,
  EVENT_TASK_BASED_START_CAMOGRAM,
} from 'common/constants';
import { track } from 'utils/segmentAnalytics';
import { EditorDataSource, ProviderKey } from 'types';
import fetchGenericImagesWithCameras from 'utils/api/fetchGenericImagesWithCamerasApi';
import { configureCameraImageIdFromProviderKey } from 'utils/providerKey';
import Flex from 'components/Flex';

const CameraSelectorsContainer = styled.div`
  padding: 16px;
`;

const CameraSelectorContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
`;

const CameraListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  overflow-y: auto;
  > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

const CameraItemContainer = styled.div<{ selected: boolean }>`
  max-width: 180px;
  height: auto;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? 'blue' : 'transparent')};
`;

const CameraInfoLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const Img = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

const Buttons = styled.div`
  display: flex;
  height: 50px;
  justify-content: flex-end;
  align-self: stretch;
  margin-top: 16px;
  > * {
    margin-right: 16px;
  }
`;

const CameraDetail = styled.div`
  font-size: 10px;
`;

const fetchImagesData = async ({
  storeId,
  dataSource,
}: {
  storeId: string;
  dataSource: EditorDataSource;
}) => {
  const { images } = await fetchGenericImagesWithCameras({
    storeId,
    dataSource,
  });

  return images;
};

const configureCameraIdLabel = (
  providerKey: ProviderKey,
  cameraInteger: string
): string => {
  if (cameraInteger === providerKey.cameraId) {
    return `Cam ${cameraInteger}`;
  }
  return `Cam ${cameraInteger} (${providerKey.cameraId})`;
};

const CameraSelector = ({
  storeId,
  dataSource,
  camograms,
  onClickStart,
  onClickCancel,
  selectedProviderKeys,
  setSelectedProviderKeys,
}) => {
  const { call, isReady, data } = useAsyncCall<any>();
  // const [selectedProviderKeys, setSelectedProviderKeys] = useState<any[]>([]);

  useEffect(() => {
    call(fetchImagesData({ storeId, dataSource }));
  }, []);

  if (!data || !isReady) return <Loader loading />;
  const onClickStartButton = () => {
    onClickStart(selectedProviderKeys);
  };

  const onClickItem = providerKey => () => {
    setSelectedProviderKeys(prev => {
      if (prev.some(value => _.isEqual(providerKey, value))) {
        const others = prev.filter(value => !_.isEqual(value, providerKey));
        return others;
      } else {
        return [...prev, providerKey];
      }
    });
  };
  for (const camogram of _.sortBy(
    camograms,
    camogram => camogram.approved_at
  )) {
    if (camogram.providerKeys) {
      for (const providerKey of camogram.providerKeys) {
        const cameraImageId = configureCameraImageIdFromProviderKey(
          providerKey
        );
        if (data[cameraImageId]) {
          data[cameraImageId].approved_at = camogram.approved_at;
        }
      }
    }
  }

  const sortedCameraImage = _.sortBy(Object.values<any>(data), value =>
    Number(value.cameraInteger)
  );

  const formatLastUpdated = time => {
    if (!time) {
      return '';
    }

    return `Last Updated: ${moment(time).format('MM/DD/YY hh:mm A')} (${moment(
      time
    ).fromNow()})`;
  };

  const onClickSelectAll = () => {
    const allProviderKeys = sortedCameraImage.map(
      ({ providerKey }) => providerKey
    );
    setSelectedProviderKeys(prev => [...prev, ...allProviderKeys]);
  };

  return (
    <CameraSelectorContainer>
      <Flex alignItems="center">
        <h3>Please choose all cameras that you're annotating</h3>
        <Button
          variant="contained"
          style={{ marginLeft: 8 }}
          size="small"
          onClick={onClickSelectAll}
        >
          Select All
        </Button>
      </Flex>
      <CameraListContainer>
        {sortedCameraImage.map(image => (
          <CameraItemContainer
            key={configureCameraImageIdFromProviderKey(image.providerKey)}
            onClick={onClickItem(image.providerKey)}
            selected={selectedProviderKeys.some(value =>
              _.isEqual(image.providerKey, value)
            )}
          >
            <Img src={image.src} />
            <CameraInfoLabel>
              {configureCameraIdLabel(image.providerKey, image.cameraInteger)}
            </CameraInfoLabel>
            {image.providerKey.shelfId !== undefined && (
              <CameraInfoLabel>{image.providerKey.shelfId}</CameraInfoLabel>
            )}
            {image.providerKey.camogramSectionId !== undefined && (
              <CameraInfoLabel>
                Section {image.providerKey.camogramSectionId}
              </CameraInfoLabel>
            )}
            <CameraDetail>{formatLastUpdated(image.approved_at)}</CameraDetail>
          </CameraItemContainer>
        ))}
      </CameraListContainer>
      <Buttons>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickStartButton}
        >
          Start Labeling
        </Button>
      </Buttons>
    </CameraSelectorContainer>
  );
};

const StartTaskBasedEditorButton = ({
  storeId,
  camograms,
  annotationTypes,
}) => {
  const history = useHistory();
  const { isLoading, call } = useAsyncCall<{
    groupId: string;
    jobs: any[];
  }>();
  const [openCameraSelector, setOpenCameraSelector] = useState(false);
  const [selectedProviderKeys, setSelectedProviderKeys] = useState<any[]>([]);
  const onClick = () => {
    setOpenCameraSelector(true);
  };

  const onCloseDialog = () => {
    setOpenCameraSelector(false);
  };

  const onClickStart = async (providerKeys: ProviderKey[]) => {
    setOpenCameraSelector(false);
    const datetime = moment().toISOString();
    const res = await call(
      createBulkJobsApi({ providerKeys, datetime, storeId })
    );

    track(EVENT_TASK_BASED_START_CAMOGRAM, {
      storeId: storeId,
      camogramTimestamp: datetime,
      cameraIds: providerKeys.map(({ cameraId }) => cameraId),
      jobs: res.jobs.map(job => ({
        cameraId: job.data.camera_id,
        id: job.id,
        currentTaskName: job.current_task_name,
      })),
      groupId: res.groupId,
    });
    history.push(`/stores/${storeId}/task-based-editor/${res.groupId}`);
  };

  return (
    <>
      <Button onClick={onClick} variant="contained" color="primary">
        Start TaskBased
      </Button>
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openCameraSelector}
        fullWidth
        maxWidth="xl"
        onClose={onCloseDialog}
      >
        <CameraSelectorsContainer>
          {annotationTypes.includes(ANNOTATION_TYPE_HIGH_RES) && (
            <>
              <h2>Static Highres Cameras</h2>
              <CameraSelector
                storeId={storeId}
                camograms={camograms}
                onClickStart={onClickStart}
                onClickCancel={onCloseDialog}
                dataSource={EditorDataSource.StaticHighRes}
                selectedProviderKeys={selectedProviderKeys}
                setSelectedProviderKeys={setSelectedProviderKeys}
              />
            </>
          )}
          {annotationTypes.includes(ANNOTATION_TYPE_OMNI) && (
            <>
              <h2>360 Cameras</h2>
              <CameraSelector
                storeId={storeId}
                camograms={camograms}
                onClickStart={onClickStart}
                onClickCancel={onCloseDialog}
                dataSource={EditorDataSource.Omni}
                selectedProviderKeys={selectedProviderKeys}
                setSelectedProviderKeys={setSelectedProviderKeys}
              />
            </>
          )}
        </CameraSelectorsContainer>
      </Dialog>
    </>
  );
};

export default StartTaskBasedEditorButton;
