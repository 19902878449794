import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  Tab as MuiTab,
  Tabs,
} from '@material-ui/core';
import _ from 'lodash';

import Loader from 'components/Loader';
import {
  WORKFLOW_VERSION,
  WORKFLOW_NAME,
  EVENT_START_NEXT_TASK,
  EVENT_START_NEXT_QC_TASK,
} from 'common/constants';

import useAccount from 'hooks/useAccount';
import { CamogramJob, CamogramJobBase, TaskType } from 'types/taskBased';
import { CameraState, Store } from 'types';
import { postStartNextAnnotationApi } from 'utils/api/postStartNextAnnotationApi';
import { fetchCameraStatesApi } from 'utils/api/fetchCameraStatesApi';
import MyInProgressCamogramItem from './components/MyInProgressCamogramItem';
import { useAppSelector } from 'hooks';
import { postStartNextReviewAnnotationsTaskApi } from 'utils/api/postStartNextReviewAnnotationTaskApi';
import { exist } from 'utils';
import fetchJobsApi from 'utils/api/taskBasedEditor/fetchJobsApi';
import { configureCameraImageIdFromProviderKey } from 'utils/providerKey';
import useAsyncCall from 'hooks/useAsyncCall';
import FinalizedCamogramsTable from './components/FinalizedCamogramsTable';
import ProviderStatesTable from './components/ProviderStatesTable';
import { track } from 'utils/segmentAnalytics';
import InProgressTable, {
  InProgressCamogramJob,
} from './components/InProgressTable';
import InProgressQcTable from './components/InProgressQcTable';
import { toast } from 'react-toastify';

const MyInProgressTasksContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow: auto;
  > * {
    margin-right: 16px;
  }
`;

const Tab = styled(MuiTab)`
  text-align: left;
`;

const configureCameraListLabel = providerKeyWithInteger => {
  const {
    shelfId,
    camogramSectionId,
    cameraInteger,
    cameraId,
  } = providerKeyWithInteger;
  const cameraLabelId = exist(cameraInteger) ? cameraInteger : cameraId;

  if (exist(shelfId) && exist(camogramSectionId)) {
    return `${cameraLabelId} (${shelfId}, Section ${camogramSectionId})`;
  }
  return cameraLabelId;
};

type TabPanelProps = {
  index: number;
  value: number;
};

const TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
  return <div hidden={value !== index}>{value === index && children}</div>;
};

type FetchInitialDataRes = {
  jobs: CamogramJobBase[];
  cameraStates: CameraState[];
};

const fetchInitialData = async (): Promise<FetchInitialDataRes> => {
  const [cameraStates, jobsRaw] = await Promise.all([
    fetchCameraStatesApi(),
    fetchJobsApi(),
  ]);
  const jobs = jobsRaw.filter(
    ({ currentTaskName }) => currentTaskName !== TaskType.Completed
  );
  return { cameraStates, jobs };
};

const configureInProgressTasks = ({
  jobs,
  email,
  stores,
  cameraStates,
  isQc,
}: {
  jobs: CamogramJobBase[];
  email: string;
  stores: Store[];
  cameraStates: CameraState[];
  isQc: boolean;
}): { yours: InProgressCamogramJob[]; others: InProgressCamogramJob[] } => {
  const allInProgress = jobs
    .filter(job => job.hasProgressedToReviewAnnotations === isQc)
    .map(job => {
      const store = stores.find(store => store.id === job.storeId);
      const cameraState = cameraStates.find(({ providerKey }) => {
        return (
          configureCameraImageIdFromProviderKey(providerKey) ===
          configureCameraImageIdFromProviderKey(job.providerKey)
        );
      });

      return {
        ...job,
        storeName: store?.name ?? 'Unknown Store',
        cameraInteger: cameraState?.providerKey?.cameraId,
      };
    })
    .filter(Boolean);
  const createdByKey = isQc ? 'startedReviewAnnotationsBy' : 'createdBy';
  const sortKey = isQc ? 'startedReviewAnnotationsAt' : 'createdAt';
  const yours = _.sortBy(
    allInProgress.filter(job => job[createdByKey] === email),
    sortKey
  ).reverse();
  const others = allInProgress.filter(
    job => job[createdByKey] !== undefined && job[createdByKey] !== email
  );

  return { yours, others };
};

const configureQcQueueLength = ({ jobs }: { jobs: CamogramJobBase[] }) => {
  const qcQueueJobs = jobs.filter(
    job =>
      job.hasProgressedToReviewAnnotations &&
      job.startedReviewAnnotationsAt === undefined
  );

  return qcQueueJobs.length;
};

const goToTaskEditor = (history, storeId, groupId) => {
  history.push(`/stores/${storeId}/task-based-editor/${groupId}`);
};

const goToReviewAnnotations = (history, storeId, groupId) => {
  history.push(`/stores/${storeId}/task-based-review-annotations/${groupId}`);
};

const NextAnnotationButton = ({ email }: { email: string }) => {
  const { call, isLoading } = useAsyncCall<CamogramJob>();
  const history = useHistory();
  const onClick = async () => {
    const job = await call(
      postStartNextAnnotationApi({
        userId: email,
        workflowVersion: WORKFLOW_VERSION,
        workflowName: WORKFLOW_NAME,
      }),
      {
        handleAlert: error => {
          if (error.isKnownError?.()) {
            toast.info(error.configureKnownErrorDescription?.(), {
              autoClose: false,
            });
          } else {
            alert(`Message: ${error.message}\nStack: ${error.stack}`);
          }
        },
      }
    );
    const storeId = job.storeId;
    const groupId = job.groupId;
    track(EVENT_START_NEXT_TASK, { storeId, groupId });
    goToTaskEditor(history, storeId, groupId);
  };

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        'Next in Queue'
      )}
    </Button>
  );
};

const NextQcButton = ({ email }: { email: string }) => {
  const { call, isLoading } = useAsyncCall<CamogramJob>();
  const history = useHistory();
  const onClick = async () => {
    const job = await call(
      postStartNextReviewAnnotationsTaskApi({
        userId: email,
      }),
      {
        handleAlert: (error: any) => {
          if (!error.isKnownError?.()) {
            toast.info(error.configureKnownErrorDescription(), {
              autoClose: false,
            });
          } else {
            alert(`Message: ${error.message}\nStack: ${error.stack}`);
          }
        },
      }
    );
    const storeId = job.storeId;
    const groupId = job.groupId;
    track(EVENT_START_NEXT_QC_TASK, { storeId, groupId });

    goToReviewAnnotations(history, storeId, groupId);
  };

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        'Next in Queue'
      )}
    </Button>
  );
};

export default function TaskList() {
  const { call, isLoading, data, error } = useAsyncCall<FetchInitialDataRes>();
  const stores = useAppSelector(state => state.stores);

  const history = useHistory();
  const { email } = useAccount();

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    call(fetchInitialData());
  }, []);

  const onClickTaskBasedQcButton = (
    storeId: string,
    groupId: string
  ) => async () => {
    history.push(`/stores/${storeId}/task-based-review-annotations/${groupId}`);
  };

  const onClickTaskBasedContinueButton = (
    storeId: string,
    groupId: string
  ) => () => {
    history.push(`/stores/${storeId}/task-based-editor/${groupId}`);
  };

  const onChangeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  if (data === undefined || isLoading) return <Loader loading />;

  const { jobs, cameraStates } = data;
  const {
    yours: yourInProgressTasks,
    others: othersInProgressTasks,
  } = configureInProgressTasks({
    jobs,
    email,
    stores: Object.values(stores) as Store[],
    cameraStates,
    isQc: false,
  });
  const {
    yours: yourInprogressQcTasks,
    others: othersInprogressQcTasks,
  } = configureInProgressTasks({
    jobs,
    email,
    stores: Object.values(stores) as Store[],
    cameraStates,
    isQc: true,
  });
  const qcQueueLength = configureQcQueueLength({ jobs });

  return (
    <div>
      <h2>Camera Queue</h2>
      <NextAnnotationButton email={email} />
      <h2>My InProgress Tasks</h2>
      {yourInProgressTasks.length > 0 && (
        <MyInProgressTasksContainer>
          {yourInProgressTasks.map(task => {
            const storeId = task.storeId;
            const store = stores[storeId];
            if (store === undefined) return null;
            return (
              <MyInProgressCamogramItem
                key={task.id}
                storeId={storeId}
                groupId={task.groupId}
                storeName={store.name}
                cameraId={configureCameraListLabel(task.providerKey)}
                createdAt={task.createdAt}
                currentTaskName={task.currentTaskName}
                onClickContinueButton={onClickTaskBasedContinueButton(
                  storeId,
                  task.groupId
                )}
              />
            );
          })}
        </MyInProgressTasksContainer>
      )}
      <h2>QC Queue ({qcQueueLength})</h2>
      <NextQcButton email={email} />
      <h2>My InProgress QC Tasks</h2>
      <MyInProgressTasksContainer>
        {yourInprogressQcTasks.map(task => {
          const storeId = task.storeId;
          const store = stores[storeId];
          return (
            <MyInProgressCamogramItem
              key={task.id}
              storeId={storeId}
              groupId={task.groupId}
              storeName={task.storeName}
              cameraId={configureCameraListLabel(task.providerKey)}
              createdAt={task.createdAt}
              createdBy={task.createdBy}
              currentTaskName={task.currentTaskName}
              startedReviewAnnotationsAt={task.startedReviewAnnotationsAt}
              onClickContinueButton={onClickTaskBasedQcButton(
                storeId,
                task.groupId
              )}
            />
          );
        })}
      </MyInProgressTasksContainer>
      <h2>Others</h2>
      <Tabs
        indicatorColor="primary"
        value={tabValue}
        onChange={onChangeTab}
        style={{ borderBottom: '1px solid #e8e8e8' }}
      >
        <Tab style={{ textAlign: 'left' }} label="In Progress" disableRipple />
        <Tab label="Camera Queue" disableRipple />
        <Tab label="QC Tasks" disableRipple />
        <Tab label="Finalized" disableRipple />
      </Tabs>
      <TabPanel index={0} value={tabValue}>
        <InProgressTable inProgressCamogramJobs={othersInProgressTasks} />
      </TabPanel>
      <TabPanel index={1} value={tabValue}>
        <ProviderStatesTable providerStates={cameraStates} />
      </TabPanel>
      <TabPanel index={2} value={tabValue}>
        <InProgressQcTable inProgressQcCamogramJobs={othersInprogressQcTasks} />
      </TabPanel>
      <TabPanel index={3} value={tabValue}>
        <FinalizedCamogramsTable />
      </TabPanel>
    </div>
  );
}
