import api from '.';
import { addNumericCamIdParam } from './helpers';
import { configureListJob } from './taskBasedEditor/helpers';

type StartNextAnnotationApiProps = {
  userId: string;
  workflowVersion: number;
  workflowName: string;
};

export const postStartNextAnnotationApi = async ({
  userId,
  workflowVersion,
  workflowName,
}: StartNextAnnotationApiProps) => {
  const response = await api.post(
    addNumericCamIdParam('/camogram_queue/start_next_annotation'),
    {
      user_id: userId,
      workflow_version: workflowVersion,
      workflow_name: workflowName,
    }
  );
  const result = configureListJob(response.data.results);
  return result;
};
