import { createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';

const Hide = createGlobalStyle`
  .standard-profile {
    display: none;
  }
`;

const Smaller = createGlobalStyle`
  .standard-profile {
    width: 40px;
    height: 40px;
    top: ${({ top }) => top} ;
  }
`;

export default function PortalStyle() {
  const ffpanel = useSelector(state => state.compare?.type);
  const panes = useSelector(state => state.compare.panes);

  if (ffpanel) return <Hide />;

  return <Smaller top="5px" />;
}
