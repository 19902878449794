export const SET_CAMOGRAMS = 'SET_CAMOGRAMS';

export const SET_VISIBLE = 'SET_VISIBLE';
export const INIT_VISIBLE = 'INIT_VISIBLE';

export const SET_CURSOR_MODE = 'SET_CURSOR_MODE';
export const SET_EDITOR_CAM_NUM = 'SET_EDITOR_CAM_NUM';
export const SET_MENU = 'SET_MENU';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';
export const CREATE_EXTREME_ZOOM_IMAGE = 'CREATE_EXTREME_ZOOM_IMAGE';
export const SET_SELECTED_EXTREME_ZOOM_SHELF =
  'SET_SELECTED_EXTREME_ZOOM_SHELF';
export const REMOVE_EXTREME_ZOOM_IMAGE = 'REMOVE_EXTREME_ZOOM_IMAGE';
export const SET_EDITOR_IMAGE_SIZE = 'SET_EDITOR_IMAGE_SIZE';
export const RESET_CURRENT_CAMOGRAM = 'RESET_CURRENT_CAMOGRAM';

// Compare
export const START_COMPARE_CAMOGRAMS = 'START_COMPARE_CAMOGRAMS';
export const START_COMPARE_OMNI_CAMOGRAMS = 'START_COMPARE_OMNI_CAMOGRAMS';
export const START_COMPARE_FF_IMAGES = 'START_COMPARE_FF_IMAGES';
export const START_COMPARE_FF_IMAGES_BY_SECTION =
  'START_COMPARE_FF_IMAGES_BY_SECTION';
export const START_COMPARE_FF_IMAGES_FOR_EXTREME_ZOOM =
  'START_COMPARE_FF_IMAGES_FOR_EXTREME_ZOOM';
export const CLOSE_COMPARE = 'CLOSE_COMPARE';

// Editor
export const ADD_AND_SELECT_PRODUCT_LOCATION =
  'ADD_AND_SELECT_PRODUCT_LOCATION';

// Aside
export const TOGGLE_ASIDE = 'TOGGLE_ASIDE';
