import api from '.';
import { addNumericCamIdParam } from './helpers';

const configureCams = cams => {
  if (!cams) return {};
  const byCamNum = {};
  cams.forEach(camera => {
    byCamNum[camera.provider_key.cam_id] = {
      ...camera,
      cameraId: camera.provider_key.cam_id,
      cam_num: camera.provider_key.cam_id,
    };
  });
  return byCamNum;
};

const fetchGenericCamogramApi = async (storeId, timestamp) => {
  try {
    const response = await api.post(
      addNumericCamIdParam(`stores/${storeId}/generic_camogram/search`),
      {
        timestamp,
        data_source: 'STATIC_HIGH_RES',
        provider_key: {},
      }
    );
    const cams = configureCams(response.data.results.reverse());
    return { cams };
  } catch (error) {
    alert('Failed to fetch camogram', error);
    throw error;
  }
};

export default fetchGenericCamogramApi;
