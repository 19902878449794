import React from 'react';
import styled from 'styled-components';

const LinkGroup = styled.g`
  cursor: pointer;
  rect,
  circle,
  path {
    transition: all 0.1s ease;
    stroke: white;
    stroke-width: 4px;
  }
  &:hover rect,
  &:hover circle,
  &:hover path {
    fill: #f00;
  }
`;

type LinkProps = {
  id: string;
  onClick: (id: string) => void;
};

const Link: React.FC<LinkProps> = ({ id, onClick, children }) => {
  if (onClick) {
    return <LinkGroup onClick={() => onClick(id)}>{children}</LinkGroup>;
  }
  return <>{children}</>;
};

export default Link;
