import { convertToCamelCaseKeys } from 'utils';
import api from '..';
import { CheckinApiRes } from './types';

const getUserCheckinStateApi = async () => {
  const res = await api.get('/camogram_queue/get_user_checkin_state');

  return convertToCamelCaseKeys(res.data.result) as CheckinApiRes;
};

export default getUserCheckinStateApi;
