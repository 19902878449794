import { Tune } from '@material-ui/icons';
import { createContext, useContext, useReducer, useState } from 'react';
import styled from 'styled-components';

import { Slider, IconButton, Popover, Button } from '@material-ui/core';

const filters = {
  brightness: {
    label: 'Brightness',
    defaultValue: 1,
    min: 0.5,
    max: 3,
    step: 0.1,
  },
  contrast: {
    label: 'Contrast',
    defaultValue: 1,
    min: 0.5,
    max: 1.5,
    step: 0.1,
  },
  saturate: {
    label: 'Saturation',
    defaultValue: 1,
    min: 0.5,
    max: 3,
    step: 0.1,
  },
};

const FilterSlider = ({ filterKey }) => {
  const { imageFilter, setImageFilter } = useContext(ImageFilterContext);
  const value = imageFilter[filterKey];
  const onChange = (e, newValue) => {
    setImageFilter(filterKey, newValue);
  };
  const { min, max, defaultValue, step, label } = filters[filterKey];
  return (
    <div>
      <div>{label}</div>
      <Slider
        onChange={onChange}
        valueLabelDisplay="auto"
        value={value}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
      />
    </div>
  );
};

const SlidersContainer = styled.div`
  min-width: 320px;
  padding: 16px;
  > * {
    margin-bottom: 8px;
  }
`;

const ImageFilterSliders = () => {
  const { imageFilter, resetImageFilter } = useContext(ImageFilterContext);
  const filterKeys = Object.keys(imageFilter);
  const onReset = () => {
    resetImageFilter();
  };
  return (
    <SlidersContainer>
      {filterKeys.map(filterKey => (
        <FilterSlider key={filterKey} filterKey={filterKey} />
      ))}
      <Button variant="contained" color="primary" onClick={onReset}>
        Reset
      </Button>
    </SlidersContainer>
  );
};

export const ImageFilterToggle = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton aria-label="Adjust" onClick={handleClick} color="inherit">
        <Tune fontSize="small" />
      </IconButton>
      <Popover open={open} anchorEl={anchorEl} onClose={onClose}>
        <ImageFilterSliders />
      </Popover>
    </>
  );
};

export const ImageFilterContext = createContext();
const SET_IMAGE_FILTER = 'SET_IMAGE_FILTER';
const RESET_IMAGE_FILTER = 'RESET_IMAGE_FILTER';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_IMAGE_FILTER:
      return { ...state, [payload.key]: payload.value };
    case RESET_IMAGE_FILTER:
      return initialState;
    default:
      return state;
  }
};
const initialState = {
  brightness: 1,
  contrast: 1,
  saturate: 1,
};
export const ImageFilterProvider = ({ children }) => {
  const [imageFilter, dispatch] = useReducer(reducer, initialState);

  const setImageFilter = (key, value) => {
    dispatch({ type: SET_IMAGE_FILTER, payload: { key, value } });
  };

  const resetImageFilter = () => {
    dispatch({ type: RESET_IMAGE_FILTER });
  };

  return (
    <ImageFilterContext.Provider
      value={{
        imageFilter,
        setImageFilter,
        resetImageFilter,
      }}
    >
      {children}
    </ImageFilterContext.Provider>
  );
};

export default ImageFilterSliders;
