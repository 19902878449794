import { createContext, useContext } from 'react';
import { HighresCamera, HighresActiveRegion, Layout } from 'types';

type State = {
  cameras: HighresCamera[];
  activeRegions: HighresActiveRegion[];
  layout: Layout;
};
const HighresCameraSettingsContext = createContext<State | undefined>(
  undefined
);

export const HighresCameraSettingsProvider: React.FC<State> = ({
  children,
  ...props
}) => {
  return (
    <HighresCameraSettingsContext.Provider value={props}>
      {children}
    </HighresCameraSettingsContext.Provider>
  );
};

export const useHighresCameraSettingContext = () => {
  const context = useContext(HighresCameraSettingsContext);

  if (context === undefined) {
    throw new Error(
      'useHighresCameraSettingContext must be used within a HighresCameraSettingsProvider'
    );
  }

  return context;
};
