import _ from 'lodash';

const uvToPoint = ({ u, v }, { width, height }) => {
  return [u * width, v * height];
};

const usToBoundingBox = (regions, dimensions) => {
  const points: number[][] = regions.map(uv => uvToPoint(uv, dimensions));
  const maxX = _.max(points.map(point => point[0])) as number;
  const minX = _.min(points.map(point => point[0])) as number;
  const maxY = _.max(points.map(point => point[1])) as number;
  const minY = _.min(points.map(point => point[1])) as number;
  const boundingBox = {
    maxX,
    minX,
    maxY,
    minY,
  };

  for (const point of points) {
    if (point[0] < boundingBox.minX) {
      boundingBox.minX = point[0];
    }

    if (point[0] > boundingBox.maxX) {
      boundingBox.maxX = point[0];
    }

    if (point[1] < boundingBox.minY) {
      boundingBox.minY = point[1];
    }

    if (point[1] > boundingBox.maxY) {
      boundingBox.maxY = point[1];
    }
  }

  return boundingBox;
};

const calculateRenderedImageDimensions = (
  imageDimensions,
  containerDimensions
) => {
  const aspectRatio = imageDimensions.width / imageDimensions.height;
  const width = containerDimensions.width;
  const height = containerDimensions.width / aspectRatio;

  if (height <= containerDimensions.height) {
    return {
      width,
      height,
    };
  }

  // container is not tall enough to use full width
  return {
    width: containerDimensions.height * aspectRatio,
    height: containerDimensions.height,
  };
};

const calculateDistance = (point1, point2) => {
  const x = Math.abs(point1[0] - point2[0]);
  const y = Math.abs(point1[1] - point2[1]);

  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
};

export const calculateLocationPosition = (
  region,
  rotation,
  imageDimensions,
  containerDimensions,
  zoomToLocation
) => {
  if (!region || !imageDimensions) {
    return null;
  }

  const renderedImageDimensions = calculateRenderedImageDimensions(
    imageDimensions,
    containerDimensions
  );

  const boundingBox = usToBoundingBox(region, imageDimensions);
  const points = region.map(uv => uvToPoint(uv, imageDimensions));
  const height = calculateDistance(points[0], points[1]);
  const width = calculateDistance(points[1], points[2]);
  const xWidth = Math.abs(
    Math.max(...points.map(point => point[0])) -
      Math.min(...points.map(point => point[0]))
  );
  const yHeight = Math.abs(
    Math.max(...points.map(point => point[1])) -
      Math.min(...points.map(point => point[1]))
  );

  // Translate location to center of bounding box
  const translate = {
    x: (boundingBox.maxX - boundingBox.minX - width) / 2,
    y: (boundingBox.maxY - boundingBox.minY - height) / 2,
  };

  // When calculating scale, check all dimensions against each other and find
  // the minimum zoom factor since locations can be rotated 89.9 degrees.
  const zoomScale = zoomToLocation
    ? Math.min(
        containerDimensions.width / xWidth,
        containerDimensions.height / yHeight,
        containerDimensions.width / yHeight,
        containerDimensions.height / xWidth
      ) * 0.7
    : Math.min(
        renderedImageDimensions.width / imageDimensions.width,
        renderedImageDimensions.height / imageDimensions.height
      );
  const minPadding =
    Math.max(
      boundingBox.maxX - boundingBox.minX,
      boundingBox.maxY - boundingBox.minY
    ) * 0.05;
  const centerOffset = {
    width: Math.max(
      (containerDimensions.width / zoomScale -
        (boundingBox.maxX - boundingBox.minX)) /
        2,
      minPadding
    ),
    height: Math.max(
      (containerDimensions.height / zoomScale -
        (boundingBox.maxY - boundingBox.minY)) /
        2,
      minPadding
    ),
  };
  const zoomTranslate = {
    x: zoomToLocation ? -boundingBox.minX + centerOffset.width : 0,
    y: zoomToLocation ? -boundingBox.minY + centerOffset.height : 0,
  };

  return {
    boundingBox,
    width,
    height,
    rotation,
    translate,
    zoomScale,
    zoomTranslate,
    imageDimensions,
  };
};

export const calcIsRotated = angle => [90, 270].includes(Math.abs(angle));
