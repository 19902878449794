import _ from 'lodash';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { configureCamerasInCamogramList, useFilters } from '../helpers';
import { formatDatetime } from 'utils/formatDatetime';
import { EditorDataSource } from 'types';
import { useEffect } from 'react';
import {
  fetchNewEditorCamerasApi,
  fetchStoreCamogramsApi,
  fetchStoreHighResCamogramsApi,
} from 'utils/api';
const getDataSource = camogram => camogram.data_source;
import { configureCameraImages } from 'utils';
import GeneralTable from './GeneralTable';
import useAsyncCall from 'hooks/useAsyncCall';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import StoreSelector from './StoreSelector';
import Flex from 'components/Flex';
import { InProgressCamogramJob } from './InProgressTable';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 16px;
  }
`;

const configureTableData = (
  camograms: any[],
  onClickOpen: (editorType: EditorDataSource, id: string) => void
) => {
  const keys = [
    'createdAt',
    'startedBy',
    'approvedAt',
    'approvedBy',
    'cameras',
    'data_source',
    'open',
  ];
  const labels = {
    createdAt: 'Created At',
    startedBy: 'Started By',
    approvedAt: 'Approved At',
    approvedBy: 'Approved By',
    cameras: 'Cameras',
    data_source: 'Data Source',
    open: 'Open',
  };
  const items = camograms.map(camogram => ({
    key: camogram.rockset_created_at || camogram.created_at,
    createdAt: formatDatetime(
      camogram.rockset_created_at || camogram.created_at
    ),
    startedBy: camogram.started_by,
    approvedAt: formatDatetime(camogram.approved_at),
    approvedBy: camogram.approved_by ?? 'None',
    cameras: configureCamerasInCamogramList(camogram.cameraIntegers),
    data_source: getDataSource(camogram),
    open: (
      <Button
        onClick={() =>
          onClickOpen(
            getDataSource(camogram),
            camogram.rockset_created_at || camogram.created_at
          )
        }
        variant="contained"
        size="small"
      >
        <Launch style={{ paddingRight: '6px' }} /> Open
      </Button>
    ),
  }));

  return { keys, labels, items };
};

const fetchHistoricalCamograms = async (storeId: string) => {
  const [
    regularCamogramsResponse,
    highResCamogramsResponse,
    cameras,
  ] = await Promise.all([
    fetchStoreCamogramsApi({ storeId }),
    fetchStoreHighResCamogramsApi({ storeId }),
    fetchNewEditorCamerasApi(storeId),
  ]);

  const mergedCamograms = [
    ...regularCamogramsResponse,
    // Deduplicate high res camograms by created_at timestamp
    ..._.uniqBy(highResCamogramsResponse, 'created_at'),
  ].sort((a, b) => {
    const date1 = new Date(b.rockset_created_at || b.created_at);
    const date2 = new Date(a.rockset_created_at || a.created_at);
    return date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
  });
  const withCameraInteger = mergedCamograms.map(camogram => {
    const providerKeys = camogram.provider_keys
      ? _.mapValues(
          _.groupBy(
            camogram.provider_keys,
            provider_key => provider_key.cam_id
          ),
          val => val[0]
        )
      : {};
    const cameraIntegers = Object.values(
      configureCameraImages(providerKeys, cameras)
    ).map(providerKey => providerKey.cameraInteger);
    return { ...camogram, cameraIntegers };
  });

  return withCameraInteger;
};

export default function FinalizedCamogramsTable() {
  const history = useHistory();
  const { call, data, isLoading } = useAsyncCall<any[]>();

  const {
    selectedStoreId,
    handleChangeStoreId,
    userFilterText,
    cameraId,
    handleChangeCameraId,
    handleChangeUserFilterText,
    filtered,
  } = useFilters<InProgressCamogramJob>(data ?? []);

  const openCamogramView = (editorType: EditorDataSource, id: string) => {
    if (editorType === EditorDataSource.Omni) {
      history.push(`/stores/${selectedStoreId}/omni-camograms/${id}`);
    } else if (editorType === EditorDataSource.StaticHighRes) {
      history.push(`/stores/${selectedStoreId}/highres-camograms/${id}`);
    } else {
      history.push(`/stores/${selectedStoreId}/camograms/${id}`);
    }
  };

  useEffect(() => {
    if (selectedStoreId) {
      call(fetchHistoricalCamograms(selectedStoreId));
    }
  }, [selectedStoreId]);
  const shouldDisplayTable = data !== undefined && !isLoading;
  return (
    <Container>
      <Flex itemSpace={16} alignItems="flex-end">
        <StoreSelector
          handleChange={handleChangeStoreId}
          value={selectedStoreId}
          disableEmpty
        />
        <TextField
          label="Filter User"
          value={userFilterText}
          onChange={handleChangeUserFilterText}
        />
        <TextField
          label="Filter Camera"
          value={cameraId}
          onChange={handleChangeCameraId}
        />
      </Flex>
      {shouldDisplayTable ? (
        <GeneralTable {...configureTableData(filtered, openCamogramView)} />
      ) : (
        <div>Please choose store</div>
      )}
      {isLoading && <CircularProgress />}
    </Container>
  );
}
