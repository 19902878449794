import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import ActionDialog from 'components/ActionDialog';
import Backdrop from './Backdrop';
import deleteGroupJobsApi from 'utils/api/taskBasedEditor/deleteGroupJobsApi';
import useAsyncCall from 'hooks/useAsyncCall';

type DeleteDialogProps = {
  groupId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchJobs: () => void;
};

function DeleteDialog({
  groupId,
  open,
  setOpen,
  fetchJobs,
}: DeleteDialogProps) {
  const { isLoading, call } = useAsyncCall();
  const title = 'Delete the Group?';
  const text =
    'Deleting the group will remove the uncompleted jobs and it cannot be undone.';

  const onClickOk = async () => {
    await toast.promise(call(deleteGroupJobsApi({ groupId })), {
      pending: 'Deleting...',
      success: 'The group has been deleted!',
      error: 'Could not delete.',
    });
    await fetchJobs();
  };

  return (
    <>
      {/*@ts-ignore */}
      <ActionDialog
        open={open}
        setOpen={setOpen}
        title={title}
        text={text}
        cancelable
        actions={[
          { label: 'Delete camogram', color: 'secondary', onClick: onClickOk },
        ]}
      />
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

type DeleteTaskBasedCamogramButtonProps = {
  groupId: string;
  fetchJobs: () => void;
};

export default function DeleteTaskBasedCamogramButton({
  groupId,
  fetchJobs,
}: DeleteTaskBasedCamogramButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const onClick = () => {
    setOpenDialog(true);
  };
  return (
    <>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        groupId={groupId}
        fetchJobs={fetchJobs}
      />
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onClick}
      >
        <Delete style={{ paddingRight: '6px' }} /> Delete
      </Button>
    </>
  );
}
