import { Rect, Stage as StageRK, Layer } from 'react-konva';
import styled from 'styled-components';

import Polygon from 'components/Editor/Polygon';
import { HighresActiveSection } from 'types';
import { convertRegionToPoints } from 'utils';

const Stage = styled(StageRK)`
  position: absolute;
  left: 0;
  top: 0;
  cursor: ${({ draw }) => (draw ? 'crosshair' : 'auto')};
  z-index: 10;
  pointer-events: none;
`;

type ActiveShelfsProps = {
  width: number;
  height: number;
  angle: number;
  activeShelfs: HighresActiveSection[];
};

export default function ActiveShelfs({
  width,
  height,
  angle,
  activeShelfs,
}: ActiveShelfsProps) {
  return (
    <Stage width={width} height={height}>
      <Layer>
        <Rect
          fill="#FF713355"
          x={0}
          y={0}
          width={width}
          height={height}
          listening={false}
        />
        {activeShelfs.map(({ camogramSectionId, coordinates }) => (
          // @ts-ignore
          <Polygon
            key={camogramSectionId}
            points={convertRegionToPoints(coordinates, width, height, angle)}
            closed
            globalCompositeOperation="destination-out"
            fill="red"
            listening={false}
          />
        ))}
      </Layer>
    </Stage>
  );
}
