import api from '.';

const configureLegacySectionId = (sectionId: string) =>
  sectionId.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

const configureCamogramSectionId = (
  shelfId: string,
  sectionId: string,
  storeId: string
) =>
  `${storeId}_shelf_${shelfId}_section_${configureLegacySectionId(sectionId)}`;
const configureLegacyShelfId = (storeId: string, shelfId: string) =>
  `${storeId}_shelf_${shelfId}`;
const configureCoordinates = (rawGeometry: number[][]) =>
  rawGeometry.map(geometry => ({
    x: geometry[0] * 100,
    y: geometry[1] * 100,
    z: geometry[2] * 100,
  }));

const configureObject = (raw: any) => ({
  coordinates: configureCoordinates(raw.geometry),
});

const configurePerimeter = (perimeter: any) => ({
  coordinates: configureCoordinates(perimeter.geometry),
  height: perimeter.height * 100,
});

const configureLayout = (rawLayout: any, storeId: string) => {
  const { exits, perimeters, shelves } = rawLayout;

  return {
    exits: exits.map((exit: any) => configureObject(exit)),
    perimeters: perimeters.map((perimeter: any) =>
      configurePerimeter(perimeter)
    ),
    shelves: shelves.map((shelf: any) => ({
      coordinates: configureCoordinates(shelf.shelf_bounds.vertices),
      id: shelf.shelf_id,
      legacyId: configureLegacyShelfId(storeId, shelf.shelf_id),
      depth: shelf.depth * 100,
    })),
    sections: shelves.flatMap(({ sections, shelf_id }: any) => {
      return sections.map((section: any) => ({
        coordinates: configureCoordinates(section.section_bounds.vertices),
        id: section.section_id,
        legacyId: configureLegacySectionId(section.section_id),
        shelfId: shelf_id,
        legacyShelfId: configureLegacyShelfId(storeId, shelf_id),
        camogramSectionId: configureCamogramSectionId(
          shelf_id,
          section.section_id,
          storeId
        ),
      }));
    }),
  };
};

export default async function fetchLayoutApi({ storeId }: { storeId: string }) {
  const layout = (await api.get(`stores/${storeId}/layout?layout_version=1`))
    .data.results;

  const result = configureLayout(layout, storeId);

  return result;
}
