import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Panel from 'components/Panel';
import { MapInteractionCSS } from 'react-map-interaction';
import {
  Cancel,
  OpenInBrowser,
  ZoomIn,
  ZoomOut,
  Delete,
  Refresh,
} from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import { ContainerWidthContext } from './ExtremeZoomImagesComponent';
import useHold from 'hooks/useHold';
import { DEFAULT_CAMERA_IMAGE_ASPECT_RATIO } from 'common/constants';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Loader from 'components/Loader';
import { fetchExtremeZoomImageApi } from 'utils/api';
import {
  extremeZoomImageRequestStart,
  removeExtremeZoomImage,
  updateExtremeZoomImage,
} from 'actions';

const ToolbarContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  width: 100%;
`;

const ToolbarControls = styled.div`
  > * {
    margin: 0 0.3rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: #444;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const Image = styled.img`
  object-fit: contain;
`;

const ItemNumber = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border 2px black solid;
  background-color: orange;
  color: black;
  text-align: center;
  font-size: 14px;
  flex-shrink: 0;
  line-height: 18px;
  margin-right: 80px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const defaultZoom = {
  scale: 1,
  translation: { x: 0, y: 0 },
};

const ZOOM_INTERVAL = 0.3;
const Toolbar = ({ setZoom, extremeZoomImage, itemNumber }) => {
  const dispatch = useDispatch();
  const onZoomIn = () => {
    setZoom(prev => {
      const scale = prev.scale + ZOOM_INTERVAL;
      const ratio = scale / prev.scale;
      return {
        translation: {
          x: Math.floor(prev.translation.x * ratio),
          y: Math.floor(prev.translation.y * ratio),
        },
        scale,
      };
    });
  };
  const onZoomOut = () => {
    setZoom(prev => {
      const scale = Math.max(prev.scale - ZOOM_INTERVAL, ZOOM_INTERVAL);
      const ratio = scale / prev.scale;
      return {
        translation: {
          x: prev.translation.x * ratio,
          y: prev.translation.y * ratio,
        },
        scale,
      };
    });
  };

  const zoomInEvents = useHold(onZoomIn);
  const zoomOutEvents = useHold(onZoomOut);
  return (
    <ToolbarContainer>
      <ItemNumber>{itemNumber}</ItemNumber>
      <ToolbarControls>
        <IconButton
          color="inherit"
          size="small"
          title="Zoom Out"
          disabled={!extremeZoomImage.url}
          {...zoomOutEvents}
        >
          <ZoomOut />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          title="Zoom In"
          disabled={!extremeZoomImage.url}
          {...zoomInEvents}
        >
          <ZoomIn />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          title="Refresh Image"
          onClick={() =>
            dispatch(
              extremeZoomImageRequestStart({
                storeId: extremeZoomImage.storeId,
                shelfId: extremeZoomImage.shelfId,
                sectionId: extremeZoomImage.sectionId,
                point: extremeZoomImage.point,
              })
            )
          }
          disabled={!extremeZoomImage.url}
        >
          <Refresh />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          title="Remove Image"
          onClick={() => {
            dispatch(removeExtremeZoomImage(extremeZoomImage));
          }}
          disabled={!extremeZoomImage.url}
        >
          <Delete />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          title="Open image in new tab"
          onClick={() => window.open(extremeZoomImage.url)}
          disabled={!extremeZoomImage.url}
        >
          <OpenInBrowser />
        </IconButton>
      </ToolbarControls>
    </ToolbarContainer>
  );
};

const ExtremeZoomImage = ({ extremeZoomImage, itemNumber }) => {
  const [zoom, setZoom] = useState(defaultZoom);
  const containerWidth = useContext(ContainerWidthContext);
  const dispatch = useDispatch();

  const width = containerWidth - 16;
  const height = width * DEFAULT_CAMERA_IMAGE_ASPECT_RATIO;
  const onDoubleClick = e => {
    const scale = 3;
    const x = width / scale - scale * e.nativeEvent.layerX;
    const y = height / scale - scale * e.nativeEvent.layerY;

    setZoom(prev => {
      if (prev.scale === defaultZoom.scale) {
        return {
          translation: { x, y },
          scale,
        };
      } else {
        return defaultZoom;
      }
    });
  };

  const requestExtremeZoomImage = useCallback(
    async ({ point, storeId, shelfId, sectionId, captureUUID }) => {
      try {
        const response = await fetchExtremeZoomImageApi({
          captureUUID,
          storeId,
          shelfId,
          sectionId,
          point,
        });

        if (response.capture_uuid) {
          captureUUID = response.capture_uuid;
        }

        if (response.signed_image_url !== undefined) {
          return dispatch(
            updateExtremeZoomImage({
              point,
              storeId,
              shelfId,
              sectionId,
              url: response.signed_image_url,
            })
          );
        } else if (captureUUID !== undefined) {
          // Poll for completion every 5 seconds
          const pollTimeout = setTimeout(
            () =>
              requestExtremeZoomImage({
                point,
                storeId,
                shelfId,
                sectionId,
                captureUUID,
              }),
            5000
          );

          return dispatch(
            updateExtremeZoomImage({
              point,
              storeId,
              shelfId,
              sectionId,
              pollTimeout,
            })
          );
        }

        throw new Error('Unexpected ptz_image response', response);
      } catch (error) {
        console.error(error);
        toast('Error fetching extreme zoom image', { type: 'error' });
      }
    },
    [dispatch]
  );

  const cancelExtremeZoomImageRequest = extremeZoomImage => {
    if (extremeZoomImage.pollTimeout) {
      clearTimeout(extremeZoomImage.pollTimeout);
      dispatch(removeExtremeZoomImage(extremeZoomImage));
    }
  };

  useEffect(() => {
    if (!extremeZoomImage.url && !extremeZoomImage.pollTimeout) {
      requestExtremeZoomImage({
        point: extremeZoomImage.point,
        storeId: extremeZoomImage.storeId,
        shelfId: extremeZoomImage.shelfId,
        sectionId: extremeZoomImage.sectionId,
      });
    }
  }, [extremeZoomImage, requestExtremeZoomImage]);

  return (
    <Panel
      toolBar={
        <Toolbar
          setZoom={setZoom}
          extremeZoomImage={extremeZoomImage}
          itemNumber={itemNumber}
        />
      }
    >
      <Loader
        loading={!extremeZoomImage.url}
        full={false}
        text="Extreme zoom request added to camera queue"
      >
        <ImageContainer>
          <MapInteractionCSS
            minScale={0.9}
            value={zoom}
            onChange={setZoom}
            disableZoom
          >
            <Image
              src={extremeZoomImage.url}
              alt="camera_image"
              width={width}
              height={height}
              onDoubleClick={onDoubleClick}
            />
          </MapInteractionCSS>
        </ImageContainer>
      </Loader>
      {!extremeZoomImage.url && (
        <ButtonContainer>
          <Button
            variant="contained"
            startIcon={<Cancel />}
            size="small"
            color="secondary"
            onClick={() => cancelExtremeZoomImageRequest(extremeZoomImage)}
          >
            Cancel
          </Button>
        </ButtonContainer>
      )}
    </Panel>
  );
};

export default ExtremeZoomImage;
