import React from 'react';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import { useSelector } from 'react-redux';

const StoreSelector = () => {
  const { storeId } = useParams();
  const history = useHistory();
  const stores = useSelector(state => Object.values(state.stores));

  const onChangeStore = e => {
    history.push(`/stores/${e.target.value}`);
  };

  return (
    <Loader loader={!stores} text="Loading Stores">
      <div>
        <InputLabel>Store</InputLabel>
        <Select value={storeId} onChange={onChangeStore} fullWidth>
          {stores.map(store => (
            <MenuItem key={store.id} value={store.id}>
              {store.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Loader>
  );
};

export default StoreSelector;
