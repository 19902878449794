import moment from 'moment';
import { EditorDataSource } from 'types';

import api from '..';
import { addNumericCamIdParam } from '../helpers';

const configureProviderKey = raw => ({
  dataSource: EditorDataSource.StaticHighRes,
  cameraId: raw.cam_id,
});

const fetchStoreHighResCamogramsApi = async ({ storeId }) => {
  // Only fetch camograms from past two weeks
  const start = moment().subtract(14, 'days').toISOString();
  const res = await api.get(
    addNumericCamIdParam(
      `stores/${storeId}/generic_camogram/historical?start=${start}&data_source=${EditorDataSource.StaticHighRes}`
    )
  );

  const camograms = res.data.results.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return camograms.map(camogram => ({
    ...camogram,
    providerKeys: camogram.provider_keys.map(configureProviderKey),
  }));
};

export default fetchStoreHighResCamogramsApi;
