import { combineReducers } from 'redux';

import {
  CREATE_EXTREME_ZOOM_IMAGE,
  REMOVE_EXTREME_ZOOM_IMAGE,
  SET_SELECTED_EXTREME_ZOOM_SHELF,
} from 'actions/types';

const extremeZoomImages = (state = { images: [] }, { type, payload }) => {
  if (type === CREATE_EXTREME_ZOOM_IMAGE) {
    const { point, url, storeId, shelfId, sectionId, pollTimeout } = payload;
    const extremeZoomId = [storeId, shelfId, sectionId, ...point].join('-');

    const newPoint = {
      id: extremeZoomId,
      point,
      url,
      storeId,
      shelfId,
      sectionId,
      pollTimeout,
    };

    let imageIndex = state.images.findIndex(item => item.id === extremeZoomId);

    // If not present, add new extremeZoomImage
    if (imageIndex === -1) {
      return { ...state, images: [...state.images, newPoint] };
    }

    // Otherwise, update existing extremeZoomImage
    const images = state.images.map((image, index) => {
      if (index !== imageIndex) {
        return image;
      }
      return newPoint;
    });

    return { ...state, images };
  }

  if (type === SET_SELECTED_EXTREME_ZOOM_SHELF) {
    const { selectedShelf } = payload;
    return { ...state, selectedShelf };
  }

  if (type === REMOVE_EXTREME_ZOOM_IMAGE) {
    const { point, storeId, shelfId, sectionId } = payload;
    const extremeZoomId = [storeId, shelfId, sectionId, ...point].join('-');

    const images = state.images.filter(image => image.id !== extremeZoomId);

    return { ...state, images };
  }

  return state;
};

const reducers = combineReducers({
  extremeZoomImages,
});

export default reducers;
