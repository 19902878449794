import styled from 'styled-components';
import { WarningOutlined } from '@material-ui/icons';

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  padding: 20px;
  background: #ff9800;
  border-radius: 5px;
  border: 1px solid #945800;
  color: #4a2c01;
`;

const Warning = ({ message }) => {
  return (
    <WarningContainer>
      <WarningOutlined fontSize="large" />
      <h3 style={{ margin: '0', paddingLeft: '10px' }}>
        {message || 'Warning'}
      </h3>
    </WarningContainer>
  );
};

export default Warning;
