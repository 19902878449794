import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import Main from 'layouts/Main';
import CamerasLayerToggle from 'components/CameraToggles';
import LogoutButton from 'components/LogoutButton';
import StoreSelector from 'components/StoreSelector';
import CamogramsContainer from './CamogramsContainer';
import Header from './Header';
import ContentLayout from 'layouts/ContentLayout';
import CamogramsSelector from 'components/CamogramSelector';
import {
  ANNOTATION_TYPE_HIGH_RES,
  ANNOTATION_TYPE_OMNI,
} from 'common/constants';

const AsideContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const AsideInner = styled.div`
  > * {
    margin-bottom: 1.2rem;
  }
`;

const CamogramsSelectorContainer = () => {
  const history = useHistory();
  const { storeId, datetime } = useParams();
  const onSelect = camogram => {
    if (camogram.type === ANNOTATION_TYPE_OMNI) {
      history.push(
        `/stores/${storeId}/omni-camograms/${camogram.rockset_created_at}`
      );
    } else if (camogram.type === ANNOTATION_TYPE_HIGH_RES) {
      history.push(
        `/stores/${storeId}/highres-camograms/${camogram.rockset_created_at}`
      );
    } else {
      history.push(
        `/stores/${storeId}/camograms/${camogram.rockset_created_at}`
      );
    }
  };

  return (
    <CamogramsSelector
      storeId={storeId}
      datetime={datetime}
      onSelect={onSelect}
    />
  );
};

const Aside = () => {
  return (
    <AsideContainer>
      <AsideInner>
        <Button to="/" component={NavLink}>
          ← Home
        </Button>
        <StoreSelector />
        <CamogramsSelectorContainer />
        <CamerasLayerToggle />
      </AsideInner>
      <LogoutButton />
    </AsideContainer>
  );
};

const Camogram = () => {
  const { storeId, datetime } = useParams();
  return (
    <Main aside={<Aside />}>
      <ContentLayout header={<Header storeId={storeId} datetime={datetime} />}>
        <CamogramsContainer />
      </ContentLayout>
    </Main>
  );
};

export default Camogram;
