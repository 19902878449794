import { useState } from 'react';
import { ProviderKey } from 'types';
import { useStoreSelector } from './components/StoreSelector';

export const configureCamerasInCamogramList = (cameraIntegers: string[]) => {
  if (cameraIntegers.length === 0) {
    // Old camogram which is for all cameras doesn't have cameraIntegers
    return 'All';
  } else {
    return cameraIntegers.join(', ');
  }
};

const filterJobs = <T extends RawData>({
  jobs,
  storeId,
  userFilterText,
  cameraId,
}: {
  jobs: T[];
  storeId?: string;
  userFilterText?: string;
  cameraId?: string;
}) =>
  jobs.filter(job => {
    let result = true;
    const {
      createdBy,
      startedReviewAnnotationsBy,
      storeId: jobStoreId,
      providerKey,
    } = job;
    const existUserFilterText =
      userFilterText !== undefined && userFilterText !== '';
    if (createdBy && existUserFilterText) {
      result =
        result &&
        createdBy.toLowerCase().includes(userFilterText.toLowerCase());
    }

    if (startedReviewAnnotationsBy && existUserFilterText) {
      result =
        result &&
        startedReviewAnnotationsBy
          .toLowerCase()
          .includes(userFilterText.toLowerCase());
    }

    if (jobStoreId && storeId) {
      result = result && jobStoreId === storeId;
    }
    if (providerKey && cameraId) {
      result = result && providerKey.cameraId === cameraId;
    }
    return result;
  });

interface RawData {
  storeId?: string;
  createdBy?: string;
  startedReviewAnnotationsBy?: string;
  providerKey?: ProviderKey;
}

export const useFilters = <T extends RawData>(allData: T[]) => {
  const { selectedStoreId, handleChangeStoreId } = useStoreSelector();
  const [userFilterText, setUserFilterText] = useState('');
  const [cameraId, setCameraId] = useState('');

  const handleChangeUserFilterText = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserFilterText(e.target.value);
  };

  const handleChangeCameraId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCameraId(e.target.value);
  };
  const filtered = filterJobs({
    jobs: allData,
    storeId: selectedStoreId,
    userFilterText,
    cameraId,
  });

  return {
    filtered,
    selectedStoreId,
    userFilterText,
    cameraId,
    handleChangeStoreId,
    handleChangeUserFilterText,
    handleChangeCameraId,
  };
};
