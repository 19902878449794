import { createContext, useContext } from 'react';
import { Dimensions, Zoom } from 'types';

type State = {
  imageSize?: Dimensions;
};

const CamogramLocationsContext = createContext<State | undefined>(undefined);

export const CamogramLocationsProvider: React.FC<State> = ({
  children,
  ...props
}) => {
  return (
    <CamogramLocationsContext.Provider value={props}>
      {children}
    </CamogramLocationsContext.Provider>
  );
};

export const useCamogramLocationsContext = () => {
  const context = useContext(CamogramLocationsContext);

  if (context === undefined) {
    throw new Error(
      'useCamogramLocationsContext must be used within a CamogramLocationsProvider'
    );
  }

  return context;
};
