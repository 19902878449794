import React from 'react';
import { useParams } from 'react-router-dom';

import OmniCamogramDetail from 'components/OmniCamogramDetail';

const CamogramsContainer = () => {
  const { storeId, datetime } = useParams();
  return <OmniCamogramDetail storeId={storeId} datetime={datetime} />;
};

export default CamogramsContainer;
