import _ from 'lodash';

import {
  CamogramJob,
  LocationReviewStatus,
  LocationToReview,
  TaskType,
  Prediction,
  JobContextLocationKeys,
  LocationToReviewWithSku,
} from 'types/taskBased';
import { Coordinate, Location } from 'types';

import { groupByUniqueKey } from 'utils';
import { UNKNOWN_SKU_BARCODE } from 'common/constants';

export const configureTaskAnnotation = ({
  job,
  locationsToReview,
}: {
  job: CamogramJob;
  locationsToReview: LocationToReview[];
}) => {
  switch (job.currentTaskName) {
    case TaskType.SelectUnchangedPreviousLocations: {
      const unchanged_previous_location_selections = locationsToReview
        .filter(({ status }) => status === LocationReviewStatus.Unchanged)
        .map(({ id }) => id);

      const previousLocationsReviewed =
        job.context.previousLocationsReviewedForChanges;

      // Locations reviewed by an annotator
      const previous_locations_reviewed_for_changes = _.uniq(
        locationsToReview
          .filter(({ status, id }) => {
            return (
              status !== LocationReviewStatus.Init &&
              (!job.context.initialUnchangedPreviousLocationSelections.includes(
                id
              ) ||
                status === LocationReviewStatus.Changed)
            );
          })
          .map(({ id }) => id)
          .concat(previousLocationsReviewed)
      );

      return {
        unchanged_previous_location_selections,
        previous_locations_reviewed_for_changes,
      };
    }
    case TaskType.SelectLocalizedPreviousLocations: {
      const localized_previous_location_selections = locationsToReview
        .filter(({ status }) => status === LocationReviewStatus.UseLocation)
        .map(({ id }) => id);
      const previous_locations_reviewed_for_localization = locationsToReview
        .filter(({ status }) => status !== LocationReviewStatus.Init)
        .map(({ id }) => id);
      return {
        localized_previous_location_selections,
        previous_locations_reviewed_for_localization,
      };
    }
    case TaskType.RoutePreviousLocations: {
      const reviewedLocations = locationsToReview.filter(
        ({ status }) => status !== LocationReviewStatus.Init
      );
      const previous_location_route_tags = _.mapValues(
        groupByUniqueKey(reviewedLocations, 'id'),
        ({ status }) => status
      );
      const previous_locations_reviewed_for_route = reviewedLocations.map(
        ({ id }) => id
      );
      const previous_location_flags = _.mapValues(
        groupByUniqueKey(reviewedLocations, 'id'),
        ({ isEmpty, isGroup, isOccluded, isUncertain }) => ({
          is_empty: isEmpty,
          is_group: isGroup,
          is_occluded: isOccluded,
          is_uncertain: isUncertain,
        })
      );
      return {
        previous_location_route_tags,
        previous_locations_reviewed_for_route,
        previous_location_flags,
      };
    }
    case TaskType.ReshapePreviousLocations: {
      const reshaped_previous_locations = _.mapValues(
        groupByUniqueKey(locationsToReview, 'id'),
        ({
          skuLabel,
          skuId,
          isOccluded,
          isGroup,
          isUncertain,
          isEmpty,
          region,
          id,
        }) => ({
          id,
          sku_id: skuId,
          sku_label: skuLabel,
          is_occluded: isOccluded,
          is_group: isGroup,
          is_uncertain: isUncertain,
          is_empty: isEmpty,
          region,
        })
      );
      const previous_locations_reviewed_for_reshape = locationsToReview
        .filter(({ status }) => status !== LocationReviewStatus.Init)
        .map(({ id }) => id);

      return {
        reshaped_previous_locations,
        previous_locations_reviewed_for_reshape,
      };
    }
    case TaskType.SelectLocalizedProposedLocations: {
      const localized_proposed_location_selections = locationsToReview
        .filter(({ status }) => status === LocationReviewStatus.UseLocation)
        .map(({ id }) => id);
      const proposed_locations_reviewed_for_localization = locationsToReview
        .filter(({ status }) => status !== LocationReviewStatus.Init)
        .map(({ id }) => id);
      return {
        localized_proposed_location_selections,
        proposed_locations_reviewed_for_localization,
      };
    }
    case TaskType.CreateLocations: {
      const created_locations = _.mapValues(
        groupByUniqueKey(locationsToReview, 'id'),
        ({ id, region }) => ({ id, region })
      );

      return {
        created_locations,
      };
    }
    case TaskType.ClassifyLocations: {
      const filtered = locationsToReview.filter(
        ({ skuId }) => skuId !== undefined
      );
      const classifications = _.mapValues(
        groupByUniqueKey(filtered, 'id'),
        ({ skuLabel, skuId, isOccluded, isGroup, isUncertain, isEmpty }) => ({
          sku_id: skuId,
          sku_label: skuLabel,
          is_occluded: isOccluded,
          is_group: isGroup,
          is_uncertain: isUncertain,
          is_empty: isEmpty,
        })
      );
      const unchanged = _.mapValues(
        job.context.unchangedPreviousLocations,
        ({ skuId, skuLabel, isOccluded, isGroup, isUncertain, isEmpty }) => ({
          sku_id: skuId,
          sku_label: skuLabel,
          is_occluded: isOccluded,
          is_group: isGroup,
          is_uncertain: isUncertain,
          is_empty: isEmpty,
        })
      );
      const reshaped = _.mapValues(
        job.context.reshapedPreviousLocations,
        ({ skuId, skuLabel, isOccluded, isGroup, isUncertain, isEmpty }) => ({
          sku_id: skuId,
          sku_label: skuLabel,
          is_occluded: isOccluded,
          is_group: isGroup,
          is_uncertain: isUncertain,
          is_empty: isEmpty,
        })
      );
      const kept = _.mapValues(
        job.context.keptPreviousLocations,
        ({ skuId, skuLabel, isOccluded, isGroup, isUncertain, isEmpty }) => ({
          sku_id: skuId,
          sku_label: skuLabel,
          is_occluded: isOccluded,
          is_group: isGroup,
          is_uncertain: isUncertain,
          is_empty: isEmpty,
        })
      );
      const previousReviewedLocationIds =
        job.context.locationsReviewedForClassification;
      const locations_reviewed_for_classification = _.uniq(
        locationsToReview
          .filter(({ status, id, skuId }) => {
            if (status === LocationReviewStatus.Init) return false;
            const initialClassification =
              job.context.initialClassifications[id];

            return (
              initialClassification === undefined ||
              initialClassification.skuId !== skuId
            );
          })
          .map(({ id }) => id)
          .concat(previousReviewedLocationIds)
      );
      return {
        classifications: {
          ...classifications,
          ...unchanged,
          ...reshaped,
          ...kept,
        },
        locations_reviewed_for_classification,
      };
    }
    case TaskType.ReviewAnnotations: {
      const reviewedLocations = locationsToReview.filter(
        ({ status }) => status !== LocationReviewStatus.Init
      );
      const review_location_flags = _.mapValues(
        groupByUniqueKey(reviewedLocations, 'id'),
        ({ isEmpty, isGroup, isOccluded, isUncertain }) => ({
          is_empty: isEmpty,
          is_group: isGroup,
          is_occluded: isOccluded,
          is_uncertain: isUncertain,
        })
      );
      return {
        review_location_flags,
      };
    }
    default:
      throw new Error(
        `No Task Type Definition in configureTaskAnnotation, taskType: ${job.currentTaskName}`
      );
  }
};

export const shouldNotBeAutoApproved = (
  location: { isOccluded?: boolean; isEmpty?: boolean },
  sku?: { retired_at?: string; barcode: string }
) => {
  const isOcculuded = location.isOccluded;
  const isEmpty = location.isEmpty;
  return Boolean(
    sku?.retired_at ||
      sku?.barcode === UNKNOWN_SKU_BARCODE ||
      isOcculuded ||
      isEmpty
  );
};

export const configureLocationToReview = (locations: Location[]) =>
  locations.map(location => ({
    ...location,
    status: LocationReviewStatus.Init,
    key: location.id,
  }));

export const configureLocationsToReview = (
  job: CamogramJob,
  skus: any
): LocationToReview[] => {
  switch (job.currentTaskName) {
    case TaskType.RoutePreviousLocations: {
      const allLocations = Object.values<Location>(
        job.context.previousLocationsToCheckForRoute
      );
      const allLocationsWithSku = allLocations.map(location => {
        if (location.skuId === undefined) return location;
        const sku = skus[location.skuId];
        if (sku === undefined) return location;
        return {
          ...location,
          sku,
        };
      }) as LocationToReviewWithSku[];
      const reviewedLocationIds = job.context.previousLocationsReviewedForRoute;
      const initialPreviousLocationRouteTags: { [locationId: string]: string } =
        job.context.initialPreviousLocationRouteTags;
      const routedLocationIds = Object.keys(
        initialPreviousLocationRouteTags
      ).filter(locationId => {
        const location = allLocationsWithSku.find(
          ({ id }) => id === locationId
        );
        if (location === undefined)
          throw new Error(
            'location is undefined in configureLocationsToReview'
          );

        return (
          !shouldNotBeAutoApproved(location, location.sku) ||
          reviewedLocationIds.includes(locationId)
        );
      });

      const routedLocations = job.context.previousLocationsRowOrder
        .filter(locationId => routedLocationIds.includes(locationId))
        .map(locationId => {
          const location = allLocations.find(({ id }) => id === locationId);
          if (location === undefined)
            throw new Error(
              'location is undefined in configureLocationsToReview'
            );
          const status = initialPreviousLocationRouteTags[locationId];
          return {
            ...location,
            status,
            key: locationId,
          };
        });
      const notReviewedLocations = job.context.previousLocationsRowOrder
        .filter(locationId => !routedLocationIds.includes(locationId))
        .map(locationId => {
          const location = allLocations.find(({ id }) => id === locationId);
          if (location === undefined)
            throw new Error(
              'location is undefined in configureLocationsToReview'
            );
          return {
            ...location,
            status: LocationReviewStatus.Init,
            key: locationId,
          };
        });

      return [...routedLocations, ...notReviewedLocations];
    }
    case TaskType.SelectUnchangedPreviousLocations: {
      const allLocations = Object.values<Location>(
        job.context.previousLocationsToCheckForChanges
      );
      const unchangedIds: string[] =
        job.context.initialUnchangedPreviousLocationSelections;
      const reviewedIds: string[] =
        job.context.previousLocationsReviewedForChanges;
      const changedIds: string[] = reviewedIds.filter(
        id => !unchangedIds.includes(id)
      );
      const unchanged = job.context.previousLocationsRowOrder
        .filter(locationId => unchangedIds.includes(locationId))
        .map(locationId => {
          const location = allLocations.find(({ id }) => id === locationId);
          if (location === undefined)
            throw new Error(
              'location is undefined in configureLocationsToReview'
            );

          return {
            ...location,
            status: LocationReviewStatus.Unchanged,
            key: locationId,
          };
        });
      const changed = job.context.previousLocationsRowOrder
        .filter(locationId => changedIds.includes(locationId))
        .map(locationId => {
          const location = allLocations.find(({ id }) => id === locationId);
          if (location === undefined)
            throw new Error(
              'location is undefined in configureLocationsToReview'
            );
          return {
            ...location,
            status: LocationReviewStatus.Changed,
            key: locationId,
          };
        });
      const notReviewed = job.context.previousLocationsRowOrder
        .filter(
          locationId =>
            !unchangedIds.includes(locationId) &&
            !changedIds.includes(locationId)
        )
        .map(locationId => {
          const location = allLocations.find(({ id }) => id === locationId);
          if (location === undefined)
            throw new Error('location was undefined on notReviewed');

          return location;
        })
        .map(location => ({
          ...location,
          status: LocationReviewStatus.Init,
          key: location.id,
        }));
      return [...unchanged, ...changed, ...notReviewed];
    }

    case TaskType.SelectLocalizedPreviousLocations: {
      const used: string[] =
        job.context.initialLocalizedPreviousLocationSelections;
      const unUsed: string[] = job.context.previousLocationsReviewedForLocalization.filter(
        locationId => !used.includes(locationId)
      );
      const result = job.context.previousLocationsRowOrder.map(locationId => {
        const location =
          job.context.previousLocationsToCheckForLocalization[locationId];
        const isUsed = used.includes(location.id);
        const isUnUsed = unUsed.includes(location.id);
        const status = isUsed
          ? LocationReviewStatus.UseLocation
          : isUnUsed
          ? LocationReviewStatus.UnuseLocation
          : LocationReviewStatus.Init;
        return { ...location, status, key: location.id };
      });
      return result;
    }
    case TaskType.SelectLocalizedProposedLocations: {
      const used: string[] =
        job.context.initialLocalizedProposedLocationSelections;
      const unUsed: string[] = job.context.proposedLocationsReviewedForLocalization.filter(
        locationId => !used.includes(locationId)
      );

      const result = job.context.proposedLocationsRowOrder.map(locationId => {
        const location =
          job.context.proposedLocationsToCheckForLocalization[locationId];
        const isUsed = used.includes(location.id);
        const isUnUsed = unUsed.includes(location.id);
        const status = isUsed
          ? LocationReviewStatus.UseLocation
          : isUnUsed
          ? LocationReviewStatus.UnuseLocation
          : LocationReviewStatus.Init;
        return { ...location, status, key: location.id };
      });

      return result;
    }
    case TaskType.CreateLocations:
      return configureLocationToReview(
        Object.values(job.context.initialCreatedLocations)
      );
    case TaskType.ReshapePreviousLocations: {
      const allLocations = job.context.previousLocationsToReshape;
      const reshapedLocations = job.context.initialReshapedPreviousLocations;
      const locationsToReview = job.context.previousLocationsToReshapeRowOrder.map(
        locationId => {
          const isReshaped = Object.keys(reshapedLocations).includes(
            locationId
          );
          const location = isReshaped
            ? reshapedLocations[locationId]
            : allLocations[locationId];
          if (location === undefined)
            throw new Error(
              'location was undefined on locationsToReview in ReshapePreviousLocations'
            );
          return {
            ...location,
            status: isReshaped
              ? LocationReviewStatus.Reshaped
              : LocationReviewStatus.Init,
          };
        }
      );
      return locationsToReview;
    }
    case TaskType.ClassifyLocations: {
      const allClassified = job.context.initialClassifications;
      const allPredictionCandidates: { [locationId: string]: Prediction } = {
        ...job.context.localizedProposedLocationItemPredictions,
        ...job.context.previousLocationItemPredictions,
        ...job.context.createdLocationItemPredictions,
      };
      const normalizedPredictionCandidates = _.mapValues(
        allPredictionCandidates,
        ({ ids, locationId, similarities }) => {
          const predictions = ids.map((id, index) => ({
            skuId: id,
            similarity: similarities[index],
          }));
          const uniqPredictions = _.uniqBy(predictions, 'skuId');

          return { locationId, predictions: uniqPredictions };
        }
      );
      const allUniqLocations = getContextFromKeys(job.context, [
        JobContextLocationKeys.LocalizedPreviousLocations,
        JobContextLocationKeys.LocalizedProposedLocations,
        JobContextLocationKeys.CreatedLocations,
        JobContextLocationKeys.PreviousLocationsToReclassify,
      ]);
      const locationsToReview: LocationToReview[] = job.context.allLocationsRowOrder
        .map(locationId => {
          const location = allUniqLocations.find(({ id }) => locationId === id);
          return location;
        })
        .filter(location => Boolean(location))
        .map(location => {
          const classified = allClassified[location.id];
          const predictions =
            normalizedPredictionCandidates[location.id]?.predictions;
          if (classified) {
            return {
              ...location,
              ...classified,
              status: LocationReviewStatus.Classified,
              key: location.id,
              predictions,
            };
          } else {
            return {
              ...location,
              status: LocationReviewStatus.Init,
              key: location.id,
              predictions,
            };
          }
        });

      return locationsToReview;
    }
    case TaskType.ReviewAnnotations: {
      const classifications = job.context.classifications;
      const locationsToReview: LocationToReview[] = Object.entries<Location>(
        classifications
      ).map(([id, classification]) => {
        const regionCandidates = getContextFromKeys(job.context, [
          JobContextLocationKeys.UnchangedPreviousLocations,
          JobContextLocationKeys.LocalizedPreviousLocations,
          JobContextLocationKeys.LocalizedProposedLocations,
          JobContextLocationKeys.PreviousLocationsToReclassify,
          JobContextLocationKeys.ReshapedPreviousLocations,
          JobContextLocationKeys.KeptPreviousLocations,
          JobContextLocationKeys.CreatedLocations,
        ]);
        const region: Coordinate[] | undefined = regionCandidates.find(
          ({ id: locationId }) => locationId === id
        )?.region;

        if (!region)
          throw new Error(`There is not region for the classification: ${id}`);
        return {
          ...classification,
          id,
          region,
          key: id,
          status: LocationReviewStatus.Classified,
        };
      });

      return locationsToReview;
    }
    default: {
      return [];
    }
  }
};

export const getContextFromKeys = (
  context: any,
  keys: JobContextLocationKeys[]
) => {
  const result = _.uniqBy(
    keys
      .map(key => {
        return Object.values<Location>(context[key] ?? {});
      })
      .flat(),
    'id'
  );

  return result;
};

export const configureExistingLocations = (job: CamogramJob): Location[] => {
  switch (job.currentTaskName) {
    case TaskType.ReshapePreviousLocations: {
      return getContextFromKeys(job.context, [
        JobContextLocationKeys.KeptPreviousLocations,
        JobContextLocationKeys.PreviousLocationsToReclassify,
      ]);
    }
    case TaskType.ClassifyLocations:
      return getContextFromKeys(job.context, [
        JobContextLocationKeys.UnchangedPreviousLocations,
        JobContextLocationKeys.KeptPreviousLocations,
        JobContextLocationKeys.ReshapedPreviousLocations,
      ]);
    case TaskType.ReviewAnnotations:
      return [];
    case TaskType.Completed:
      return [];
    default: {
      return getContextFromKeys(job.context, [
        JobContextLocationKeys.UnchangedPreviousLocations,
        JobContextLocationKeys.LocalizedPreviousLocations,
        JobContextLocationKeys.LocalizedProposedLocations,
        JobContextLocationKeys.PreviousLocationsToReclassify,
        JobContextLocationKeys.ReshapedPreviousLocations,
        JobContextLocationKeys.KeptPreviousLocations,
      ]);
    }
  }
};
