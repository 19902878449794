import api from '.';

const fetchFFImages = async ({ storeId, camNum, datetime }) => {
  const response = await api.get(
    `stores/${storeId}/camera/${camNum}/ff_images_and_machine_camograms/${datetime}?image_occlusion_type=UNOCCLUDED_IMAGE`
  );

  if (Array.isArray(response.data)) {
    return [];
  }

  return response.data.ff_photos.map(image => ({
    camNum,
    url: image.signed_image_url,
    storeId: image.store_id,
    sectionId: image.camogram_section_id,
    shelfId: image.shelf_id,
    uploadedAt: image.gcs_upload_datetime,
  }));
};

export default fetchFFImages;
