import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSkusApi } from 'utils/api';

const fetchSkus = createAsyncThunk(
  'fetchSkus',
  async ({ storeId }: { storeId: string }) => {
    const skus = await fetchSkusApi(storeId);
    return { skus };
  }
);

export default fetchSkus;
