import api from '..';

type SaveJobApiProps = {
  jobId: string;
  annotation: any;
};

const saveJobApi = async ({ jobId, annotation }: SaveJobApiProps) => {
  const res = await api.post('task-based/generic/save-job', {
    job_id: jobId,
    annotation,
  });

  return res;
};

export default saveJobApi;
