import { INIT_VISIBLE, SET_VISIBLE } from '../actions/types';

const layers = (state = {}, action) => {
  if (action.type === INIT_VISIBLE) {
    const { id, value } = action.payload;
    return { ...state, [id]: value };
  }

  if (action.type === SET_VISIBLE) {
    const { id, value } = action.payload;
    // Get the namespace
    const ns = id.split('_').shift();

    const next = { ...state, [id]: value };

    // When editing a sub-item
    if (id.includes('_')) {
      // It might toggle the parent on if it is turning the child on
      if (value === true) {
        return { ...next, [ns]: true };
      }
      return next;
    }

    // Change all of the keys that are descendants of the current key
    for (let key in state) {
      if (key.split('_')[0] === id) {
        next[key] = value;
      }
    }
    return next;
  }

  return state;
};

export default layers;
