import { HighresActiveRegion, HighresActiveSection } from 'types';
import api from '.';

type PostHighresActiveRegionsApiRequest = {
  storeId: string;
  activeRegions: HighresActiveRegion[];
};

const configureActiveShelfs = (activeSections: HighresActiveSection[]) => {
  const activeShelfs = activeSections.map(
    ({ coordinates, camogramSectionId }) => ({
      coordinates,
      camogram_section_id: [camogramSectionId],
    })
  );
  return activeShelfs;
};

const configureBody = (activeRegions: HighresActiveRegion[]) => {
  const active_regions = activeRegions.map(
    ({ camNum, activeSections, enabled }) => {
      const active_shelfs = activeSections
        ? configureActiveShelfs(activeSections)
        : [];
      const camogram_section_ids = active_shelfs
        .map(({ camogram_section_id }) => camogram_section_id)
        .flat();
      return {
        cam_num: camNum,
        enabled,
        active_shelfs,
        camogram_section_ids,
      };
    }
  );

  return { active_regions };
};

export default async function postHighresActiveRegionsApi({
  storeId,
  activeRegions,
}: PostHighresActiveRegionsApiRequest) {
  const body = configureBody(activeRegions);
  const res = await api.post(
    `/admin/stores/${storeId}/static_4k_active_regions`,
    body
  );

  return res.data;
}
