import { Button } from '@material-ui/core';
import CamogramLocations from 'components/CamogramLocations';
import CamogramTooltip from 'components/CamogramTooltip';
import Flex from 'components/Flex';
import HighlightLocationContainer from 'pages/TaskBasedEditor/Content/common/HighlightLocationContainer';
import { useAppSelector } from 'hooks';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useDispatch } from 'react-redux';
import { reviewLocation, setSelectedLocationId } from 'slices/taskBasedSlice';
import styled from 'styled-components';

import {
  LocationReviewStatus,
  LocationToReview,
  LocationToReviewWithSku,
  LocationToReviewWithSkuId,
} from 'types/taskBased';
import { useJob } from '../helpers';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';
import { setActiveLocation } from 'actions';
import { COLORS } from 'common/constants';
import useShortcut from 'hooks/useShortcut';
import LocationHorizontalScrollListCollapse from 'pages/TaskBasedEditor/components/LocationHorizontalScrollListCollapse';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import FlagButtons from 'pages/TaskBasedEditor/components/FlagButtons';
import { shouldNotBeAutoApproved } from 'slices/taskBasedSlice/helpers';

const configureColor = (location: LocationToReview, selected: boolean) => {
  if (selected) {
    return COLORS.selected;
  } else if (location.status === LocationReviewStatus.Init) {
    return COLORS.init;
  } else if (location.status === LocationReviewStatus.Keep) {
    return COLORS.approved;
  } else if (location.status === LocationReviewStatus.Discard) {
    return COLORS.rejected;
  } else if (location.status === LocationReviewStatus.Reshape) {
    return COLORS.reshape;
  } else if (location.status === LocationReviewStatus.Reclassify) {
    return COLORS.reclassify;
  } else {
    const errorMessage = `This status must not happen in configureColor in CompareLocations. location: ${JSON.stringify(
      location
    )}, selected: ${selected}`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};
const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 0;
  flex: 1;
  gap: 16px;
  position: relative;
`;

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 8px;
  }
`;

const HorizontalScrollListsContainer = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: stretch;
  align-items: stretch;
`;

const ListContainer = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: ${({ visible }) => (visible ? 1 : 0)};
  flex-basis: ${({ visible }) => (visible ? '100%' : 'auto')};
  min-width: 0;
`;

const FlagsContainer = styled.div`
  width: 320px;

  border-left: 1px solid #ccc;
  padding-left: 16px;
  margin-left: 16px;
`;

type ComponentProps = {
  currentImageUrl: string;
  previousImageUrl: string;
  preApprovedLocations: LocationToReviewWithSku[];
  locationsToReview: LocationToReviewWithSku[];
  imageAngle: number;
  selectedLocationId?: string;
  onChangeLocationId: (locationId: string) => void;
  onClickDiscard: (locationId: string) => void;
  onClickReclassify: (locationId: string) => void;
  onClickReshape: (locationId: string) => void;
  onClickKeep: (locationId: string) => void;
};

function RoutePreviousLocationsComponent({
  currentImageUrl,
  previousImageUrl,
  imageAngle,
  preApprovedLocations = [],
  locationsToReview = [],
  selectedLocationId,
  onChangeLocationId,
  onClickDiscard,
  onClickReclassify,
  onClickReshape,
  onClickKeep,
}: ComponentProps) {
  const [
    visiblePreApprovedLocations,
    setVisiblePreApprovedLocations,
  ] = useState(false);
  const allLocations = preApprovedLocations.concat(locationsToReview);
  const selectedLocation = allLocations.find(
    ({ id }) => id === selectedLocationId
  );

  useShortcut('a', () => {
    if (selectedLocationId === undefined) return;
    onClickDiscard(selectedLocationId);
  });

  useShortcut('s', () => {
    if (selectedLocationId === undefined) return;
    onClickReshape(selectedLocationId);
  });
  useShortcut('d', () => {
    if (selectedLocationId === undefined) return;
    onClickReclassify(selectedLocationId);
  });
  useShortcut('f', () => {
    if (selectedLocationId === undefined) return;
    onClickKeep(selectedLocationId);
  });
  useEffect(() => {
    const isPreApprovedSelected = preApprovedLocations.some(
      ({ id }) => id === selectedLocationId
    );
    const isLocationsToReviewSelected = locationsToReview.some(
      ({ id }) => id === selectedLocationId
    );

    if (isPreApprovedSelected && !visiblePreApprovedLocations) {
      setVisiblePreApprovedLocations(true);
    } else if (isLocationsToReviewSelected && visiblePreApprovedLocations) {
      setVisiblePreApprovedLocations(false);
    }
  }, [selectedLocationId]);

  if (selectedLocationId === undefined || !selectedLocation)
    return <div>No Location</div>;

  const onClickPreApprovedVisibility = (visible: boolean) => {
    setVisiblePreApprovedLocations(visible);
    const locationId = preApprovedLocations[0].id;
    onChangeLocationId(locationId);
  };

  const onClickLocationsToReviewVisibility = (visible: boolean) => {
    setVisiblePreApprovedLocations(!visible);
    const locationId = locationsToReview[0].id;
    onChangeLocationId(locationId);
  };

  const disableKeep = Boolean(selectedLocation.sku.retired_at);

  return (
    <Flex direction="column" padding={16} itemSpace={8}>
      <ImagesContainer>
        <ImageItem>
          <div>Previous Image</div>
          <HighlightLocationContainer
            imageSrc={previousImageUrl}
            angle={imageAngle}
            rotation={0}
            region={selectedLocation.region}
            itemUuid={selectedLocation.skuId}
          />
        </ImageItem>
        <ImageItem>
          <div>Current Image</div>
          <HighlightLocationContainer
            rotation={0}
            imageSrc={currentImageUrl}
            angle={imageAngle}
            region={selectedLocation.region}
          />
        </ImageItem>
      </ImagesContainer>
      <HorizontalScrollListsContainer>
        {preApprovedLocations.length > 0 && (
          <ListContainer
            visible={visiblePreApprovedLocations}
            style={{ marginRight: 8 }}
          >
            <LocationHorizontalScrollListCollapse
              visible={visiblePreApprovedLocations}
              onClickVisibility={onClickPreApprovedVisibility}
              title={`Auto Approved (${preApprovedLocations.length})`}
              skuIds={_.take(preApprovedLocations, 3).map(({ skuId }) => skuId)}
            >
              <LocationHorizontalScrollList
                locations={preApprovedLocations}
                selectedLocationUuId={selectedLocationId}
                onChangeLocationUuid={onChangeLocationId}
              />
            </LocationHorizontalScrollListCollapse>
          </ListContainer>
        )}
        {locationsToReview.length > 0 && (
          <ListContainer visible={!visiblePreApprovedLocations}>
            <LocationHorizontalScrollListCollapse
              visible={!visiblePreApprovedLocations}
              onClickVisibility={onClickLocationsToReviewVisibility}
              title="Locations to Review"
              skuIds={_.take(locationsToReview, 3).map(({ skuId }) => skuId)}
            >
              <LocationHorizontalScrollList
                locations={locationsToReview}
                selectedLocationUuId={selectedLocationId}
                onChangeLocationUuid={onChangeLocationId}
              />
            </LocationHorizontalScrollListCollapse>
          </ListContainer>
        )}
      </HorizontalScrollListsContainer>
      <ButtonsContainer>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: COLORS.rejected, color: 'white' }}
          onClick={() => onClickDiscard(selectedLocationId)}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: COLORS.reshape, color: 'white' }}
          onClick={() => onClickReshape(selectedLocationId)}
        >
          Reshape
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: COLORS.reclassify, color: 'white' }}
          onClick={() => onClickReclassify(selectedLocationId)}
        >
          Reclassify
        </Button>
        <Button
          variant="contained"
          style={
            disableKeep
              ? undefined
              : { backgroundColor: COLORS.approved, color: 'white' }
          }
          size="small"
          onClick={() => onClickKeep(selectedLocationId)}
          disabled={disableKeep}
        >
          Keep
        </Button>
        <FlagsContainer>
          <FlagButtons availableFlags={['isEmpty', 'isGroup', 'isOccluded']} />
        </FlagsContainer>
      </ButtonsContainer>
    </Flex>
  );
}

const configureLocationWithSku = (
  location: LocationToReviewWithSkuId,
  skus: any
) => {
  const sku = skus[location.skuId];
  return {
    ...location,
    sku,
  };
};

const configureLocationIdsToExcludeFromPreApproved = (
  locations: LocationToReviewWithSku[],
  routeTags: string[]
) => {
  const locationIdsWithRetiredSkus = locations
    .filter(location => shouldNotBeAutoApproved(location, location.sku))
    .map(location => location.id);
  return _.without(routeTags, ...locationIdsWithRetiredSkus);
};

export default function RoutePreviousLocations() {
  const dispatch = useDispatch();
  const allLocationsWithoutSku = useAppSelector(
    state => state.taskBased.locationsToReview as LocationToReviewWithSkuId[]
  );
  const skus = useAppSelector(state => {
    const storeId = state.taskBased.selectedJob?.storeId;
    if (storeId === undefined) return {};

    const skus = state.skus[storeId];

    return skus;
  });
  const allLocations = allLocationsWithoutSku.map(location =>
    configureLocationWithSku(location, skus)
  ) as LocationToReviewWithSku[];

  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);

  // Locations auto approved by ml model
  const preApprovedLocationIds: string[] = _.without(
    configureLocationIdsToExcludeFromPreApproved(
      allLocations,
      Object.keys(jobContext.initialPreviousLocationRouteTags)
    ),
    ...jobContext.previousLocationsReviewedForRoute
  );

  const preApprovedLocations = allLocations.filter(({ id }) =>
    preApprovedLocationIds.includes(id)
  );
  const locationsToReview = allLocations.filter(
    ({ id }) => !preApprovedLocationIds.includes(id)
  );

  const onClickDiscard = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.Discard },
      })
    );
  };
  const onClickReshape = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.Reshape },
      })
    );
  };
  const onClickReclassify = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.Reclassify },
      })
    );
  };
  const onClickKeep = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.Keep },
      })
    );
  };

  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };

  const onMouseEnter = (event: any, locationId: string) => {
    const location = allLocations.find(({ id }) => locationId === id);
    if (location === undefined)
      throw new Error('Location is undefined onMouseEnter');
    dispatch(
      setActiveLocation({ ...location, x: event.clientX, y: event.clientY })
    );
  };
  const onMouseLeave = (event: any, locationId?: string) => {
    if (locationId) {
      dispatch(setActiveLocation(null));
    }
  };

  const locations = allLocations.map(locationReview => ({
    ...locationReview,
    color: configureColor(
      locationReview,
      selectedLocationId === locationReview.id
    ),
  }));

  if (locations.length === 0) return <div>There's no Locations to Review</div>;

  return (
    <Flex direction="column">
      <Flex style={{ maxHeight: 200 }}>
        <CamogramLocations
          activeShelfs={image.meta?.activeShelfs}
          locations={locations}
          imageSrc={jobContext.currentImageUrl}
          angle={image.angle ?? 0}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </Flex>
      <RoutePreviousLocationsComponent
        selectedLocationId={selectedLocationId}
        onChangeLocationId={onChangeLocationId}
        preApprovedLocations={preApprovedLocations}
        locationsToReview={locationsToReview}
        previousImageUrl={jobContext.previousImageUrl}
        currentImageUrl={jobContext.currentImageUrl}
        imageAngle={image.angle ?? 0}
        onClickDiscard={onClickDiscard}
        onClickKeep={onClickKeep}
        onClickReshape={onClickReshape}
        onClickReclassify={onClickReclassify}
      />
      <CamogramTooltip />
    </Flex>
  );
}
