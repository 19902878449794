import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Panel from 'components/Panel';
import { MapInteractionCSS } from 'react-map-interaction';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ContainerWidthContext } from './FFImagesComponent';
import useHold from 'hooks/useHold';
import { DEFAULT_CAMERA_IMAGE_ASPECT_RATIO } from 'common/constants';
import moment from 'moment';

const ToolbarContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: #444;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const Image = styled.img`
  object-fit: contain;
`;

const defaultZoom = {
  scale: 1,
  translation: { x: 0, y: 0 },
};

const ZOOM_INTERVAL = 0.3;
const Toolbar = ({ setZoom, shelfId, sectionId, uploadedAt }) => {
  const onZoomIn = () => {
    setZoom(prev => {
      const scale = prev.scale + ZOOM_INTERVAL;
      const ratio = scale / prev.scale;
      return {
        translation: {
          x: Math.floor(prev.translation.x * ratio),
          y: Math.floor(prev.translation.y * ratio),
        },
        scale,
      };
    });
  };
  const onZoomOut = () => {
    setZoom(prev => {
      const scale = Math.max(prev.scale - ZOOM_INTERVAL, ZOOM_INTERVAL);
      const ratio = scale / prev.scale;
      return {
        translation: {
          x: prev.translation.x * ratio,
          y: prev.translation.y * ratio,
        },
        scale,
      };
    });
  };

  const zoomInEvents = useHold(onZoomIn);
  const zoomOutEvents = useHold(onZoomOut);
  const datetime = moment(uploadedAt).format('lll');
  return (
    <ToolbarContainer>
      <IconButton color="inherit" size="small" {...zoomOutEvents}>
        <ZoomOut />
      </IconButton>
      <IconButton color="inherit" size="small" {...zoomInEvents}>
        <ZoomIn />
      </IconButton>
      <div>
        {shelfId} / {sectionId} / {datetime}
      </div>
    </ToolbarContainer>
  );
};

const FFImage = ({ url, sectionId, shelfId, uploadedAt }) => {
  const [zoom, setZoom] = useState(defaultZoom);
  const containerWidth = useContext(ContainerWidthContext);

  const width = containerWidth - 16;
  const height = width * DEFAULT_CAMERA_IMAGE_ASPECT_RATIO;
  const onDoubleClick = e => {
    const scale = 3;
    const x = width / scale - scale * e.nativeEvent.layerX;
    const y = height / scale - scale * e.nativeEvent.layerY;

    setZoom(prev => {
      if (prev.scale === defaultZoom.scale) {
        return {
          translation: { x, y },
          scale,
        };
      } else {
        return defaultZoom;
      }
    });
  };
  return (
    <Panel
      toolBar={
        <Toolbar
          setZoom={setZoom}
          shelfId={shelfId}
          sectionId={sectionId}
          uploadedAt={uploadedAt}
        />
      }
    >
      <ImageContainer>
        <MapInteractionCSS
          minScale={0.9}
          value={zoom}
          onChange={setZoom}
          disableZoom
        >
          <Image
            src={url}
            alt="camera_image"
            width={width}
            height={height}
            onDoubleClick={onDoubleClick}
          />
        </MapInteractionCSS>
      </ImageContainer>
    </Panel>
  );
};

export default FFImage;
