import { useSelector } from 'react-redux';
import { calcIsRotated } from 'utils/position';

const useCameraAngle = camNum => {
  const angle = useSelector(
    state => state.cameraExtrinsics?.[camNum]?.angle ?? 0
  );
  const isRotated = calcIsRotated(angle);

  return { angle, isRotated };
};

export default useCameraAngle;
