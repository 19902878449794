import { useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { getUserCheckinState, postCheckin } from 'slices/checkinSlice';
import useAccount from 'hooks/useAccount';
import Loader from 'components/Loader';
import { UserCheckinState } from 'types';
import { Button, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
`;

const Description = styled.div`
  margin-bottom: 20px;
  max-width: 400px;
  font-size: 18px;
  text-align: center;
`;

const CheckinButton = () => {
  const { dispatch, isLoading } = useAsyncDispatch();
  const { email } = useAccount();

  const handleClick = () => {
    if (email === undefined) return;
    toast.promise(dispatch(postCheckin(email)), {
      pending: 'Checking in...',
      success: 'Checked In!',
      error: 'Could not check-in, please try again.',
    });
  };
  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Check-In'}
    </Button>
  );
};

const Checkin: React.FC = ({ children }) => {
  const { email } = useAccount();
  const { dispatch, isReady } = useAsyncDispatch();
  const isCheckedin = useAppSelector(
    state => state.checkin.userCheckinState === UserCheckinState.CheckedIn
  );
  useEffect(() => {
    if (email === undefined) return;
    dispatch(getUserCheckinState());
  }, []);

  if (!isReady) return <Loader loading />;
  return isCheckedin ? (
    <>{children}</>
  ) : (
    <Container>
      <Description>
        You must first <b>check-in</b> to use the app. <br /> When you have
        completed your task or you're away from your PC for 15 mins, Please{' '}
        <b>check-out</b>.
      </Description>
      <CheckinButton />
    </Container>
  );
};

export default Checkin;
