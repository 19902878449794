import { Button } from '@material-ui/core';
import CamogramLocations from 'components/CamogramLocations';
import CamogramTooltip from 'components/CamogramTooltip';
import Flex from 'components/Flex';
import HighlightLocationContainer from 'pages/TaskBasedEditor/Content/common/HighlightLocationContainer';
import { useAppSelector } from 'hooks';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useDispatch } from 'react-redux';
import { reviewLocation, setSelectedLocationId } from 'slices/taskBasedSlice';
import styled from 'styled-components';

import {
  LocationReviewStatus,
  LocationToReview,
  LocationToReviewWithSkuId,
} from 'types/taskBased';
import { useJob } from '../helpers';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';
import { setActiveLocation } from 'actions';
import { COLORS } from 'common/constants';
import useShortcut from 'hooks/useShortcut';
import LocationHorizontalScrollListCollapse from 'pages/TaskBasedEditor/components/LocationHorizontalScrollListCollapse';
import { useEffect, useState } from 'react';
import _ from 'lodash';

const configureColor = (location: LocationToReview, selected: boolean) => {
  if (selected) {
    return COLORS.selected;
  } else if (location.status === LocationReviewStatus.Init) {
    return COLORS.init;
  } else if (location.status === LocationReviewStatus.Unchanged) {
    return COLORS.approved;
  } else if (location.status === LocationReviewStatus.Changed) {
    return COLORS.rejected;
  } else {
    const errorMessage = `This status must not happen in configureColor in CompareLocations. location: ${JSON.stringify(
      location
    )}, selected: ${selected}`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};
const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 0;
  flex: 1;
  gap: 16px;
  position: relative;
`;

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 8px;
  }
`;

const HorizontalScrollListsContainer = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: stretch;
  align-items: stretch;
`;

const ListContainer = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: ${({ visible }) => (visible ? 1 : 0)};
  flex-basis: ${({ visible }) => (visible ? '100%' : 'auto')};
  min-width: 0;
`;

type CompareLocationsProps = {
  currentImageUrl: string;
  previousImageUrl: string;
  preApprovedLocations: LocationToReviewWithSkuId[];
  locationsToReview: LocationToReviewWithSkuId[];
  imageAngle: number;
  selectedLocationId?: string;
  onChangeLocationId: (locationId: string) => void;
  onClickChanged: (locationId: string) => void;
  onClickUnChanged: (locationId: string) => void;
};

export function CompareLocationsComponent({
  currentImageUrl,
  previousImageUrl,
  imageAngle,
  preApprovedLocations = [],
  locationsToReview = [],
  selectedLocationId,
  onChangeLocationId,
  onClickChanged,
  onClickUnChanged,
}: CompareLocationsProps) {
  const [
    visiblePreApprovedLocations,
    setVisiblePreApprovedLocations,
  ] = useState(false);
  const allLocations = preApprovedLocations.concat(locationsToReview);
  const selectedLocation = allLocations.find(
    ({ id }) => id === selectedLocationId
  );

  useShortcut('a', () => {
    if (selectedLocationId === undefined) return;
    onClickChanged(selectedLocationId);
  });

  useShortcut('s', () => {
    if (selectedLocationId === undefined) return;
    onClickUnChanged(selectedLocationId);
  });
  useEffect(() => {
    const isPreApprovedSelected = preApprovedLocations.some(
      ({ id }) => id === selectedLocationId
    );
    const isLocationsToReviewSelected = locationsToReview.some(
      ({ id }) => id === selectedLocationId
    );

    if (isPreApprovedSelected && !visiblePreApprovedLocations) {
      setVisiblePreApprovedLocations(true);
    } else if (isLocationsToReviewSelected && visiblePreApprovedLocations) {
      setVisiblePreApprovedLocations(false);
    }
  }, [selectedLocationId]);

  if (selectedLocationId === undefined || !selectedLocation)
    return <div>No Location</div>;

  const onClickPreApprovedVisibility = (visible: boolean) => {
    setVisiblePreApprovedLocations(visible);
    const locationId = preApprovedLocations[0].id;
    onChangeLocationId(locationId);
  };

  const onClickLocationsToReviewVisibility = (visible: boolean) => {
    setVisiblePreApprovedLocations(!visible);
    const locationId = locationsToReview[0].id;
    onChangeLocationId(locationId);
  };

  return (
    <Flex direction="column" padding={16} itemSpace={8}>
      <ImagesContainer>
        <ImageItem>
          <div>Previous Image</div>
          <HighlightLocationContainer
            imageSrc={previousImageUrl}
            angle={imageAngle}
            rotation={0}
            region={selectedLocation.region}
            itemUuid={selectedLocation.skuId}
          />
        </ImageItem>
        <ImageItem>
          <div>Current Image</div>
          <HighlightLocationContainer
            rotation={0}
            imageSrc={currentImageUrl}
            angle={imageAngle}
            region={selectedLocation.region}
          />
        </ImageItem>
      </ImagesContainer>
      <HorizontalScrollListsContainer>
        {preApprovedLocations.length > 0 && (
          <ListContainer
            visible={visiblePreApprovedLocations}
            style={{ marginRight: 8 }}
          >
            <LocationHorizontalScrollListCollapse
              visible={visiblePreApprovedLocations}
              onClickVisibility={onClickPreApprovedVisibility}
              title={`Auto Approved (${preApprovedLocations.length})`}
              skuIds={_.take(preApprovedLocations, 3).map(({ skuId }) => skuId)}
            >
              <LocationHorizontalScrollList
                locations={preApprovedLocations}
                selectedLocationUuId={selectedLocationId}
                onChangeLocationUuid={onChangeLocationId}
              />
            </LocationHorizontalScrollListCollapse>
          </ListContainer>
        )}
        {locationsToReview.length > 0 && (
          <ListContainer visible={!visiblePreApprovedLocations}>
            <LocationHorizontalScrollListCollapse
              visible={!visiblePreApprovedLocations}
              onClickVisibility={onClickLocationsToReviewVisibility}
              title="Locations to Review"
              skuIds={_.take(locationsToReview, 3).map(({ skuId }) => skuId)}
            >
              <LocationHorizontalScrollList
                locations={locationsToReview}
                selectedLocationUuId={selectedLocationId}
                onChangeLocationUuid={onChangeLocationId}
              />
            </LocationHorizontalScrollListCollapse>
          </ListContainer>
        )}
      </HorizontalScrollListsContainer>
      <ButtonsContainer>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: COLORS.rejected, color: 'white' }}
          onClick={() => onClickChanged(selectedLocationId)}
        >
          Changed
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: COLORS.approved, color: 'white' }}
          size="small"
          onClick={() => onClickUnChanged(selectedLocationId)}
        >
          Unchanged
        </Button>
      </ButtonsContainer>
    </Flex>
  );
}

export default function CompareLocations() {
  const dispatch = useDispatch();
  const allLocations = useAppSelector(
    state => state.taskBased.locationsToReview as LocationToReviewWithSkuId[]
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);

  // Locations auto approved by ml model
  const preApprovedLocationIds: string[] = _.without(
    jobContext.initialUnchangedPreviousLocationSelections,
    ...jobContext.previousLocationsReviewedForChanges
  );

  const preApprovedLocations = allLocations.filter(({ id }) =>
    preApprovedLocationIds.includes(id)
  );
  const locationsToReview = allLocations.filter(
    ({ id }) => !preApprovedLocationIds.includes(id)
  );

  const onClickChanged = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.Changed },
      })
    );
  };
  const onClickUnChanged = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.Unchanged },
      })
    );
  };

  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };

  const onMouseEnter = (event: any, locationId: string) => {
    const location = allLocations.find(({ id }) => locationId === id);
    if (location === undefined)
      throw new Error('Location is undefined onMouseEnter');
    dispatch(
      setActiveLocation({ ...location, x: event.clientX, y: event.clientY })
    );
  };
  const onMouseLeave = (event: any, locationId?: string) => {
    if (locationId) {
      dispatch(setActiveLocation(null));
    }
  };

  const locations = allLocations.map(locationReview => ({
    ...locationReview,
    color: configureColor(
      locationReview,
      selectedLocationId === locationReview.id
    ),
  }));

  if (locations.length === 0) return <div>There's no Locations to Review</div>;

  return (
    <Flex direction="column">
      <Flex style={{ maxHeight: 240 }}>
        <CamogramLocations
          activeShelfs={image.meta?.activeShelfs}
          locations={locations}
          imageSrc={jobContext.currentImageUrl}
          angle={image.angle ?? 0}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </Flex>
      <CompareLocationsComponent
        selectedLocationId={selectedLocationId}
        onChangeLocationId={onChangeLocationId}
        preApprovedLocations={preApprovedLocations}
        locationsToReview={locationsToReview}
        previousImageUrl={jobContext.previousImageUrl}
        currentImageUrl={jobContext.currentImageUrl}
        imageAngle={image.angle ?? 0}
        onClickChanged={onClickChanged}
        onClickUnChanged={onClickUnChanged}
      />
      <CamogramTooltip />
    </Flex>
  );
}
