import styled from 'styled-components';
import Flex from 'components/Flex';

const Label = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export default function NoReviewItems() {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ textAlign: 'center' }}
    >
      <Label>
        There are no boxes to classify because nothing has changed since the
        previous camogram!🎉
      </Label>
      <Label>Please complete the task and work on another camera!</Label>
    </Flex>
  );
}
