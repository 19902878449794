import { useAppSelector } from 'hooks';
import _ from 'lodash';
import { TypedUseSelectorHook } from 'react-redux';
import { Flags, Location } from 'types';
import { CamogramJob } from 'types/taskBased';
import { exist } from 'utils';

export const useJob: TypedUseSelectorHook<CamogramJob> = callback => {
  const data = useAppSelector(state => {
    const job = state.taskBased.selectedJob;

    if (!job)
      throw new Error(
        'job is undefined. useJob hook must be used within TaskBasedContentDataLoader'
      );

    return callback(job);
  });

  return data;
};

export const configureFlags = (location: Location) => {
  const flags = _.pick(location, [
    'isOccluded',
    'isGroup',
    'isUncertain',
    'isEmpty',
  ]) as Flags;
  return flags;
};

export const isSkuUnavailable = (sku?: any) => {
  return !exist(sku) || sku.name === undefined || Boolean(sku.retired_at);
};
