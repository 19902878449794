import { memo } from 'react';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { useSelector } from 'react-redux';

import './pane.css';
import {
  COMPARE_TYPE_CAMOGRAMS,
  COMPARE_TYPE_FF_IMAGES,
  COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM,
  COMPARE_TYPE_FF_IMAGES_BY_SECTION,
  COMPARE_TYPE_OMNI_CAMOGRAMS,
} from 'reducers/compare';
import CamogramsPane from './CamogramsPane';
import FFImagesPane from './FFImagesPane';
import FFImagesForExtremeZoomPane from './FFImagesForExtremeZoomPane';
import FFImagesBySectionPane from './FFImagesBySectionPane';
import OmniCamogramsPane from './OmniCamogramsPane';
import ExtremeZoomImagesPane from './ExtremeZoomImagesPane';
import { PaneContext } from './contexts';

const PaneContent = ({ type, size, uuid }) => {
  if (type === COMPARE_TYPE_CAMOGRAMS) {
    return <CamogramsPane uuid={uuid} />;
  } else if (type === COMPARE_TYPE_OMNI_CAMOGRAMS) {
    return <OmniCamogramsPane uuid={uuid} />;
  } else if (type === COMPARE_TYPE_FF_IMAGES) {
    return <FFImagesPane uuid={uuid} />;
  } else if (type === COMPARE_TYPE_FF_IMAGES_BY_SECTION) {
    return <FFImagesBySectionPane uuid={uuid} />;
  }

  return null;
};

const PaneContentMemo = memo(PaneContent);

const SplitLayout = ({ children }) => {
  const panes = useSelector(state => state.compare.panes);
  const mainSize = panes.length > 0 ? (100 / (panes.length + 2)) * 2 : 100;
  const contentSize = 100 / (panes.length + 2);

  return (
    <SplitPane split="vertical" className="scrollable flex">
      <Pane intilalSize={`${mainSize}%`}>{children}</Pane>
      {panes.map(({ type, uuid }) => {
        if (type === COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM) {
          // Pane component should be the top component, so we can't use <></>
          const panes = [
            <Pane initialSize="25%" className="scrollable">
              <PaneContext.Provider value={{ uuid }}>
                <FFImagesForExtremeZoomPane />
              </PaneContext.Provider>
            </Pane>,
            <Pane initialSize="40%" className="scrollable">
              <PaneContext.Provider value={{ uuid }}>
                <ExtremeZoomImagesPane />
              </PaneContext.Provider>
            </Pane>,
          ];
          return panes;
        } else {
          return (
            <Pane className="scrollable" initialSize={`${contentSize}%`}>
              <PaneContext.Provider value={{ uuid }}>
                <PaneContentMemo type={type} key={uuid} />
              </PaneContext.Provider>
            </Pane>
          );
        }
      })}
    </SplitPane>
  );
};

export default SplitLayout;
