import styled from 'styled-components';
import { useRef } from 'react';
import { Image } from '@material-ui/icons';

const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageInputContainer = styled.div`
  cursor: pointer;
  border-radius: 10px;
  border: 1px dashed lightgray;
  width: 250px;
  height: 150px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #00000033;
  }
`;

const ImageInput = ({ value, onChange }) => {
  const inputRef = useRef(null);
  const onChangeInner = e => {
    const file = e.target.files?.[0];
    if (file) {
      onChange(file);
    }
  };
  const onClick = () => {
    inputRef.current?.click();
  };
  return (
    <>
      <input
        type="file"
        name="newImage"
        onChange={onChangeInner}
        ref={inputRef}
        style={{ display: 'none' }}
      />
      <ImageInputContainer onClick={onClick}>
        {value ? (
          <Img src={createObjectURL(value)} alt="newImage" />
        ) : (
          <>
            <Image />
            Add Screenshot
          </>
        )}
      </ImageInputContainer>
    </>
  );
};

export default ImageInput;
