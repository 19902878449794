import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';
import { STORE_NAME_MAPPINGS } from 'common/constants';

const imgUrl = id => `https://pipelineviz.appstandard.ai/stores/${id}.jpg`;

const Flex = styled.div`
  margin-left: -30px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);

  & > * {
    padding: 0 0 30px 30px;
    width: calc(25% - 30px);
  }
`;

const Store = styled(Link)`
  display: block;
  position: relative;
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  :hover {
    transform: scale(1.02);
  }
`;

const StoreImage = styled.div`
  width: 100%;
  background: #eee;
  border-radius: 12px;
  background: #f3f3f3 url(${p => p.src});
  background-size: cover;
  margin-bottom: 5px;
  @media (min-width: 430px) {
    width: 100%;
    padding-bottom: 62.5%;
  }
`;

const Name = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Id = styled.p`
  margin: 0;
  float: right;
`;

const configureStoreSections = stores => {
  const storeArray = Object.values(stores);
  const sortedById = _.sortBy(storeArray, 'id');
  const grouped = _.groupBy(sortedById, store => store.id.split('.')[0]);

  return grouped;
};

const StorePicker = () => {
  const storeSections = useSelector(
    state => configureStoreSections(state.stores),
    shallowEqual
  );
  return (
    <div>
      {Object.entries(storeSections).map(([tenant, stores]) => (
        <div key={tenant}>
          <h2>{STORE_NAME_MAPPINGS[tenant] ?? tenant}</h2>
          <Flex>
            {stores.map(store => (
              <div key={store.id}>
                <Store to={`/stores/${store.id}`}>
                  <StoreImage src={imgUrl(store.id)} />
                  <Id>{store.id}</Id>
                  <Name>{store.name}</Name>
                </Store>
              </div>
            ))}
          </Flex>
        </div>
      ))}
    </div>
  );
};

export default StorePicker;
