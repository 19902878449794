import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Vec2D } from 'types';

const Container = styled.g<{ shouldHandle: boolean }>`
  cursor: ${({ shouldHandle }) => (shouldHandle ? 'pointer' : 'inherit')};
`;

type RotateTransformProps = {
  children: ({
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
  }: {
    handlePointerDown: (e: any) => void;
    handlePointerMove: (e: any) => void;
    handlePointerUp: React.PointerEventHandler<any>;
  }) => JSX.Element;
  start: Vec2D;
  initialDegrees: number;
  onChangeDegrees?: (degrees: number) => void;
  svgRef: React.RefObject<SVGSVGElement>;
};

const RotateTransform: React.FC<RotateTransformProps> = ({
  children,
  onChangeDegrees,
  initialDegrees,
  svgRef,
  start,
}) => {
  const [active, setActive] = useState(false);
  const [degrees, setDegrees] = useState(initialDegrees);

  useEffect(() => {
    setDegrees(initialDegrees);
  }, [initialDegrees]);

  const handlePointerDown = (e: any) => {
    if (!svgRef.current || !onChangeDegrees) return;
    const el = e.target;
    el.setPointerCapture(e.pointerId);
    setActive(true);
    e.preventDefault();
  };
  const handlePointerMove = (e: any) => {
    if (active) {
      if (!svgRef.current) return;
      const svgPoint = svgRef.current.createSVGPoint();
      svgPoint.x = e.clientX;
      svgPoint.y = e.clientY;
      const point = svgPoint.matrixTransform(
        svgRef.current.getScreenCTM()?.inverse()
      );
      const newAngle =
        (180 * Math.atan2(point.y - start.y, point.x - start.x)) / Math.PI;
      setDegrees(newAngle);
    }
  };
  const handlePointerUp = (e: any) => {
    if (!active || !onChangeDegrees) return;
    setActive(false);
    onChangeDegrees(degrees);
  };

  if (typeof children !== 'function')
    throw new Error('Children must be function in RotateTransform component');
  return (
    <Container
      transform={`rotate(${degrees}, ${start.x}, ${start.y})`}
      shouldHandle={Boolean(onChangeDegrees)}
    >
      {children({ handlePointerDown, handlePointerMove, handlePointerUp })}
    </Container>
  );
};

export default RotateTransform;
