import moment from 'moment';
import { EditorDataSource } from 'types';
import { ProviderKey } from 'types';
import api from '.';
import { addNumericCamIdParam } from './helpers';
const configureProviderKey: ProviderKey = raw => ({
  dataSource: EditorDataSource.Omni,
  cameraId: raw.cam_id,
  camogramSectionId: raw.camogram_section_id,
  shelfId: raw.shelf_id,
});
const fetchStoreCamogramsApi = async ({ storeId }) => {
  // Only fetch camograms from past week
  const start = moment().subtract(14, 'days').toISOString();
  const res = await api.get(
    addNumericCamIdParam(`stores/${storeId}/camograms?start=${start}`)
  );

  const camograms = res.data.results
    .sort(
      (a, b) => new Date(b.rockset_created_at) - new Date(a.rockset_created_at)
    )
    .map(camogram => {
      const providerKeys = camogram.provider_keys.map(configureProviderKey);

      return {
        ...camogram,
        providerKeys,
      };
    });

  return camograms;
};

export default fetchStoreCamogramsApi;
