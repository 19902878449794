import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import PaneLayout from './PaneLayout';
import FFImages from 'components/FFImages';
import { fetchFFImagesBySectionApi } from 'utils/api';
import { configureShelfSectionIdFromOmniImageId } from 'utils';
import { useCompareParams } from './helpers';
import useEditorPresent from 'hooks/useEditorPresent';

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;
const Header = ({ storeId, datetime, shelfId, sectionId }) => {
  const store = useSelector(state => state.stores[storeId]);
  return (
    <HeaderContainer>
      {`${store.name} (${storeId}) / ${moment(datetime).format(
        'lll'
      )} / ${shelfId} / ${sectionId}`}
    </HeaderContainer>
  );
};

const FFImagesBySectionPane = () => {
  const { storeId, datetime } = useCompareParams();
  const camNum = useEditorPresent(state => state.camNum);

  const { shelfId, sectionId } = configureShelfSectionIdFromOmniImageId(camNum);

  const fetchFFImages = useCallback(
    async () =>
      fetchFFImagesBySectionApi({ storeId, datetime, shelfId, sectionId }),
    [storeId, datetime, shelfId, sectionId]
  );

  return (
    <PaneLayout
      header={
        <Header
          storeId={storeId}
          datetime={datetime}
          shelfId={shelfId}
          sectionId={sectionId}
        />
      }
    >
      <FFImages fetchFFImages={fetchFFImages} full={false} />
    </PaneLayout>
  );
};

export default FFImagesBySectionPane;
