import PolygonOrPoint from 'components/PolygonOrPoint';
import { BOX_COLORS } from 'common/constants';
import React from 'react';

/**
 * Returns a SVG node for a single item
 */
export default function ItemLocation({
  imageHeight,
  imageWidth,
  productLocation,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  const stroke = productLocation.retired ? BOX_COLORS.retired : 'lime';
  return productLocation.region ? (
    <PolygonOrPoint
      imageHeight={imageHeight}
      imageWidth={imageWidth}
      region={productLocation.region}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      stroke={stroke}
    />
  ) : null;
}
