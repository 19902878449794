import _ from 'lodash';
import { EditorDataSource } from 'types';
import { addNumericCamIdParam, configureMetas } from 'utils/api/helpers';
import api from '../../';
import { configureCameraExtrinsics } from './helpers';

const fetchNewEditorCamerasApi = async (
  storeId,
  dataSource = EditorDataSource.StaticHighRes
) => {
  const res = await api.get(addNumericCamIdParam(`stores/${storeId}/cameras`));
  return {
    ...res.data,
    camera_extrinsics: {
      ...res.data.camera_extrinsics,
      cameras: configureCameraExtrinsics(res.data.camera_extrinsics.cameras),
    },
    metas: configureMetas(res.data.metas),
  };
};

export default fetchNewEditorCamerasApi;
