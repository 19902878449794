import Loader from 'components/Loader';
import { useAppSelector } from 'hooks';
import { LoadingStatus } from 'types';

const TaskBasedEditorContentDataLoader: React.FC = ({ children }) => {
  const job = useAppSelector(state => state.taskBased.selectedJob);
  const isLoading = useAppSelector(
    state => state.taskBased.loadingStatus === LoadingStatus.Loading
  );

  if (job?.context === undefined || isLoading)
    return <Loader full loading text="Loading Tasks Data" />;

  return <>{children}</>;
};

export default TaskBasedEditorContentDataLoader;
