import { CameraImage, EditorDataSource } from 'types';
import { configureCameraImages } from 'utils';
import fetchCamerasApi from './fetchCamerasApi';
import fetchGenericCameraImagesApi from './fetchGenericCameraImagesApi';

export type FetchGenericImagesWithCamerasProps = {
  storeId: string;
  datetime?: string;
  dataSource: EditorDataSource;
};

export type CameraImages = {
  [key: string]: CameraImage;
};

const fetchGenericImagesWithCameras = async ({
  storeId,
  datetime,
  dataSource,
}: FetchGenericImagesWithCamerasProps) => {
  const [images, cameras] = await Promise.all([
    fetchGenericCameraImagesApi({ storeId, datetime, dataSource }),
    fetchCamerasApi(storeId),
  ]);

  const imagesWithInteger: CameraImages = configureCameraImages(
    images,
    cameras
  );

  return { images: imagesWithInteger, cameras };
};

export default fetchGenericImagesWithCameras;
