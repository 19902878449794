import { Button } from '@material-ui/core';
import { COLORS } from 'common/constants';
import CamogramLocations from 'components/CamogramLocations';
import Flex from 'components/Flex';
import HighlightLocation from 'components/HighlightLocation';
import { useAppSelector } from 'hooks';
import useShortcut from 'hooks/useShortcut';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useDispatch } from 'react-redux';
import { reviewLocation, setSelectedLocationId } from 'slices/taskBasedSlice';
import styled from 'styled-components';
import { HighresActiveSection, Location } from 'types';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';
import { useJob } from '../helpers';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 8px;
  }
`;

const configureColor = (location: LocationToReview, selected: boolean) => {
  if (selected) {
    return COLORS.selected;
  } else if (location.status === LocationReviewStatus.Init) {
    return COLORS.init;
  } else if (location.status === LocationReviewStatus.UseLocation) {
    return COLORS.approved;
  } else if (location.status === LocationReviewStatus.UnuseLocation) {
    return COLORS.rejected;
  } else {
    const errorMessage = `This status must not happen in configureColor in SelectLocalizedProposedLocation. location: ${JSON.stringify(
      location
    )}, selected: ${selected}`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};

type SelectLocalizedProposedLocationsComponentProps = {
  locations: LocationToReview[];
  existingLocations: Location[];
  selectedLocationId?: string;
  imageSrc: string;
  imageAngle: number;
  activeShelfs?: HighresActiveSection[];
  onChangeLocationId: (locationId: string) => void;
  onClickUse: (locationId: string) => void;
  onClickUnUse: (locationId: string) => void;
};
function SelectLocalizedProposedLocationsComponent({
  locations,
  existingLocations,
  selectedLocationId,
  imageSrc,
  imageAngle,
  activeShelfs,
  onChangeLocationId,
  onClickUse,
  onClickUnUse,
}: SelectLocalizedProposedLocationsComponentProps) {
  const selectedLocation = locations.find(
    ({ id }) => id === selectedLocationId
  );
  useShortcut('a', () => {
    if (selectedLocationId === undefined) return;
    onClickUnUse(selectedLocationId);
  });

  useShortcut('s', () => {
    if (selectedLocationId === undefined) return;
    onClickUse(selectedLocationId);
  });

  if (selectedLocationId === undefined || !selectedLocation)
    return <div>No Location</div>;
  const locationsToReview = locations.map(location => ({
    ...location,
    color: configureColor(location, location.id === selectedLocationId),
  }));
  const existingLocationsWithColor = existingLocations.map(location => ({
    ...location,
    color: COLORS.approved,
  }));
  const camogramLocations = existingLocationsWithColor.concat(
    locationsToReview
  );

  const onClickLocation = (locationId: string) => {
    onChangeLocationId(locationId);
  };

  return (
    <Flex padding={16} direction="column">
      <Flex>
        <HighlightLocation
          imageSrc={imageSrc}
          angle={imageAngle}
          rotation={0}
          region={selectedLocation.region}
        />
        <CamogramLocations
          imageSrc={imageSrc}
          angle={imageAngle}
          locations={camogramLocations}
          activeShelfs={activeShelfs}
          onClick={onClickLocation}
        />
      </Flex>
      <LocationHorizontalScrollList
        locations={locations}
        selectedLocationUuId={selectedLocationId}
        onChangeLocationUuid={onChangeLocationId}
      />
      <ButtonsContainer>
        <Button
          variant="contained"
          style={{ backgroundColor: COLORS.rejected, color: 'white' }}
          size="large"
          onClick={() => onClickUnUse(selectedLocationId)}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: COLORS.approved, color: 'white' }}
          onClick={() => onClickUse(selectedLocationId)}
        >
          Use
        </Button>
      </ButtonsContainer>
    </Flex>
  );
}

export default function SelectLocalizedProposedLocations() {
  const dispatch = useDispatch();
  const locationsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const existingLocations = useAppSelector(
    state => state.taskBased.existingLocations
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);
  const onClickUse = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.UseLocation },
      })
    );
  };
  const onClickUnUse = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.UnuseLocation },
      })
    );
  };

  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };

  return (
    <SelectLocalizedProposedLocationsComponent
      locations={locationsToReview}
      existingLocations={existingLocations}
      selectedLocationId={selectedLocationId}
      imageSrc={jobContext.imageUrl}
      imageAngle={image.angle ?? 0}
      activeShelfs={image.meta?.activeShelfs}
      onChangeLocationId={onChangeLocationId}
      onClickUse={onClickUse}
      onClickUnUse={onClickUnUse}
    />
  );
}
