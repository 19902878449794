import React from 'react';
import styled from 'styled-components';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Sidebar from './Sidebar';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  cursor: pointer;
  font-size: 24px;
  margin-left: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #dddddd;
  padding-right: 60px;
  padding-left: 16px;
  color: white;
  background: hsl(4, 97%, 67%);
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex: 1;
`;

export default function AdminLayout({ children }) {
  const history = useHistory();
  const { storeId } = useParams();
  const store = useSelector(state => (storeId ? state.stores[storeId] : null));
  const storeName = store ? `${store.name} (${store.id})` : '';

  const onClickAnnotatorMode = () => {
    history.push('/');
  };

  const onClickTitle = () => {
    history.push('/admin');
  };
  return (
    <Container>
      <Header>
        <HeaderLeftContainer>
          <SupervisorAccountIcon fontSize="large" />
          <Title onClick={onClickTitle}>
            CamogramViz Admin
            {storeName && ` / ${storeName}`}
          </Title>
        </HeaderLeftContainer>
        <Button
          style={{ textTransform: 'none', marginRight: '10px' }}
          onClick={onClickAnnotatorMode}
          variant="contained"
          color="primary"
        >
          Annotator Mode
        </Button>
      </Header>
      <Main>
        {storeId && <Sidebar />}
        {children}
      </Main>
    </Container>
  );
}
