import { ZoomContext } from 'contexts/Editor';
import { createContext, useContext } from 'react';
import { CursorMode, Dimensions, Zoom } from 'types';
import { DrawLocationProvider } from 'contexts/DrawLocationProvider';

type State = {
  imageSize?: Dimensions;
  zoom: Zoom;
};

const ActiveRegionEditorContext = createContext<State | undefined>(undefined);

export const ActiveRegionEditorProvider: React.FC<State> = ({
  children,
  ...props
}) => {
  return (
    <ActiveRegionEditorContext.Provider value={props}>
      <ZoomContext.Provider value={props.zoom}>
        <DrawLocationProvider
          defaultCursorMode={CursorMode.Pointer}
          pointsLimit={4}
        >
          {children}
        </DrawLocationProvider>
      </ZoomContext.Provider>
    </ActiveRegionEditorContext.Provider>
  );
};

export const useActiveRegionEditor = () => {
  const context = useContext(ActiveRegionEditorContext);

  if (context === undefined) {
    throw new Error(
      'useActiveRegionEditor must be used within a ActiveRegionEditorProvider'
    );
  }

  return context;
};
