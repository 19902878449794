import { useCallback, useState } from 'react';
import { LoadingStatus } from 'types';

type Options = {
  handleAlert?: (error: any) => void;
};

const defaultOptions = {
  handleAlert: (error: any) => {
    alert(`Message: ${error.message}\nStack: ${error.stack}`);
  },
};

const useAsyncCall = <T>(initialState?: T) => {
  const [data, setData] = useState(initialState);
  const [status, setStatus] = useState(LoadingStatus.Init);
  const [error, setError] = useState<any>();
  const isReady =
    status === LoadingStatus.Error || status === LoadingStatus.Success;
  const isLoading = status === LoadingStatus.Loading;

  const call = useCallback(
    async (asyncFunc: Promise<T>, options: Options = defaultOptions) => {
      setStatus(LoadingStatus.Loading);
      try {
        const res = await asyncFunc;
        setStatus(LoadingStatus.Success);
        setData(res);
        setError(undefined);
        return res;
      } catch (error) {
        setError(error);
        setStatus(LoadingStatus.Error);
        const { handleAlert = defaultOptions.handleAlert } = options;

        handleAlert(error);

        throw error;
      }
    },
    []
  );

  return { call, isReady, isLoading, data, setData, error };
};

export default useAsyncCall;
