import { convertToCamelCaseKeys } from 'utils';
import api from '..';
import { CheckinApiRes } from './types';

const postCheckinApi = async (userEmail: string) => {
  const res = await api.post('/camogram_queue/checkin', {
    user_id: userEmail,
  });

  return convertToCamelCaseKeys(res.data.results) as CheckinApiRes;
};

export default postCheckinApi;
