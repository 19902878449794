import api from '.';

// 'cams' is originally array, so turn into object grouping by 'cam_num'.
const configureCams = cams => {
  if (!cams) return {};
  const byCamNum = {};
  cams.forEach(camera => {
    byCamNum[camera.cam_num] = { ...camera, cameraId: camera.cam_num };
  });
  return byCamNum;
};

const fetchCamogramApi = async (storeId, datetime) => {
  try {
    const response = await api.get(`stores/${storeId}/camograms/${datetime}`);
    const cams = configureCams(response.data.cams);
    return { ...response.data, cams };
  } catch (error) {
    alert('Failed to fetch camogram', error);
    throw error;
  }
};

export default fetchCamogramApi;
