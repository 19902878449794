import { configureOmniImageId } from 'utils';
import api from '../index';

const configureCams = cams => {
  if (!cams) return {};
  const byCamNum = {};
  cams.forEach(camera => {
    const { cam_num, shelf_id, camogram_section_id } = camera;
    const imageId = configureOmniImageId(
      cam_num,
      shelf_id,
      camogram_section_id
    );
    byCamNum[imageId] = {
      ...camera,
      cam_num: imageId,
      cameraId: cam_num,
      shelfId: camera.shelf_id,
      sectionId: camera.camogram_section_id,
    };
  });
  return byCamNum;
};

const configureAnnotators = annotators => {
  if (!annotators) return {};
  const byCamNum = {};
  Object.entries(annotators).forEach(([camNum, shelfs]) => {
    Object.entries(shelfs).forEach(([shelfId, sections]) => {
      Object.entries(sections).forEach(([sectionId, annotator]) => {
        const omniImageId = configureOmniImageId(camNum, shelfId, sectionId);
        byCamNum[omniImageId] = annotator;
      });
    });
  });
  return byCamNum;
};

const fetchOmniCamogramApi = async (storeId, datetime) => {
  try {
    const response = await api.get(
      `stores/${storeId}/section_camograms/${datetime}`
    );
    const cams = configureCams(response.data.cams);
    const annotators = configureAnnotators(response.data.annotators);
    return { ...response.data, cams, annotators };
  } catch (error) {
    alert('Failed to fetch camogram', error);
    throw error;
  }
};

export default fetchOmniCamogramApi;
