import api from '.';

const fetchSkuImageApi = async uuid => {
  try {
    const res = await api.post(`catalog/api/v2/store_info/sku_image`, {
      sku_uuid: [uuid],
    });
    return res.data[0]?.image_url ?? null;
  } catch (e) {
    return null;
  }
};

export default fetchSkuImageApi;
