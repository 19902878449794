import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCameraImagesApi,
  fetchCamerasApi,
  fetchCamogramApi,
  fetchImageOptionsApi,
  fetchOmniImagesApi,
  fetchOmniCamogramApi,
  fetchStoresApi,
  fetchHighResCameraImagesApi,
} from 'utils/api';
import fetchGenericCamogramApi from 'utils/api/fetchGenericCamogramApi';
import {
  SET_VISIBLE,
  INIT_VISIBLE,
  SET_MENU,
  SET_CURSOR_MODE,
  ADD_AND_SELECT_PRODUCT_LOCATION,
  SET_ACTIVE_LOCATION,
  CREATE_EXTREME_ZOOM_IMAGE,
  SET_SELECTED_EXTREME_ZOOM_SHELF,
  REMOVE_EXTREME_ZOOM_IMAGE,
  SET_EDITOR_IMAGE_SIZE,
  CLOSE_COMPARE,
  START_COMPARE_CAMOGRAMS,
  RESET_CURRENT_CAMOGRAM,
  START_COMPARE_FF_IMAGES,
  START_COMPARE_FF_IMAGES_FOR_EXTREME_ZOOM,
  START_COMPARE_OMNI_CAMOGRAMS,
  TOGGLE_ASIDE,
} from './types';

export const setMenu = menu => ({
  type: SET_MENU,
  payload: menu,
});

export const initVisible = (id, show) => ({
  type: INIT_VISIBLE,
  payload: { id, value: show },
});

export const showLayer = id => ({
  type: SET_VISIBLE,
  payload: { id, value: true },
});

export const hideLayer = id => ({
  type: SET_VISIBLE,
  payload: { id, value: false },
});

export const setCursorMode = mode => ({
  type: SET_CURSOR_MODE,
  payload: mode,
});

export const addAndSelectProductLocation = (
  camNum,
  region,
  itemUuid,
  itemName,
  locationUuid,
  rotation = 0,
  position
) => ({
  type: ADD_AND_SELECT_PRODUCT_LOCATION,
  payload: {
    region,
    item_uuids: itemUuid ? [itemUuid] : [],
    item_labels: itemName ? [itemName] : [],
    camNum,
    location_uuid: locationUuid,
    rotation,
    position,
  },
});

export const setActiveLocation = location => ({
  type: SET_ACTIVE_LOCATION,
  payload: location,
});

export const setEditorImageSize = size => ({
  type: SET_EDITOR_IMAGE_SIZE,
  payload: size,
});

export const fetchImageOptions = createAsyncThunk(
  'fetchImageOptions',
  async ({ storeId, camNum, datetime }) =>
    fetchImageOptionsApi(storeId, camNum, datetime)
);

export const fetchCamogramInitialData = createAsyncThunk(
  'fetchCamogramInitialData',
  async ({ storeId, datetime }) => {
    const [
      camogram,
      cameraImages,
      cameraExtrinsics,
      stores,
    ] = await Promise.all([
      fetchCamogramApi(storeId, datetime),
      fetchCameraImagesApi(storeId, datetime),
      fetchCamerasApi(storeId),
      fetchStoresApi(),
    ]);

    return { camogram, cameraImages, cameraExtrinsics, stores };
  }
);

export const fetchHighResCamogramInitialData = createAsyncThunk(
  'fetchCamogramInitialData',
  async ({ storeId, datetime, type }) => {
    const [camogram, cameraImages, cameras, stores] = await Promise.all([
      fetchGenericCamogramApi(storeId, datetime),
      fetchHighResCameraImagesApi(storeId, datetime),
      fetchCamerasApi(storeId),
      fetchStoresApi(),
    ]);

    const { cameraExtrinsics } = cameras;

    return {
      camogram,
      cameraImages,
      cameraExtrinsics,
      stores,
    };
  }
);

export const fetchOmniCamogramInitialData = createAsyncThunk(
  'fetchOmniCamogramInitialData',
  async ({ storeId, datetime }) => {
    const [camogram, { images }, stores] = await Promise.all([
      fetchOmniCamogramApi(storeId, datetime),
      fetchOmniImagesApi({ storeId, datetime }),
      fetchStoresApi(),
    ]);

    return { camogram, cameraImages: images, stores };
  }
);

export const startCompareCamograms = ({ storeId, datetime, uuid }) => ({
  type: START_COMPARE_CAMOGRAMS,
  payload: { storeId, datetime, uuid },
});

export const startCompareOmniCamograms = ({ storeId, datetime, uuid }) => ({
  type: START_COMPARE_OMNI_CAMOGRAMS,
  payload: { storeId, datetime, uuid },
});

export const startCompareFFImages = ({ storeId, datetime, camNum, uuid }) => ({
  type: START_COMPARE_FF_IMAGES,
  payload: { storeId, datetime, camNum, uuid },
});

export const startCompareFFImagesForExtremeZoom = ({
  storeId,
  datetime,
  camNum,
  uuid,
}) => ({
  type: START_COMPARE_FF_IMAGES_FOR_EXTREME_ZOOM,
  payload: { storeId, datetime, camNum, uuid },
});

export const extremeZoomImageRequestStart = ({
  point,
  storeId,
  shelfId,
  sectionId,
}) => ({
  type: CREATE_EXTREME_ZOOM_IMAGE,
  payload: { point, storeId, shelfId, sectionId },
});

export const updateExtremeZoomImage = ({
  point,
  storeId,
  shelfId,
  sectionId,
  url,
  pollTimeout,
}) => ({
  type: CREATE_EXTREME_ZOOM_IMAGE,
  payload: { point, storeId, shelfId, sectionId, url, pollTimeout },
});

export const removeExtremeZoomImage = ({
  point,
  storeId,
  shelfId,
  sectionId,
}) => ({
  type: REMOVE_EXTREME_ZOOM_IMAGE,
  payload: { point, storeId, shelfId, sectionId },
});

export const setSelectedExtremeZoomShelf = ({ selectedShelf }) => ({
  type: SET_SELECTED_EXTREME_ZOOM_SHELF,
  payload: { selectedShelf },
});

export const closeCompare = ({ uuid }) => ({
  type: CLOSE_COMPARE,
  payload: { uuid },
});

export const resetCurrentCamogram = () => ({
  type: RESET_CURRENT_CAMOGRAM,
});

export const showAside = () => ({
  type: TOGGLE_ASIDE,
  payload: { isVisible: true },
});

export const hideAside = () => ({
  type: TOGGLE_ASIDE,
  payload: { isVisible: false },
});

export { default as fetchFFImages } from './fetchFFImages';
export { default as fetchFFImagesForExtremeZoom } from './fetchFFImagesForExtremeZoom';
export { default as postSkuReport } from './postSkuReport';
export { default as fetchSkus } from './fetchSkus';
export { default as fetchStores } from './fetchStores';
export { default as fetchSkuImage } from './fetchSkuImage';
