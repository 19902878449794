import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { closeCompare, startCompareFFImagesForExtremeZoom } from 'actions';
import CamerasLayerToggle from 'components/CameraToggles';
import {
  COMPARE_TYPE_CAMOGRAMS,
  COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM,
} from 'reducers/compare';
import { v4 } from 'uuid';
import useShortcut from 'hooks/useShortcut';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useJob } from '../helpers';

const ListContainer = styled.div`
  overflow: auto;
  padding: 16px;
  > * {
    margin-bottom: 16px;
  }
`;

const existsCompareType = (panes, type) =>
  Boolean(panes.find(pane => type === pane.type));

const useCompareKeyboardShortcut = ({
  shortcutKey,
  panes,
  actionTypes,
  dispatch,
  handleShortcut,
}) => {
  useShortcut(shortcutKey, e => {
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault();
      if (actionTypes.some(type => existsCompareType(panes, type))) {
        const pane = panes.find(({ type }) => actionTypes.includes(type));

        dispatch(closeCompare({ uuid: pane.uuid }));
      } else {
        handleShortcut();
      }
    }
  });
};

const Compare = ({ onClickFFImagesForExtremeZoom }) => {
  const dispatch = useDispatch();
  const { storeId } = useParams();

  const panes = useSelector(state => state.compare.panes);

  useCompareKeyboardShortcut({
    shortcutKey: 'e',
    panes,
    actionTypes: [COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM],
    dispatch,
    handleShortcut: onClickFFImagesForExtremeZoom,
  });

  return (
    <ListContainer>
      <>
        {onClickFFImagesForExtremeZoom &&
          !existsCompareType(
            panes,
            COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM
          ) && (
            <Button
              variant="contained"
              fullWidth
              onClick={onClickFFImagesForExtremeZoom}
            >
              Display Extreme Zoom →
            </Button>
          )}
      </>
      {existsCompareType(panes, COMPARE_TYPE_CAMOGRAMS) && (
        <CamerasLayerToggle />
      )}
    </ListContainer>
  );
};

const CompareContainer = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const datetime = useJob(job => job.data.timestamp);
  const camNum = useJob(job => job.providerKey.cameraId);

  const onClickFFImagesForExtremeZoom = () => {
    const uuid = v4();
    dispatch(
      startCompareFFImagesForExtremeZoom({
        storeId,
        datetime,
        camNum,
        uuid,
      })
    );
  };

  return (
    <Compare onClickFFImagesForExtremeZoom={onClickFFImagesForExtremeZoom} />
  );
};

export default CompareContainer;
