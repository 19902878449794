import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAsyncCall from './useAsyncCall';

const useAsyncDispatch = <T>(initialState?: T) => {
  const dispatch = useDispatch();
  const { call, ...others } = useAsyncCall(initialState);

  const dispatchWrapper = useCallback(
    async action => {
      const dispatchAction = async () => {
        const res = await dispatch(action);
        if (res.error) {
          // To make an error message more understandable
          const error = new Error(res.error.message);
          error.stack = res.error.stack;
          error.name = res.error.name;
          console.warn(res);
          throw error;
        }

        return res;
      };

      return call(dispatchAction());
    },
    [dispatch, call]
  );

  return { dispatch: dispatchWrapper, ...others };
};

export default useAsyncDispatch;
