import { CircularProgress } from '@material-ui/core';
import useSkuImage from 'hooks/useSkuImage';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Image = styled.img`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 5px;
  background-color: transparent;
`;

const Container = styled.div`
  margin-right: 8px;
  background-color: lightgrey;
  min-width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const SkuImageComponent = ({
  onClick,
  alt,
  imageUrl,
  width = 80,
  height = 80,
  ...others
}) => {
  return (
    <Container width={width} height={height}>
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt={alt}
          onClick={onClick}
          width={width}
          height={height}
          {...others}
        />
      ) : (
        <CircularProgress size={30} color="inherit" />
      )}
    </Container>
  );
};

const SkuImageContainer = props => {
  const { storeId } = useParams();
  const { imageUrl } = useSkuImage(storeId, props.uuid);

  return <SkuImageComponent {...props} imageUrl={imageUrl} />;
};

export default SkuImageContainer;
