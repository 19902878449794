import { EVENT_TASK_BASED_DISCARD } from 'common/constants';
import { useAppSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchCameraJobForAnnotation } from 'slices/taskBasedSlice';
import { track } from 'utils/segmentAnalytics';
import JobButton from './JobButton';

export default function DiscardButton() {
  const dispatch = useDispatch();
  const selectedJob = useAppSelector(state => state.taskBased.selectedJob);
  const { storeId, groupId } = useParams<{
    storeId: string;
    groupId: string;
  }>();
  const onClick = async job => {
    if (
      window.confirm(
        'Are you sure? Discarding will remove the unsaved annotation and it cannot be undone.'
      )
    ) {
      await toast.promise(
        dispatch(
          fetchCameraJobForAnnotation({
            storeId,
            groupId,
            providerKey: job.providerKey,
            version: job.version,
          })
        ) as any,
        {
          pending: 'Discarding...',
          success: 'Discarded!',
          error: 'Could not discard, please try again.',
        }
      );
      track(EVENT_TASK_BASED_DISCARD, {
        jobId: job.id,
        currentTaskName: job.currentTaskName,
        cameraId: job.cameraId,
        storeId: job.storeId,
        groupId: job.groupId,
      });
    }
  };
  const disabled = selectedJob === undefined;
  return (
    <JobButton color="secondary" disabled={disabled} onClick={onClick}>
      Discard
    </JobButton>
  );
}
