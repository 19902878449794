import useResize from 'hooks/useResize';
import React, { createContext } from 'react';
import styled from 'styled-components';
import FFImageForExtremeZoom from './FFImageForExtremeZoom';
import { Mouse } from '@material-ui/icons';

const FFImagesContainer = styled.div`
  background-color: #eee;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  padding-bottom: 100px;
  z-index: 100;

  > * {
    margin-bottom: 1.2rem;
  }
`;

const HelpText = styled.div`
  background-color: #cff4fc;
  border: 1px solid #b6effb;
  border-radius: 10px;
  color: #055160;
  padding: 4px 8px;
  display: flex;
  align-items: center;
`;

export const ContainerWidthContext = createContext(0);
const FFImagesList = ({ children }) => {
  const { ref, width } = useResize();

  return (
    <ContainerWidthContext.Provider value={width}>
      <FFImagesContainer ref={ref}>{width && children}</FFImagesContainer>
    </ContainerWidthContext.Provider>
  );
};

const FFImagesComponent = ({ images }) => {
  return (
    <FFImagesList>
      <HelpText>
        <Mouse style={{ paddingRight: '8px' }} />
        <div>
          Double-click to load an{' '}
          <u>
            <i>Extreme Zoom</i>
          </u>{' '}
          image
        </div>
      </HelpText>
      {images.map(image => (
        <FFImageForExtremeZoom
          key={image.url}
          url={image.url}
          shelfId={image.shelfId}
          sectionId={image.sectionId}
          uploadedAt={image.uploadedAt}
        />
      ))}
    </FFImagesList>
  );
};

export default FFImagesComponent;
