import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import Konva from 'konva';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './utils/toastify-styles.css';
import * as Sentry from '@sentry/react';
import * as Integrations from '@sentry/integrations';
import Auth from '@standard/portal';
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';

import store from './store';
import StoresProvider from 'components/StoresProvider';
import PortalStyle from './PortalStyle';
import Camogram from 'pages/Camogram';
import Home from 'pages/Home';
import CamogramPicker from 'pages/CamogramPicker';
import analytics, { getTracker } from 'utils/segmentAnalytics';
import OmniCamogram from 'pages/OmniCamogram';
import AdminPage from 'pages/Admin';
import AdminScanogramMetricsPage from 'pages/AdminScanogramMetrics';
import AdminHighresCameraSettingsPage from 'pages/AdminHighresCameraSettings';
import TaskBasedEditor from 'pages/TaskBasedEditor/Annotation';
import { createTheme } from '@material-ui/core';
import AdminPTZCameraSettingsPage from 'pages/AdminPTZCameraSettings';
import useAccount from 'hooks/useAccount';
import TaskBasedReviewAnnotationsPage from 'pages/TaskBasedEditor/ReviewAnnotations';
import Checkin from 'components/Checkin';
import TaskList from 'pages/TaskList';

Konva.pixelRatio = 1;
Konva.dragDistance = 0.2;

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#2876d4',
    },
  },
});

if (process.env.REACT_APP_SENTRY) {
  const logErrors = new Integrations.CaptureConsole({ levels: ['error'] });
  const integrations = process.env.REACT_APP_SENTRY_ORG_SLUG
    ? [logErrors, new SentryFullStory(process.env.REACT_APP_SENTRY_ORG_SLUG)]
    : [logErrors];
  const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'dev';
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations,
    environment,
    beforeSend(event, hint) {
      const error = hint && hint.originalException;
      return error.isKnownError?.() ? null : event;
    },
  });
}

if (process.env.REACT_APP_SEGMENT_KEY) {
  analytics.load(process.env.REACT_APP_SEGMENT_KEY);
}

if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
  FullStory.init({
    orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  });
}

// Register the user on Sentry
const onUser = ({ name, email }) => {
  if (!process.env.REACT_APP_SENTRY) {
    Sentry.configureScope(function (scope) {
      scope.setUser({ username: name, email });
    });
  }
  if (process.env.REACT_APP_SEGMENT_KEY) {
    getTracker().identify(email, { name, email });
  }

  if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
    FullStory.identify(email, {
      displayName: name,
      email,
    });
  }
};

const AuthRoutes = () => {
  const ldClient = useLDClient();
  const { email, name } = useAccount();
  useEffect(() => {
    if (!ldClient) return;
    ldClient.identify({ key: email, email, name });
  }, [ldClient, email, name]);
  return (
    <>
      <PortalStyle />
      <StoresProvider>
        <BrowserRouter>
          <Switch>
            <Route
              path="/stores/:storeId/camograms/:datetime"
              component={Camogram}
            />
            <Route
              path="/stores/:storeId/highres-camograms/:datetime"
              component={Camogram}
            />
            <Route
              path="/stores/:storeId/omni-camograms/:datetime"
              component={OmniCamogram}
            />
            <Route
              path="/stores/:storeId/task-based-editor/:groupId"
              component={TaskBasedEditor}
            />
            <Route
              path="/stores/:storeId/task-based-review-annotations/:groupId"
              component={TaskBasedReviewAnnotationsPage}
            />
            <Route path="/stores/:storeId" component={CamogramPicker} />
            <Route
              path="/admin/stores/:storeId/highres_camera_settings"
              component={AdminHighresCameraSettingsPage}
            />
            <Route
              path="/admin/stores/:storeId/ptz_camera_settings"
              component={AdminPTZCameraSettingsPage}
            />
            <Route
              path="/admin/stores/:storeId/scanogram_metrics"
              component={AdminScanogramMetricsPage}
            />
            <Route path="/admin" component={AdminPage} />
            <Route path="/stores" component={Home} />
            <Route path="/" component={TaskList} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </StoresProvider>
    </>
  );
};

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ToastContainer></ToastContainer>
          <Auth onUser={onUser}>
            <Checkin>
              <AuthRoutes />
            </Checkin>
          </Auth>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  );
}

const WithLDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
})(App);

export default WithLDProvider;
