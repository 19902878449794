import { useAppSelector } from 'hooks';
import { TaskType } from 'types/taskBased';
import { ContentSwitcher } from '../Content';
import { useJob } from '../Content/helpers';
import { configureTaskContentState } from '../helpers';

const completionTasks = [TaskType.Completed];

export default function ContentSwitcherContainer() {
  const taskType = useJob(job => job.currentTaskName);
  const isAllJobsDone = useAppSelector(state => {
    const jobs = Object.values(state.camogramJobs.listData);
    return jobs.every(job => completionTasks.includes(job.currentTaskName));
  });
  const isTaskDone = completionTasks.includes(taskType);

  const taskContentState = configureTaskContentState(
    isAllJobsDone,
    isTaskDone,
    taskType
  );

  return <ContentSwitcher taskContentState={taskContentState} />;
}
