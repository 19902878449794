import { useCallback, useRef } from 'react';

const useHold = (onHold, { delay = 300, interval = 100 } = {}) => {
  const intervalTimer = useRef();
  const timeout = useRef();
  const longPressTriggered = useRef(false);

  const start = useCallback(
    event => {
      timeout.current = setTimeout(() => {
        onHold(event);
        longPressTriggered.current = true;
        intervalTimer.current = setInterval(() => {
          onHold(event);
        }, interval);
      }, delay);
    },
    [onHold, delay, interval]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      if (timeout.current) clearTimeout(timeout.current);
      if (intervalTimer.current) clearInterval(intervalTimer.current);
      if (shouldTriggerClick && !longPressTriggered.current) onHold(event);
      longPressTriggered.current = false;
    },
    [onHold, longPressTriggered]
  );

  return {
    onMouseDown: e => start(e),
    onMouseUp: e => clear(e),
    onMouseLeave: e => clear(e, false),
  };
};

export default useHold;
