import { Location, Modify, ProviderKey } from './';

export enum TaskType {
  SelectUnchangedPreviousLocations = 'select_unchanged_previous_locations',
  SelectLocalizedPreviousLocations = 'select_localized_previous_locations',
  SelectLocalizedProposedLocations = 'select_localized_proposed_locations',
  CreateLocations = 'create_locations',
  ClassifyLocations = 'classify_locations',
  ReviewAnnotations = 'review_annotations',
  RoutePreviousLocations = 'route_previous_locations',
  ReshapePreviousLocations = 'reshape_previous_locations',
  Completed = '',
}

export type CamogramJobBase = {
  currentTaskName: TaskType;
  id: string;
  workflowTasksInfo: any[];
  storeId: string;
  data: any;
  datetime: string;
  groupId: string;
  providerKey: ProviderKey;
  cameraImageId: string;
  version: number;
  hasProgressedToReviewAnnotations: boolean;
  createdAt: string;
  createdBy: string;
  startedReviewAnnotationsAt?: string;
  startedReviewAnnotationsBy?: string;
};

export type CamogramJob = CamogramJobBase & {
  context: any;
};

export enum LocationReviewStatus {
  Init = 'Init',
  Unknown = 'Unknown',
  Changed = 'Changed',
  Unchanged = 'Unchanged',
  UseLocation = 'UseLocation',
  UnuseLocation = 'UnuseLocation',
  Classified = 'Classified',
  Reshaped = 'Reshaped',
  // The backend has CAP tags
  Keep = 'KEEP',
  Reshape = 'RESHAPE',
  Reclassify = 'RECLASSIFY',
  Discard = 'DISCARD',
}

export type LocationToReview = Location & {
  status: LocationReviewStatus;
  key: string;
  reviewedAt?: string;
  predictions?: NormalizedPrediction[];
};

export type LocationToReviewWithSkuId = Modify<
  LocationToReview,
  { skuId: string; skuLabel: string }
>;

export type LocationToReviewWithSku = LocationToReviewWithSkuId & { sku: any };

export type Prediction = {
  ids: string[];
  locationId: string;
  similarities: number[];
};

type NormalizedPrediction = {
  skuId: string;
  similarity: number;
};

export enum JobContextLocationKeys {
  UnchangedPreviousLocations = 'unchangedPreviousLocations',
  KeptPreviousLocations = 'keptPreviousLocations',
  PreviousLocationsToReclassify = 'previousLocationsToReclassify',
  ReshapedPreviousLocations = 'reshapedPreviousLocations',
  LocalizedPreviousLocations = 'localizedPreviousLocations',
  LocalizedProposedLocations = 'localizedProposedLocations',
  CreatedLocations = 'createdLocations',
}
