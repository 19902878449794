import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import styled from 'styled-components';

import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { closeCompare } from 'actions';
import { PaneContext } from './contexts';

export const HeaderContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid #ccc;
`;

export const HeaderText = styled.div`
  padding: 8px 0 8px 16px;
`;

const PaneLayout = ({ header, children }) => {
  const { uuid } = useContext(PaneContext);
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeCompare({ uuid }));

  return (
    <>
      <HeaderContainer>
        <HeaderText>{header}</HeaderText>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </HeaderContainer>
      {children}
    </>
  );
};

export default PaneLayout;
