import { SET_CAMOGRAMS } from 'actions/types';

const camograms = (state = {}, { type, payload }) => {
  if (type === SET_CAMOGRAMS) {
    return {
      ...state,
      [payload.storeId]: payload.camograms,
    };
  }
  return state;
};

export default camograms;
