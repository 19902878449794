import api from '../';
import { configureOmniImageId } from '../../index';

const configureCameraImage = image => ({
  src: image.image,
  imageSource: image.image_source,
  status: image.status,
  unknown: image.unknown,
});

const fetchOmniImagesApi = async ({ storeId, datetime }) => {
  const res = await api.get(
    `/stores/${storeId}/section_camograms/${datetime}/images`
  );
  const imagesWithOmniImageId = {};
  Object.entries(res.data).forEach(([camNum, shelfs]) => {
    Object.entries(shelfs).forEach(([shelfId, sections]) => {
      Object.entries(sections).forEach(([sectionId, image]) => {
        const omniImageId = configureOmniImageId(camNum, shelfId, sectionId);
        imagesWithOmniImageId[omniImageId] = configureCameraImage(image);
      });
    });
  });

  return { images: imagesWithOmniImageId };
};

export default fetchOmniImagesApi;
