import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { hideAside, showAside } from 'actions';
import { useDispatch, useSelector } from 'react-redux';

const AsideVisibilityToggler = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  background: white;
  padding: 5px 10px 5px 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #999;
  border-left: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: #eee;
  }
`;

const AsideVisibilityToggle = () => {
  const dispatch = useDispatch();
  const { isVisible } = useSelector(state => state.aside);

  if (isVisible) {
    return (
      <AsideVisibilityToggler onClick={() => dispatch(hideAside())}>
        <ChevronLeft style={{ marginLeft: '-10px' }} />
        <span>Hide</span>
      </AsideVisibilityToggler>
    );
  } else {
    return (
      <AsideVisibilityToggler onClick={() => dispatch(showAside())}>
        <span>Show</span>
        <ChevronRight style={{ marginRight: '-10px' }} />
      </AsideVisibilityToggler>
    );
  }
};

export default AsideVisibilityToggle;
