import React, { useState } from 'react';
import styled from 'styled-components';

const MENU_ITEM_HEIGHT = 40;

export const Stripes = styled.div`
  background: #eee;
  border-radius: 6px;
  margin: 0 0 12px 0;
  position: relative;
  background: ${`repeating-linear-gradient(
    to bottom,
    #eee 0,
    #eee ${MENU_ITEM_HEIGHT}px,
    #f5f5f5 ${MENU_ITEM_HEIGHT}px,
    #f5f5f5 ${MENU_ITEM_HEIGHT * 2}px
  )`};
  background-position: top left;
  border-radius: 0 6px 6px 6px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 22px;
`;

const SmallLabel = styled.div`
  display: inline-block;
  width: auto;
  padding: 0 8px;
  height: 22px;
  line-height: 22px;
  color: #666;
  background: #f5f5f5;
  border-radius: ${p => (p.show ? '6px 6px 0 0' : '6px')};
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  :hover {
    background: #ddd;
  }
`;

const Actions = styled.div`
  flex-grow: 0;

  button {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 3px;
    margin-left: 3px;
    font-size: inherit;
    padding: 0 5px;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      background: #ddd;
    }
    &:active {
      background: #ccc;
    }
  }
`;

export default function Group({ label, actions, children }) {
  const [show, setShow] = useState(true);
  return (
    <div>
      <Header>
        {label ? (
          <SmallLabel show={show} onClick={e => setShow(!show)}>
            {label}
          </SmallLabel>
        ) : null}
        {actions ? <Actions>{actions}</Actions> : null}
      </Header>
      <Stripes>{show ? children : null}</Stripes>
    </div>
  );
}
