import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import PaneLayout from './PaneLayout';
import ExtremeZoomImages from 'components/ExtremeZoomImages';

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;
const Header = () => {
  const selectedShelf = useSelector(
    state => state.extremeZoom.extremeZoomImages.selectedShelf
  );

  return (
    <HeaderContainer>Extreme Zoom Images - {selectedShelf}</HeaderContainer>
  );
};

const ExtremeZoomImagesPane = ({ uuid }) => {
  return (
    <PaneLayout header={<Header />} uuid={uuid}>
      <ExtremeZoomImages />
    </PaneLayout>
  );
};

export default ExtremeZoomImagesPane;
