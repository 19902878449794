import styled from 'styled-components';
import { PhotoCameraRounded } from '@material-ui/icons';
import { Button, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useAccount from 'hooks/useAccount';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { postCheckout } from 'slices/checkinSlice';
import { toast } from 'react-toastify';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  cursor: pointer;
  font-size: 24px;
  margin-left: 16px;
`;

const Version = styled.div`
  font-size: 16px;
  margin-left: 3px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #dddddd;
  padding-right: 60px;
  padding-left: 16px;
  color: #0d66ca;
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default function AnnotatorLayout({ children }) {
  const history = useHistory();
  const { isAdmin, email } = useAccount();
  const { dispatch, isLoading } = useAsyncDispatch();

  const onClickAdminMode = () => {
    history.push('/admin');
  };

  const onClickTitle = () => {
    history.push('/');
  };
  const onClickCheckout = () => {
    toast.promise(dispatch(postCheckout(email)), {
      pending: 'Checking out...',
      success: 'Checked Out!',
      error: 'Could not check-out, please try again.',
    });
  };

  const onClickStoreList = () => {
    history.push('/stores');
  };
  const onClickTaskQueue = () => {
    history.push('/');
  };
  const shouldShowStoreList = history.location.pathname === '/';
  const version = process.env.REACT_APP_VERSION;
  return (
    <Container>
      <Header>
        <HeaderLeftContainer>
          <PhotoCameraRounded fontSize="large" />
          <Title onClick={onClickTitle}>CamogramViz</Title>
          <Version>(v{version})</Version>
        </HeaderLeftContainer>
        <div>
          {shouldShowStoreList ? (
            <Button
              style={{ textTransform: 'none', marginRight: '10px' }}
              onClick={onClickStoreList}
              variant="contained"
              color="primary"
            >
              Store List
            </Button>
          ) : (
            <Button
              style={{ textTransform: 'none', marginRight: '10px' }}
              onClick={onClickTaskQueue}
              variant="contained"
              color="primary"
            >
              Task Queue
            </Button>
          )}
          {isAdmin && (
            <Button
              style={{ textTransform: 'none', marginRight: '10px' }}
              onClick={onClickAdminMode}
              variant="contained"
              color="primary"
            >
              Admin Mode
            </Button>
          )}
          <Button onClick={onClickCheckout} variant="contained" color="primary">
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Check Out'
            )}
          </Button>
        </div>
      </Header>
      <div>{children}</div>
    </Container>
  );
}
