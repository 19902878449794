import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Home } from '@material-ui/icons';

import ActionDialog from 'components/ActionDialog';

const HomeDialog = ({ open, setOpen, onClickOk }) => {
  const title = 'Back to home?';
  const text = `You haven't saved the current annotations. Switching will remove the unsaved annotations. This cannot be undone.`;

  return (
    <ActionDialog
      open={open}
      setOpen={setOpen}
      title={title}
      text={text}
      cancelable
      actions={[
        { label: 'Back to home', color: 'secondary', onClick: onClickOk },
      ]}
    />
  );
};

const HomeButton = ({ editing, onClickHome }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const _onClickHome = () => {
    if (editing) {
      setOpenDialog(true);
    } else {
      onClickHome();
    }
  };
  return (
    <>
      <Button
        onClick={_onClickHome}
        style={{ minWidth: '20px', padding: '6px 12px' }}
      >
        <Home />
      </Button>
      <HomeDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClickOk={onClickHome}
      />
    </>
  );
};

export default HomeButton;
