import { Coordinate, HighresActiveRegion, HighresActiveSection } from 'types';
import api from '.';

type FetchHighresActiveRegionsApiActiveShelfsRes = {
  camogram_section_id: string[];
  coordinates: Coordinate[];
};

type FetchHighresActiveRegion = {
  cam_num: number;
  camogram_section_ids: string[];
  enabled: boolean;
  active_shelfs: FetchHighresActiveRegionsApiActiveShelfsRes[];
};

type FetchHighresActiveRegionsApiRes = {
  active_regions: FetchHighresActiveRegion[];
};

const configureActiveShelfs = (
  raw: FetchHighresActiveRegionsApiActiveShelfsRes[]
): HighresActiveSection[] => {
  const activeShelfs = raw.map(rawActiveShelf => ({
    camogramSectionId: rawActiveShelf.camogram_section_id[0],
    coordinates: rawActiveShelf.coordinates,
  }));

  return activeShelfs;
};

const configureActiveRegions = (
  raw: FetchHighresActiveRegionsApiRes
): HighresActiveRegion[] => {
  const activeRegions = raw.active_regions.map(rawActiveRegion => ({
    camNum: String(rawActiveRegion.cam_num),
    enabled: rawActiveRegion.enabled,
    activeSections: configureActiveShelfs(rawActiveRegion.active_shelfs),
  }));

  return activeRegions;
};

const fetchHighresActiveRegionsApi = async (storeId: string) => {
  const endpoint = `/admin/stores/${storeId}/static_4k_active_regions`;
  const res = await api.get(endpoint);

  const data = res.data.results[0];

  if (data === undefined) {
    throw new Error(
      `The response of ${endpoint} was empty. Please contact the camogram backend service owner in #team-camogram!\nstoreId: ${storeId}`
    );
  }
  return configureActiveRegions(data);
};

export default fetchHighresActiveRegionsApi;
