import { Dialog } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  font-size: 16px;
  margin: 20px;
  border-collapse: collapse;
  tr {
    border-bottom: 1.5px dashed #eee;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const Kbd = styled.kbd`
  display: inline-block;
  height: 14px;
  line-height: 14px;
  background: #eee;
  border-radius: 2px;
  padding: 6px 12px;
  margin-right: 4px;
  margin-bottom: 3px;
  margin-top: 3px;
`;

const commands = [
  {
    command: ['a', 's', 'd', 'f'],
    title:
      'Choose Buttons in Step 1 - 3 ("Discard", "Use", "Keep", etc). The order is from left',
  },
  { command: ['s (Step 4)'], title: 'Choose "Select" mode' },
  { command: ['d (Step 4)'], title: 'Choose "Draw" mode' },
  { command: ['l'], title: 'Focus on "Filter items" bar' },
  { command: ['enter'], title: 'Send the selected SKU' },
  {
    command: ['escape'],
    title: 'Discard a polygon halfway through drawing it',
  },
  {
    command: ['← / →'],
    title: 'Select Previous/Next Location',
  },
  {
    command: ['↓ / ↑'],
    title: 'Select Previous/Next SKU',
  },
];

const ShortcutList = () => (
  <Table>
    <tbody>
      {commands.map(({ command, title }) => (
        <tr key={command.join('_')}>
          <td>
            {command.map(k => (
              <Kbd key={k}>{k}</Kbd>
            ))}
          </td>
          <td>{title}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

type ShortcutListModal = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};
export function ShortcutListModal({ show, setShow }) {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Dialog open={show} onClose={handleClose} fullWidth>
      <ShortcutList />
    </Dialog>
  );
}

export default ShortcutList;
