import { TextField } from '@material-ui/core';
import Flex from 'components/Flex';
import styled from 'styled-components';
import { CamogramJobBase } from 'types/taskBased';
import { useFilters } from '../helpers';
import { formatDatetime } from 'utils/formatDatetime';
import GeneralTable from './GeneralTable';
import StoreSelector from './StoreSelector';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 16px;
  }
`;

export type InProgressCamogramJob = CamogramJobBase & {
  storeName: string;
  cameraInteger?: string;
};

const cofigureInProgressTableData = (jobs: InProgressCamogramJob[]) => {
  const keys = ['store', 'groupId', 'camera', 'createdAt', 'createdBy'];
  const labels = {
    store: 'Store',
    groupId: 'Group ID',
    camera: 'Camera',
    createdAt: 'Started At',
    createdBy: 'Started By',
  };
  const items = jobs.map(job => ({
    key: job.id,
    store: `${job.storeName} (${job.storeId})`,
    groupId: job.groupId,
    camera: job.cameraInteger ?? job.cameraImageId,
    createdAt: formatDatetime(job.createdAt),
    createdBy: job.createdBy,
  }));

  return { keys, labels, items };
};

type Props = {
  inProgressCamogramJobs: InProgressCamogramJob[];
};

export default function InProgressTable({ inProgressCamogramJobs }: Props) {
  const {
    selectedStoreId,
    handleChangeStoreId,
    userFilterText,
    cameraId,
    handleChangeCameraId,
    handleChangeUserFilterText,
    filtered,
  } = useFilters<InProgressCamogramJob>(inProgressCamogramJobs);

  const inProgressTableDatas = cofigureInProgressTableData(filtered);

  return (
    <Container>
      <Flex itemSpace={16} alignItems="flex-end">
        <StoreSelector
          value={selectedStoreId}
          handleChange={handleChangeStoreId}
        />
        <TextField
          label="Filter User"
          value={userFilterText}
          onChange={handleChangeUserFilterText}
        />
        <TextField
          label="Filter Camera"
          value={cameraId}
          onChange={handleChangeCameraId}
        />
      </Flex>
      <GeneralTable
        keys={inProgressTableDatas.keys}
        labels={inProgressTableDatas.labels}
        items={inProgressTableDatas.items}
      />
    </Container>
  );
}
