import React, { useState } from 'react';
import { Circle, Text } from 'react-konva';

const CirclePoint = ({
  fill = 'orange',
  stroke = 'black',
  strokeWidth,
  point,
  hover,
  dash,
  isSelected = false,
  onClick,
  textOverlay,
  scale,
}) => {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = e => {
    if (!onClick) return;
    e.evt.target.style.cursor = 'pointer';
    setIsHover(true);
  };
  const onMouseLeave = e => {
    if (!onClick) return;
    e.evt.target.style.removeProperty('cursor');
    setIsHover(false);
  };

  if (!strokeWidth) {
    strokeWidth = 3 / scale;
  }
  if (isSelected) {
    strokeWidth = 2 * strokeWidth;
  }

  const onMouseDown = event => {
    event.evt.stopPropagation();
  };

  const radius = 10 / scale;
  const fontSize = radius * 1.4;

  return (
    <>
      <Circle
        fill={fill}
        stroke={isSelected ? 'red' : stroke}
        strokeWidth={strokeWidth}
        dash={dash}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        onMouseDown={onMouseDown}
        perfectDrawEnabled={false}
        x={point[0]}
        y={point[1]}
        radius={radius}
        {...(isHover ? hover : {})}
      />
      <Text
        text={textOverlay}
        fontSize={fontSize}
        fill="black"
        x={point[0]}
        y={point[1]}
        offsetX={(radius - 1) / 2}
        offsetY={(radius + 1) / 2}
      />
    </>
  );
};

export default CirclePoint;
