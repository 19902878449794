import React from 'react';
import styled from 'styled-components';

import ItemLocation from './ItemLocation';

const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

export default function ItemLocations({
  imageHeight,
  imageWidth,
  productLocations,
  onMouseEnter,
  onMouseLeave,
  onClick = () => {},
}) {
  if (!productLocations) {
    return null;
  }

  return (
    <SVG
      width="100%"
      height="100%"
      viewBox={`0 0 ${imageWidth} ${imageHeight}`}
    >
      {productLocations.map((productLocation, idx) => (
        <ItemLocation
          // use index since there is no natural key here
          key={idx}
          productLocation={productLocation}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          onMouseEnter={e => onMouseEnter(e, productLocation)}
          onMouseLeave={e => onMouseLeave(e, productLocation)}
          onClick={e => onClick(e, productLocation)}
        />
      ))}
    </SVG>
  );
}
