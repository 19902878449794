import styled from 'styled-components';
import AnnotatorLayout from 'layouts/AnnotatorLayout';

import StorePicker from './StorePicker';

const Main = styled.div`
  margin: 50px auto;
  max-width: 1290px;
  width: 90%;
`;

export default function HomePage() {
  return (
    <AnnotatorLayout>
      <Main>
        <StorePicker />
      </Main>
    </AnnotatorLayout>
  );
}
