import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Coordinate } from 'types';

import HighlightLocation from 'components/HighlightLocation';
import { useAppSelector } from 'hooks';
import ItemLabel from '../ItemLabel';
import _ from 'lodash';
import { configureFlags } from '../../helpers';

type HighlightLocationContainerProps = {
  region: Coordinate[];
  rotation: number;
  imageSrc: string;
  zoomIn?: boolean;
  angle?: number;
  itemUuid?: string;
};

type QueryParams = {
  storeId?: string;
};

export default function HighlightLocationContainer({
  region,
  rotation,
  imageSrc,
  zoomIn = true,
  angle = 0,
  itemUuid = '',
}: HighlightLocationContainerProps) {
  const { storeId = '' } = useParams<QueryParams>();
  const itemInfo = useSelector(
    (state: RootState) => state.skus?.[storeId]?.[itemUuid] || null
  );
  const selectedLocation = useAppSelector(state => {
    const selectedLocationId = state.taskBased.selectedLocationId;
    const location = state.taskBased.locationsToReview.find(
      ({ id }) => selectedLocationId === id
    );

    if (location === undefined) throw new Error('Location not found');

    return location;
  });

  const flags = configureFlags(selectedLocation);

  return (
    <HighlightLocation
      angle={angle}
      imageSrc={imageSrc}
      rotation={rotation}
      region={region}
      label={<ItemLabel itemInfo={itemInfo} flags={flags} />}
      zoomIn={zoomIn}
    />
  );
}
