import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSkuImageApi } from 'utils/api';

const fetchSkuImage = createAsyncThunk(
  'fetchSkuImage',
  async ({ storeId, skuUuid }) => {
    const imageUrl = await fetchSkuImageApi(skuUuid);

    return { imageUrl };
  }
);

export default fetchSkuImage;
