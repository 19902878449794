import styled from 'styled-components';
// @ts-ignore
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import {
  HighresActiveRegion,
  HighresActiveSection,
  HighresCamera,
  Section,
} from 'types';
const SIDE_WIDTH = 256;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: ${SIDE_WIDTH}px;
  flex-shrink: 0;
  align-self: stretch;
  padding: 16px;
  border-right: 1px solid grey;
  justify-content: flex-start;
`;
const ButtonsContainer = styled.div`
  display: flex;

  > * {
    margin-right: 16px;
  }
`;

const CamTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
`;

const NewRegionListTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

type SidebarProps = {
  onClickOk: () => void;
  onCancel: () => void;
  camera: HighresCamera;
  sections: Section[];
  selectedSectionId: string;
  selectedActiveSectionId: string;
  activeRegion: HighresActiveRegion;
  onChangeSelectedSection: (selectedSectionId: string) => void;
  onChangeSelectedActiveSection: (selectedActiveSectionId: string) => void;
  onDeleteActiveSection: (deletedActiveSection: HighresActiveSection) => void;
  onChangeEnabled: (enabled: boolean) => void;
};

export default function Sidebar({
  camera,
  sections,
  selectedSectionId,
  selectedActiveSectionId,
  activeRegion,
  onClickOk,
  onCancel,
  onChangeSelectedSection,
  onChangeSelectedActiveSection,
  onDeleteActiveSection,
  onChangeEnabled: onChangeEnabledProps,
}: SidebarProps) {
  const onChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeEnabledProps(event.target.checked);
  };
  return (
    <SideContainer>
      <ButtonsContainer>
        <Button variant="contained" color="primary" onClick={onClickOk}>
          OK
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonsContainer>
      <CamTitle>Cam {camera.id}</CamTitle>
      <NewRegionListTitle>Choose New Region</NewRegionListTitle>
      <ListContainer>
        <List dense>
          {sections.map(section => {
            return (
              <div key={section.camogramSectionId}>
                <ListItem
                  button
                  selected={selectedSectionId === section.camogramSectionId}
                  onClick={() =>
                    onChangeSelectedSection(section.camogramSectionId)
                  }
                >
                  <ListItemText>{section.camogramSectionId}</ListItemText>
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </ListContainer>
      <NewRegionListTitle>Active Regions</NewRegionListTitle>
      <ListContainer>
        <List dense>
          {activeRegion.activeSections?.map(section => (
            <div key={section.camogramSectionId}>
              <ListItem
                button
                selected={selectedActiveSectionId === section.camogramSectionId}
                onClick={() =>
                  onChangeSelectedActiveSection(section.camogramSectionId)
                }
              >
                <ListItemText>{section.camogramSectionId}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onDeleteActiveSection(section)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </ListContainer>
      <FormControlLabel
        control={
          <Switch
            checked={activeRegion.enabled}
            onChange={onChangeEnabled}
            name="enabled"
            color="primary"
          />
        }
        label="Camera Enabled"
      />
    </SideContainer>
  );
}
