import { toPath } from './helpers';
import Text from './Text';
import Link from './Link';
import { Section } from 'types';
import { useLayout2DContext } from '.';

const getCentroid = (height: number) => (points: any[]) =>
  points.reduce(
    (center, point, i, all) => {
      return [
        center[0] + point.x / all.length,
        center[1] + (height - point.y) / all.length,
      ];
    },
    [0, 0]
  );

type SectionType = Section & { color?: string };

type SectionsCompnentProps = {
  height: number;
  sections: SectionType[];
  onClick?: any;
};

function SectionsComponent({
  height,
  sections: sectionsProps,
  onClick,
}: SectionsCompnentProps) {
  const sections = sectionsProps.map(section => ({
    ...section,
    id: section.id,
    path: toPath(height)(section.coordinates),
    center: getCentroid(height)(section.coordinates),
    componentKey: `${section.shelfId}_${section.id}`,
  }));

  const sectionLabels = sections.map(({ id, center, componentKey }) => (
    <Text key={componentKey} center={center} fontSize="16px">
      {id}
    </Text>
  ));

  const sectionRectangles = sections.map(
    ({ id, path, componentKey, color = '#ffffff' }) => (
      <Link key={componentKey} id={id} onClick={onClick}>
        <path d={path} stroke={color} strokeWidth={12} />
      </Link>
    )
  );

  return <>{[sectionRectangles, sectionLabels]}</>;
}

export default function Sections() {
  const { height, layout } = useLayout2DContext();

  return <SectionsComponent height={height} sections={layout.sections} />;
}
