import api from '.';

const fetchStoresApi = async () => {
  const stores = await api.get('stores');

  return stores.data.results.map(store => ({
    id: store.id ? store.id : store.unique_id,
    ...store,
  }));
};

export default fetchStoresApi;
