import _ from 'lodash';
import { ProviderKey } from 'types';

enum CameraImageIdKeys {
  CameraId = 'cameraId',
  SectionId = 'camogramSectionId',
  ShelfId = 'shelfId',
  DataSource = 'dataSource',
}

const configureKeyValueString = (key: CameraImageIdKeys, value: string) =>
  `${key}__${value}`;

type KeyValueItem = [CameraImageIdKeys, string];

const exist = (value: any) => value !== undefined && value !== null;

// CameraImageID: key1__value1--key2__value2....
export const configureCameraImageIdFromProviderKey = (
  providerKey: ProviderKey
) => {
  const keyValues: KeyValueItem[] = [];

  if (exist(providerKey.cameraId)) {
    keyValues.push([CameraImageIdKeys.CameraId, providerKey.cameraId]);
  }

  if (
    providerKey.camogramSectionId !== undefined &&
    providerKey.camogramSectionId !== null
  ) {
    keyValues.push([
      CameraImageIdKeys.SectionId,
      providerKey.camogramSectionId,
    ]);
  }

  if (providerKey.shelfId !== undefined && providerKey.shelfId !== null) {
    keyValues.push([CameraImageIdKeys.ShelfId, providerKey.shelfId]);
  }

  const cameraImageId = keyValues
    .map(([key, value]) => configureKeyValueString(key, value))
    .join('--');

  return cameraImageId;
};
