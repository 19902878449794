import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Camera, EditorDataSource, Modify } from 'types';

import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchGenericImagesWithCameras, {
  FetchGenericImagesWithCamerasProps,
} from 'utils/api/fetchGenericImagesWithCamerasApi';
import { fetchCamerasApi } from 'utils/api';

type FetchCameraImagesProps = Modify<
  FetchGenericImagesWithCamerasProps,
  { dataSource?: EditorDataSource }
>;
const dataSourcesForImage = [
  EditorDataSource.Omni,
  EditorDataSource.StaticHighRes,
];

export const fetchCameras = createAsyncThunk(
  'fetchCameras',
  async ({ storeId }: { storeId: string }) => {
    const cameras = await fetchCamerasApi(storeId);

    return { cameras };
  }
);

const configureCameras = (raw: any): Cameras => {
  const { cameraExtrinsics, metas } = raw;
  const result: Cameras = {};

  Object.entries<any>(cameraExtrinsics).forEach(([cameraId, extrinsic]) => {
    const { angle } = extrinsic;
    const meta = metas[cameraId];

    result[cameraId] = {
      cameraId,
      angle,
      meta,
    };
  });

  return result;
};

type Cameras = {
  [key: string]: Camera;
};

export type CamerasState = {
  data: Cameras;
};

const initialState: CamerasState = {
  data: {},
};
const camerasSlice = createSlice({
  name: 'cameras',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCameras.fulfilled, (state, action) => {
      const { cameras } = action.payload;

      state.data = configureCameras(cameras);
    });
  },
});

export default camerasSlice;
