import api from '.';

const fetchSkusApi = async storeId => {
  const results = [];
  let url = `/catalog/get_store_skus_paginated?store_name=${storeId}&limit=5000`;
  while (url) {
    const response = await api.get(url);
    results.push(...response.data.results);
    url = response.data.meta.next;
  }
  // We want to display French Sku Name only in this store. The french name is in sku.name
  const shouldDisplayName = storeId === 'couchetard.ca.s67';
  const rawSkus = results
    .filter(sku => !sku.retired_at)
    .map(sku => ({
      ...sku,
      display_name: shouldDisplayName
        ? `${sku.display_name} (${sku.name})`
        : sku.display_name,
    }));

  return rawSkus;
};

export default fetchSkusApi;
