import { WORKFLOW_NAME, WORKFLOW_VERSION } from 'common/constants';
import { ProviderKey } from 'types';
import api from '..';
import { addNumericCamIdParam } from '../helpers';

type CreateJobApiProps = {
  storeId: string;
  providerKeys: ProviderKey[];
  datetime: string;
};

const configureProviderKeyForRequest = (raw: ProviderKey) => {
  const result = {
    camera_id: raw.cameraId,
    data_source: raw.dataSource,
  };

  if (raw.camogramSectionId !== undefined) {
    result['camogram_section_id'] = raw.camogramSectionId;
  }

  if (raw.shelfId !== undefined) {
    result['shelf_id'] = raw.shelfId;
  }

  return result;
};

const createBulkJobsApi = async ({
  storeId,
  providerKeys,
  datetime,
}: CreateJobApiProps) => {
  const jobsParm = providerKeys.map(providerKey => ({
    workflow_name: WORKFLOW_NAME,
    workflow_version: WORKFLOW_VERSION,
    data: {
      store_id: storeId,
      provider_key: configureProviderKeyForRequest(providerKey),
      timestamp: datetime,
    },
  }));
  const params = {
    jobs: jobsParm,
    group_jobs: true,
  };
  const res = await api.post(
    addNumericCamIdParam('task-based/generic/create-jobs'),
    params
  );
  const groupId = res.data.data[0].group_id;
  const jobs = res.data.data;
  return { jobs, groupId };
};

export default createBulkJobsApi;
