import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchSkuImagesApi from 'utils/api/fetchSkuImagesApi';

type Props = {
  storeId: string;
  skuUuids: string[];
};

const fetchSkuImages = createAsyncThunk(
  'fetchSkuImages',
  async ({ skuUuids }: Props) => {
    const imageUrls = await fetchSkuImagesApi(skuUuids);

    return { imageUrls };
  }
);

export default fetchSkuImages;
