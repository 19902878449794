import React, { memo } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import SimilarSkus from '../../SimilarSkus';
import SkuInfo from './SkuInfo';

const SkuContainer = styled.div`
  border-bottom: solid 1px #cdcdcd;
  max-width: 480px;
`;

const SkuInner = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 8px;
  flex-grow: 1;
`;

const SkuInnerWithHover = styled(SkuInner)`
  background-color: ${({ active }) =>
    active ? 'rgba(46, 137, 255, 0.4)' : '#fff'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  :hover {
    background-color: ${({ active, onClick }) =>
      onClick ? (active ? 'rgba(46, 137, 255, 0.4)' : '#c4c4c4') : '#fff'};
  }
`;

const Item = ({
  itemUuid,
  itemName,
  barcode,
  active = false,
  similarSkus = [],
  onClick,
  onClickSend,
  onClickSendReport = () => {},
  hideSimilar,
  hideReport,
}) => {
  const onClickItem = () => (onClick ? onClick({ itemName, itemUuid }) : null);

  return (
    <SkuContainer>
      <SkuInnerWithHover active={active} onClick={onClick}>
        <SkuInfo
          itemName={itemName}
          itemUuid={itemUuid}
          barcode={barcode}
          onClickSendReport={onClickSendReport}
          hideReport={hideReport}
        />
        {onClickSend && (
          <IconButton
            onClick={() => onClickSend({ name: itemName, uuid: itemUuid })}
          >
            <Send />
          </IconButton>
        )}
      </SkuInnerWithHover>
      {!hideSimilar && (
        <SimilarSkus
          similarSkus={similarSkus}
          onClickSend={onClickSend}
          onClickItem={onClickItem}
        />
      )}
    </SkuContainer>
  );
};

export default memo(Item);
