import { useAppSelector } from 'hooks';
import JobButton from './JobButton';
import { toast } from 'react-toastify';
import saveJobApi from 'utils/api/taskBasedEditor/saveJobApi';
import { configureTaskAnnotation } from 'slices/taskBasedSlice/helpers';
import { track } from 'utils/segmentAnalytics';
import { EVENT_TASK_BASED_SAVE } from 'common/constants';

export default function SaveButton() {
  const locationsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const onClick = async job => {
    const annotation = configureTaskAnnotation({ job, locationsToReview });
    await toast.promise(saveJobApi({ jobId: job.id, annotation }), {
      pending: 'Saving...',
      success: 'Task saved!',
      error: 'Could not save, please try again.',
    });
    track(EVENT_TASK_BASED_SAVE, {
      jobId: job.id,
      currentTaskName: job.currentTaskName,
      cameraId: job.cameraId,
      storeId: job.storeId,
      groupId: job.groupId,
    });
  };
  return <JobButton onClick={onClick}>Save</JobButton>;
}
