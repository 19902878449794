import Flex from 'components/Flex';
import styled from 'styled-components';

const Label = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export default function TaskCompleted() {
  return (
    <Flex justifyContent="center" alignItems="center" direction="column">
      <Label>All Tasks are Completed!🎉</Label>
      <Label>
        Please choose another camera or go back to Home if all tasks are done!
      </Label>
    </Flex>
  );
}
