import { useState } from 'react';
import { Check, FileCopy, Flag } from '@material-ui/icons';
import styled from 'styled-components';
import { Flags, FlagKeys } from 'types';

import formatPrice from 'utils/formatPrice';
import SkuImage from 'components/SkuImage';
import { COLORS } from 'common/constants';
import { isSkuUnavailable } from '../../helpers';

const CopyContainer = styled.div`
  margin-left: 8px;
`;

const Container = styled.div<{ isUnavailable: boolean }>`
  height: 96px;
  padding: 6px 8px;

  border: ${({ isUnavailable }) =>
    isUnavailable ? '4px solid yellow' : 'none'};
`;

const RetiredLabel = styled.div`
  color: yellow;
  font-size: 12px;
  font-weight: bold;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemDetails = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const FlagListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-end;
  flex-grow: 1;
`;

const FlagItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
`;

const configureFlagColor = (flagKey: FlagKeys) => {
  switch (flagKey) {
    case 'isOccluded':
      return COLORS.flags.isOccluded;
    case 'isGroup':
      return COLORS.flags.isGroup;
    case 'isUncertain':
      return COLORS.flags.isUncertain;
    case 'isEmpty':
      return COLORS.flags.isEmpty;
    default:
      throw new Error('Flag key not found');
  }
};

const configureFlagLabel = (flagKey: FlagKeys) => {
  switch (flagKey) {
    case 'isOccluded':
      return 'Occluded';
    case 'isGroup':
      return 'Group';
    case 'isUncertain':
      return 'Unknown';
    case 'isEmpty':
      return 'Empty';
    default:
      throw new Error('Flag key not found');
  }
};

const FlagList = ({ flags }: { flags: Flags }) => {
  const visibleFlags = Object.entries(flags).filter(
    ([, value]) => value === true
  ) as [FlagKeys, boolean][];
  return (
    <FlagListContainer>
      {visibleFlags.map(([key, value]) => (
        <FlagItemContainer key={key}>
          <Flag fontSize="small" style={{ color: configureFlagColor(key) }} />{' '}
          {configureFlagLabel(key)}
        </FlagItemContainer>
      ))}
    </FlagListContainer>
  );
};

export type ItemLabelInfo = {
  barcode: string;
  price: object;
  uuid: string;
  retired_at?: string;
  display_name: string;
};

type ItemProps = {
  itemInfo: ItemLabelInfo;
  flags?: Flags;
};

const ItemLabel = ({ itemInfo, flags }: ItemProps) => {
  if (!itemInfo) {
    return null;
  }

  const [copiedType, setCopiedType] = useState('');
  const { barcode, price, uuid, display_name } = itemInfo;
  const formattedPrice = formatPrice(price || null);
  const isUnavailable = isSkuUnavailable(itemInfo);

  const onClickCopy = (text, type) => () => {
    navigator.clipboard.writeText(text);
    setCopiedType(type);
  };

  const setBackgroundOnType = type =>
    copiedType === type ? 'rgba(255, 255, 0, 0.5)' : '';

  return (
    <Container isUnavailable={Boolean(isUnavailable)}>
      {isUnavailable && <RetiredLabel>Retired</RetiredLabel>}
      <ItemInfo>
        <span>
          <SkuImage uuid={uuid} alt="sku_image" height={80} width={80} />
        </span>

        <ItemDescription>
          <ItemDetails
            onClick={onClickCopy(display_name, 'name')}
            style={{ background: setBackgroundOnType('name') }}
          >
            <span>
              <b>Name: {display_name}</b>
            </span>
            <CopyContainer>
              {copiedType === 'name' ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <FileCopy style={{ fontSize: 16 }} />
              )}
            </CopyContainer>
          </ItemDetails>

          <ItemDetails
            onClick={onClickCopy(formattedPrice, 'price')}
            style={{ background: setBackgroundOnType('price') }}
          >
            <span>
              <b>Price:</b> {formattedPrice}
            </span>
            <CopyContainer>
              {copiedType === 'price' ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <FileCopy style={{ fontSize: 16 }} />
              )}
            </CopyContainer>
          </ItemDetails>

          <ItemDetails
            onClick={onClickCopy(uuid, 'uuid')}
            style={{ background: setBackgroundOnType('uuid') }}
          >
            <span>
              <b>UUID:</b> {uuid}
            </span>
            <CopyContainer>
              {copiedType === 'uuid' ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <FileCopy style={{ fontSize: 16 }} />
              )}
            </CopyContainer>
          </ItemDetails>

          <ItemDetails
            onClick={onClickCopy(barcode, 'barcode')}
            style={{ background: setBackgroundOnType('barcode') }}
          >
            <span>
              <b>Barcode:</b> {barcode}
            </span>
            <CopyContainer>
              {copiedType === 'barcode' ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <FileCopy style={{ fontSize: 16 }} />
              )}
            </CopyContainer>
          </ItemDetails>
        </ItemDescription>
        {flags && <FlagList flags={flags} />}
      </ItemInfo>
    </Container>
  );
};

export default ItemLabel;
