import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { PaneContext } from './contexts';

export const useCompareParams = () => {
  const { uuid } = useContext(PaneContext);
  return useSelector(state => {
    const pane = state.compare.panes.find(pane => pane.uuid === uuid);
    return pane.params;
  });
};
