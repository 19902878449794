// Shelf ID: stcg.jp.0000_shelf_52
// Section ID: 00

import _ from 'lodash';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';

// OmniCamNum: cam_0-stcg.jp.0000_shelf_52-00
export const configureOmniImageId = (camNum, shelfId, sectionId) =>
  `${camNum}-${shelfId}-${sectionId}`;

export const configureShelfSectionIdFromOmniImageId = omniCamNum => {
  return {
    camNum: omniCamNum.split('-')[0],
    shelfId: omniCamNum.split('-')[1],
    sectionId: omniCamNum.split('-')[2],
  };
};

// From: stcg.jp.0000_shelf_52
// To: shelf_52
export const configureShortShelfId = shelfId => {
  const shortShelfId = shelfId.split('_').slice(1).join('_');
  return shortShelfId;
};

export const configureShelfNum = shelfId => {
  const shelfNum = shelfId.split('_')[2];
  return shelfNum;
};

export const exist = value => value !== null && value !== undefined;
export const configureCameraLabel = ({ cameraId, shelfId, sectionId }) => {
  const cam = exist(cameraId) ? cameraId : '';
  const shelf = exist(shelfId) ? `/${configureShortShelfId(shelfId)}` : '';
  const section = exist(sectionId) ? `/${sectionId}` : '';
  return `${cam}${shelf}${section}`;
};

export const includesUnknown = locations => {
  return locations.some(location => location.unknown);
};

export const convertRegionToPoints = (region, width, height, angle) => {
  if (!region) return [];
  return region.map(({ u, v }) => {
    const rotated = convertAngle(u, v, angle);
    return [rotated.u * width, rotated.v * height];
  });
};

export const convertAngle = (u, v, angle) => {
  switch (angle) {
    case 180:
      return { u: 1 - u, v: 1 - v };
    case 270:
      return { u: v, v: 1 - u };
    case 90:
      return { u: 1 - v, v: u };
    default:
      return { u, v };
  }
};

const revertAngle = (u, v, angle) => {
  switch (angle) {
    case 180:
      return { u: 1 - u, v: 1 - v };
    case 270:
      return { u: 1 - v, v: u };
    case 90:
      return { u: v, v: 1 - u };
    default:
      return { u, v };
  }
};

export const convertPointsToRegion = (points, width, height, angle) => {
  const region = points.map(chunk => {
    const u = chunk[0] / width;
    const v = chunk[1] / height;
    const rotated = revertAngle(u, v, angle);
    return {
      u: rotated.u,
      v: rotated.v,
    };
  });

  return region;
};

// gs://ffcameraservice-data.s0001.phx.cirk.nonstandard.ai/tis_cameras/unoccluded_images/cam_00000/2021/03/29/18/05/59.jpg"
// It could be blank if the store doesn't use ff service
export const configureIsoFromImageUrl = imageUrl => {
  // Some image url has "N/A"
  if (!imageUrl || imageUrl === 'N/A') return null;
  const raws = imageUrl.split('/').slice(-6);
  const isoString = `${raws[0]}-${raws[1]}-${raws[2]}T${raws[3]}:${raws[4]}:${
    raws[5].split('.')[0]
  }+00:00`;
  return isoString;
};

export const configureEditorType = pathname => {
  if (pathname.includes('task-based-editor')) {
    return 'taskBased';
  } else {
    return 'editor';
  }
};

export const toRectangle = (points: any) => {
  const maxX = Math.max(...points.map(p => p[0]));
  const maxY = Math.max(...points.map(p => p[1]));
  const minX = Math.min(...points.map(p => p[0]));
  const minY = Math.min(...points.map(p => p[1]));
  return [
    [minX, minY],
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
  ];
};

const findDeviceSetting = (cameraId, deviceSettings) => {
  const deviceSetting = deviceSettings.cameras.find(
    camera => camera.serial.toLowerCase() === cameraId
  );

  return deviceSetting;
};

export const configureCameraImages = (images, cameras) => {
  const withCameraInteger = _.mapValues(images, (image, camKey) => {
    const camId =
      image.providerKey?.cameraId ?? image.providerKey?.cam_id ?? camKey;
    if (isNaN(Number(camId))) {
      const deviceSetting = findDeviceSetting(camId, cameras.device_settings);

      if (deviceSetting) {
        const cameraInteger = deviceSetting.cam.toString();
        const extrinsics = cameras.camera_extrinsics.cameras[cameraInteger];
        return {
          ...image,
          cameraInteger,
          width: deviceSetting.width,
          height: deviceSetting.height,
          angle: extrinsics?.angle,
        };
      } else {
        return { ...image };
      }
    } else {
      const extrinsics = cameras.camera_extrinsics.cameras[camId];
      return { ...image, cameraInteger: camId, angle: extrinsics.angle };
    }
  });

  const result = _.mapValues(withCameraInteger, (image, camId) => {
    if (image.cameraInteger === undefined || cameras.metas === undefined)
      return image;
    const meta = cameras.metas[image.cameraInteger];
    if (!meta) return image;

    return {
      ...image,
      meta,
    };
  });

  return result;
};

export const convertToCamelCaseKeys = (object: any): any => {
  const shouldProcess = typeof object === 'object' && object !== null;
  if (!shouldProcess) return object;

  if (Array.isArray(object)) {
    return object.map(objectValue => convertToCamelCaseKeys(objectValue));
  }

  const result = {};

  Object.entries(object).map(([key, value]) => {
    if (key.includes('-')) {
      // if the key has hyphen, then it is uuid, and we don't want to convert uuid
      result[key] = convertToCamelCaseKeys(value);
    } else {
      const camelKey = _.camelCase(key);
      result[camelKey] = convertToCamelCaseKeys(value);
    }
  });

  return result;
};

export const groupByUniqueKey = (list, key) =>
  _.mapValues(_.groupBy(list, key), values => values[0]);

const shouldReviewed = (status: LocationReviewStatus) =>
  status === LocationReviewStatus.Init;
export const findNextLocationToReview = (
  locations: LocationToReview[],
  currentIndex: number
) => {
  for (let i = currentIndex + 1; i < locations.length; i++) {
    const location = locations[i];
    if (shouldReviewed(location.status)) {
      return location;
    }
  }

  return null;
};

export const findPreviousLocationToReview = (
  locations: LocationToReview[],
  currentIndex: number
) => {
  for (let i = currentIndex - 1; i >= 0; i--) {
    const location = locations[i];
    if (shouldReviewed(location.status)) {
      return location;
    }
  }

  return null;
};

export const calcPolygonArea = points => {
  let total = 0;

  for (let i = 0, l = points.length; i < l; i++) {
    const addX = points[i].u;
    const addY = points[i == points.length - 1 ? 0 : i + 1].v;
    const subX = points[i == points.length - 1 ? 0 : i + 1].u;
    const subY = points[i].v;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
};
