import { TextField } from '@material-ui/core';
import styled from 'styled-components';

import Flex from 'components/Flex';
import { useFilters } from '../helpers';
import { formatDatetime } from 'utils/formatDatetime';
import GeneralTable from './GeneralTable';
import { InProgressCamogramJob } from './InProgressTable';
import StoreSelector from './StoreSelector';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const cofigureInProgressQcTableData = (jobs: InProgressCamogramJob[]) => {
  const keys = [
    'store',
    'groupId',
    'camera',
    'createdAt',
    'createdBy',
    'qcStartedAt',
    'qcStartedBy',
  ];
  const labels = {
    store: 'Store',
    groupId: 'Group ID',
    camera: 'Camera',
    createdAt: 'Created At',
    createdBy: 'Created By',
    qcStartedAt: 'QC Started At',
    qcStartedBy: 'QC Started By',
  };
  const items = jobs.map(job => ({
    key: job.id,
    store: `${job.storeName} (${job.storeId})`,
    groupId: job.groupId,
    camera: job.cameraInteger ?? job.cameraImageId,
    createdAt: formatDatetime(job.createdAt),
    createdBy: job.createdBy,
    qcStartedAt: formatDatetime(job.startedReviewAnnotationsAt),
    qcStartedBy: job.startedReviewAnnotationsBy,
  }));

  return { keys, labels, items };
};

type Props = {
  inProgressQcCamogramJobs: InProgressCamogramJob[];
};

export default function InProgressQcTable({ inProgressQcCamogramJobs }: Props) {
  const {
    selectedStoreId,
    handleChangeStoreId,
    userFilterText,
    cameraId,
    handleChangeCameraId,
    handleChangeUserFilterText,
    filtered,
  } = useFilters<InProgressCamogramJob>(inProgressQcCamogramJobs);

  const inProgressTableDatas = cofigureInProgressQcTableData(filtered);

  return (
    <Container>
      <Flex itemSpace={16} alignItems="flex-end">
        <StoreSelector
          value={selectedStoreId}
          handleChange={handleChangeStoreId}
        />
        <TextField
          label="Filter User"
          value={userFilterText}
          onChange={handleChangeUserFilterText}
        />
        <TextField
          label="Filter Camera"
          value={cameraId}
          onChange={handleChangeCameraId}
        />
      </Flex>
      <GeneralTable
        keys={inProgressTableDatas.keys}
        labels={inProgressTableDatas.labels}
        items={inProgressTableDatas.items}
      />
    </Container>
  );
}
