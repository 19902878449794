import { combineReducers } from 'redux';

import { TOGGLE_ASIDE } from 'actions/types';

const isVisible = (state = true, { type, payload }) => {
  if (type === TOGGLE_ASIDE) {
    return payload.isVisible;
  }

  return state;
};

export default combineReducers({
  isVisible,
});
