import { createAsyncThunk } from '@reduxjs/toolkit';
import { EVENT_REPORT_SKU } from 'common/constants';
import { TaskBasedState } from 'slices/taskBasedSlice';
import { postSkuReportApi } from 'utils/api';
import getSkuImageURL from 'utils/getSkuImageUrl';
import { track } from 'utils/segmentAnalytics';

const configureDescription = ({
  options,
  description,
  storeId,
  storeName,
  itemUuid,
  barcode,
  email,
  camNum,
  imageLink,
}) => {
  return `POS Code: ${barcode}
  Store: ${storeName}
  Store ID: ${storeId}
  CamNum: ${camNum}
  What's wrong: ${options.join()}
  Description: ${description}
  Item Image: ${getSkuImageURL(itemUuid)}
  User: ${email}
  Image Link: ${imageLink}
  `;
};
const postSkuReport = createAsyncThunk(
  'postSkuReport',
  async (
    {
      itemUuid,
      itemName,
      description,
      options,
      storeId,
      storeName,
      barcode,
      email,
      camNum,
      imageFile,
      imageLink,
    }: any,
    { getState }
  ) => {
    const { taskBased } = getState() as {
      taskBased: TaskBasedState;
    };
    const jobId = taskBased?.selectedJob?.id;
    const groupId = taskBased?.selectedJob?.groupId;
    await postSkuReportApi({
      itemUuid,
      itemName,
      description: configureDescription({
        options,
        description,
        storeId,
        storeName,
        itemUuid,
        barcode,
        email,
        camNum,
        imageLink,
      }),
      image: imageFile,
    });
    track(EVENT_REPORT_SKU, {
      itemUuid,
      itemName,
      storeId,
      email,
      camNum,
      description,
      imageLink,
      options: options.join(),
      jobId,
      groupId,
    });
  }
);

export default postSkuReport;
