import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const CameraImage = ({ camNum, setError, width, height, onLoad }) => {
  const src = useSelector(state => state.current.cameraImages[camNum]?.src);

  return (
    <Container width={width} height={height}>
      <img
        src={src}
        alt="camera_image"
        width={width}
        height={height}
        onLoad={onLoad}
      />
    </Container>
  );
};

export default CameraImage;
