import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Stage as StageRK, Layer } from 'react-konva';
import { MapInteractionCSS } from 'react-map-interaction';
import Panel from 'components/Panel';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useHold from 'hooks/useHold';
import useResizeAware from 'react-resize-aware';
import { toast } from 'react-toastify';
import { ListAlt, ZoomOut, ZoomIn } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import {
  extremeZoomImageRequestStart,
  setSelectedExtremeZoomShelf,
} from 'actions';

import Circle from './Circle';

const ToolbarContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: #444;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  cursor: crosshair;
`;

const defaultZoom = {
  scale: 1,
  translation: { x: 0, y: 0 },
};

const ZOOM_INTERVAL = 0.3;
const Toolbar = ({ shelfId, sectionId, uploadedAt, setZoom }) => {
  const dispatch = useDispatch();
  const datetime = moment(uploadedAt).format('lll');
  const onZoomIn = () => {
    setZoom(prev => {
      const scale = prev.scale + ZOOM_INTERVAL;
      const ratio = scale / prev.scale;
      return {
        translation: {
          x: Math.floor(prev.translation.x * ratio),
          y: Math.floor(prev.translation.y * ratio),
        },
        scale,
      };
    });
  };
  const onZoomOut = () => {
    setZoom(prev => {
      const scale = Math.max(prev.scale - ZOOM_INTERVAL, ZOOM_INTERVAL);
      const ratio = scale / prev.scale;
      return {
        translation: {
          x: prev.translation.x * ratio,
          y: prev.translation.y * ratio,
        },
        scale,
      };
    });
  };

  const zoomInEvents = useHold(onZoomIn);
  const zoomOutEvents = useHold(onZoomOut);

  return (
    <ToolbarContainer>
      <IconButton color="inherit" size="small" {...zoomOutEvents}>
        <ZoomOut />
      </IconButton>
      <IconButton color="inherit" size="small" {...zoomInEvents}>
        <ZoomIn />
      </IconButton>
      <IconButton
        color="inherit"
        size="small"
        onClick={() =>
          dispatch(
            setSelectedExtremeZoomShelf({
              selectedShelf: `${shelfId}_section_${sectionId}`,
            })
          )
        }
        title="Show Extreme Zoom Images"
      >
        <ListAlt />
      </IconButton>
      <div>
        {shelfId} / {sectionId} / {datetime}
      </div>
    </ToolbarContainer>
  );
};

const Stage = styled(StageRK)`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const FFImage = ({ url, sectionId, shelfId, uploadedAt }) => {
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const [resizeListener, imageSize] = useResizeAware();
  const [zoom, setZoom] = useState(defaultZoom);
  const [imageNaturalSize, setImageNaturalSize] = useState();

  const extremeZoomImages = useSelector(state =>
    state.extremeZoom.extremeZoomImages.images.filter(
      extremeZoomImage =>
        extremeZoomImage.storeId === storeId &&
        extremeZoomImage.shelfId === shelfId &&
        extremeZoomImage.sectionId === sectionId
    )
  );

  const requestExtremeZoomImage = useCallback(
    async point => {
      dispatch(
        extremeZoomImageRequestStart({ point, storeId, shelfId, sectionId })
      );
    },
    [dispatch, storeId, shelfId, sectionId]
  );

  const onDoubleClick = event => {
    if (!imageNaturalSize) {
      return toast('Wait for FF Image to fully load', { type: 'warning' });
    }

    const point = [
      Math.round(
        (event.nativeEvent.offsetX / imageSize.width) * imageNaturalSize.width
      ),
      Math.round(
        (event.nativeEvent.offsetY / imageSize.height) * imageNaturalSize.height
      ),
    ];
    requestExtremeZoomImage(point);
  };

  const onLoad = event => {
    setImageNaturalSize({
      width: event.target.naturalWidth,
      height: event.target.naturalHeight,
    });
  };

  return (
    <Panel
      toolBar={
        <Toolbar
          setZoom={setZoom}
          shelfId={shelfId}
          sectionId={sectionId}
          uploadedAt={uploadedAt}
        />
      }
    >
      <MapInteractionCSS
        minScale={0.9}
        value={zoom}
        onChange={setZoom}
        disableZoom
      >
        <ImageContainer>
          {resizeListener}
          <img
            src={url}
            alt="camera_image"
            width="100%"
            onDoubleClick={onDoubleClick}
            onLoad={onLoad}
          />
          <Stage width={imageSize.width} height={imageSize.height}>
            <Layer>
              {imageNaturalSize &&
                extremeZoomImages.map((image, index) => (
                  <Circle
                    point={[
                      Math.round(
                        (image.point[0] / imageNaturalSize.width) *
                          imageSize.width
                      ),
                      Math.round(
                        (image.point[1] / imageNaturalSize.height) *
                          imageSize.height
                      ),
                    ]}
                    key={index}
                    textOverlay={(index + 1).toString()}
                    scale={zoom.scale}
                  />
                ))}
            </Layer>
          </Stage>
        </ImageContainer>
      </MapInteractionCSS>
    </Panel>
  );
};

export default FFImage;
