import styled from 'styled-components';

type FlexProps = {
  direction?: 'column' | 'row';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  padding?: number;
  itemSpace?: number;
};

const Flex = styled.div<FlexProps>`
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'stretch'};
  padding: ${({ padding }) => padding}px;
  > * {
    margin-bottom: ${({ itemSpace, direction }) =>
      direction === 'column' ? itemSpace : 0}px;

    margin-right: ${({ itemSpace, direction }) =>
      direction === 'column' ? 0 : itemSpace}px;
  }
`;

export default Flex;
