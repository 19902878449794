import React from 'react';
import { SVGProps } from 'react';

type TextProps = {
  center: [number, number] | { x: number; y: number };
} & SVGProps<SVGTextElement>;

const Text: React.FC<TextProps> = ({ center, children, ...props }: any) => (
  <text
    x={center[0] || center.x}
    y={center[1] || center.y}
    fill="#333"
    pointerEvents="none"
    fontFamily="Arial, Helvetica, sans-serif"
    fontSize="20px"
    textAnchor="middle"
    dominantBaseline="central"
    {...props}
  >
    {children}
  </text>
);

export default Text;
