import React, { useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import ActionDialog from 'components/ActionDialog';
import ImageInput from './ImageInput';
import SkuInfo from './SkuInfo';

const reportOptions = ['Bad Name', 'Bad Image', 'Missing Size'];

const SkuReportDialog = ({
  itemName,
  itemUuid,
  open,
  setOpen,
  barcode,
  onClickSendReport,
}) => {
  const [options, setOptions] = useState({});
  const [reportText, setReportText] = useState('');
  const [imageFile, setImageFile] = useState();
  const [imageLink, setImageLink] = useState('');

  const onClickSendReportButton = () => {
    const selectedOptions = Object.entries(options)
      .filter(([key, value]) => value)
      .map(([key, _]) => key);
    onClickSendReport({
      itemUuid,
      itemName,
      barcode,
      description: reportText,
      options: selectedOptions,
      imageLink,
      imageFile,
    });
    setReportText('');
    setImageLink('');
    setImageFile(null);
    setOptions({});
  };

  const onChangeReportText = e => {
    setReportText(e.target.value);
  };
  const onOptionChange = e => {
    setOptions(prev => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const onChangeImageLink = e => {
    setImageLink(e.target.value);
  };

  const isInvalidImageLink = imageLink && !imageLink.startsWith('http');

  return (
    <ActionDialog
      open={open}
      setOpen={setOpen}
      title={`Report the sku?`}
      text="The report will be posted in the slack channel (#project-sku-hunters)."
      content={
        <>
          <SkuInfo
            itemName={itemName}
            itemUuid={itemUuid}
            barcode={barcode}
            hideReport
          />
          <div>Please choose what's wrong</div>
          {reportOptions.map(option => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={options[option]}
                  name={option}
                  onChange={onOptionChange}
                />
              }
              label={option}
            />
          ))}
          <TextField
            style={{ marginBottom: 20 }}
            fullWidth
            label="Explain what's wrong"
            onChange={onChangeReportText}
          />
          <TextField
            style={{ marginBottom: 20 }}
            fullWidth
            label="URL of suggest replacement image"
            onChange={onChangeImageLink}
            error={isInvalidImageLink}
            helperText="Incorrect URL"
          />
          <ImageInput value={imageFile} onChange={setImageFile} />
        </>
      }
      cancelable
      actions={[
        {
          label: 'Post to Slack',
          color: 'primary',
          onClick: onClickSendReportButton,
        },
      ]}
    />
  );
};

export default SkuReportDialog;
