import { LayoutObject } from 'types';
import { useLayout2DContext } from '.';
import { toPath } from './helpers';

type ExitsProps = {
  height: number;
  exits: LayoutObject[];
};
function Exits({ height, exits }: ExitsProps) {
  const exitsPath = exits.map(exit => toPath(height)(exit.coordinates));
  return (
    <>
      {exitsPath.map((exit, i) => (
        <path
          key={`exit_${i}`}
          d={exit}
          stroke="white"
          strokeWidth={3}
          fill="#ffff88aa"
        >
          <title>Exit</title>
        </path>
      ))}
    </>
  );
}

export default function ExitsContainer() {
  const { height, layout } = useLayout2DContext();

  return <Exits height={height} exits={layout.exits} />;
}
