import { LayoutObject } from 'types';
import { useLayout2DContext } from '.';
import { toPath } from './helpers';

function PerimeterComponent({
  height,
  perimeter,
}: {
  height: number;
  perimeter: LayoutObject;
}) {
  const perimeterPath = toPath(height)(perimeter.coordinates);
  return <path d={perimeterPath} stroke="#eee" strokeWidth={3} fill="#666" />;
}

export default function Perimeter() {
  const { height, layout } = useLayout2DContext();

  return (
    <PerimeterComponent height={height} perimeter={layout.perimeters[0]} />
  );
}
