import { Button } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { COLORS } from 'common/constants';
import Flex from 'components/Flex';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Label = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export default function JobCompleted() {
  const history = useHistory();
  const { storeId } = useParams<{ storeId: string }>();

  const onClickHome = () => {
    history.push(`/`);
  };
  return (
    <Flex justifyContent="center" alignItems="center" direction="column">
      <CheckCircleOutline fontSize="large" style={{ color: COLORS.approved }} />
      <Label>All Cameras are Completed!🎉</Label>
      <Button color="primary" onClick={onClickHome}>
        Go to home
      </Button>
    </Flex>
  );
}
