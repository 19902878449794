import React from 'react';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid #ccc;
  padding: 8px 16px;
`;

const ContentLayout = ({ header, children }) => {
  return (
    <>
      <HeaderContainer>{header}</HeaderContainer>
      {children}
    </>
  );
};

export default ContentLayout;
