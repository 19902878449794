import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Button, TextField } from '@material-ui/core';
import { v4 } from 'uuid';

import AdminLayout from 'layouts/AdminLayout';
import useAsyncCall from 'hooks/useAsyncCall';
import { Layout, PtzConfig } from 'types';
import Loader from 'components/Loader';
import { fetchPtzConfigApi } from 'utils/api';
import fetchLayoutApi from 'utils/api/fetchStoreLayoutApi';
import postPtzConfigApi from 'utils/api/postPtzConfigApi';

const ContentContainer = styled.div`
  padding: 10px 20px;
`;

const CameraSettingsContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-bottom: 15px;
`;

const CameraSettingRow = ({ shelfId, sectionId, ptzConfig, onSave }) => {
  const [editing, setEditing] = useState(false);
  const [panValue, setPanValue] = useState(ptzConfig?.pan_value ?? '');
  const [tiltValue, setTiltValue] = useState(ptzConfig?.tilt_value ?? '');
  const [zoomValue, setZoomValue] = useState(ptzConfig?.zoom_value ?? '');
  const [camId, setCamId] = useState(ptzConfig?.cam_id ?? '');

  if (!ptzConfig) {
    ptzConfig = {
      uuid: v4(),
      camogram_section_id: sectionId,
      shelf_id: shelfId,
      store_layout_version_num: '1',
      camera_access_base_url: 'https://standard.ai',
      last_updated_by: 'camogramviz',
    };
  }
  return (
    <CameraSettingsContainer>
      <div>
        <div>Shelf {shelfId}</div>
        <div>Section {sectionId}</div>
      </div>
      <div style={{ width: '100px' }}>
        <TextField
          id="filled-basic"
          label="Pan"
          variant="filled"
          value={panValue}
          InputProps={{ type: 'number' }}
          inputProps={{ max: 360, min: -360 }}
          fullWidth
          onChange={event => {
            setEditing(true);
            setPanValue(parseInt(event.target.value, 10));
          }}
        />
      </div>
      <div style={{ width: '100px' }}>
        <TextField
          id="filled-basic"
          label="Tilt"
          variant="filled"
          value={tiltValue}
          InputProps={{ type: 'number' }}
          inputProps={{ max: 360, min: -360 }}
          fullWidth
          onChange={event => {
            setEditing(true);
            setTiltValue(parseInt(event.target.value, 10));
          }}
        />
      </div>
      <div style={{ width: '100px' }}>
        <TextField
          id="filled-basic"
          label="Zoom"
          variant="filled"
          value={zoomValue}
          InputProps={{ type: 'number' }}
          inputProps={{ min: 0 }}
          fullWidth
          onChange={event => {
            setEditing(true);
            setZoomValue(parseInt(event.target.value, 10));
          }}
        />
      </div>

      <div style={{ width: '140px' }}>
        <TextField
          id="filled-basic"
          label="Camera ID"
          variant="filled"
          value={camId}
          fullWidth
          onChange={event => {
            setEditing(true);
            setCamId(event.target.value);
          }}
        />
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ minWidth: '20px', padding: '6px 12px' }}
          title="Save"
          size="large"
          onClick={async () => {
            try {
              await onSave({
                ...ptzConfig,
                pan_value: panValue,
                tilt_value: tiltValue,
                zoom_value: zoomValue,
                cam_id: camId,
              });
              setEditing(false);
            } catch (error) {
              toast(error.message, { type: 'error' });
            }
          }}
          disabled={!editing}
        >
          Save
        </Button>
      </div>
    </CameraSettingsContainer>
  );
};

const fetchInitialData = async (storeId: string) => {
  const [ptzConfigs, layout] = await Promise.all([
    fetchPtzConfigApi({ storeId }),
    fetchLayoutApi({ storeId }),
  ]);

  return {
    ptzConfigs: _.keyBy(
      ptzConfigs,
      ptzConfig => `${ptzConfig.camogram_section_id}|${ptzConfig.shelf_id}`
    ) as { PtzConfig },
    layout,
  };
};

export default function AdminPTZCameraSettingsPage() {
  const { storeId } = useParams<{ storeId: string }>();

  const { data, isLoading, call, setData } = useAsyncCall<{
    ptzConfigs: { PtzConfig };
    layout: Layout;
  }>();

  const shouldDisplayLoader = !data || isLoading;

  useEffect(() => {
    call(fetchInitialData(storeId));
  }, [call, storeId]);

  const setPtzConfig: React.Dispatch<
    React.SetStateAction<PtzConfig>
  > = ptzConfig => {
    if (!setData || typeof ptzConfig === 'function') {
      throw new Error('setData is undefined');
    }

    setData(prev => {
      if (!prev) {
        throw new Error('No prev data');
      }

      return {
        ...prev,
        ptzConfigs: {
          ...prev.ptzConfigs,
          [`${ptzConfig.camogram_section_id}|${ptzConfig.shelf_id}`]: ptzConfig,
        },
      };
    });
  };

  const onSave = async ptzConfig => {
    if (!data) return;

    if (ptzConfig.pan_value < -360) {
      throw new Error('Pan value must be greater than -360');
    } else if (ptzConfig.pan_value > 360) {
      throw new Error('Pan value must be less than 360');
    } else if (ptzConfig.tilt_value < -360) {
      throw new Error('Tilt value must be greater than -360');
    } else if (ptzConfig.tilt_value > 360) {
      throw new Error('Tilt value must be less than 360');
    } else if (ptzConfig.zoom_value < 0) {
      throw new Error('Zoom value must not be negative');
    } else if (ptzConfig.cam_id === '' || ptzConfig.cam_id === undefined) {
      throw new Error('Camera ID is required');
    }

    await toast.promise(postPtzConfigApi({ storeId, ptzConfig }), {
      pending: 'Saving...',
      success: 'PTZ config saved!',
      error: 'Could not save, please try again.',
    });

    setPtzConfig(ptzConfig);
  };

  return (
    <AdminLayout>
      {shouldDisplayLoader ? (
        <Loader text="Loading Layout" loading />
      ) : (
        <ContentContainer>
          <h2>PTZ Settings</h2>

          <div>
            {data.layout.sections.map(layout => (
              <CameraSettingRow
                shelfId={layout.legacyShelfId}
                sectionId={layout.legacyId}
                key={layout.camogramSectionId}
                ptzConfig={
                  data.ptzConfigs[`${layout.legacyId}|${layout.legacyShelfId}`]
                }
                onSave={onSave}
              />
            ))}
          </div>
        </ContentContainer>
      )}
    </AdminLayout>
  );
}
