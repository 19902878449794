import React from 'react';
import styled from 'styled-components';

import CamogramPicker from './CamogramPicker';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import AnnotatorLayout from 'layouts/AnnotatorLayout';

const Main = styled.div`
  margin: 20px auto 50px;
  max-width: 1290px;
  width: 90%;
`;

const Picker = () => {
  return (
    <AnnotatorLayout>
      <Button component={NavLink} to="/stores">
        <ArrowBack /> Store Picker
      </Button>
      <Main>
        <CamogramPicker />
      </Main>
    </AnnotatorLayout>
  );
};

export default Picker;
