import { combineReducers } from 'redux';

import stores from './stores';
import current from './current';
import layers from './layers';
import camograms from './camograms';
import extremeZoom from './extremeZoom';
import skus from './skus';
import cameraExtrinsics from './cameraExtrinsics';
import activeLocation from './activeLocation';
import compare from './compare';
import aside from './aside';
import camerasSlice from 'slices/cameraImagesSlice';
import camogramJobsSlice from 'slices/camogramJobsSlice';
import taskBasedSlice from 'slices/taskBasedSlice';
import checkinSlice from 'slices/checkinSlice';

export default combineReducers({
  stores,
  current,
  layers,
  camograms,
  extremeZoom,
  skus,
  activeLocation,
  cameraExtrinsics,
  compare,
  aside,
  cameraImages: camerasSlice.reducer,
  camogramJobs: camogramJobsSlice.reducer,
  taskBased: taskBasedSlice.reducer,
  checkin: checkinSlice.reducer,
});
