export type Orientation = {
  roll: number;
  pitch: number;
  yaw: number;
};

export type Vec3D = { x: number; y: number; z: number };

export type CameraPositionBase = {
  position: Vec3D;
  normal: Vec3D;
  angle: number;
};

export type CameraPosition = {
  id: string;
} & CameraPositionBase;

export type LayoutObject = {
  coordinates: Vec3D[];
};

export type Shelf = LayoutObject & {
  id: string;
  legacyId: string;
};

export type Section = LayoutObject & {
  sectionId: string;
  shelfId: string;
  legacyShelfId: string;
  id: string;
  legacyId: string;
  camogramSectionId: string;
};

export type Perimeter = LayoutObject & { height: number };

export type Layout = {
  exits: LayoutObject[];
  shelves: Shelf[];
  perimeters: Perimeter[];
  sections: Section[];
};

export type Modify<T, R> = Omit<T, keyof R> & R;
export type Vec2D = { x: number; y: number };

export type Coordinate = {
  u: number;
  v: number;
};

export type HighresActiveSection = {
  camogramSectionId: string;
  coordinates: Coordinate[];
};

export type HighresActiveRegion = {
  camNum: string;
  enabled: boolean;
  activeSections: HighresActiveSection[] | null;
};

export type HighresCamera = CameraPosition & { src: string };

export type Dimensions = { width: number; height: number };

export type Zoom = {
  scale: number;
  translation: {
    x: number;
    y: number;
  };
};

export type ProviderKey = {
  cameraId: string;
  camogramSectionId?: string;
  shelfId?: string;
  dataSource: EditorDataSource;
};

export enum EditorDataSource {
  Omni = 'CAM_360',
  Tis = 'CAM_TIS',
  StaticHighRes = 'STATIC_HIGH_RES',
}

type CameraMeta = {
  enabled: boolean;
  activeShelfs: HighresActiveSection[];
};

export type CameraImage = {
  uuid: string;
  src: string;
  imageSource: string;
  providerKey: ProviderKey;
  status: string;
  unknown: boolean;
  cameraInteger?: number;
  widht?: number;
  height?: number;
  angle?: number;
  meta?: CameraMeta;
};

export type Camera = {
  cameraId: string;
  angle: number;
  meta?: CameraMeta;
};

export type Flags = {
  isGroup?: boolean;
  isOccluded?: boolean;
  isUncertain?: boolean;
  isEmpty?: boolean;
};

export type FlagKeys = keyof Flags;

export type Location = Flags & {
  region: Coordinate[];
  saved: boolean;
  id: string;
  rotation: number;
  skuId?: string;
  skuLabel?: string;
};

export enum CursorMode {
  Pointer,
  Draw,
  Drawing,
}

export enum LoadingStatus {
  Init,
  Loading,
  Success,
  Error,
}

export type PtzConfig = {
  cam_id: string;
  shelf_id: string;
  camogram_section_id: string;
  store_layout_version_num: string;
  pan_value: number;
  tilt_value: number;
  zoom_value: number;
  camera_access_base_url: string;
  last_updated_by: string;
};

export enum UserCheckinState {
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
}
export type CameraState = {
  id: string;
  storeId: string;
  providerKey: ProviderKey;
  cameraPriority: number;
  annotationDeadline: string;
  intervalMinutes: number;
  providerPriority: string;
  isActive: boolean;
};

export type Store = {
  id: string;
  name: string;
  camogramAnnotationType: string[];
};
