import { useAppSelector } from 'hooks';
import _ from 'lodash';
import { TypedUseSelectorHook } from 'react-redux';
import { Camera } from 'types';
import { TaskType } from 'types/taskBased';
import { TaskCompletionState, TaskContentState } from './Content';

export const useCameraImage: TypedUseSelectorHook<Camera> = callback => {
  const cameraImage = useAppSelector(state => {
    const cameraId = state.taskBased.selectedJob?.providerKey.cameraId;

    if (cameraId === undefined)
      throw new Error(
        'cameraId is undefined. useCameraImage must be used within TasdBasedEditorInitialDataLoader'
      );

    return state.cameraImages.data[cameraId];
  });

  return callback(cameraImage);
};

export const configureTaskContentState = (
  isAllJobsDone: boolean,
  isTaskDone: boolean,
  taskType: TaskType
): TaskContentState => {
  if (isAllJobsDone) {
    return TaskCompletionState.AllJobsDone;
  }

  if (isTaskDone) {
    return TaskCompletionState.TaskDone;
  }

  return taskType;
};
