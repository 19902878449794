import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EVENT_CHECK_IN, EVENT_CHECK_OUT } from 'common/constants';
import { UserCheckinState } from 'types';
import getUserCheckinStateApi from 'utils/api/checkin/getUserCheckinStateApi';
import postCheckinApi from 'utils/api/checkin/postCheckinApi';
import postCheckoutApi from 'utils/api/checkin/postCheckoutApi';
import { track } from 'utils/segmentAnalytics';

export type CameraImagesState = {
  userCheckinState: UserCheckinState;
  stateTimestamp?: string;
};

const initialState: CameraImagesState = {
  userCheckinState: UserCheckinState.CheckedOut,
  stateTimestamp: undefined,
};

export const postCheckin = createAsyncThunk(
  'checkin/postCheckin',
  async (email: string) => {
    const res = await postCheckinApi(email);
    track(EVENT_CHECK_IN, { email });
    return res;
  }
);

export const postCheckout = createAsyncThunk(
  'checkin/postCheckout',
  async (email: string) => {
    const res = await postCheckoutApi(email);
    track(EVENT_CHECK_OUT, { email });
    return res;
  }
);

export const getUserCheckinState = createAsyncThunk(
  'checkin/getUserCheckinState',
  getUserCheckinStateApi
);

const checkinSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(postCheckin.fulfilled, (state, action) => {
        state.userCheckinState = UserCheckinState.CheckedIn;
        state.stateTimestamp = action.payload.stateTimestamp;
      })
      .addCase(postCheckout.fulfilled, (state, action) => {
        state.userCheckinState = UserCheckinState.CheckedOut;
        state.stateTimestamp = action.payload.stateTimestamp;
      })
      .addCase(getUserCheckinState.fulfilled, (state, action) => {
        const { state: checkinState, stateTimestamp } = action.payload;
        state.userCheckinState = checkinState;
        state.stateTimestamp = stateTimestamp;
      });
  },
});

export default checkinSlice;
