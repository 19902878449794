import { createReducer } from '@reduxjs/toolkit';
import {
  START_COMPARE_CAMOGRAMS,
  START_COMPARE_FF_IMAGES,
  START_COMPARE_FF_IMAGES_BY_SECTION,
  START_COMPARE_OMNI_CAMOGRAMS,
  CLOSE_COMPARE,
  START_COMPARE_FF_IMAGES_FOR_EXTREME_ZOOM,
  SET_EDITOR_CAM_NUM,
} from 'actions/types';
import { updateSelectedJobId } from 'slices/taskBasedSlice';
import {
  COMPARE_TYPE_CAMOGRAMS,
  COMPARE_TYPE_FF_IMAGES,
  COMPARE_TYPE_FF_IMAGES_BY_SECTION,
  COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM,
  COMPARE_TYPE_OMNI_CAMOGRAMS,
} from './compareTypes';

const compareTypeMap = {
  [START_COMPARE_CAMOGRAMS]: COMPARE_TYPE_CAMOGRAMS,
  [START_COMPARE_OMNI_CAMOGRAMS]: COMPARE_TYPE_OMNI_CAMOGRAMS,
  [START_COMPARE_FF_IMAGES]: COMPARE_TYPE_FF_IMAGES,
  [START_COMPARE_FF_IMAGES_BY_SECTION]: COMPARE_TYPE_FF_IMAGES_BY_SECTION,
  [START_COMPARE_FF_IMAGES_FOR_EXTREME_ZOOM]: COMPARE_TYPE_FF_IMAGES_FOR_EXTREME_ZOOM,
};

const isStartCompareAction = ({ type }) => type.startsWith('START_COMPARE');
const initialState = [];
const panes = createReducer(initialState, builder => {
  builder
    .addCase(CLOSE_COMPARE, (state, { payload: { uuid } }) => {
      const newState = state.filter(pane => pane.uuid !== uuid);
      return newState;
    })
    .addCase(SET_EDITOR_CAM_NUM, () => {
      return initialState;
    })
    .addCase(updateSelectedJobId, () => {
      return initialState;
    })
    .addMatcher(isStartCompareAction, (state, { type, payload }) => {
      const compareType = compareTypeMap[type];
      state.push({ type: compareType, params: payload, uuid: payload.uuid });
    })
    .addDefaultCase(() => {});
});

export default panes;
