import { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch } from 'react-redux';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import { FixedSizeList, areEqual } from 'react-window';
import Item from './Item';
import useShortcut from 'hooks/useShortcut';
import { selectCategories, selectSkuIds } from './helpers';
import Filter from './Filter';
import useFilter from 'hooks/useFilter';
import { postSkuReport } from 'actions';
import useAccount from 'hooks/useAccount';
import { useAppSelector } from 'hooks';
import memoize from 'memoize-one';
import { reviewLocation } from 'slices/taskBasedSlice';
import { LocationReviewStatus } from 'types/taskBased';
import _ from 'lodash';
import { track } from 'utils/segmentAnalytics';
import {
  BOX_COLORS,
  EVENT_TASK_BASED_CLASSIFY_ITEM,
  UNKNOWN_SKU_BARCODE,
} from 'common/constants';
import SkuImageContainer from 'components/SkuImage';
import { isSkuUnavailable } from '../../helpers';

const Span = styled.span`
  flex-grow: 1;
`;

const ListContainer = styled.div`
  width: 300px;
  min-width: 300px;
  overflow: auto;
`;

const StickContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  padding: 8px;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
`;

const OutdatedItemContainer = styled.div`
  border-top: 1px solid #cdcdcd;
  height: 100%;
  margin: auto 0px;
  display: flex;
`;

const OutdatedItemInner = styled.div`
  padding: 16px;
  border: 6px solid ${BOX_COLORS.retired};
  height: 100%;
  box-sizing: border-box;
  margin: auto 0px;
  display: flex;
  font-size: 10px;
`;

const ItemRow = memo(({ data, index, style }: any) => {
  // Data passed to List as "itemData" is available as props.data
  const {
    skuIds,
    storeId,
    onClickSend,
    onClickSendReport,
    selectedIndex,
    predictions,
  } = data;
  const selected = index === selectedIndex;
  const prediction = predictions[index];
  const isPredicted = Boolean(prediction);
  const skuId = skuIds[index];
  const sku = useAppSelector(state => state.skus[storeId][skuId]);
  const tags = isPredicted
    ? [`ML Prediction, ${Math.round(prediction.similarity * 100)}%`]
    : [];

  const isOutdated = isSkuUnavailable(sku);
  useShortcut('enter', () =>
    selected && !isOutdated ? onClickSend(sku) : null
  );

  return (
    <div style={style}>
      {!isOutdated ? (
        <Item
          key={sku.uuid}
          active={selected}
          itemName={sku.display_name}
          itemUuid={sku.uuid}
          barcode={sku.barcode}
          onClickSend={onClickSend}
          onClickSendReport={onClickSendReport}
          hideReport={false}
          tags={tags}
        />
      ) : (
        <OutdatedItemContainer>
          <OutdatedItemInner>
            <SkuImageContainer
              uuid={sku?.uuid || null}
              alt="sku_image"
              width={60}
              height={60}
            />
            SKU ID ({skuId || 'unknown'}) is outdated
          </OutdatedItemInner>
        </OutdatedItemContainer>
      )}
    </div>
  );
}, areEqual);

const createItemData = memoize(
  (
    storeId,
    skuIds,
    selectedIndex,
    onClickSend,
    onClickSendReport,
    predictions
  ) => ({
    storeId,
    skuIds,
    selectedIndex,
    onClickSend,
    onClickSendReport,
    predictions,
  })
);

function ItemListContainer() {
  const dispatch = useDispatch();
  const { storeId } = useParams<{ storeId: string }>();
  const { email } = useAccount();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const { filter, setProxy, setCategory } = useFilter();
  const [activeFilter, setActiveFilter] = useState(false);
  const textFieldRef = useRef<any>();
  const scrollRef = useRef<any>();

  const cameraId = useAppSelector(
    state => state.taskBased.selectedJob?.providerKey.cameraId
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const searchSkuIds = useAppSelector(state => {
    if (!searchText) return [];
    return selectSkuIds({ storeId, searchText, filter })(state);
  }, shallowEqual);
  const predictions = useAppSelector(state => {
    const predictions =
      state.taskBased.locationsToReview.find(
        ({ id }) => id === selectedLocationId
      )?.predictions ?? [];
    const sortedPredictions = _.sortBy(predictions, 'similarity').reverse();
    return sortedPredictions;
  }, shallowEqual);
  const skuIds = _.uniq([
    ...predictions.map(({ skuId }) => skuId),
    ...searchSkuIds,
  ]);
  const categories = useAppSelector(
    selectCategories({ storeId }),
    shallowEqual
  );
  const storeName = useAppSelector(state => state.stores[storeId].name);
  const onChangeSearch = e => {
    setSearchText(e.target.value);
  };

  const onClickFilterButton = () => {
    setActiveFilter(prev => !prev);
  };

  const onChangeProxy = e => {
    setProxy(e.target.checked);
  };

  const onChangeCategory = e => {
    setCategory(e.target.value);
  };

  const onClickSendReport = ({
    itemUuid,
    itemName,
    description,
    options,
    barcode,
    imageLink,
    imageFile,
  }) => {
    dispatch(
      postSkuReport({
        itemUuid,
        itemName,
        description,
        options,
        storeId,
        storeName,
        barcode,
        email,
        camNum: cameraId,
        imageLink,
        imageFile,
      })
    );
  };
  const onClickSend = ({
    name,
    uuid,
    barcode,
  }: {
    name: string;
    uuid: string;
    barcode: string;
  }) => {
    if (selectedLocationId === undefined)
      throw new Error(
        `selectedLocationId is undefined on classify items, itemName: ${name}, uuid: ${uuid}`
      );
    const data = {
      status: LocationReviewStatus.Classified,
      skuId: uuid,
      skuLabel: name,
      saved: false,
    };

    if (barcode === UNKNOWN_SKU_BARCODE) {
      data['isUncertain'] = true;
    }
    dispatch(
      reviewLocation({
        locationUuid: selectedLocationId,
        data,
      })
    );
    const isMlPredicted = predictions.map(({ skuId }) => skuId).includes(uuid);
    track(EVENT_TASK_BASED_CLASSIFY_ITEM, {
      skuId: uuid,
      skuName: name,
      locationUuid: selectedLocationId,
      isMlPredicted,
    });
  };
  const itemData = createItemData(
    storeId,
    skuIds,
    selectedIndex,
    onClickSend,
    onClickSendReport,
    predictions
  );
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onChangeSearch(e);
      setSelectedIndex(0);
      textFieldRef.current?.blur();
    }
  };

  useShortcut('l', e => {
    if (!textFieldRef.current) return;
    e.preventDefault();
    textFieldRef.current.focus();
  });
  useShortcut(
    'down',
    () => setSelectedIndex(i => Math.min(skuIds.length - 1, i + 1)),
    true
  );
  useShortcut('up', () => setSelectedIndex(i => Math.max(0, i - 1)), true);

  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current?.scrollToItem(selectedIndex);
  }, [selectedIndex]);

  return (
    <ListContainer>
      <StickContainer>
        <Row>
          <TextField
            inputRef={textFieldRef}
            fullWidth
            placeholder="Filter Items"
            onKeyDown={onKeyDown}
            size="small"
            inputProps={{ style: { fontSize: '12px' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="inherit" />
                </InputAdornment>
              ),
            }}
          />
          <Span />
          <Button size="small" onClick={onClickFilterButton}>
            <FilterList /> Filter
          </Button>
        </Row>
        <Filter
          filter={filter}
          active={activeFilter}
          categories={categories}
          onChangeCategory={onChangeCategory}
          onChangeProxy={onChangeProxy}
        />
      </StickContainer>
      <FixedSizeList
        style={{ borderBottom: '1px solid #ccc' }}
        height={800}
        itemCount={skuIds.length}
        itemData={itemData}
        itemSize={100}
        width={300}
        ref={scrollRef}
      >
        {ItemRow}
      </FixedSizeList>
    </ListContainer>
  );
}

export default memo(ItemListContainer);
