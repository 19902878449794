import React, { useState } from 'react';

import FFImagesComponent from './FFImagesComponent';
import Loader from 'components/Loader';
import useAsyncCall from 'hooks/useAsyncCall';
import useAsyncEffect from 'use-async-effect';

const FFImages = ({ full = true, fetchFFImages }) => {
  const { isReady, call } = useAsyncCall();
  const [images, setImages] = useState([]);

  useAsyncEffect(async () => {
    try {
      const images = await call(fetchFFImages());
      setImages(images);
    } catch (e) {
      setImages([]);
    }
  }, [fetchFFImages]);

  return (
    <Loader loading={!isReady} full={full}>
      {images.length > 0 ? (
        <FFImagesComponent images={images} />
      ) : (
        <div>No FF Images</div>
      )}
    </Loader>
  );
};

export default FFImages;
