import _ from 'lodash';

import { ProviderKey } from 'types';
import { exist } from 'utils';
import api from '..';
import { addNumericCamIdParam } from '../helpers';
import { configureJobForAnnotation } from './helpers';

export type FetchJobsForAnnotationApiProps = {
  storeId: string;
  groupId: string;
  providerKey: ProviderKey;
  version: number;
};

const fetchJobsForAnnotationApi = async ({
  storeId,
  groupId,
  providerKey,
  version,
}: FetchJobsForAnnotationApiProps) => {
  const params = {
    'job.data.store_id.str.eq': storeId,
    'job.group_id.str.eq': groupId,
  };
  switch (version) {
    case 0:
      params['job.data.camera_id.str.eq'] = providerKey.cameraId;
      break;
    case 1:
    case 2: {
      params['job.data.provider_key.camera_id.str.eq'] = providerKey.cameraId;
      params['job.data.provider_key.data_source.str.eq'] =
        providerKey.dataSource;
      if (exist(providerKey.shelfId) && exist(providerKey.camogramSectionId)) {
        params['job.data.provider_key.shelf_id.str.eq'] = providerKey.shelfId;
        params['job.data.provider_key.camogram_section_id.str.eq'] =
          providerKey.camogramSectionId;
      }
      break;
    }
    default:
      throw new Error(
        `Unknown workflow version: ${version}, groupId: ${groupId}, storeId: ${storeId}`
      );
  }
  const response = await api.get(
    addNumericCamIdParam('/task-based/generic/list-jobs-for-annotation'),
    {
      params,
    }
  );

  const result = configureJobForAnnotation(response.data.data);

  return result;
};

export default fetchJobsForAnnotationApi;
