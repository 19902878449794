import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Flag } from '@material-ui/icons';
import { COLORS } from 'common/constants';

import { useAppSelector } from 'hooks';
import { updateLocation } from 'slices/taskBasedSlice';
import { Flags } from 'types';

const FlagButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 100px));
  gap: 8px;
`;

type FlagKey = keyof Flags;
const flagTitleMap = {
  isEmpty: 'Empty',
  isGroup: 'Group',
  isOccluded: 'Occluded',
  isUncertain: 'Unknown',
};

type FlagsProps = {
  availableFlags?: FlagKey[];
};

export default function FlagButtons({
  availableFlags = ['isEmpty', 'isGroup', 'isOccluded', 'isUncertain'],
}: FlagsProps) {
  const selectedLocation = useAppSelector(state => {
    const selectedLocationId = state.taskBased.selectedLocationId;
    const location = state.taskBased.locationsToReview.find(
      ({ id }) => selectedLocationId === id
    );

    return location;
  });
  const dispatch = useDispatch();

  if (selectedLocation === undefined) return null;

  const onClick = (key: string) => () => {
    dispatch(
      updateLocation({
        locationId: selectedLocation.id,
        data: { [key]: !selectedLocation[key] },
      })
    );
  };
  return (
    <FlagButtonsContainer>
      {availableFlags.map(flag => (
        <Button
          key={flag}
          variant="contained"
          size="small"
          style={{
            backgroundColor: selectedLocation[flag]
              ? COLORS.flags[flag]
              : undefined,
          }}
          onClick={onClick(flag)}
        >
          <Flag
            fontSize="small"
            htmlColor={selectedLocation[flag] ? undefined : COLORS.flags[flag]}
          />{' '}
          {flagTitleMap[flag]}
        </Button>
      ))}
    </FlagButtonsContainer>
  );
}
