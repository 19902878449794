import { useState } from 'react';

const useFilter = (defaultFilter = { proxy: false, category: '' }) => {
  const [filter, setFilter] = useState(defaultFilter);

  const setProxy = proxy => {
    setFilter(prev => ({ ...prev, proxy }));
  };

  const setCategory = category => {
    setFilter(prev => ({ ...prev, category: category }));
  };

  return {
    filter,
    setProxy,
    setFilter,
    setCategory,
  };
};

export default useFilter;
