import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import styled from 'styled-components';
import { Home, CameraAlt } from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useHistory } from 'react-router-dom';
import { useParams, useRouteMatch } from 'react-router-dom';

const SidebarContainer = styled.div`
  width: 240px;
  border-right: solid 1px #ddd;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 40px;
`;

const SidebarItem = ({ to, icon, text, nested = false }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(to);
  };
  const match = useRouteMatch({ path: to });
  return (
    <ListItem
      style={{ paddingLeft: nested ? 56 : 16 }}
      button
      onClick={onClick}
      selected={Boolean(match?.isExact)}
      dense
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default function Sidebar() {
  const { storeId } = useParams();
  const [openCameraSettings, setOpenCameraSettings] = React.useState(true);

  const onClickCameraSettings = () => {
    setOpenCameraSettings(!openCameraSettings);
  };

  return (
    <SidebarContainer>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <SidebarItem to="/admin" icon={<Home />} text="Home" />
        <ListItem dense button onClick={onClickCameraSettings}>
          <ListItemIcon>
            <CameraAlt />
          </ListItemIcon>
          <ListItemText primary="Camera Settings" />
          {openCameraSettings ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCameraSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <SidebarItem
              to={`/admin/stores/${storeId}/highres_camera_settings`}
              text="4K"
              nested
            />
            <SidebarItem
              to={`/admin/stores/${storeId}/ptz_camera_settings`}
              text="PTZ"
              nested
            />
          </List>
        </Collapse>
      </List>
    </SidebarContainer>
  );
}
