import _ from 'lodash';

import { CamogramJob, TaskType } from 'types/taskBased';
import { convertToCamelCaseKeys } from 'utils';
import { configureCameraImageIdFromProviderKey } from 'utils/providerKey';

const configureLocation = (raw: any): Location => {
  return {
    ...raw,
  };
};

const configureContextLocations = (rawContext: any, keys: string[]) => {
  const newContextLocations = keys.map(key => ({
    [key]: _.mapValues(rawContext[key], configureLocation),
  }));

  return _.assign(rawContext, ...newContextLocations);
};

enum Change {
  Progress = 'PROGRESS',
  Acquire = 'ACQUIRE',
  Release = 'RELEASE',
}

type TaskChange = {
  change: Change;
  srcTaskName: TaskType;
  dstTaskName: TaskType;
  changedAt: string;
  changedBy: string;
};

const configureHasProgressedToReviewAnnotations = (
  taskChanges: TaskChange[]
): boolean => {
  const result = taskChanges.some(
    ({ change, dstTaskName }) =>
      change === Change.Progress && dstTaskName === TaskType.ReviewAnnotations
  );

  return result;
};

const configureStartedReviewAnnotation = (
  taskChanges: TaskChange[]
): {
  startedReviewAnnotationsAt: string | undefined;
  startedReviewAnnotationsBy: string | undefined;
} => {
  const qcTasks = taskChanges.filter(
    ({ change, dstTaskName }) =>
      change === Change.Acquire && dstTaskName === TaskType.ReviewAnnotations
  );

  if (qcTasks.length === 0)
    return {
      startedReviewAnnotationsAt: undefined,
      startedReviewAnnotationsBy: undefined,
    };

  const sorted = qcTasks.sort((a, b) => (a.changedAt > b.changedAt ? -1 : 1));

  const first = sorted[0];
  return {
    startedReviewAnnotationsAt: first.changedAt,
    startedReviewAnnotationsBy: first.changedBy,
  };
};

export const configureBasicJobInfo = (raw: any) => {
  const providerKey = raw.data.providerKey ?? { cameraId: raw.data.cameraId };
  const cameraImageId = configureCameraImageIdFromProviderKey(providerKey);
  return {
    providerKey,
    storeId: raw.data.storeId,
    datetime: raw.data.timestamp,
    groupId: raw.groupId,
    version: raw.workflowOnCreateInfo.version,
    cameraImageId,
    hasProgressedToReviewAnnotations: configureHasProgressedToReviewAnnotations(
      raw.taskChanges
    ),
    ...configureStartedReviewAnnotation(raw.taskChanges),
  };
};

const configureJobWithContext = (raw: any): CamogramJob => {
  if (raw.currentTaskName === TaskType.SelectUnchangedPreviousLocations) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'previousLocationsToCheckForChanges',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (
    raw.currentTaskName === TaskType.SelectLocalizedPreviousLocations
  ) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'previousLocationsToCheckForLocalization',
        'unchangedPreviousLocations',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (raw.currentTaskName === TaskType.RoutePreviousLocations) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'previousLocationsToCheckForRoute',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (raw.currentTaskName === TaskType.ReshapePreviousLocations) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'previousLocationsToReclassify',
        'previousLocationsToReshape',
        'keptPreviousLocations',
        'initialReshapedPreviousLocations',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (
    raw.currentTaskName === TaskType.SelectLocalizedProposedLocations
  ) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'proposedLocationsToCheckForLocalization',
        'previousLocationsToReclassify',
        'previousLocationsToReshape',
        'keptPreviousLocations',
        'reshapedPreviousLocations',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (raw.currentTaskName === TaskType.CreateLocations) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'unchangedPreviousLocations',
        'localizedPreviousLocations',
        'localizedProposedLocations',
        'initialCreatedLocations',
        'previousLocationsToReclassify',
        'previousLocationsToReshape',
        'keptPreviousLocations',
        'reshapedPreviousLocations',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (raw.currentTaskName === TaskType.ClassifyLocations) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'unchangedPreviousLocations',
        'localizedPreviousLocations',
        'localizedProposedLocations',
        'createdLocations',
        'initialClassifications',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  } else if (raw.currentTaskName === TaskType.ReviewAnnotations) {
    const result = {
      ...raw,
      context: configureContextLocations(raw.context, [
        'unchangedPreviousLocations',
        'localizedPreviousLocations',
        'localizedProposedLocations',
        'createdLocations',
        'classifications',
        'previousLocationsToReclassify',
        'reshapedPreviousLocations',
        'keptPreviousLocations',
      ]),
      ...configureBasicJobInfo(raw),
    };

    return result;
  }
  throw new Error('no task type');
};

export const configureJobForAnnotation = (raw: any[]) => {
  return raw.map(convertToCamelCaseKeys).map(configureJobWithContext);
};

export const configureListJob = (raw: any) => {
  const camelCaseJob = convertToCamelCaseKeys(raw);
  return {
    ...camelCaseJob,
    ...configureBasicJobInfo(camelCaseJob),
  };
};
