import { CamogramJobBase } from 'types/taskBased';
import { Cell, Row, Table, Tbody } from './styled';

type Props = {
  keys: string[];
  labels: Labels;
  items: Items;
};

type Labels = {
  [key: string]: React.ReactNode;
};

type Item = {
  [key: string]: React.ReactNode;
} & { key: string };

type Items = Item[];

export default function GeneralTable({ keys, labels, items }: Props) {
  return (
    <Table>
      <thead>
        <tr>
          {keys.map(key => (
            <Cell key={key}>{labels[key]}</Cell>
          ))}
        </tr>
      </thead>
      <Tbody>
        {items.map(item => (
          <Row key={item.key}>
            {keys.map(key => (
              <Cell key={key}>{item[key]}</Cell>
            ))}
          </Row>
        ))}
      </Tbody>
    </Table>
  );
}
