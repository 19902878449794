import api from '.';

const fetchSkuImagesApi = async (uuids: string[]) => {
  const res = await api.post(`catalog/api/v2/store_info/sku_image`, {
    sku_uuid: uuids,
  });

  return res.data.map(({ sku_uuid, image_url }) => ({
    skuUuid: sku_uuid,
    imageUrl: image_url,
  }));
};

export default fetchSkuImagesApi;
