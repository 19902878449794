import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Popover } from '@material-ui/core';
import { Check, FileCopy, Report } from '@material-ui/icons';
import useEditorPresent from 'hooks/useEditorPresent';
import _ from 'lodash';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SkuImage from 'components/SkuImage';
import SkuReportDialog from './SkuReportDialog';
import formatPrice from 'utils/formatPrice';

const SkuInner = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  flex-grow: 1;
`;

const SkuDetails = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const SkuText = styled.div`
  flex-grow: 1;
`;

const CopyContainer = styled.div`
  margin-left: 8px;
`;

const MoreButton = styled.a`
  font-size: 12px;
  text-decoration: underline;
`;

const CameraList = ({ itemUuid }) => {
  const cameraIds = useEditorPresent(state => {
    const cameraIds = [];
    for (const [camNum, locationsObject] of Object.entries(
      state.yesterday.locations
    )) {
      for (const location of Object.values(locationsObject)) {
        if (location.item_uuids?.includes(itemUuid)) {
          cameraIds.push(camNum);
        }
      }
    }
    return _.uniq(cameraIds);
  });
  const cameraImages = useEditorPresent(state => state.cameraImages);
  if (!cameraIds || cameraIds.length === 0) {
    return null;
  }
  const cameraNumbers = _.sortBy(
    [...cameraIds].map(
      cameraId => cameraImages[cameraId]?.cameraInteger ?? cameraId
    ),
    cameraNumber => parseInt(cameraNumber, 10)
  );

  return (
    <SkuDetails>
      <span>
        <b>{pluralize('Camera', cameraNumbers.length)}:</b>{' '}
        {cameraNumbers.join(', ')}
      </span>
    </SkuDetails>
  );
};

const SkuInfo = ({
  itemName,
  itemUuid,
  barcode,
  onClickSendReport,
  hideReport,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [copiedType, setCopiedType] = useState('');
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const { storeId } = useParams();
  const price = useSelector(state => state.skus[storeId]?.[itemUuid]?.price);
  const formattedPrice = formatPrice(price || null);

  const onClickItemImage = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onClickCopy = (text, type) => () => {
    navigator.clipboard.writeText(text);
    setCopiedType(type);
  };

  const onClickReportButton = () => {
    setOpenReportDialog(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <SkuInner>
        <SkuImage uuid={itemUuid} alt="sku_image" onClick={onClickItemImage} />
        <SkuText>
          <SkuDetails
            onClick={onClickCopy(itemName, 'name')}
            style={{ background: copiedType === 'name' ? 'yellow' : '' }}
          >
            <b>{itemName}</b>
            <CopyContainer>
              {copiedType === 'name' ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <FileCopy style={{ fontSize: 16 }} />
              )}
            </CopyContainer>
          </SkuDetails>

          <CameraList itemUuid={itemUuid} />

          {formattedPrice && (
            <SkuDetails
              onClick={onClickCopy(formattedPrice, 'price')}
              style={{ background: copiedType === 'price' ? 'yellow' : '' }}
            >
              <span>
                <b>Price:</b> {formattedPrice}
              </span>
              <CopyContainer>
                {copiedType === 'price' ? (
                  <Check style={{ fontSize: 16 }} />
                ) : (
                  <FileCopy style={{ fontSize: 16 }} />
                )}
              </CopyContainer>
            </SkuDetails>
          )}

          {showMore ? (
            <>
              <SkuDetails
                onClick={onClickCopy(itemUuid, 'uuid')}
                style={{ background: copiedType === 'uuid' ? 'yellow' : '' }}
              >
                <span>
                  <b>UUID:</b> {itemUuid}
                </span>
                <CopyContainer>
                  {copiedType === 'uuid' ? (
                    <Check style={{ fontSize: 16 }} />
                  ) : (
                    <FileCopy style={{ fontSize: 16 }} />
                  )}
                </CopyContainer>
              </SkuDetails>

              <SkuDetails
                onClick={onClickCopy(barcode, 'barcode')}
                style={{ background: copiedType === 'barcode' ? 'yellow' : '' }}
              >
                <span>
                  <b>Barcode:</b> {barcode}
                </span>
                <CopyContainer>
                  {copiedType === 'barcode' ? (
                    <Check style={{ fontSize: 16 }} />
                  ) : (
                    <FileCopy style={{ fontSize: 16 }} />
                  )}
                </CopyContainer>
              </SkuDetails>

              {!hideReport && (
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<Report />}
                  onClick={onClickReportButton}
                >
                  Report
                </Button>
              )}
            </>
          ) : (
            <MoreButton
              onClick={event => {
                event.preventDefault();
                setShowMore(true);
              }}
            >
              More
            </MoreButton>
          )}
        </SkuText>
      </SkuInner>

      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <SkuImage uuid={itemUuid} alt="large_image" width={500} height={500} />
      </Popover>

      <SkuReportDialog
        itemName={itemName}
        itemUuid={itemUuid}
        open={openReportDialog}
        setOpen={setOpenReportDialog}
        barcode={barcode}
        onClickSendReport={onClickSendReport}
      />
    </>
  );
};

export default SkuInfo;
