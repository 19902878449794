import _ from 'lodash';

const configureActiveShelfs = ({ coordinates, camogram_section_id }) => ({
  coordinates,
  camogramSectionId: camogram_section_id[0],
});

const configureMeta = ({ enabled, provider_key, active_shelfs = [] }) => ({
  enabled,
  providerKey: provider_key,
  activeShelfs: active_shelfs.map(configureActiveShelfs),
});

export const configureMetas = raw => {
  if (!raw) return {};
  const metas = _.mapValues(
    _.groupBy(raw, ({ provider_key: { cam_id } }) => cam_id),
    metaArray => configureMeta(metaArray[0])
  );
  return metas;
};

const addQueryParam = (path: string, key: string, value: string) => {
  const queryStringIndex = path.indexOf('?');
  const queryString =
    queryStringIndex !== -1 ? path.slice(queryStringIndex + 1) : '';
  const searchParams = new URLSearchParams(queryString);
  searchParams.set(key, value);

  const newPath =
    queryStringIndex !== -1 ? path.slice(0, queryStringIndex) : path;
  return `${newPath}?${searchParams.toString()}`;
};

export const addNumericCamIdParam = (url: string) => {
  return addQueryParam(url, 'use_numeric_cam_id', 'true');
};
