import { SET_ACTIVE_LOCATION } from 'actions/types';

// The item you are currently hovering
const activeLocation = (state = null, { type, payload }) => {
  if (type === SET_ACTIVE_LOCATION) {
    return payload;
  }

  return state;
};

export default activeLocation;
