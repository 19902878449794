import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import SkuImage from './SkuImage';

const Container = styled.div`
  position: fixed;
  margin: ${({ offset }) => offset}px;
  ${({ yPosition, yDimension }) => `${yDimension}: ${yPosition}px`};
  ${({ xPosition, xDimension }) => `${xDimension}: ${xPosition}px`};
  pointer-events: none;
  z-index: 100;
  opacity: 0.9;
  display: flex;
  background-color: white;
`;

const ItemDetail = styled.div`
  max-width: 18rem;
  align-items: center;
`;

export default function CamogramTooltip({
  offset = 20,
  size = 120,
  disabled = false,
}) {
  const { itemUuid, itemName, mouseX, mouseY, poscode, status } = useSelector(
    state => {
      const activeLocation = state.activeLocation;
      return {
        itemUuid: activeLocation?.skuId ?? activeLocation?.item_uuids?.[0],
        itemName: activeLocation?.skuLabel ?? activeLocation?.item_labels?.[0],
        poscode: activeLocation?.sku?.barcode,
        mouseX: activeLocation?.x,
        mouseY: activeLocation?.y,
        status: activeLocation?.status,
      };
    }
  );

  // force tooltip to stay inside bounds
  const { innerWidth: width, innerHeight: height } = window;

  const isBelowMiddle = mouseY > height / 2;
  const yDimension = isBelowMiddle ? 'bottom' : 'top';
  const yPosition = isBelowMiddle ? height - mouseY : mouseY;

  const isRightOfMiddle = mouseX > width / 2;
  const xDimension = isRightOfMiddle ? 'right' : 'left';
  const xPosition = isRightOfMiddle ? width - mouseX : mouseX;

  if (!itemUuid || disabled) return null;

  return (
    <Container
      offset={offset}
      yDimension={yDimension}
      xDimension={xDimension}
      yPosition={yPosition}
      xPosition={xPosition}
    >
      <SkuImage uuid={itemUuid} width={size} height={size} />
      <ItemDetail>
        {itemName && (
          <p>
            <strong>{itemName}</strong>
          </p>
        )}
        {poscode && (
          <p>
            <strong>Poscode:</strong> {poscode}
          </p>
        )}
        {status && (
          <p>
            <strong>Status:</strong> {status}
          </p>
        )}
      </ItemDetail>
    </Container>
  );
}
