import api from '.';

const fetchFFImagesBySectionApi = async ({
  storeId,
  shelfId,
  sectionId,
  datetime,
}) => {
  const response = await api.get(
    `stores/${storeId}/ptz_image?shelf_id=${shelfId}&camogram_section_id=${sectionId}`
  );
  return response.data.map(image => ({
    url: image.signed_image_url,
    storeId: image.store_id,
    sectionId: image.camogram_section_id,
    shelfId: image.shelf_id,
    uploadedAt: image.gcs_upload_datetime,
  }));
};

export default fetchFFImagesBySectionApi;
