import useResize from 'hooks/useResize';
import React from 'react';
import styled from 'styled-components';

import Camogram from './Camogram';
import Layer from './Layer';

const CameraList = styled.div`
  background-color: #eee;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 100px;
  > * {
    margin-top: 1.2rem;
  }
`;

const Camograms = ({ cameras }) => {
  const { ref, width } = useResize();
  return (
    <Layer id="cameras">
      <CameraList ref={ref}>
        {width &&
          cameras.map(camera => (
            <Camogram
              key={camera.cam_num}
              camNum={camera.cam_num}
              containerWidth={width}
            />
          ))}
      </CameraList>
    </Layer>
  );
};

export default Camograms;
