import {
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
} from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ active }) => (active ? '6px 8px' : '0px 8px')};
  border-top: ${({ active }) => (active ? '1px solid #333' : 'none')};
  border-bottom: ${({ active }) => (active ? '1px solid #333' : 'none')};
  height: ${({ active }) => (active ? 'auto' : '0px')};
  transition: all 1s ease;
  overflow: ${({ active }) => (active ? 'auto' : 'hidden')};
  max-height: 400px;
  background: #eee;
`;

const FormControl = styled(MuiFormControl)`
  min-width: 120px;
`;

const FilterTitle = styled.h3`
  margin: 0;
`;

const Filter = ({
  filter,
  active,
  categories,
  onChangeProxy,
  onChangeCategory,
}) => {
  return (
    <Container active={active}>
      <FilterTitle>Filters</FilterTitle>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={filter.proxy}
              onChange={onChangeProxy}
              size="small"
            />
          }
          label="Proxy"
        />
      </div>
      <FormControl>
        <InputLabel id="category-label">Category</InputLabel>
        <Select
          labelId="category-label"
          onChange={onChangeCategory}
          value={filter.category}
        >
          <MenuItem value="">None</MenuItem>
          {categories.map(category => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
};

export default Filter;
