import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import PaneLayout from './PaneLayout';
import FFImagesForExtremeZoom from 'components/FFImagesForExtremeZoom';
import {
  fetchFFImagesBySectionApi,
  fetchFFImagesForExtremeZoomApi,
} from 'utils/api';
import { useCompareParams } from './helpers';
import { configureShelfSectionIdFromOmniImageId } from 'utils';

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 80%;
`;
const Header = ({ storeId, datetime, camNum }) => {
  const store = useSelector(state => state.stores[storeId]);

  return (
    <HeaderContainer>
      {store.name} ({storeId})<br />
      {moment(datetime).format('lll')} / Cam {camNum}
    </HeaderContainer>
  );
};

const FFImagesForExtremeZoomPane = () => {
  const { storeId, datetime, camNum } = useCompareParams();

  const fetchFFImagesForExtremeZoom = useCallback(async () => {
    if (camNum.includes('-')) {
      // OmniEditor camNum includes store and shelf, need to parse it out.
      const { shelfId, sectionId } = configureShelfSectionIdFromOmniImageId(
        camNum
      );
      return fetchFFImagesBySectionApi({
        storeId,
        datetime,
        shelfId,
        sectionId,
      });
    }

    // Other editors camNum is fine as is
    return fetchFFImagesForExtremeZoomApi({ storeId, datetime, camNum });
  }, [storeId, datetime, camNum]);

  return (
    <PaneLayout
      header={<Header storeId={storeId} datetime={datetime} camNum={camNum} />}
    >
      <FFImagesForExtremeZoom
        fetchFFImages={fetchFFImagesForExtremeZoom}
        full={false}
      />
    </PaneLayout>
  );
};

export default FFImagesForExtremeZoomPane;
