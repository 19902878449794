import CamogramLocations from 'components/CamogramLocations';
import { CamogramLocation } from 'components/CamogramLocations/types';
import { useAppSelector } from 'hooks';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useDispatch } from 'react-redux';
import { LocationToReview } from 'types/taskBased';
import { useJob } from '../helpers';
import {
  Coordinate,
  CursorMode,
  HighresActiveSection,
  Location,
  Modify,
} from 'types';
import {
  DrawLocationProvider,
  useDrawLocationContext,
} from 'contexts/DrawLocationProvider';
import {
  addLocationToReview,
  deleteLocation,
  setSelectedLocationId,
  updateLocation,
} from 'slices/taskBasedSlice';
import { v4 } from 'uuid';
import CursorModePicker from 'contexts/DrawLocationProvider/CursorModePicker';
import styled from 'styled-components';
import useShortcut from 'hooks/useShortcut';
import { track } from 'utils/segmentAnalytics';
import { EVENT_TASK_BASED_DELETE_LOCATION } from 'common/constants';
import { calcPolygonArea } from 'utils';

const CursorModePickerContainer = styled.div`
  align-self: flex-end;
  padding: 16px;
`;

const configureLocationsColor = (selected: boolean, isExist: boolean) => {
  if (selected) {
    return '#ff0000';
  } else if (isExist) {
    return '#ffffff';
  } else {
    return '#aaffaa';
  }
};

type CreateLocationsComponentProps = Modify<
  React.ComponentProps<typeof CamogramLocations>,
  { locations: LocationToReview[] }
> & {
  existingLocations: Location[];
  selectedLocationId?: string;
  activeShelfs?: HighresActiveSection[];
};

function CreateLocationsComponent({
  existingLocations,
  locations,
  selectedLocationId,
  activeShelfs,
  ...others
}: CreateLocationsComponentProps) {
  const { cursorMode } = useDrawLocationContext();
  const allLocations: CamogramLocation[] = [
    ...existingLocations.map(location => ({
      ...location,
      color: configureLocationsColor(selectedLocationId === location.id, true),
    })),
    ...locations.map(location => ({
      ...location,
      color: configureLocationsColor(selectedLocationId === location.id, false),
      editable: selectedLocationId === location.id,
    })),
  ];
  const { onClick } = others;
  return (
    <CamogramLocations
      {...others}
      locations={allLocations}
      onClick={cursorMode === CursorMode.Pointer ? onClick : undefined}
      activeShelfs={activeShelfs}
    />
  );
}
export default function CreateLocations() {
  const dispatch = useDispatch();
  const locationsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const existingLocations = useAppSelector(
    state => state.taskBased.existingLocations
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);
  const onClick = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };

  const onAddRegion = (region: Coordinate[]) => {
    const locationId = v4();
    const area = calcPolygonArea(region);
    if (area === 0) {
      alert('The box has zero area. Could you please draw it again?');
    } else {
      dispatch(addLocationToReview({ region, id: locationId }));
    }
  };

  const onChange = ({
    locationId,
    region,
  }: {
    locationId: string;
    region: Coordinate[];
  }) => {
    dispatch(updateLocation({ locationId, data: { region } }));
  };

  useShortcut('backspace', e => {
    if (selectedLocationId) {
      e.preventDefault();
      dispatch(deleteLocation({ locationId: selectedLocationId }));
      track(EVENT_TASK_BASED_DELETE_LOCATION, {
        locationId: selectedLocationId,
      });
    }
  });

  return (
    <DrawLocationProvider defaultCursorMode={CursorMode.Draw} pointsLimit={4}>
      <CursorModePickerContainer>
        <CursorModePicker />
      </CursorModePickerContainer>
      <CreateLocationsComponent
        angle={image.angle ?? 0}
        imageSrc={jobContext.imageUrl}
        locations={locationsToReview}
        existingLocations={existingLocations}
        selectedLocationId={selectedLocationId}
        activeShelfs={image.meta?.activeShelfs}
        onClick={onClick}
        onAddRegion={onAddRegion}
        onChange={onChange}
      />
    </DrawLocationProvider>
  );
}
