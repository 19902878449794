import { fetchSkuImage } from 'actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAsyncDispatch from './useAsyncDispatch';

const useSkuImage = (storeId, uuid) => {
  const imageUrl = useSelector(state =>
    uuid ? state.skus[storeId]?.[uuid]?.imageUrl : null
  );
  const { dispatch, data, error } = useAsyncDispatch();

  useEffect(() => {
    if (imageUrl === undefined && uuid) {
      dispatch(fetchSkuImage({ storeId, skuUuid: uuid }));
    }
  }, [imageUrl, uuid, dispatch]);
  const defaultImage = '/image-not-found.svg';
  if (error) return { imageUrl: defaultImage };
  return { imageUrl: imageUrl !== null ? imageUrl : defaultImage };
};

export default useSkuImage;
