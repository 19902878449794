import { fetchCamogramInitialData } from 'actions';

const cameraExtrinsics = (state = {}, { type, payload }) => {
  const CAMOGRAM_FULFILLED = fetchCamogramInitialData.fulfilled().type;

  if (type === CAMOGRAM_FULFILLED) {
    return payload.cameraExtrinsics;
  }

  return state;
};

export default cameraExtrinsics;
