import React, { createContext, memo, useContext, useRef } from 'react';
import styled from 'styled-components';

import { CameraPosition, Layout } from 'types';
import Cameras from './Cameras';
import Exits from './Exits';
import Perimeter from './Perimeter';
import Sections from './Sections';
import Shelves from './Shelves';

function getBounds(layout: Layout) {
  // Find all the relevant points to calculate the bounding box
  const points = [
    ...layout.perimeters[0].coordinates,
    ...layout.shelves.map(s => s.coordinates).flat(),
    ...layout.exits.map(e => e.coordinates).flat(),
  ];

  const xMin = Math.min(...points.map(p => p.x));
  const yMin = Math.min(...points.map(p => p.y));
  const xMax = Math.max(...points.map(p => p.x));
  const yMax = Math.max(...points.map(p => p.y));
  const width = xMax - xMin;
  const height = yMax - yMin;
  return { xMin, yMin, xMax, yMax, width, height };
}

// The padding around the SVG in CM
// play with it until it's right
const PADDING = 100;

// The definitive guide: https://css-tricks.com/scale-svg/
const Svg = styled.svg`
  width: 100%;
  height: calc(100% - 48px);
`;

type State = {
  layout: Layout;
  cameras?: CameraPosition[];
  onCameraClick?: any;
  height: number;
  svgRef: React.RefObject<SVGSVGElement>;
  selectedCameraId?: string;
};
const Layout2DContext = createContext<State | undefined>(undefined);

const Layout2DProvider: React.FC<State> = ({ children, ...props }) => {
  return (
    <Layout2DContext.Provider value={props}>
      {children}
    </Layout2DContext.Provider>
  );
};

export const useLayout2DContext = () => {
  const context = useContext(Layout2DContext);

  if (context === undefined) {
    throw new Error(
      'useLayout2DContext must be used within a Layout2DProvider'
    );
  }

  return context;
};

type Layout2DProps = {
  layout: Layout;
  cameras?: CameraPosition[];
  onCameraClick?: (cameraId: string, e: any) => void;
  selectedCameraId?: string;
};
function Layout2D({ layout, cameras, ...others }: Layout2DProps) {
  const svgRef = useRef<SVGSVGElement>(null);
  // Find a "box" that has the size of the whole content in pixels
  const box = getBounds(layout);

  // Find the SVG bounds based on the box (same + a 100px|cm margin all around)
  const xMin = box.xMin - PADDING;
  const yMin = box.yMin - PADDING;
  const width = box.width + PADDING * 2;
  const height = box.height + PADDING * 2;
  const vOffset = box.yMin + box.yMax;

  return (
    <Layout2DProvider
      layout={layout}
      height={vOffset}
      cameras={cameras}
      svgRef={svgRef}
      {...others}
    >
      <Svg
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`${xMin} ${yMin} ${width} ${height}`}
      >
        <Perimeter />
        <Exits />
        <Shelves />
        <Sections />
        <Cameras />
      </Svg>
    </Layout2DProvider>
  );
}

export default memo(Layout2D);
