import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const DialogContainer = styled.div`
  padding: 16px;
`;

// The format of action is { label: "OK", onClick: () => {}, color: "primary" }
// Color is either "default", "primary", "inherit" or "secondary". These are from the spec of Material UI
const ActionDialog = ({
  title,
  text,
  open,
  setOpen,
  actions = [],
  cancelable = true,
  content,
}) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContainer>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        {content}
        <DialogActions>
          {cancelable && (
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
          )}
          {actions.map(({ label, onClick, color = 'default' }) => (
            <Button
              key={label}
              color={color}
              variant="contained"
              onClick={() => {
                onClick();
                setOpen(false);
              }}
            >
              {label}
            </Button>
          ))}
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
};

export default ActionDialog;
