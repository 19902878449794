import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFFImagesForExtremeZoomApi } from 'utils/api';

const fetchFFImagesForExtremeZoom = createAsyncThunk(
  'fetchFFImagesForExtremeZoom',
  async ({ storeId, datetime, camNum }) => {
    const images = await fetchFFImagesForExtremeZoomApi({
      storeId,
      datetime,
      camNum,
    });
    return { images };
  }
);

export default fetchFFImagesForExtremeZoom;
