import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Image, Layer } from 'react-konva';
import { Stage as StageRK } from 'react-konva';
import useCanvasImage from '@notthatnathan/use-canvas-image';

import Polygon from 'components/Editor/Polygon';
import { Coordinate, CursorMode, Modify } from 'types';
import { useCamogramLocationsContext } from './context';
import { convertPointsToRegion, convertRegionToPoints } from 'utils';
import { CamogramLocation } from './types';
import {
  useDrawHandlers,
  useDrawLocationContext,
} from 'contexts/DrawLocationProvider';
import useImage from 'use-image';
import './canvasImage.css';

const Stage = styled(StageRK)`
  position: absolute;
  left: 0;
  top: 0;
  cursor: ${({ draw }) => (draw ? 'crosshair' : 'auto')};
`;

type LocationsComponentProps = {
  locations: CamogramLocation[];
  angle: number;
  drawingPoints?: number[][];
  imageSrc: string;
  handleMouseDown?: (event: any) => void;
  handleMouseMove?: (event: any) => void;
  cursorMode?: CursorMode;
  onClick?: (locationId: string) => void;
  onMouseEnter?: (event: any, locationId: string) => void;
  onMouseLeave?: (event: any, locationId: string) => void;
  onChange?: ({
    locationId,
    region,
  }: {
    locationId: string;
    region: Coordinate[];
  }) => void;
};

const LocationsComponent = ({
  locations,
  angle,
  drawingPoints,
  imageSrc,
  handleMouseDown,
  handleMouseMove,
  cursorMode,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onChange,
}: LocationsComponentProps) => {
  const { imageSize } = useCamogramLocationsContext();
  const { width, height } = imageSize ?? { width: 0, height: 0 };
  const [image] = useImage(imageSrc, 'anonymous', 'origin');
  const shouldDraw = drawingPoints !== undefined && drawingPoints.length > 0;
  const draw =
    cursorMode === CursorMode.Draw || cursorMode === CursorMode.Drawing;
  const _onMouseEnter = onMouseEnter
    ? (locationId: string) => (event: any) => {
        onMouseEnter(event, locationId);
      }
    : undefined;
  const _onMouseLeave = onMouseLeave
    ? (locationId: string) => (event: any) => {
        onMouseLeave(event, locationId);
      }
    : undefined;
  const canvasRef = useRef();
  const layerRef = useRef<any>();

  const updateCanvasImage = useCanvasImage({
    canvas: '#canvas-id-camogramviz',
    canvasClassname: 'fs-exclude',
    quality: 0.3,
    imgClassname: 'canvasImage',
  });

  useEffect(() => {
    if (!layerRef.current) return;
    layerRef.current.getCanvas()._canvas.id = 'canvas-id-camogramviz';
    layerRef.current.getCanvas().context._context.willReadFrequently = true;
  }, [layerRef.current]);

  useEffect(() => {
    let id;
    const onFocus = () => {
      id = setInterval(() => {
        updateCanvasImage();
      }, 100);
      return true;
    };
    const onBlur = () => {
      clearInterval(id);
      return true;
    };
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    onFocus();
    return () => {
      clearInterval(id);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <>
      <Stage
        width={width}
        height={height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        draw={draw}
        ref={canvasRef}
      >
        <Layer ref={layerRef}>
          <Image
            image={image}
            width={imageSize?.width}
            height={imageSize?.height}
            perfectDrawEnabled={false}
          />
          {shouldDraw && (
            // @ts-ignore
            <Polygon
              points={drawingPoints}
              closed
              stroke="#9be931"
              strokeWidth={4}
              fill="#9be93155"
            />
          )}
          {locations &&
            locations.map(location => (
              <React.Fragment key={location.id}>
                {/* @ts-ignore */}
                <Polygon
                  points={convertRegionToPoints(
                    location.region,
                    width,
                    height,
                    angle
                  )}
                  closed
                  stroke={location.color}
                  strokeWidth={4}
                  fill={`${location.color}22`}
                  onClick={onClick ? () => onClick(location.id) : undefined}
                  onMouseEnter={_onMouseEnter?.(location.id)}
                  onMouseLeave={_onMouseLeave?.(location.id)}
                  isSelected={Boolean(location.editable)}
                  onChange={points =>
                    onChange?.({
                      locationId: location.id,
                      region: convertPointsToRegion(
                        points,
                        width,
                        height,
                        angle
                      ),
                    })
                  }
                />
              </React.Fragment>
            ))}
        </Layer>
      </Stage>
    </>
  );
};

type LocationWithDrawProps = Modify<
  LocationsProps,
  {
    onAddRegion: (region: Coordinate[]) => void;
  }
>;
function LocationsWithDraw(props: LocationWithDrawProps) {
  const { imageSize } = useCamogramLocationsContext();
  const { width, height } = imageSize ?? { width: 0, height: 0 };
  const angle = props.angle;
  const { cursorMode, allPoints } = useDrawLocationContext();
  const { handleMouseDown, handleMouseMove } = useDrawHandlers({
    width,
    height,
    angle,
    onAddRegion: props.onAddRegion,
  });

  return (
    <LocationsComponent
      {...props}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      cursorMode={cursorMode}
      drawingPoints={allPoints}
      onChange={props.onChange}
    />
  );
}

type LocationsProps = {
  locations: CamogramLocation[];
  angle: number;
  imageSrc: string;
  onClick?: (locationId: string) => void;
  onAddRegion?: (region: Coordinate[]) => void;
  onMouseEnter?: (event: any, locationId: string) => void;
  onMouseLeave?: (event: any, locationId?: string) => void;
  onChange?: ({
    locationId,
    region,
  }: {
    locationId: string;
    region: Coordinate[];
  }) => void;
};

export default function Locations({ onAddRegion, ...others }: LocationsProps) {
  if (onAddRegion) {
    return <LocationsWithDraw onAddRegion={onAddRegion} {...others} />;
  } else {
    return <LocationsComponent {...others} />;
  }
}
