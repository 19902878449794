import styled from 'styled-components';
import Flex from 'components/Flex';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';
import HighlightLocationContainer from 'pages/TaskBasedEditor/Content/common/HighlightLocationContainer';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { useJob } from '../helpers';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { setSelectedLocationId } from 'slices/taskBasedSlice';
import ItemListContainer from './ItemList';
import CamogramLocations from 'components/CamogramLocations';
import Compare from './Compare';
import CamogramTooltip from 'components/CamogramTooltip';
import { setActiveLocation } from 'actions';
import { COLORS } from 'common/constants';
import NoReviewItems from './NoReviewItems';
import FlagButtons from 'pages/TaskBasedEditor/components/FlagButtons';

const FlagsContainer = styled.div`
  padding: 8px;
  > * {
    margin-bottom: 8px;
  }
  border-bottom: 1px solid #ccc;
`;

const FlagsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

type ClassifyItemsComponentProps = {
  locations: LocationToReview[];
  selectedLocationId?: string;
  imageSrc: string;
  angle: number;
  zoomIn?: boolean;
  onChangeLocationId: (locationId: string) => void;
};

function ClassifyItemsComponent({
  locations,
  selectedLocationId,
  imageSrc,
  angle,
  zoomIn,
  onChangeLocationId,
}: ClassifyItemsComponentProps) {
  if (selectedLocationId === undefined || locations.length === 0)
    return <NoReviewItems />;
  const selectedLocation = locations.find(
    location => location.id === selectedLocationId
  );
  if (selectedLocation === undefined)
    throw new Error(
      'Selected location does not exist in locations in ClassifyItemsComponent'
    );
  return (
    <Flex direction="column" padding={16}>
      <HighlightLocationContainer
        region={selectedLocation.region}
        imageSrc={imageSrc}
        rotation={0}
        angle={angle}
        zoomIn={zoomIn}
        itemUuid={selectedLocation.skuId}
      />
      <div>
        <LocationHorizontalScrollList
          locations={locations}
          selectedLocationUuId={selectedLocationId}
          onChangeLocationUuid={onChangeLocationId}
        />
      </div>
    </Flex>
  );
}

const configureColor = (location: LocationToReview, selected: boolean) => {
  if (selected) {
    return COLORS.selected;
  } else if (location.isUncertain) {
    return COLORS.unknown;
  } else if (location.status === LocationReviewStatus.Init) {
    return COLORS.init;
  } else if (location.status === LocationReviewStatus.Classified) {
    return COLORS.approved;
  } else {
    const errorMessage = `This status must not happen in configureColor in SelectLocalizedProposedLocation. location: ${JSON.stringify(
      location
    )}, selected: ${selected}`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};

export default function ClassifyItems() {
  const dispatch = useDispatch();
  const locationsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const existingLocations = useAppSelector(
    state => state.taskBased.existingLocations
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);
  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };
  const onMouseEnter = (event: any, locationId: string) => {
    const location =
      locationsToReview.find(({ id }) => locationId === id) ??
      existingLocations.find(({ id }) => locationId === id);
    if (location === undefined)
      throw new Error('Location is undefined onMouseEnter');
    dispatch(
      setActiveLocation({ ...location, x: event.clientX, y: event.clientY })
    );
  };
  const onMouseLeave = (event: any, locationId?: string) => {
    if (locationId) {
      dispatch(setActiveLocation(null));
    }
  };
  const existingLocationsWithColor = existingLocations.map(location => ({
    ...location,
    color: COLORS.approved,
  }));
  const locations = locationsToReview.map(locationReview => ({
    ...locationReview,
    color: configureColor(
      locationReview,
      selectedLocationId === locationReview.id
    ),
  }));
  const zoomOutLocations = existingLocationsWithColor.concat(locations);

  return (
    <Flex>
      <Flex
        direction="column"
        style={{ maxWidth: 300, borderRight: '1px solid #ccc' }}
      >
        <ItemListContainer />
        <FlagsContainer>
          <FlagsTitle>Flags</FlagsTitle>
          <FlagButtons />
        </FlagsContainer>
        <Compare />
      </Flex>
      <Flex direction="column">
        <Flex style={{ maxHeight: 220 }}>
          <CamogramLocations
            locations={zoomOutLocations}
            imageSrc={jobContext.imageUrl}
            angle={image.angle ?? 0}
            activeShelfs={image.meta?.activeShelfs}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Flex>
        <ClassifyItemsComponent
          locations={locationsToReview}
          selectedLocationId={selectedLocationId}
          imageSrc={jobContext.imageUrl}
          angle={image.angle ?? 0}
          onChangeLocationId={onChangeLocationId}
        />
      </Flex>
      <CamogramTooltip />
    </Flex>
  );
}
