import styled from 'styled-components';

import TaskListComponent from './TaskList';
import AnnotatorLayout from 'layouts/AnnotatorLayout';

const Main = styled.div`
  margin: 20px auto 50px;
  max-width: 1290px;
  width: 90%;
`;

export default function TaskList() {
  return (
    <AnnotatorLayout>
      <Main>
        <TaskListComponent />
      </Main>
    </AnnotatorLayout>
  );
}
