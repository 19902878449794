import { IconButton } from '@material-ui/core';
import {
  ArrowBackIos,
  ArrowForwardIos,
  ArrowDropDown,
  SkipNext,
  SkipPrevious,
} from '@material-ui/icons';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 0;
`;

const Title = styled.div`
  align-self: center;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  flex: 1;
  padding: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding-bottom: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ContentContainer = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  transition: all 0.3s ease;
  ${({ selected }) => (selected ? 'transform: scale(1.4)' : null)};
  margin: ${({ selected }) => (selected ? '0px 16px' : null)};
`;

type HorizontalScrollListProps<T> = {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  currentIndex: number;
  onNext: () => void;
  onPrevious: () => void;
  onSkipNext: () => void;
  onSkipPrevious: () => void;
  onClick: (item: T, index: number) => void;
  title?: React.ReactNode;
};

type ItemBase = {
  key: string;
};

export default function HorizontalScrollList<T extends ItemBase>({
  items,
  currentIndex,
  title,
  renderItem,
  onNext,
  onPrevious,
  onSkipNext,
  onSkipPrevious,
  onClick,
}: HorizontalScrollListProps<T>) {
  const _onClick = (item: T, index: number) => () => onClick(item, index);
  const ref = useRef<SVGSVGElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentIndex]);
  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        <ButtonsContainer>
          <IconButton onClick={onSkipPrevious}>
            <SkipPrevious />
          </IconButton>
          <IconButton onClick={onPrevious}>
            <ArrowBackIos />
          </IconButton>
        </ButtonsContainer>
        <ItemsContainer>
          {items.map((item, index) => (
            <ItemContainer key={item.key} onClick={_onClick(item, index)}>
              <div style={{ height: 24 }}>
                {currentIndex === index ? (
                  <ArrowDropDown
                    color="secondary"
                    style={{ marginTop: -30, fontSize: 50 }}
                    ref={ref}
                  />
                ) : (
                  index
                )}
              </div>
              <ContentContainer selected={currentIndex === index}>
                {renderItem(item, index)}
              </ContentContainer>
            </ItemContainer>
          ))}
        </ItemsContainer>
        <ButtonsContainer>
          <IconButton onClick={onNext}>
            <ArrowForwardIos />
          </IconButton>
          <IconButton onClick={onSkipNext}>
            <SkipNext />
          </IconButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}
