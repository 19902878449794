import styled from 'styled-components';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';

import { Coordinate, Dimensions, HighresActiveSection } from 'types';
import { CamogramLocationsProvider } from './context';
import { CamogramLocation } from './types';
import Locations from './Locations';
import ActiveShelfs from 'pages/TaskBasedEditor/Content/ActiveShelfs';

const ContentContainer = styled.div`
  flex: 1;
`;

type CamogramLocationsProps = {
  locations: CamogramLocation[];
  imageSrc: string;
  angle: number;
  activeShelfs?: HighresActiveSection[];
  onClick?: (locationId: string) => void;
  onAddRegion?: (region: Coordinate[]) => void;
  onMouseEnter?: (event: any, locationId: string) => void;
  onMouseLeave?: (event: any, locationId?: string) => void;
  onChange?: ({
    locationId,
    region,
  }: {
    locationId: string;
    region: Coordinate[];
  }) => void;
};

export default function CamogramLocations({
  locations,
  imageSrc,
  angle,
  activeShelfs,
  onClick,
  onAddRegion,
  onMouseEnter,
  onMouseLeave,
  onChange,
}: CamogramLocationsProps) {
  const [zoom, setZoom] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const imageWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [containerDimensions, setContainerDimensions] = useState<Dimensions>();
  const shouldDisplayActiveShelfs =
    activeShelfs !== undefined && activeShelfs.length > 0;
  useLayoutEffect(() => {
    if (imageWrapperRef.current) {
      setContainerDimensions({
        width: imageWrapperRef.current.offsetWidth,
        height: imageWrapperRef.current.offsetHeight,
      });
    }
  }, []);
  const onLoad = ({ target }: any) => {
    // containerDimensions must be set before onLoad
    if (!containerDimensions) return;
    const imageWidth = target.naturalWidth;
    const imageHeight = target.naturalHeight;
    const {
      width: containerWidth,
      height: containerHeight,
    } = containerDimensions;
    const containerRatio = containerHeight / containerWidth;
    const imageRatio = imageHeight / imageWidth;
    const displayRatio = 0.8;

    const shouldFitWidth = containerRatio > imageRatio;
    const width = shouldFitWidth
      ? containerWidth * displayRatio
      : (containerHeight * displayRatio) / imageRatio;
    const height = width * imageRatio;
    const translation = shouldFitWidth
      ? { x: 0, y: (containerHeight - height) / 2 }
      : { x: (containerWidth - width) / 2, y: 0 };
    const scale = width / imageWidth;

    setImageSize({ width: imageWidth, height: imageHeight });
    setZoom({
      scale: scale,
      translation,
    });
  };

  useEffect(() => {
    // To determine image's size
    if (!containerDimensions) return;
    const image = new Image();
    image.src = imageSrc;
    image.onload = e => {
      onLoad(e);
    };
  }, [containerDimensions]);
  return (
    <ContentContainer ref={imageWrapperRef}>
      {containerDimensions && (
        <MapInteractionCSS
          minScale={0.1}
          maxScale={15}
          value={zoom}
          onChange={setZoom}
        >
          {shouldDisplayActiveShelfs && (
            <ActiveShelfs
              width={imageSize.width}
              height={imageSize.height}
              activeShelfs={activeShelfs}
              angle={angle}
            />
          )}
          <CamogramLocationsProvider imageSize={imageSize}>
            <Locations
              imageSrc={imageSrc}
              locations={locations}
              angle={angle}
              onClick={onClick}
              onAddRegion={onAddRegion}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onChange={onChange}
            />
          </CamogramLocationsProvider>
        </MapInteractionCSS>
      )}
    </ContentContainer>
  );
}
