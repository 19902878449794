import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { formatDatetime } from 'utils/formatDatetime';

const Container = styled.div`
  border-radius: 10px;
  border: solid 1px #cdcdcd;
  min-width: 268px;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const InfoContainer = styled.div`
  flex: 1;
  > * {
    margin-bottom: 4px;
  }
`;

type Props = {
  storeId: string;
  groupId: string;
  storeName: string;
  cameraId: string;
  createdAt: string;
  createdBy?: string;
  startedReviewAnnotationsAt?: string;
  currentTaskName: string;
  onClickContinueButton: () => void;
};

export default function MyInProgressCamogramItem({
  storeId,
  groupId,
  storeName,
  cameraId,
  createdAt,
  createdBy,
  startedReviewAnnotationsAt,
  currentTaskName,
  onClickContinueButton,
}: Props) {
  return (
    <Container>
      <InfoContainer>
        <b>
          {storeName} ({storeId})
        </b>
        <div>
          <b>Group ID:</b> {groupId}
        </div>
        <div>
          <b>Cam:</b> {cameraId}
        </div>
        <div>
          <b>Task:</b> {currentTaskName}
        </div>
        <div>
          <b>Created at </b>
          {formatDatetime(createdAt)}{' '}
        </div>
        {createdBy && (
          <div>
            <b>Created By </b>
            {createdBy}{' '}
          </div>
        )}
        {startedReviewAnnotationsAt && (
          <div>
            <b>Started QC at </b>
            {formatDatetime(startedReviewAnnotationsAt)}{' '}
          </div>
        )}
      </InfoContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={onClickContinueButton}
        style={{ alignSelf: 'flex-end' }}
      >
        Continue
      </Button>
    </Container>
  );
}
