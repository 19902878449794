import { useRef, useEffect } from 'react';

const clean = key => key.toLowerCase().replace(/^arrow/, '');

// When the target is one of these, skip the keystroke
const skipOn = ['INPUT', 'TEXTAREA'];

// Handle single-key shortcuts like "up", "space", "enter", etc.
// Optimized to avoid too many re-renders, see
// https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
export default function useShortcut(key, cb, forceInput) {
  const ref = useRef(cb);

  useEffect(() => {
    ref.current = cb;
  }, [cb]);

  useEffect(() => {
    const onKeyUp = e => {
      if (!forceInput && skipOn.includes(e.target.nodeName)) return;
      if (clean(e.key) === key) ref.current(e);
      e.stopPropagation();
    };
    window.addEventListener('keydown', onKeyUp);
    return () => window.removeEventListener('keydown', onKeyUp);
  }, [forceInput, key]);
}
