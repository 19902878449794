import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu } from '@material-ui/core';

import Item from '../Aside/Item';
import SkuImage from 'components/SkuImage';

const SimilarItemsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 8px;
`;

const SimilarSkuContainer = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  :hover {
    background-color: ${({ onClick }) => (onClick ? '#c4c4c4' : '#fff')};
  }
`;

const SimilarSku = ({
  uuid,
  name,
  barcode,
  onClickSend,
  onClickSendReport,
  onClickItem,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const onCloseMenu = () => {
    setAnchorEl(null);
  };
  const onClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <SimilarSkuContainer onClick={onClick}>
        <SkuImage uuid={uuid} width={48} height={48} alt="similar_sku" />
      </SimilarSkuContainer>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseMenu}>
        <Item
          itemName={name}
          itemUuid={uuid}
          barcode={barcode}
          onClickSend={onClickSend}
          onClickSendReport={onClickSendReport}
          onClick={onClickItem}
        />
      </Menu>
    </>
  );
};

const SimilarSkus = ({ similarSkus, onClickSend, onClickItem }) => {
  if (similarSkus.length === 0) return null;
  return (
    <div>
      <div>Similar Items</div>
      <SimilarItemsContainer>
        {similarSkus.map(sku => (
          <SimilarSku
            key={sku.uuid}
            name={sku.display_name}
            uuid={sku.uuid}
            barcode={sku.barcode}
            onClickSend={onClickSend}
            onClickItem={onClickItem}
          />
        ))}
      </SimilarItemsContainer>
    </div>
  );
};

export default SimilarSkus;
