import api from '..';
import { addNumericCamIdParam } from '../helpers';
import { configureJobForAnnotation } from './helpers';

type ProgressJobApiProps = {
  jobId: string;
  annotation: any;
};

const progressJobApi = async ({ jobId, annotation }: ProgressJobApiProps) => {
  const res = await api.post(
    addNumericCamIdParam('task-based/generic/progress-job'),
    {
      job_id: jobId,
      annotation,
      return_job_for_annotation: false,
    }
  );
  const data = res.data.data;

  // If all tasks are completed, the backend returns { data: None }
  if (!data) return { job: null };

  const job = configureJobForAnnotation([data])[0];
  return { job };
};

export default progressJobApi;
