import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import fetchSkuImages from 'actions/fetchSkuImages';
import {
  EVENT_TASK_BASED_ADD_LOCATION,
  EVENT_TASK_BASED_COMPLETE_TASK,
  EVENT_TASK_BASED_REGRESS_TASK,
  EVENT_TASK_BASED_REVIEW_LOCATION,
  EVENT_TASK_BASED_UPDATE_CAMERA,
  EVENT_TASK_BASED_UPDATE_LOCATION,
} from 'common/constants';
import _ from 'lodash';
import moment from 'moment';

import { Coordinate, LoadingStatus, Location, ProviderKey } from 'types';
import {
  CamogramJob,
  LocationReviewStatus,
  LocationToReview,
  TaskType,
} from 'types/taskBased';
import { findNextLocationToReview } from 'utils';
import fetchJobsApi from 'utils/api/taskBasedEditor/fetchJobsApi';
import fetchJobsForAnnotationApi, {
  FetchJobsForAnnotationApiProps,
} from 'utils/api/taskBasedEditor/fetchJobsForAnnotationAPi';
import progressJobApi from 'utils/api/taskBasedEditor/progressJobApi';
import regressJobApi from 'utils/api/taskBasedEditor/regressJobApi';
import { configureCameraImageIdFromProviderKey } from 'utils/providerKey';
import { track } from 'utils/segmentAnalytics';
import {
  configureExistingLocations,
  configureLocationsToReview,
  configureTaskAnnotation,
} from './helpers';

type CompleteTaskProps = {
  job: CamogramJob;
};
export const completeTask = createAsyncThunk(
  'taskBased/completeTask',
  async ({ job }: CompleteTaskProps, { getState, dispatch }) => {
    const { taskBased } = getState() as {
      taskBased: TaskBasedState;
    };
    const annotation = configureTaskAnnotation({
      job,
      locationsToReview: taskBased.locationsToReview,
    });
    await progressJobApi({
      jobId: job.id,
      annotation,
    });

    track(EVENT_TASK_BASED_COMPLETE_TASK, {
      storeId: job.storeId,
      cameraId: job.providerKey.cameraId,
      camogramTimestamp: job.datetime,
      groupId: job.groupId,
      currentTaskName: job.currentTaskName,
      jobId: job.id,
      providerKey: job.providerKey,
    });

    const jobs = await fetchJobsApi({
      storeId: job.storeId,
      groupId: job.groupId,
    });
    const newJob = jobs.find(newJob => newJob.id === job.id);

    if (newJob === undefined)
      throw new Error('newJob is undefined on complete');

    // If the job is completed or the last task before "ReviewAnnotations" is completed by an annotator, we don't want to go to the next task
    const shouldNotGoToNextTask =
      newJob.currentTaskName === TaskType.Completed ||
      (newJob.currentTaskName === TaskType.ReviewAnnotations &&
        !job.hasProgressedToReviewAnnotations);
    if (shouldNotGoToNextTask) {
      return { newJob: { ...newJob, context: null } };
    }

    const res = await dispatch(
      fetchCameraJobForAnnotation({
        storeId: job.storeId,
        groupId: job.groupId,
        providerKey: job.providerKey,
        version: job.version,
      })
    );

    const { cameraJob } = res.payload as { cameraJob: CamogramJob };

    return { newJob: cameraJob };
  }
);

const getSkuUuidsFromJob = (job: CamogramJob, skus: any) => {
  const skuUuidsForLocationsToReview = configureLocationsToReview(
    job,
    skus
  ).map(({ skuId }) => skuId);
  const skuUuidsForExistingLocations = configureExistingLocations(job).map(
    ({ skuId }) => skuId
  );

  const skuUuids = [
    ...skuUuidsForLocationsToReview,
    ...skuUuidsForExistingLocations,
  ].filter(uuid => uuid !== undefined) as string[];

  return skuUuids;
};

export const fetchCameraJobForAnnotation = createAsyncThunk<
  { cameraJob: CamogramJob },
  FetchJobsForAnnotationApiProps
>(
  'fetchCameraJobForAnnotation',
  async (
    { storeId, groupId, providerKey, version }: FetchJobsForAnnotationApiProps,
    { dispatch, getState }
  ) => {
    const cameraJobs = await fetchJobsForAnnotationApi({
      storeId,
      groupId,
      providerKey,
      version,
    });

    const cameraJob = cameraJobs.find(
      ({ cameraImageId }) =>
        cameraImageId === configureCameraImageIdFromProviderKey(providerKey)
    );

    if (cameraJob === undefined)
      throw new Error(
        `There is no job for providerKey: ${JSON.stringify(
          providerKey
        )} of ${storeId} / ${groupId}`
      );

    const { skus: allSkus } = getState() as { skus: any };
    const skus = allSkus[storeId];
    const skuUuids = getSkuUuidsFromJob(cameraJob, skus);

    await dispatch(fetchSkuImages({ storeId, skuUuids }));
    dispatch(initializeTaskBasedState({ job: cameraJob, skus }));

    return { cameraJob };
  }
);

type RegressTaskProps = {
  job: CamogramJob;
  toTaskName: string;
};

export const regressTask = createAsyncThunk(
  'taskBased/regressTask',
  async ({ job, toTaskName }: RegressTaskProps, { dispatch }) => {
    const { job: newJob } = await regressJobApi({
      jobId: job.id,
      taskName: toTaskName,
    });
    const isSameTask = newJob.currentTaskName === job.currentTaskName;
    track(EVENT_TASK_BASED_REGRESS_TASK, {
      storeId: job.storeId,
      cameraId: job.providerKey.cameraId,
      camogramTimestamp: job.datetime,
      groupId: job.groupId,
      currentTaskName: job.currentTaskName,
      toTaskName,
      jobId: job.id,
      providerKey: job.providerKey,
    });
    await dispatch(
      fetchCameraJobForAnnotation({
        storeId: job.storeId,
        groupId: job.groupId,
        providerKey: job.providerKey,
        version: job.version,
      })
    );
    return { newJob, isSameTask };
  }
);

export const updateSelectedJobId = createAsyncThunk(
  'taskBased/updateSelectedJobId',
  async (
    {
      providerKey,
      storeId,
      groupId,
      version,
      jobId,
    }: {
      providerKey: ProviderKey;
      storeId: string;
      groupId: string;
      version: number;
      jobId: string;
    },
    { dispatch }
  ) => {
    track(EVENT_TASK_BASED_UPDATE_CAMERA, {
      cameraId: providerKey.cameraId,
      providerKey: providerKey,
      jobId,
    });

    await dispatch(
      fetchCameraJobForAnnotation({
        storeId,
        groupId,
        providerKey,
        version,
      })
    );
    return { providerKey, jobId };
  }
);

export type TaskBasedState = {
  locationsToReview: LocationToReview[];
  existingLocations: Location[];
  selectedLocationId?: string;
  loadingStatus: LoadingStatus;
  selectedJob?: CamogramJob;
};
const initialState: TaskBasedState = {
  locationsToReview: [],
  existingLocations: [],
  selectedLocationId: undefined,
  loadingStatus: LoadingStatus.Init,
  selectedJob: undefined,
};
const taskBasedSlice = createSlice({
  name: 'taskBased',
  initialState,
  reducers: {
    initializeTaskBasedState(
      state,
      action: PayloadAction<{
        job: CamogramJob;
        skus: any;
      }>
    ) {
      const { job, skus } = action.payload;
      const locationsToReview = configureLocationsToReview(job, skus);
      const existingLocations = configureExistingLocations(job);
      state.locationsToReview = locationsToReview;
      state.selectedLocationId =
        job.currentTaskName !== TaskType.CreateLocations
          ? locationsToReview.filter(
              ({ status }) => status === LocationReviewStatus.Init
            )[0]?.id ?? locationsToReview[0]?.id
          : undefined;
      state.existingLocations = existingLocations;
      state.loadingStatus = LoadingStatus.Success;
      state.selectedJob = job;
    },
    reviewLocation(
      state,
      action: PayloadAction<{
        locationUuid: string;
        data: Partial<LocationToReview>;
      }>
    ) {
      const { locationUuid, data } = action.payload;
      const locationsToReview = state.locationsToReview;
      const currentIndex = locationsToReview.findIndex(
        ({ id }) => id === locationUuid
      );
      const reviewedAt = moment().toISOString();

      const nextLocationToReview = findNextLocationToReview(
        locationsToReview,
        currentIndex
      );
      state.selectedLocationId = nextLocationToReview?.id ?? locationUuid;
      state.locationsToReview = locationsToReview.map(location =>
        location.id === locationUuid
          ? { ...location, ...data, reviewedAt }
          : location
      );
      track(EVENT_TASK_BASED_REVIEW_LOCATION, {
        locationId: locationUuid,
        cameraId: state.selectedJob?.providerKey.cameraId,
        data,
      });
    },
    setSelectedLocationId(
      state,
      action: PayloadAction<{ locationId: string | undefined }>
    ) {
      state.selectedLocationId = action.payload.locationId;
    },
    addLocationToReview(
      state,
      action: PayloadAction<{ id: string; region: Coordinate[] }>
    ) {
      const { id, region } = action.payload;
      const newLocation: LocationToReview = {
        id,
        region,
        rotation: 0,
        saved: false,
        key: id,
        status: LocationReviewStatus.Init,
      };
      state.locationsToReview = [...state.locationsToReview, newLocation];
      state.selectedLocationId = undefined;
      track(EVENT_TASK_BASED_ADD_LOCATION, {
        locationId: id,
        region,
        cameraId: state.selectedJob?.providerKey.cameraId,
      });
    },
    updateLocation(
      state,
      action: PayloadAction<{
        locationId: string;
        data: Partial<LocationToReview>;
      }>
    ) {
      const { locationId, data } = action.payload;
      state.selectedLocationId = locationId;
      const locationsToReview = state.locationsToReview;
      state.locationsToReview = locationsToReview.map(location =>
        location.id === locationId ? { ...location, ...data } : location
      );
      track(EVENT_TASK_BASED_UPDATE_LOCATION, {
        locationId,
        data,
        cameraId: state.selectedJob?.providerKey.cameraId,
      });
    },
    deleteLocation(state, action: PayloadAction<{ locationId: string }>) {
      const { locationId } = action.payload;
      const locationsToReview = state.locationsToReview;

      state.locationsToReview = locationsToReview.filter(
        ({ id }) => id !== locationId
      );
      state.selectedLocationId = undefined;
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(fetchCameraJobForAnnotation.fulfilled), state => {
        state.loadingStatus = LoadingStatus.Success;
      })
      .addMatcher(isAnyOf(fetchCameraJobForAnnotation.pending), state => {
        state.loadingStatus = LoadingStatus.Loading;
      })
      .addMatcher(
        isAnyOf(fetchCameraJobForAnnotation.rejected, completeTask.rejected),
        state => {
          state.loadingStatus = LoadingStatus.Error;
        }
      );
  },
});

export const {
  reviewLocation,
  initializeTaskBasedState,
  setSelectedLocationId,
  addLocationToReview,
  updateLocation,
  deleteLocation,
  clear,
} = taskBasedSlice.actions;
export default taskBasedSlice;
