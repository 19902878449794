import React, { useContext, useEffect, useRef, useState } from 'react';
import { Line, Circle } from 'react-konva';
import _ from 'lodash';
import { ZoomContext } from 'contexts/Editor';

const computeDraggedNextPoint = (offset, points) => {
  return points.map(point => [point[0] + offset.x, point[1] + offset.y]);
};

const Polygon = ({
  stroke = '#eee',
  strokeWidth,
  points,
  hover,
  dash,
  fill,
  isSelected = false,
  closed = false,
  setIsMouseOverStartPoint = () => {},
  onClick,
  onChange,
  onMouseEnter: onMouseEnterProps,
  onMouseLeave: onMouseLeaveProps,
  ...others
}) => {
  const { scale } = useContext(ZoomContext);

  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = e => {
    if (!onClick && !onMouseEnter) return;
    e.evt.target.style.cursor = 'pointer';
    setIsHover(true);
    onMouseEnterProps?.(e.evt);
  };
  const onMouseLeave = e => {
    if (!onClick && !onMouseLeave) return;
    e.evt.target.style.removeProperty('cursor');
    setIsHover(false);
    onMouseLeaveProps?.(e.evt);
  };

  if (!strokeWidth) {
    strokeWidth = 1 / Math.sqrt(scale);
  }
  if (isSelected) {
    strokeWidth = 2 * strokeWidth;
  }
  const circleWidth = 16 / Math.sqrt(scale);

  const pointsToFlattened = points => points.reduce((a, b) => a.concat(b), []);
  const [flattenedPoints, setFlattentedPoints] = useState(
    pointsToFlattened(points)
  );
  const [isDragging, setIsDragging] = useState(false);
  const lineRef = useRef(null);

  useEffect(() => {
    setFlattentedPoints(pointsToFlattened(points));
    // Need to reset x,y when points are changed
    if (lineRef?.current?.x) {
      lineRef.current.x(0);
      lineRef.current.y(0);
    }
  }, [points.flat().join(',')]);

  const handleMouseOverStartPoint = event => {
    if (closed || points.length < 3) return;
    event.target.scale({ x: 2, y: 2 });
    setIsMouseOverStartPoint(true);
  };

  const handleMouseOutStartPoint = event => {
    event.target.scale({ x: 1, y: 1 });
    setIsMouseOverStartPoint(false);
  };

  const computeNextPoints = event => {
    const index = event.target.attrs.index;
    const pos = [event.target.attrs.x, event.target.attrs.y];
    return [...points.slice(0, index), pos, ...points.slice(index + 1)];
  };

  const onMouseDown = event => {
    if (!onClick) return;
    event.evt.stopPropagation();
  };

  const handleDragMovePoint = event => {
    setFlattentedPoints(pointsToFlattened(computeNextPoints(event)));
  };

  const handleDragEndPoint = event => {
    onChange(computeNextPoints(event));
  };

  const circlePoints = closed ? points : _.dropRight(points);

  const onDragStart = e => {
    setIsDragging(true);
  };

  const onDragEnd = e => {
    const offset = {
      x: e.target.x(),
      y: e.target.y(),
    };
    const nextPoints = computeDraggedNextPoint(offset, points);
    setIsDragging(false);
    onChange(nextPoints);
  };

  return (
    <>
      <Line
        ref={lineRef}
        points={flattenedPoints}
        stroke={isSelected ? 'red' : stroke}
        strokeWidth={strokeWidth}
        dash={dash}
        perfectDrawEnabled={false}
        closed={closed}
        fill={fill}
        shadowColor="black"
        shadowBlur={0}
        shadowOffset={{ x: 1, y: 1 }}
        shadowOpacity={0.5}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        draggable={isSelected && closed}
        onMouseDown={onMouseDown}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        {...(isHover ? hover : {})}
        {...others}
      />
      {isSelected &&
        !isDragging &&
        circlePoints.map((point, index) => {
          const x = point[0];
          const y = point[1];
          const startPointAttr =
            index === 0
              ? {
                  onMouseEnter: handleMouseOverStartPoint,
                  onMouseLeave: handleMouseOutStartPoint,
                }
              : null;
          return (
            <Circle
              key={index}
              index={index}
              x={x}
              y={y}
              hitStrokeWidth={2}
              width={circleWidth}
              height={circleWidth}
              fill="white"
              stroke="black"
              strokeWidth={strokeWidth}
              onMouseDown={onMouseDown}
              onDragMove={handleDragMovePoint}
              onDragEnd={handleDragEndPoint}
              draggable={closed}
              {...startPointAttr}
            />
          );
        })}
    </>
  );
};

export default Polygon;
