import { MenuItem, Select } from '@material-ui/core';
import { useAppSelector } from 'hooks';
import { useState } from 'react';
import { Store } from 'types';

type Props = {
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  value?: string;
  disableEmpty?: boolean;
};

export const useStoreSelector = () => {
  const [selectedStoreId, setSelectedStoreId] = useState<string>();

  const handleChangeStoreId = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedStoreId(event.target.value as string);
  };

  return { selectedStoreId, handleChangeStoreId };
};

export default function StoreSelector({
  handleChange,
  value,
  disableEmpty = false,
}: Props) {
  const stores = useAppSelector(state => state.stores);
  const storeList = Object.values(stores) as Store[];
  return (
    <Select displayEmpty autoWidth onChange={handleChange} value={value ?? ''}>
      <MenuItem value="" disabled={disableEmpty}>
        Choose Store
      </MenuItem>
      {storeList.map(store => (
        <MenuItem key={store.id} value={store.id}>
          {store.name} ({store.id})
        </MenuItem>
      ))}
    </Select>
  );
}
