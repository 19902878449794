import api from '..';

type DeleteGroupJobsApiProps = {
  groupId: string;
};

const deleteGroupJobsApi = async ({ groupId }: DeleteGroupJobsApiProps) => {
  const res = await api.delete('task-based/generic/delete-jobs', {
    params: {
      'job.group_id.str.eq': groupId,
    },
  });

  return res.data;
};

export default deleteGroupJobsApi;
