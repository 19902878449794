import React, { memo } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import SimilarSkus from 'components/Editor/SimilarSkus';
import SkuInfo from './SkuInfo';

const SkuContainer = styled.div`
  border-top: solid 1px #cdcdcd;
  max-width: 300px;
  height: 100%;
`;

const SkuInner = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 4px 0px 0px;
  flex-grow: 1;
  height: 100%;
`;

const SkuInnerWithHover = styled(SkuInner)`
  background-color: ${({ active }) =>
    active ? 'rgba(46, 137, 255, 0.4)' : '#fff'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  :hover {
    background-color: ${({ active, onClick }) =>
      onClick ? (active ? 'rgba(46, 137, 255, 0.4)' : '#c4c4c4') : '#fff'};
  }
`;

const Item = ({
  itemUuid,
  itemName,
  barcode,
  active = false,
  onClick = () => {},
  onClickSend,
  onClickSendReport = () => {},
  hideReport,
  tags,
}) => {
  const onClickItem = () => (onClick ? onClick({ itemName, itemUuid }) : null);

  return (
    <SkuContainer>
      <SkuInnerWithHover active={active} onClick={onClick}>
        <SkuInfo
          itemName={itemName}
          itemUuid={itemUuid}
          barcode={barcode}
          onClickSendReport={onClickSendReport}
          hideReport={hideReport}
          tags={tags}
        />
        {onClickSend && (
          <IconButton
            size="small"
            style={{ padding: '0px 0px' }}
            onClick={() =>
              onClickSend({ name: itemName, uuid: itemUuid, barcode })
            }
          >
            <Send fontSize="small" style={{ padding: '0px 0px' }} />
          </IconButton>
        )}
      </SkuInnerWithHover>
    </SkuContainer>
  );
};

export default memo(Item);
