import { useEffect } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { fetchStores } from 'actions';
import Loader from 'components/Loader';

// The store list is needed *everywhere* so make it that it's loaded in the beginning
const StoresProvider = ({ children }) => {
  const { dispatch, isReady } = useAsyncDispatch();

  useEffect(() => {
    dispatch(fetchStores());
  }, [dispatch]);

  if (!isReady) return <Loader loading={!isReady} full text="Loading Stores" />;

  return children;
};

export default StoresProvider;
