import { Button, ButtonProps } from '@material-ui/core';
import { useAppSelector } from 'hooks';
import { Modify } from 'types';
import { CamogramJob } from 'types/taskBased';

type JobButtonProps = Modify<
  ButtonProps,
  {
    onClick: (job?: CamogramJob) => void;
  }
>;
const JobButton: React.FC<JobButtonProps> = ({
  onClick,
  children,
  disabled,
  ...others
}) => {
  const job = useAppSelector(state => {
    const selectedJob = state.taskBased.selectedJob;

    if (selectedJob === undefined) return undefined;

    const job = state.taskBased.selectedJob;

    return job;
  });
  const _disabled = Boolean(!job || disabled);
  const _onClick = job ? () => onClick(job) : undefined;
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      disabled={_disabled}
      onClick={_onClick}
      {...others}
    >
      {children}
    </Button>
  );
};

export default JobButton;
