import styled from 'styled-components';

import HorizontalScrollList from 'components/HorizontalScrollList';
import SkuImage from 'components/SkuImage';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';
import {
  CheckCircle,
  CancelRounded,
  Search,
  Cancel,
  FilterList,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import { COLORS } from 'common/constants';
import { findNextLocationToReview, findPreviousLocationToReview } from 'utils';
import useShortcut from 'hooks/useShortcut';
import { useEffect, useRef, useState } from 'react';
import {
  Badge,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
} from '@material-ui/core';
import { fuzzySearchSkus } from '../ClassifyItems/ItemList/helpers';
import { Flags } from 'types';
import { configureFlags } from '../helpers';

const isReviewedAllLocations = (locations: LocationToReview[]) => {
  return !locations.some(
    location => location.status === LocationReviewStatus.Init
  );
};

const ITEM_SIZE = 45;
const LABEL_SIZE = 55;

const ItemContainer = styled.div`
  width: ${ITEM_SIZE}px;
  height: ${ITEM_SIZE + LABEL_SIZE}px;
  position: relative;
`;

const SkuImageContainer = styled.div`
  width: ${ITEM_SIZE}px;
  height: ${ITEM_SIZE}px;
  border: 1px solid black;
`;

const SkuLabel = styled.div`
  width: ${ITEM_SIZE}px;
  height: ${LABEL_SIZE}px;
  font-size: 8px;
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AllReviewedContaiener = styled.div`
  font-weight: bold;
`;

const StatusIconContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
`;

const FlagsContainer = styled.div`
  height: 3px;
  display: flex;
  width: 100%;
`;

const FlagItem = styled.div<{ color: string }>`
  width: ${ITEM_SIZE / 4}px;
  background-color: ${props => props.color};
  height: 100%;
`;

const FlagsIndicator = ({ flags }: { flags: Flags }) => {
  const visibleFlags = Object.entries(flags)
    .filter(([, value]) => value)
    .sort((a, b) => (a[0] > b[0] ? -1 : 1)) as [FlagKeys, boolean][];

  return (
    <FlagsContainer>
      {visibleFlags.map(([key]) => (
        <FlagItem key={key} color={COLORS.flags[key]} />
      ))}
    </FlagsContainer>
  );
};

type ItemProps = {
  itemUuid?: string;
  skuLabel?: string;
  status: LocationReviewStatus;
  flags: Flags;
  index: number;
};

const configureMarkColor = (status: LocationReviewStatus) => {
  switch (status) {
    case LocationReviewStatus.Unchanged:
    case LocationReviewStatus.UseLocation:
    case LocationReviewStatus.Classified:
    case LocationReviewStatus.Keep:
    case LocationReviewStatus.Reshaped:
      return COLORS.approved;
    case LocationReviewStatus.Changed:
    case LocationReviewStatus.UnuseLocation:
    case LocationReviewStatus.Discard:
      return COLORS.rejected;
    case LocationReviewStatus.Reshape:
      return COLORS.reshape;
    case LocationReviewStatus.Reclassify:
      return COLORS.reclassify;
    case LocationReviewStatus.Init:
    case LocationReviewStatus.Unknown:
      return COLORS.init;
  }
};

function Item({ itemUuid, status, skuLabel, flags }: ItemProps) {
  const isChecked =
    status === LocationReviewStatus.Unchanged ||
    status === LocationReviewStatus.UseLocation ||
    status === LocationReviewStatus.Classified ||
    status === LocationReviewStatus.Keep ||
    status === LocationReviewStatus.Reshape ||
    status === LocationReviewStatus.Reclassify ||
    status === LocationReviewStatus.Reshaped;
  const isRejected =
    status === LocationReviewStatus.Changed ||
    status === LocationReviewStatus.UnuseLocation ||
    status === LocationReviewStatus.Discard;
  const color = configureMarkColor(status);
  return (
    <ItemContainer>
      <FlagsIndicator flags={flags} />
      <SkuImageContainer>
        <SkuImage
          width={ITEM_SIZE}
          height={ITEM_SIZE}
          uuid={itemUuid}
          alt={itemUuid}
        />
      </SkuImageContainer>
      <SkuLabel>{skuLabel}</SkuLabel>
      {isChecked && (
        <StatusIconContainer>
          <CheckCircle style={{ color }} />
        </StatusIconContainer>
      )}
      {isRejected && (
        <StatusIconContainer>
          <CancelRounded style={{ color }} />
        </StatusIconContainer>
      )}
    </ItemContainer>
  );
}

const FilterMenuItem = ({
  selected = false,
  onClick,
  text,
}: {
  selected?: boolean;
  onClick: () => void;
  text: string;
}) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        {selected ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank />}
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

type LocationHorizontalScrollListProps = {
  locations: LocationToReview[];
  selectedLocationUuId: string;
  onChangeLocationUuid: (locationId: string) => void;
};

const renderItem = (item: LocationToReview, index: number) => {
  const itemUuid = item.skuId;
  const flags = configureFlags(item);
  return (
    <Item
      itemUuid={itemUuid}
      status={item.status}
      index={index}
      skuLabel={item.skuLabel}
      flags={flags}
    />
  );
};

type FlagKeys = keyof Flags;
const filterKeys: FlagKeys[] = [
  'isOccluded',
  'isEmpty',
  'isUncertain',
  'isGroup',
];

type LocationWithSkuName = LocationToReview & {
  display_name?: string;
};
export default function LocationHorizontalScrollList({
  locations: allLocations,
  selectedLocationUuId,
  onChangeLocationUuid,
}: LocationHorizontalScrollListProps) {
  const textFieldRef = useRef<any>();
  const allLocationsWithSkuNames: LocationWithSkuName[] = allLocations.map(
    location => ({
      ...location,
      display_name: location.skuLabel,
    })
  );
  const [searchText, setSearchText] = useState<string>('');
  const [locations, setLocations] = useState(allLocationsWithSkuNames);
  const [selectedFlagKey, setSelectedFlagKey] = useState<keyof Flags>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);

  const currentIndex = locations.findIndex(
    ({ id }) => id === selectedLocationUuId
  );
  const onNext = () => {
    if (locations.length > currentIndex + 1) {
      onChangeLocationUuid(locations[currentIndex + 1].id);
    }
  };

  const onPrevious = () => {
    if (currentIndex > 0) {
      onChangeLocationUuid(locations[currentIndex - 1].id);
    }
  };

  const onClick = (item: LocationToReview) => {
    onChangeLocationUuid(item.id);
  };
  const onSkipNext = () => {
    const currentIndex = locations.findIndex(
      ({ id }) => id === selectedLocationUuId
    );
    const next = findNextLocationToReview(locations, currentIndex);
    if (next) {
      onChangeLocationUuid(next.id);
    }
  };
  const onSkipPrevious = () => {
    const currentIndex = locations.findIndex(
      ({ id }) => id === selectedLocationUuId
    );
    const previous = findPreviousLocationToReview(locations, currentIndex);
    if (previous) {
      onChangeLocationUuid(previous.id);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setSearchText(e.target.value);
      textFieldRef.current?.blur();
    }
  };

  const onCancelSearch = () => {
    if (textFieldRef.current) {
      textFieldRef.current.value = '';
    }
    setLocations(allLocationsWithSkuNames);
    onChangeLocationUuid(allLocationsWithSkuNames[0].id);
  };

  const onClickFilterButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseFilterMenu = () => {
    setAnchorEl(null);
  };
  const onClickFilterMenu = (key: keyof Flags) => () => {
    setSelectedFlagKey(prev => {
      if (prev === key) {
        return undefined;
      }
      return key;
    });
  };

  useShortcut('right', onNext);
  useShortcut('left', onPrevious);

  useEffect(() => {
    setLocations(prevLocations => {
      const nextLocations = prevLocations.map(location => {
        const newLocation =
          allLocationsWithSkuNames.find(({ id }) => location.id === id) ?? {};

        return { ...location, ...newLocation };
      });

      return nextLocations;
    });
  }, [allLocations]);

  useEffect(() => {
    const searchedLocations = searchText
      ? fuzzySearchSkus(allLocationsWithSkuNames, searchText)
      : allLocationsWithSkuNames;

    const result = searchedLocations.filter(location => {
      if (!selectedFlagKey) {
        return true;
      }
      return location[selectedFlagKey] === true;
    });

    if (result.length > 0) {
      onChangeLocationUuid(result[0].id);
    }

    setLocations(result);
  }, [selectedFlagKey, searchText]);

  const title = isReviewedAllLocations(allLocations) ? (
    <AllReviewedContaiener>
      Reviewed All ({allLocations.length}) Locations!🎉
    </AllReviewedContaiener>
  ) : (
    `Reviewed: ${
      locations.filter(({ status }) => status !== LocationReviewStatus.Init)
        .length
    }, All Locations: ${allLocations.length}`
  );
  return (
    <HorizontalScrollList
      title={
        <TitleContainer>
          {title}
          <TextField
            inputRef={textFieldRef}
            placeholder="Filter Location Items"
            onKeyDown={onKeyDown}
            size="small"
            variant="outlined"
            style={{ marginLeft: 8 }}
            inputProps={{ style: { fontSize: '12px' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="inherit" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onCancelSearch}>
                    <Cancel fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            size="small"
            style={{ marginLeft: 8 }}
            onClick={onClickFilterButton}
          >
            <Badge
              color="secondary"
              variant="dot"
              invisible={Boolean(!selectedFlagKey)}
            >
              <FilterList fontSize="small" />
            </Badge>
          </IconButton>
          <Menu
            open={openFilter}
            onClose={onCloseFilterMenu}
            anchorEl={anchorEl}
          >
            <MenuList dense>
              {filterKeys.map(key => {
                return (
                  <FilterMenuItem
                    key={key}
                    selected={key === selectedFlagKey}
                    text={key}
                    onClick={onClickFilterMenu(key as keyof Flags)}
                  />
                );
              })}
            </MenuList>
          </Menu>
        </TitleContainer>
      }
      items={locations}
      currentIndex={currentIndex}
      renderItem={renderItem}
      onNext={onNext}
      onPrevious={onPrevious}
      onClick={onClick}
      onSkipNext={onSkipNext}
      onSkipPrevious={onSkipPrevious}
    />
  );
}
