import React from 'react';

import Group from 'components/Group';
import { SquareToggle } from 'components/Layer';
import { useSelector, useDispatch } from 'react-redux';

import { showLayer, hideLayer } from 'actions';
import { configureCameraLabel } from 'utils';

const Actions = () => {
  const dispatch = useDispatch();
  return (
    <>
      <button onClick={e => dispatch(showLayer('cameras'))}>Show All</button>
      <button onClick={e => dispatch(hideLayer('cameras'))}>Hide All</button>
    </>
  );
};

export default function CamerasLayerToggle() {
  const cameras = useSelector(state =>
    Object.values(state.current.camogram.cams)
  );

  if (cameras.length === 0) return null;

  return (
    <Group label={`Cameras (${cameras.length})`} actions={<Actions />}>
      {cameras.map(({ cam_num, cameraId, shelfId, sectionId }) => (
        <SquareToggle
          id={`cameras_camera${cam_num}`}
          key={cam_num}
          children={configureCameraLabel({ cameraId, shelfId, sectionId })}
        />
      ))}
    </Group>
  );
}
