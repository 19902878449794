import api from '.';

const fetchExtremeZoomImageApi = async ({
  captureUUID,
  storeId,
  shelfId,
  sectionId,
  point,
  zoomFactor = 10000,
}) => {
  if (captureUUID !== undefined) {
    // This is a polling request, so use existing captureUUID instead of X,Y
    const response = await api.get(
      `stores/${storeId}/ff_camera_service/api/v0/ptz_image/zoom?camogram_section_id=${sectionId}&capture_uuid=${captureUUID}&shelf_id=${shelfId}`
    );

    return response.data;
  }

  const response = await api.get(
    `stores/${storeId}/ff_camera_service/api/v0/ptz_image/zoom?camogram_section_id=${sectionId}&center_point_x=${point[0]}&center_point_y=${point[1]}&shelf_id=${shelfId}&zoom_factor=${zoomFactor}`
  );

  return response.data;
};

export default fetchExtremeZoomImageApi;
