import { TextField } from '@material-ui/core';
import Flex from 'components/Flex';
import { useAppSelector } from 'hooks';
import { CameraState, Store } from 'types';
import { configureCameraLabel } from 'utils';
import { useFilters } from '../helpers';
import { formatDatetime } from 'utils/formatDatetime';

import GeneralTable from './GeneralTable';
import StoreSelector from './StoreSelector';
import { TableContainer } from './styled';

const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = remainingMinutes > 0 ? `${remainingMinutes}m` : '';

  return `${hoursString}${minutesString}`;
};

const configureTableData = (providerStates: CameraState[], stores: Store[]) => {
  const keys = ['store', 'camera', 'deadline', 'interval', 'priority'];
  const labels = {
    store: 'Store',
    camera: 'Camera',
    deadline: 'Deadline',
    interval: 'Interval',
    priority: 'Priority',
  };
  const sortedProviderStates = providerStates.sort((a, b) => {
    return a.annotationDeadline < b.annotationDeadline ? -1 : 1;
  });
  const items = sortedProviderStates.map((providerState, index) => {
    const store = stores.find(store => store.id === providerState.storeId);
    return {
      key: String(index), //providerState.id,
      store: store !== undefined ? `${store.name} (${store.id})` : 'Unknown',
      camera: configureCameraLabel({
        cameraId: providerState.providerKey.cameraId,
        shelfId: providerState.providerKey.shelfId,
        sectionId: providerState.providerKey.camogramSectionId,
      }),
      deadline: formatDatetime(providerState.annotationDeadline),
      interval: convertMinutesToHoursAndMinutes(providerState.intervalMinutes),
      priority: providerState.providerPriority,
    };
  });

  return { keys, labels, items };
};

type Props = {
  providerStates: CameraState[];
};

export default function ProviderStatesTable({ providerStates }: Props) {
  const stores = useAppSelector(state => state.stores);
  const {
    filtered,
    selectedStoreId,
    userFilterText,
    cameraId,
    handleChangeStoreId,
    handleChangeUserFilterText,
    handleChangeCameraId,
  } = useFilters(providerStates);

  const activeProviderStates = filtered.filter(({ isActive }) => isActive);
  return (
    <TableContainer>
      <Flex itemSpace={16} alignItems="flex-end">
        <StoreSelector
          value={selectedStoreId}
          handleChange={handleChangeStoreId}
        />
        <TextField
          label="Filter User"
          value={userFilterText}
          onChange={handleChangeUserFilterText}
        />
        <TextField
          label="Filter Camera"
          value={cameraId}
          onChange={handleChangeCameraId}
        />
      </Flex>
      <GeneralTable
        {...configureTableData(activeProviderStates, Object.values(stores))}
      />
    </TableContainer>
  );
}
