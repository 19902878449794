import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Popover } from '@material-ui/core';
import { Check, FileCopy, Report } from '@material-ui/icons';
import useEditorPresent from 'hooks/useEditorPresent';
import _ from 'lodash';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SkuImage from 'components/SkuImage';
import SkuReportDialog from './SkuReportDialog';
import formatPrice from 'utils/formatPrice';

const ICON_FONT_SIZE = 12;

const SkuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: 100%;
`;

const SkuInner = styled.div`
  overflow: auto;
  display: flex;
  align-items: flex-start;
  max-height: 100%;
`;

const SkuDetails = styled.div`
  font-size: 10px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const SkuText = styled.div`
  flex-grow: 1;
  height: 100%;
  align-self: flex-start;
  overflow: auto;
`;

const CopyContainer = styled.div`
  margin-left: 4px;
`;

const MoreButton = styled.a`
  font-size: 10px;
  text-decoration: underline;
`;

const TagsContainer = styled.div`
  display: flex;
  padding: 0px 0px 8px 0px;
  > * {
    margin-right: 8px;
  }
`;

const TagContainer = styled.div`
  border-radius: 10px;
  height: 12px;
  font-size: 8px;
  background-color: #1255e2;
  padding: 2px 8px;
  color: white;
`;

const Tag = ({ children }) => {
  return <TagContainer>{children}</TagContainer>;
};

type Props = {
  itemName: string,
  itemUuid: string,
  barcode: string,
  onClickSendReport: (item: any) => void,
  hideReport?: boolean,
  tags?: string[],
};

const SkuInfo = ({
  itemName,
  itemUuid,
  barcode,
  onClickSendReport,
  hideReport = false,
  tags,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [copiedType, setCopiedType] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const { storeId } = useParams();
  const price = useSelector(state => state.skus[storeId]?.[itemUuid]?.price);
  const formattedPrice = formatPrice(price || null);

  const onClickItemImage = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onClickCopy = (text, type) => () => {
    navigator.clipboard.writeText(text);
    setCopiedType(type);
  };

  const onClickReportButton = () => {
    setOpenReportDialog(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <SkuContainer>
        {tags && tags.length > 0 && (
          <TagsContainer>
            {tags.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </TagsContainer>
        )}
        <SkuInner>
          <SkuImage
            uuid={itemUuid}
            alt="sku_image"
            onClick={onClickItemImage}
            width={60}
            height={60}
          />
          <SkuText>
            <SkuDetails
              onClick={onClickCopy(itemName, 'name')}
              style={{ background: copiedType === 'name' ? 'yellow' : '' }}
            >
              <b>{itemName}</b>
              <CopyContainer>
                {copiedType === 'name' ? (
                  <Check style={{ fontSize: ICON_FONT_SIZE }} />
                ) : (
                  <FileCopy style={{ fontSize: ICON_FONT_SIZE }} />
                )}
              </CopyContainer>
            </SkuDetails>

            {formattedPrice && (
              <SkuDetails
                onClick={onClickCopy(formattedPrice, 'price')}
                style={{ background: copiedType === 'price' ? 'yellow' : '' }}
              >
                <span>
                  <b>Price:</b> {formattedPrice}
                </span>
                <CopyContainer>
                  {copiedType === 'price' ? (
                    <Check style={{ fontSize: ICON_FONT_SIZE }} />
                  ) : (
                    <FileCopy style={{ fontSize: ICON_FONT_SIZE }} />
                  )}
                </CopyContainer>
              </SkuDetails>
            )}

            {showMore ? (
              <>
                <SkuDetails
                  onClick={onClickCopy(itemUuid, 'uuid')}
                  style={{ background: copiedType === 'uuid' ? 'yellow' : '' }}
                >
                  <span>
                    <b>UUID:</b> {itemUuid}
                  </span>
                  <CopyContainer>
                    {copiedType === 'uuid' ? (
                      <Check style={{ fontSize: ICON_FONT_SIZE }} />
                    ) : (
                      <FileCopy style={{ fontSize: ICON_FONT_SIZE }} />
                    )}
                  </CopyContainer>
                </SkuDetails>

                <SkuDetails
                  onClick={onClickCopy(barcode, 'barcode')}
                  style={{
                    background: copiedType === 'barcode' ? 'yellow' : '',
                  }}
                >
                  <span>
                    <b>Barcode:</b> {barcode}
                  </span>
                  <CopyContainer>
                    {copiedType === 'barcode' ? (
                      <Check style={{ fontSize: ICON_FONT_SIZE }} />
                    ) : (
                      <FileCopy style={{ fontSize: ICON_FONT_SIZE }} />
                    )}
                  </CopyContainer>
                </SkuDetails>

                {!hideReport && (
                  <Button
                    size="small"
                    color="secondary"
                    startIcon={<Report />}
                    onClick={onClickReportButton}
                  >
                    Report
                  </Button>
                )}
              </>
            ) : (
              <MoreButton
                onClick={event => {
                  event.preventDefault();
                  setShowMore(true);
                }}
              >
                More
              </MoreButton>
            )}
          </SkuText>
        </SkuInner>
      </SkuContainer>

      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <SkuImage uuid={itemUuid} alt="large_image" width={500} height={500} />
      </Popover>

      <SkuReportDialog
        itemName={itemName}
        itemUuid={itemUuid}
        open={openReportDialog}
        setOpen={setOpenReportDialog}
        barcode={barcode}
        onClickSendReport={onClickSendReport}
      />
    </>
  );
};

export default SkuInfo;
