import { useParams } from 'react-router-dom';

import MainLayout from 'layouts/Main';
import TaskBasedEditorInitialDataLoader from '../TaskBasedEditorInitialDataLoader';
import ContentContainer from '../Content';
import Header from '../Header';
import Sidebar from '../Sidebar';
import SplitLayout from 'layouts/SplitLayout';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { clear } from 'slices/taskBasedSlice';
import ContentSwitcherContainer from './ContentSwitcherContainer';
import { TaskBasedContextProvider } from '../contexts/TaskBasedContext';
import { TaskType } from 'types/taskBased';

export default function TaskBasedAnnotationPage() {
  const { storeId, groupId } = useParams<{ storeId; groupId }>();
  const dispatch = useDispatch();

  const isTaskTypeEnable = useCallback((taskType: TaskType) => {
    const disabledTaskTypes = [TaskType.Completed, TaskType.ReviewAnnotations];

    return !disabledTaskTypes.includes(taskType);
  }, []);

  const isTaskDone = useCallback((taskType: TaskType) => {
    const completedTaskTypes = [TaskType.Completed, TaskType.ReviewAnnotations];

    return completedTaskTypes.includes(taskType);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <TaskBasedContextProvider
      isTaskTypeEnable={isTaskTypeEnable}
      isTaskDone={isTaskDone}
    >
      <TaskBasedEditorInitialDataLoader
        storeId={storeId}
        groupId={groupId}
        isQa={false}
      >
        <MainLayout header={<Header />} aside={<Sidebar groupId={groupId} />}>
          <SplitLayout>
            <ContentContainer>
              <ContentSwitcherContainer />
            </ContentContainer>
          </SplitLayout>
        </MainLayout>
      </TaskBasedEditorInitialDataLoader>
    </TaskBasedContextProvider>
  );
}
