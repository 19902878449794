import axios from 'axios';
import { getToken, logout } from '@standard/portal';

export class ApiError extends Error {
  title?: string;
  detail?: string;
  status?: number;
  constructor(
    message: string,
    title?: string,
    detail?: string,
    status?: number,
    ...arg
  ) {
    super(...arg);
    this.message = message;
    this.title = title;
    this.detail = detail;
    this.status = status;
  }

  isKnownError() {
    return (
      this.status !== undefined &&
      this.title !== undefined &&
      this.detail !== undefined
    );
  }

  configureKnownErrorDescription() {
    if (this.title && this.detail) {
      return `The request failed!\n\nReason: ${this.title}\nDetail: ${this.detail}`;
    } else {
      return this.message;
    }
  }
}

const configureErrorMessage = ({ errors, url, status, method }) => {
  const errorMessage = errors
    .map(
      error =>
        `${error.message}\n\nType: ${error.type}\n\n\nEndpoint: ${url}\nstatus: ${status}\nmethod: ${method}\n`
    )
    .join(', ');
  return errorMessage;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async config => {
    try {
      // Grabs the local token, or refreshes it and loads it asynchronously
      const accessToken = await getToken();
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    } catch (e) {
      console.warn('Cannot find Auth0 session token; forced logout');
      await logout();
      throw e;
    }

    return config;
  },
  err => err
);

api.interceptors.response.use(
  res => res,
  ({ response, request, config, message }) => {
    const errors: { type: string; message: string }[] = [];

    if (response) {
      if (response.status === 401) {
        if (response.data?.message === 'Jwks remote fetch is failed') {
          // the Google Endpoints setup is buggy and returns 401
          // incorrectly once in a while. The infra patch has yet to be
          // merged so we are patching this on this level
          // REMOVE THIS when infra is fixed
          if (config.data) {
            config.data = JSON.parse(config.data);
          }

          console.warn({
            response,
            request,
            config,
            message: 'Jwks remote fetch is failed',
          });
          return api.request(config);
        }
        errors.push({
          type: 'SESSION_EXPIRED',
          message: 'Session in backend expired; forced logout',
        });
        logout();
      } else if (
        response.data?.validation_errors &&
        response.data.validation_errors.length > 0
      ) {
        for (const validationError of response.data.validation_errors) {
          const type = validationError.type || 'UNKNOWN';

          let message;
          if (validationError.camera !== undefined) {
            message = `Unknown SKU on camera ${validationError.camera}`;
          } else {
            const { type, ...otherFields } = validationError;
            message = JSON.stringify(otherFields);
          }

          errors.push({ type, message });
        }
      } else {
        if (
          response?.data?.detail &&
          config.url.includes(
            'list-shelf-section-captures-from-camera-coordinate'
          )
        ) {
          errors.push({
            type: 'FAILED_TO_FETCH',
            message: response?.data?.detail,
          });
        } else if (response?.data?.detail && response?.data?.title) {
          console.log('res', response, request);
          errors.push({
            type: 'The request failed!',
            message: `\nReason: ${response.data.title}\nDetail: ${
              response.data.detail
            }\n\n\nAll Data: ${JSON.stringify(response.data)}`,
          });
        } else {
          errors.push({
            type: 'FAILED_TO_FETCH',
            message: `${response.data?.message ?? message}\n\nURL: ${
              config.url
            }\nstatus: ${response.status}`,
          });
        }
      }
    } else if (request) {
      errors.push({
        type: 'NETWORK_ERROR',
        message: `Please check your internet connection, and refresh the browser. url: ${config.url}`,
      });
    } else {
      errors.push({
        type: 'UNKNOWN',
        message,
      });
    }
    console.warn({ response, request, config, message, errors });
    const errorMessage = configureErrorMessage({
      errors,
      url: request.responseURL,
      status: response?.status,
      method: config.method,
    });
    throw new ApiError(
      errorMessage,
      response?.data?.title,
      response?.data?.detail,
      response?.status
    );
  }
);

export { default as fetchCamerasApi } from './fetchCamerasApi';
export { default as fetchCameraImagesApi } from './fetchCameraImagesApi';
export { default as fetchImageOptionsApi } from './fetchImageOptionsApi';
export { default as fetchSkusApi } from './fetchSkusApi';
export { default as fetchSkuImageApi } from './fetchSkuImageApi';
export { default as fetchCamogramApi } from './fetchCamogramApi';
export { default as fetchStoresApi } from './fetchStoresApi';
export { default as fetchFFImagesApi } from './fetchFFImagesApi';
export { default as fetchFFImagesForExtremeZoomApi } from './fetchFFImagesForExtremeZoomApi';
export { default as postSkuReportApi } from './postSkuReportApi';
export { default as fetchCameraMachineLearningApi } from './fetchCameraMachineLearningApi';
export { default as fetchExtremeZoomImageApi } from './fetchExtremeZoomImageApi';
export * from './newEditor';
export * from './omniEditor';
export { default as fetchTisCameraConfigApi } from './fetchTisCameraConfigApi';
export { default as fetchFFImagesBySectionApi } from './fetchFFImagesBySectionApi';
export { default as fetchPtzConfigApi } from './fetchPtzConfigApi';
export { default as fetchStoreCamogramsApi } from './fetchStoreCamogramsApi';
export { default as fetchLastCamogramApi } from './fetchLastCamogramApi';

export default api;
