import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const IconBox = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  line-height: 26px;
  margin: 2px;
  text-align: center;
  border-radius: 4px;
  ${({ background }) => background && `background: ${background};`}
  ${({ color }) => color && `color: ${color};`}
`;

// "video" => "faVideo" https://stackoverflow.com/a/6661012
const toFa = str => ('fa-' + str).replace(/-([a-z])/g, g => g[1].toUpperCase());

export default memo(({ name, color, background, ...props }) => (
  <IconBox color={color} background={background}>
    <FontAwesomeIcon icon={icons[toFa(name)]} fixedWidth {...props} />
  </IconBox>
));
