import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CamogramLocations from 'components/CamogramLocations';
import Flex from 'components/Flex';
import { COLORS } from 'common/constants';
import { useAppSelector } from 'hooks';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { setSelectedLocationId } from 'slices/taskBasedSlice';
import { configureFlags, useJob } from '../helpers';
import { Flags, HighresActiveSection, Location } from 'types';
import CamogramTooltip from 'components/CamogramTooltip';
import { setActiveLocation } from 'actions';
import ItemLabel from '../common/ItemLabel';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';
import FlagButtons from 'pages/TaskBasedEditor/components/FlagButtons';

const ItemLabelWrapper = styled.div`
  background-color: #555555;
  color: white;
  margin-bottom: 8px;
`;

const NoReviewLocation = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
`;

const FlagsContainer = styled.div`
  align-self: center;
  min-width: 500px;
  margin-top: 16px;
`;

type ItemLabelContainerProps = {
  itemUuid: string;
  flags: Flags;
};
function ItemLabelContainer({ itemUuid, flags }: ItemLabelContainerProps) {
  const { storeId } = useParams<{ storeId: string }>();
  const itemInfo = useAppSelector(
    state => state.skus?.[storeId]?.[itemUuid] || null
  );
  return (
    <ItemLabelWrapper>
      <ItemLabel itemInfo={itemInfo} flags={flags} />
    </ItemLabelWrapper>
  );
}

type ReviewAnnotationsComponentProps = {
  locations: Location[];
  selectedLocationId: string;
  imageSrc: string;
  imageAngle: number;
  activeShelfs?: HighresActiveSection[];
  onChangeLocationId: (locationId: string) => void;
  onMouseEnter: (e: any, locationId: string) => void;
  onMouseLeave: (e: any, locationId?: string) => void;
};
function ReviewAnnotationsComponent({
  locations,
  selectedLocationId,
  imageSrc,
  imageAngle,
  activeShelfs,
  onChangeLocationId,
  onMouseEnter,
  onMouseLeave,
}: ReviewAnnotationsComponentProps) {
  const selectedLocation = locations.find(
    ({ id }) => id === selectedLocationId
  );
  const selectedItemUuid = selectedLocation?.skuId;

  const configuredLocations = locations.map(location => ({
    ...location,
    color: location.id === selectedLocationId ? '#ff0000' : COLORS.approved,
  }));
  const onClickLocation = (locationId: string) => {
    onChangeLocationId(locationId);
  };

  const locationsToReview: LocationToReview[] = locations.map(location => ({
    ...location,
    status: LocationReviewStatus.Classified,
    key: location.id,
  }));

  const flags = selectedLocation ? configureFlags(selectedLocation) : null;

  return (
    <Flex padding={16} direction="column">
      <CamogramLocations
        imageSrc={imageSrc}
        angle={imageAngle}
        locations={configuredLocations}
        activeShelfs={activeShelfs}
        onClick={onClickLocation}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {selectedItemUuid && flags && (
        <ItemLabelContainer itemUuid={selectedItemUuid} flags={flags} />
      )}
      {selectedLocationId !== undefined && locationsToReview.length > 0 ? (
        <LocationHorizontalScrollList
          locations={locationsToReview}
          selectedLocationUuId={selectedLocationId}
          onChangeLocationUuid={onChangeLocationId}
        />
      ) : (
        <NoReviewLocation>There are no locations to review</NoReviewLocation>
      )}
      <FlagsContainer>
        <FlagButtons />
      </FlagsContainer>
    </Flex>
  );
}

export default function ReviewAnnotations() {
  const dispatch = useDispatch();

  const loctionsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);

  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };
  const onMouseEnter = (event: any, locationId: string) => {
    const location = loctionsToReview.find(({ id }) => locationId === id);
    if (location === undefined)
      throw new Error('Location is undefined onMouseEnter');
    dispatch(
      setActiveLocation({ ...location, x: event.clientX, y: event.clientY })
    );
  };
  const onMouseLeave = (event: any, locationId?: string) => {
    if (locationId) {
      dispatch(setActiveLocation(null));
    }
  };
  return (
    <Flex>
      <ReviewAnnotationsComponent
        locations={loctionsToReview}
        selectedLocationId={selectedLocationId ?? loctionsToReview[0]?.id}
        imageSrc={jobContext.imageUrl}
        imageAngle={image.angle ?? 0}
        activeShelfs={image.meta?.activeShelfs}
        onChangeLocationId={onChangeLocationId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <CamogramTooltip />
    </Flex>
  );
}
