import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import PaneLayout from './PaneLayout';
import FFImages from 'components/FFImages';
import { fetchFFImagesApi } from 'utils/api';
import { useCompareParams } from './helpers';
import useEditorPresent from 'hooks/useEditorPresent';

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;
const Header = ({ storeId, datetime, camNum }) => {
  const store = useSelector(state => state.stores[storeId]);

  return (
    <HeaderContainer>
      {`${store.name} (${storeId}) / ${moment(datetime).format(
        'lll'
      )} / Cam${camNum}`}
    </HeaderContainer>
  );
};

const FFImagesPane = () => {
  const { storeId, datetime } = useCompareParams();
  const camNum = useEditorPresent(state => state.camNum);
  const fetchFFImages = useCallback(
    async () => fetchFFImagesApi({ storeId, datetime, camNum }),
    [storeId, datetime, camNum]
  );

  return (
    <PaneLayout
      header={<Header storeId={storeId} datetime={datetime} camNum={camNum} />}
    >
      <FFImages fetchFFImages={fetchFFImages} full={false} />
    </PaneLayout>
  );
};

export default FFImagesPane;
