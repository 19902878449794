import React, { createContext, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { MapInteractionCSS } from 'react-map-interaction';

import ItemLocations from 'components/ItemLocations';
import CamogramTooltip from 'components/CamogramTooltip';
import CameraImage from 'components/CameraImage';
import Panel from 'components/Panel';
import Layer from 'components/Layer';
import Icon from 'components/Icon';
import { hideLayer, setActiveLocation } from 'actions';
import useCameraAngle from 'hooks/useCameraAngle';
import CameraTransform from 'components/CameraTransform';
import { IconButton } from '@material-ui/core';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { DEFAULT_CAMERA_IMAGE_ASPECT_RATIO } from 'common/constants';
import { configureCameraLabel } from 'utils';

const defaultZoom = {
  scale: 1,
  translation: { x: 0, y: 0 },
};
export const ZoomContext = createContext(defaultZoom);

const ItemLocationsContainer = styled.div`
  position: relative;
  background-color: #444;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const ToolbarContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
  }
`;

const Id = styled.div`
  position: relative;
  padding: 4px 8px;
  font-size: 14px;
  text-align: center;
  color: #333;
  background: #ddd;
  transition: all 0.2s ease;
  z-index: 10;
`;

const ZOOM_INTERVAL = 0.1;
const Toolbar = ({ camNum, updatedAt, updatedBy, error, setZoom }) => {
  const onZoomIn = () => {
    setZoom(prev => ({
      ...prev,
      scale: prev.scale + ZOOM_INTERVAL,
    }));
  };
  const onZoomOut = () => {
    setZoom(prev => ({
      ...prev,
      scale: Math.max(prev.scale - ZOOM_INTERVAL, ZOOM_INTERVAL),
    }));
  };
  return (
    <ToolbarContainer>
      <Id>{camNum}</Id>
      {updatedAt && (
        <div>
          Updated {updatedAt} <br />
          By {updatedBy}
        </div>
      )}
      {error && <Icon name="times" color="red" />}
      <IconButton color="inherit" size="small" onClick={onZoomOut}>
        <ZoomOut />
      </IconButton>
      <IconButton color="inherit" size="small" onClick={onZoomIn}>
        <ZoomIn />
      </IconButton>
    </ToolbarContainer>
  );
};

function Camogram({ camNum, onClick, containerWidth }) {
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(defaultZoom);
  const [error, setError] = useState(false);
  const [imageRatio, setImageRatio] = useState(
    DEFAULT_CAMERA_IMAGE_ASPECT_RATIO
  );

  const [toolTipDisabled, setToolTipDisabled] = useState(true);
  const camogram = useSelector(state => state.current.camogram.cams[camNum]);
  const {
    last_updated_at: updatedAt,
    last_updated_by: updatedBy,
  } = useSelector(state => state.current.camogram.annotators?.[camNum] ?? {});

  const { isRotated } = useCameraAngle(camNum);

  const productLocations = camogram?.locations;

  const layerId = `cameras_camera${camNum}`;
  const width =
    containerWidth > 1000 ? (containerWidth - 24) / 2 : containerWidth - 16;
  const height = width * imageRatio;

  const cameraLabel = configureCameraLabel({
    cameraId: camogram.cameraId,
    shelfId: camogram.shelfId,
    sectionId: camogram.sectionId,
  });
  const onItemLocationMouseEnter = (ev, location) => {
    dispatch(setActiveLocation({ ...location, x: ev.clientX, y: ev.clientY }));
    setToolTipDisabled(false);
  };

  const onItemLocationMouseLeave = (ev, location) => {
    if (location) {
      dispatch(setActiveLocation(null));
      setToolTipDisabled(true);
    }
  };

  const onLoadImage = ({ target }) => {
    const imageRatio = target.naturalHeight / target.naturalWidth;
    setImageRatio(imageRatio);
  };

  const onClose = () => dispatch(hideLayer(layerId));

  return (
    <Layer id={layerId}>
      <Panel
        onClose={onClose}
        toolBar={
          <Toolbar
            camNum={cameraLabel}
            updatedAt={updatedAt}
            updatedBy={updatedBy}
            error={error}
            setZoom={setZoom}
          />
        }
      >
        <ItemLocationsContainer
          width={isRotated ? height : width}
          height={isRotated ? width : height}
        >
          <CamogramTooltip disabled={toolTipDisabled} />

          <MapInteractionCSS
            minScale={0.9}
            value={zoom}
            onChange={setZoom}
            disableZoom
          >
            <ZoomContext.Provider value={zoom}>
              <CameraTransform camNum={camNum} width={width} height={height}>
                <CameraImage
                  camNum={camNum}
                  setError={setError}
                  width={width}
                  height={height}
                  onLoad={onLoadImage}
                />
                <ItemLocations
                  productLocations={productLocations}
                  imageWidth={width}
                  imageHeight={height}
                  onMouseEnter={onItemLocationMouseEnter}
                  onMouseLeave={onItemLocationMouseLeave}
                  onClick={(ev, location) => onClick && onClick(location.uuid)}
                />
              </CameraTransform>
            </ZoomContext.Provider>
          </MapInteractionCSS>
        </ItemLocationsContainer>
      </Panel>
    </Layer>
  );
}

export default React.memo(Camogram);
