import _ from 'lodash';
import api from '.';
import { addNumericCamIdParam, configureMetas } from './helpers';

const roundAngle = angle => {
  // First round it to a multiple of 90
  angle = 90 * Math.round(angle / 90);

  // Then make sure the angle is within 0-360
  if (angle >= 360) angle %= 360;

  // Convert it to the [0, -360 quadrant]
  if (angle < 0) angle = angle % 360;

  // angle can be `-0`, which we don't want to check
  if (angle === 0) angle = 0;

  // Flip it to [0, 360]. DO NOT JOIN with the previous because 0 === -0 is true
  if (angle < 0) angle = angle + 360;

  return angle;
};

// Looks like There's weird flip 0 <-> 180, 90 <-> 270
const correctAngle = {
  0: 180,
  180: 0,
  90: 270,
  270: 90,
};

const configureCameraExtrinsics = data => {
  return _.mapValues(data, cam => ({
    ...cam,
    angle: correctAngle[roundAngle(cam.angle)],
  }));
};

const fetchCamerasApi = async storeId => {
  const res = await api.get(addNumericCamIdParam(`stores/${storeId}/cameras`));
  return {
    ...res.data,
    deviceSettings: res.data.device_settings.cameras,
    cameraExtrinsics: configureCameraExtrinsics(
      res.data.camera_extrinsics.cameras
    ),
    metas: configureMetas(res.data.metas),
  };
};

export default fetchCamerasApi;
