import useResize from 'hooks/useResize';
import React, { createContext } from 'react';
import styled from 'styled-components';
import FFImage from './FFImage';

const FFImagesContainer = styled.div`
  background-color: #eee;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 100px;
  z-index: 100;
  > * {
    margin-top: 1.2rem;
  }
`;

export const ContainerWidthContext = createContext(0);
const FFImagesList = ({ children }) => {
  const { ref, width } = useResize();

  return (
    <ContainerWidthContext.Provider value={width}>
      <FFImagesContainer ref={ref}>{width && children}</FFImagesContainer>
    </ContainerWidthContext.Provider>
  );
};

const FFImagesComponent = ({ images }) => {
  return (
    <FFImagesList>
      {images.map(image => (
        <FFImage
          key={image.url}
          url={image.url}
          shelfId={image.shelfId}
          sectionId={image.sectionId}
          uploadedAt={image.uploadedAt}
        />
      ))}
    </FFImagesList>
  );
};

export default FFImagesComponent;
