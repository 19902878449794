import React from 'react';
import { Button } from '@material-ui/core';
import { logout } from '@standard/portal';

const LogoutButton = () => {
  return (
    <Button variant="contained" onClick={logout} fullWidth>
      Logout
    </Button>
  );
};

export default LogoutButton;
