import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFFImagesApi } from 'utils/api';

const fetchFFImages = createAsyncThunk(
  'fetchFFImages',
  async ({ storeId, datetime, camNum }) => {
    const images = await fetchFFImagesApi({ storeId, datetime, camNum });
    return { images };
  }
);

export default fetchFFImages;
