import React from 'react';

import Header from 'pages/Camogram/Header';
import PaneLayout from './PaneLayout';
import OmniCamogramDetail from 'components/OmniCamogramDetail';
import { useCompareParams } from './helpers';

const OmniCamogramsPane = () => {
  const { storeId, datetime } = useCompareParams();
  return (
    <PaneLayout header={<Header storeId={storeId} datetime={datetime} />}>
      <OmniCamogramDetail storeId={storeId} datetime={datetime} full={false} />
    </PaneLayout>
  );
};

export default OmniCamogramsPane;
