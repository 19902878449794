import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { CameraPosition, Vec3D } from 'types';
import Arrow from './Arrow';
import { useLayout2DContext } from '.';

const LinkGroup = styled.g`
  cursor: pointer;
  &:hover rect,
  &:hover circle,
  &:hover path {
    stroke: white;
    stroke-width: 4px;
  }
`;

const Text = ({ center, children, ...props }: any) => (
  <text
    x={center[0] || center.x}
    y={center[1] || center.y}
    fill="#333"
    pointerEvents="none"
    fontFamily="Arial, Helvetica, sans-serif"
    fontSize="30px"
    textAnchor="middle"
    dominantBaseline="central"
    {...props}
  >
    {children}
  </text>
);

type LinkProps = {
  onClick?: any;
  onDrop?: any;
  onPointerDown?: any;
  onPointerUp?: any;
  onPointerMove?: any;
};
const Link: React.FC<LinkProps> = ({
  onClick,
  onDrop,
  children,
  onPointerDown,
  onPointerUp,
  onPointerMove,
}: any) => {
  if (onClick || onDrop) {
    return (
      <LinkGroup
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerMove={onPointerMove}
      >
        {children}
      </LinkGroup>
    );
  }
  return children;
};

type CameraProps = {
  onClick?: (cameraId: string, event?: any) => void;
  onCameraDragEnd?: (id: string, position: Vec3D) => void;
  initialPosition: Vec3D;
  id: string;
  selected?: boolean;
  checked?: boolean;
  fill?: string;
};

function CameraComponent({
  onClick,
  initialPosition,
  id,
  selected = false,
  checked = false,
  fill = '#ffff00',
}: CameraProps) {
  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    setPosition(initialPosition);
    // eslint-disable-next-line
  }, [initialPosition.x, initialPosition.y, initialPosition.z]);

  const handlePointerUp = (e: any) => {
    if (onClick) {
      onClick(id, e);
    }
  };

  const strokeProps = selected
    ? {
        stroke: '#f00',
        strokeWidth: 4,
      }
    : {};

  return (
    <>
      <Arrow start={position} cameraId={id} />
      <Link onClick={onClick} onPointerUp={handlePointerUp}>
        <circle
          cx={position.x}
          cy={position.y}
          r="40"
          fill={`${fill}99`}
          {...strokeProps}
        />
        <Text center={[position.x, position.y]} fill="white">
          {id}
        </Text>
      </Link>
      {checked && (
        <FontAwesomeIcon
          x={position.x + 10}
          y={position.y - 50}
          icon={faCheckCircle}
          width={35}
          height={35}
          rotation={90}
          color="green"
        />
      )}
    </>
  );
}

type CameraType = CameraPosition & { color?: string };

type CamerasComponentType = {
  height: number;
  onClick?: (cameraId: string, event?: any) => void;
  selectedCameraId?: string;
  cameras: CameraType[];
};
function CamerasComponent({
  height,
  onClick,
  selectedCameraId,
  cameras,
}: CamerasComponentType) {
  return (
    <>
      {cameras.map(camera => (
        <CameraComponent
          key={camera.id}
          id={camera.id}
          initialPosition={{
            ...camera.position,
            y: height - camera.position.y,
          }}
          selected={selectedCameraId === camera.id}
          onClick={onClick}
          fill={camera.color}
        />
      ))}
    </>
  );
}

export default function Cameras() {
  const {
    cameras,
    onCameraClick,
    height,
    selectedCameraId,
  } = useLayout2DContext();

  if (!cameras) return null;

  return (
    <CamerasComponent
      cameras={cameras}
      height={height}
      onClick={onCameraClick}
      selectedCameraId={selectedCameraId}
    />
  );
}
