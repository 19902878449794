import Flex from 'components/Flex';
import useStore from 'hooks/useStore';
import styled from 'styled-components';
import DiscardButton from '../components/DiscardButton';
import SaveButton from '../components/SaveButton';
import HomeButton from 'components/Editor/Aside/HomeButton';
import { useHistory, useParams } from 'react-router-dom';
import releaseGroupJobsApi from 'utils/api/taskBasedEditor/releaseGroupJobsApi';
import { Button, IconButton } from '@material-ui/core';
import { Help, Keyboard } from '@material-ui/icons';
import { useRef, useState } from 'react';
import { ShortcutListModal } from './ShortcutList';
import { HELP_PAGE_URL } from 'common/constants';

const Container = styled(Flex)`
  height: 32px;
  justify-content: space-between;
  padding: 8px 56px 8px 16px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 8px;
  }
`;

function HomeButtonContainer() {
  const history = useHistory();
  const { storeId, groupId } = useParams<{
    storeId: string;
    groupId: string;
  }>();

  const onClickHome = async () => {
    if (
      window.confirm(
        'Are you sure you want to go home? Make sure you saved your work.'
      )
    ) {
      await releaseGroupJobsApi({ storeId, groupId });
      history.push(`/`);
    }
  };

  return <HomeButton editing={false} onClickHome={onClickHome} />;
}

function ShortcutButton() {
  const [visibleShortcut, setVisibleShortcut] = useState(false);
  const buttonRef = useRef<any>();
  const onClickShortcut = () => {
    setVisibleShortcut(true);

    // Without blur, when a user hits "Enter", the button is pushed again
    buttonRef.current?.blur();
  };

  return (
    <>
      <Button onClick={onClickShortcut} ref={buttonRef}>
        <Keyboard />
      </Button>
      <ShortcutListModal show={visibleShortcut} setShow={setVisibleShortcut} />
    </>
  );
}

function HelpButton() {
  return (
    <IconButton href={HELP_PAGE_URL} target="_blank" size="small">
      <Help fontSize="small" />
    </IconButton>
  );
}

type Props = {
  containerStyle?: React.CSSProperties;
};

export default function Header({ containerStyle }: Props) {
  const store = useStore();
  const version = process.env.REACT_APP_VERSION;

  return (
    <Container style={containerStyle}>
      <Content>
        <HomeButtonContainer />
        <div>v{version}</div>
      </Content>
      <div>
        {store.name} ({store.id})
        <ShortcutButton />
        <HelpButton />
      </div>
      <Content>
        <DiscardButton />
        <SaveButton />
      </Content>
    </Container>
  );
}
