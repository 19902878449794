import React from 'react';
import styled from 'styled-components';

import useCameraAngle from 'hooks/useCameraAngle';

// The possible angles:
const angleTransform = {
  180: 'transform: rotateZ(180deg) translate(-100%, -100%);',
  270: 'transform: rotateZ(270deg) translate(-100%);',
  0: '',
  90: 'transform: rotateZ(90deg) translate(0, -100%);',
};

const TransformContainer = styled.div`
  ${p => angleTransform[p.angle]}
  transform-origin: top left;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const CameraTransform = ({ camNum, width, height, children }) => {
  const { angle } = useCameraAngle(camNum);
  return (
    <TransformContainer width={width} height={height} angle={angle}>
      {children}
    </TransformContainer>
  );
};

export default CameraTransform;
