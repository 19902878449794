import { toast } from 'react-toastify';
import { useAppSelector } from 'hooks';
import JobButton from './JobButton';
import { CamogramJob, LocationReviewStatus, TaskType } from 'types/taskBased';
import { completeTask } from 'slices/taskBasedSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Warning = styled.div`
  color: red;
  font-size: 10px;
`;

const tasksToConfirm = [TaskType.ClassifyLocations, TaskType.ReviewAnnotations];

export default function CompleteTaskButton() {
  const { dispatch, isLoading } = useAsyncDispatch();
  const enabledCompleteButton = useAppSelector(state => {
    const currentTaskName = state.taskBased.selectedJob?.currentTaskName;
    const locationsToReview = state.taskBased.locationsToReview;
    if (currentTaskName === undefined) return false;
    if (
      currentTaskName === TaskType.RoutePreviousLocations ||
      currentTaskName === TaskType.ClassifyLocations ||
      currentTaskName === TaskType.ReshapePreviousLocations
    ) {
      return locationsToReview.every(
        location => location.status !== LocationReviewStatus.Init
      );
    }

    return true;
  });
  const onClick = async (job?: CamogramJob) => {
    if (job === undefined) throw new Error('Job is undefined on complete');

    const shouldConfirm =
      tasksToConfirm.includes(job.currentTaskName) &&
      confirm(
        'Have you flagged all empty, occluded, group, and unknown products?'
      );

    if (!tasksToConfirm.includes(job.currentTaskName) || shouldConfirm) {
      await toast.promise(dispatch(completeTask({ job })), {
        pending: 'Completing...',
        success: 'Task Completed!',
        error:
          'Could not complete. Please read the error message and fix annotation.',
      });
    }
  };

  return (
    <>
      <JobButton
        disabled={isLoading || !enabledCompleteButton}
        onClick={onClick}
      >
        {isLoading ? <CircularProgress size={25} /> : 'Complete Task'}
      </JobButton>
      {!enabledCompleteButton && <Warning>Please review all locations</Warning>}
    </>
  );
}
