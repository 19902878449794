import styled from 'styled-components';
import { TouchApp, CropLandscapeOutlined } from '@material-ui/icons';
import useShortcut from 'hooks/useShortcut';
import { useDrawLocationContext } from '.';
import { CursorMode } from 'types';

const Container = styled.div`
  display: flex;
`;

const MenuContainer = styled.div<{ active: boolean }>`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background: ${({ active }) => (active ? '#65a1fb' : '#2c2c2c')};
  box-shadow: ${({ active }) =>
    active ? 'inset 2px 2px 10px -8px black' : 'none'};
  height: 35px;
  line-height: 24px;
  padding: 0 20px 0 15px;
  cursor: pointer;

  svg {
    margin-right: 3px;
  }

  // Because we are centering it manually instead of using the proper "baseline"
  // with vertical-align
  span {
    margin-bottom: -2px;
  }

  &:hover {
    background: ${({ active }) => (active ? '#65a1fb' : '#000000')};
  }
`;

const Menu = ({ icon, active, label, onClick }) => {
  return (
    <MenuContainer onClick={onClick} active={active}>
      {icon}
      {label && <span>{label}</span>}
    </MenuContainer>
  );
};

const CursorModePicker = () => {
  const { cursorMode, setCursorMode } = useDrawLocationContext();

  useShortcut('s', e => {
    setCursorMode(CursorMode.Pointer);
  });

  useShortcut('d', e => {
    setCursorMode(CursorMode.Draw);
  });

  const onClickPointer = () => {
    setCursorMode(CursorMode.Pointer);
  };

  const onClickRect = () => {
    setCursorMode(CursorMode.Draw);
  };

  return (
    <Container>
      <Menu
        active={cursorMode === CursorMode.Pointer}
        onClick={onClickPointer}
        icon={<TouchApp />}
        label="Select"
      />
      <Menu
        active={
          cursorMode === CursorMode.Draw || cursorMode === CursorMode.Drawing
        }
        onClick={onClickRect}
        icon={<CropLandscapeOutlined />}
        label="Draw"
      />
    </Container>
  );
};

export default CursorModePicker;
