import React from 'react';

import { CamogramDetailComponent } from 'pages/Camogram/CamogramsContainer';
import Header from 'pages/Camogram/Header';
import PaneLayout from './PaneLayout';
import { useCompareParams } from './helpers';

const CamogramsPane = () => {
  const { storeId, datetime } = useCompareParams();
  return (
    <PaneLayout header={<Header storeId={storeId} datetime={datetime} />}>
      <CamogramDetailComponent
        storeId={storeId}
        datetime={datetime}
        full={false}
      />
    </PaneLayout>
  );
};

export default CamogramsPane;
