import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import AsideVisibilityToggle from './AsideVisibilityToggle';
import Flex from 'components/Flex';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid #ccc;
`;

const Aside = styled.div`
  width: ${props => (props.asideIsVisible ? '320px' : '0')};
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-right: 0;
  box-sizing: border-box;
  background: #fff;
  overflow-y: auto;
  transition: all 0.3s ease, width 0;
  position: fixed;
  z-index: 11;
  border-right: 1px solid #ccc;
`;

const Main = styled.div`
  display: flex;
  right: 0;
  width: ${props => (props.asideIsVisible ? 'calc(100vw - 320px)' : '100vw')};
  height: 100%;
  border-left: 2px solid #eee;
  flex-direction: column;
  position: absolute;
  z-index: 10;

  ${p =>
    p.collapsed &&
    `
    width: 100vw;
  `};
`;

const Content = styled.div`
  flex-shrink: 1;
  flex-grow: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;

type MainLayoutProps = {
  aside?: React.ReactNode,
  header?: React.ReactNode,
  children?: React.ReactNode,
};

const MainLayout = ({ aside, header, children }: MainLayoutProps) => {
  const asideIsVisible = useSelector(state => state.aside.isVisible);
  return (
    <Wrapper>
      {header && <HeaderContainer>{header}</HeaderContainer>}
      <Flex style={{ position: 'relative' }}>
        {aside && <Aside asideIsVisible={asideIsVisible}>{aside}</Aside>}
        <Main asideIsVisible={asideIsVisible}>
          <Content>{children}</Content>
          <AsideVisibilityToggle />
        </Main>
      </Flex>
    </Wrapper>
  );
};

export default MainLayout;
