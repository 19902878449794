import React from 'react';

import StorePicker from './StorePicker';
import AdminLayout from 'layouts/AdminLayout';

export const AdminPage = () => {
  return (
    <AdminLayout>
      <StorePicker />
    </AdminLayout>
  );
};

export default AdminPage;
