import _ from 'lodash';

import { CamogramJobBase } from 'types/taskBased';
import { convertToCamelCaseKeys } from 'utils';
import api from '..';
import { addNumericCamIdParam } from '../helpers';
import { configureBasicJobInfo } from './helpers';

const configureTask = (raw: any): CamogramJobBase => {
  const result = {
    ...raw,
    ...configureBasicJobInfo(raw),
  };

  return result;
};

const configureJob = (raw: any[]) => {
  return raw.map(convertToCamelCaseKeys).map(configureTask);
};

export type FetchJobsApiProps = {
  storeId?: string;
  groupId?: string;
  isQa?: boolean;
  createdAfter?: string;
};

const fetchJobsApi = async ({
  storeId,
  groupId,
  isQa = false,
  createdAfter = '',
}: FetchJobsApiProps = {}) => {
  const params = {};
  if (storeId) {
    params['job.data.store_id.str.eq'] = storeId;
  }

  if (groupId) {
    params['job.group_id.str.eq'] = groupId;
  }

  if (createdAfter) {
    params['job.created_at.dt.gt'] = createdAfter;
  }

  params['limit'] = 500;
  params['return_job_data_fields'] =
    'has_progressed_to_review_annotations,camera_id,timestamp,provider_key,store_id,timestamp';
  const response = await api.get(
    addNumericCamIdParam('/task-based/generic/list-jobs'),
    {
      params,
    }
  );

  const allJobs = configureJob(response.data.data);
  const result = isQa
    ? allJobs.filter(
        ({ hasProgressedToReviewAnnotations }) =>
          hasProgressedToReviewAnnotations
      )
    : allJobs;
  return result;
};

export default fetchJobsApi;
