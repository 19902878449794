import styled from 'styled-components';
import CameraJobList from './CameraJobList';

const AsideContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: hidden;
`;

type SidebarProps = {
  groupId: string;
};

export default function Sidebar({ groupId }: SidebarProps) {
  return (
    <AsideContainer>
      <CameraJobList groupId={groupId} />
    </AsideContainer>
  );
}
