import { EditorDataSource, CameraImage, ProviderKey } from 'types';
import { configureCameraImageIdFromProviderKey } from 'utils/providerKey';
import api from './';
import { addNumericCamIdParam } from './helpers';

type CameraImageRes = {
  [key: string]: CameraImage;
};

const configureProviderKey = (
  raw: {
    cam_id: string;
    shelf_id?: string;
    camogram_section_id?: string;
  },
  dataSource: EditorDataSource
): ProviderKey => {
  const providerKey = {
    cameraId: raw.cam_id,
    shelfId: raw.shelf_id,
    camogramSectionId: raw.camogram_section_id,
    dataSource,
  };

  return providerKey;
};

const configureCameraImages = (
  images: any,
  dataSource: EditorDataSource
): CameraImageRes => {
  if (!images) return {};
  const byCamera: CameraImageRes = {};
  for (const image of images) {
    const providerKey = configureProviderKey(image.provider_key, dataSource);
    const uuid = configureCameraImageIdFromProviderKey(providerKey);
    byCamera[uuid] = {
      uuid,
      src: image.image,
      imageSource: image.image_source,
      providerKey,
      status: image.status,
      unknown: image.unknown,
    };
  }
  return byCamera;
};

type FetchGenericCameraImagesProps = {
  storeId: string;
  datetime?: string;
  dataSource: EditorDataSource;
};

const fetchGenericCameraImagesApi = async ({
  storeId,
  datetime,
  dataSource,
}: FetchGenericCameraImagesProps) => {
  let url = addNumericCamIdParam(
    `/stores/${storeId}/generic_camogram/images?data_source=${dataSource}`
  );
  if (datetime) {
    url = url + `&timestamp=${encodeURIComponent(datetime)}`;
  }
  const res = await api.get(url);
  return configureCameraImages(res.data.results, dataSource);
};

export default fetchGenericCameraImagesApi;
