import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOmniCamogramInitialData, resetCurrentCamogram } from 'actions';
import Camograms from 'components/Camograms';
import Loader from 'components/Loader';
import useAsyncDispatch from 'hooks/useAsyncDispatch';

const OmniCamogramDetail = ({ storeId, datetime, full = true }) => {
  const dispatch = useDispatch();
  const cameras = useSelector(state =>
    Object.values(state.current.camogram.cams)
  );

  const { dispatch: dispatchAsync, isReady } = useAsyncDispatch();

  useEffect(() => {
    dispatchAsync(fetchOmniCamogramInitialData({ storeId, datetime }));

    return () => dispatch(resetCurrentCamogram());
  }, [storeId, datetime, dispatchAsync, dispatch]);

  return (
    <Loader full={full} loading={!isReady} text="Loading Camogram">
      {cameras.length > 0 ? (
        <Camograms cameras={cameras} />
      ) : (
        <div>No Camograms</div>
      )}
    </Loader>
  );
};

export default OmniCamogramDetail;
