import styled from 'styled-components';

const filterToStyle = (key: string, value: number) => `${key}(${value})`;
const filtersToStyle = (filters: { [key: string]: number }) => {
  return Object.entries(filters)
    .map(([key, value]) => filterToStyle(key, value))
    .join(' ');
};

const StyledImage = styled.img<{ imageFilter: any }>`
  filter: ${({ imageFilter }) => filtersToStyle(imageFilter)};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export default StyledImage;
