import { Modify, Vec2D } from 'types';
import { useLayout2DContext } from '.';
import RotateTransform from './RotateTransform';

type ArrowProps2 = Modify<React.SVGProps<SVGPolygonElement>, { end: Vec2D }> & {
  start: Vec2D;
};

function ArrowComponent({ start, end, ...polygonProps }: ArrowProps2) {
  const polygonPoints = `${end.x}, ${end.y + 15}, ${end.x + 25}, ${end.y}, ${
    end.x
  }, ${end.y - 15}`;
  return (
    <>
      <line
        strokeWidth={4}
        x1={start.x}
        y1={start.y}
        x2={end.x}
        y2={end.y}
        style={{ stroke: '#000000' }}
      />
      <polygon points={polygonPoints} {...polygonProps} />
    </>
  );
}

const calcDegreesFromVector = (x: number, y: number) => {
  const angle = Math.atan2(y, x);
  const degrees = (180 * angle) / Math.PI;

  return -degrees;
};

export default function Arrow({
  cameraId,
  start,
}: {
  cameraId: string;
  start: Vec2D;
}) {
  const { cameras, svgRef } = useLayout2DContext();

  const camera = cameras?.find(camera => camera.id === cameraId);

  if (!camera) return null;

  const { normal } = camera;

  const degrees = calcDegreesFromVector(normal.x, normal.y);
  const end = { x: start.x + 70, y: start.y };
  return (
    <RotateTransform start={start} initialDegrees={degrees} svgRef={svgRef}>
      {({ handlePointerDown, handlePointerMove, handlePointerUp }) => (
        <ArrowComponent
          start={start}
          end={end}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
        />
      )}
    </RotateTransform>
  );
}
