import { useState } from 'react';
import styled from 'styled-components';

import {
  HighresActiveRegion,
  HighresActiveSection,
  HighresCamera,
  Section,
} from 'types';
import Sidebar from './Sidebar';
import EditorArea from './EditorArea';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  overflow: hidden;
`;

type ActiveRegionEditorProps = {
  onOk: (activeRegion: HighresActiveRegion) => void;
  onCancel: () => void;
  initialActiveRegion: HighresActiveRegion;
  camera: HighresCamera;
  sections: Section[];
};

export default function ActiveRegionEditor({
  onOk,
  onCancel,
  initialActiveRegion,
  camera,
  sections,
}: ActiveRegionEditorProps) {
  const [selectedSectionId, setSelectedSectionId] = useState<string>('');
  const [
    selectedActiveSectionId,
    setSelectedActiveSectionId,
  ] = useState<string>('');
  const [activeRegion, setActiveRegion] = useState(initialActiveRegion);

  const onChangeSelectedSection = sectionId => {
    setSelectedSectionId(sectionId);
    setSelectedActiveSectionId('');
  };
  const onChangeSelectedActiveSection = sectionId => {
    setSelectedSectionId('');
    setSelectedActiveSectionId(sectionId);
  };

  const onAddNewActiveSection = (newActiveSection: HighresActiveSection) => {
    setActiveRegion(prev => {
      const currentActiveShelfs = prev.activeSections ?? [];
      return {
        ...prev,
        activeSections: [...currentActiveShelfs, newActiveSection],
      };
    });
    setSelectedSectionId('');
    setSelectedActiveSectionId('');
  };

  const onDeleteActiveSection = (activeSection: HighresActiveSection) => {
    setActiveRegion(prev => {
      const currentActiveShelfs = prev.activeSections;
      const other =
        currentActiveShelfs?.filter(
          ({ camogramSectionId }) =>
            camogramSectionId !== activeSection.camogramSectionId
        ) ?? [];
      return {
        ...prev,
        activeSections: other,
      };
    });
    setSelectedSectionId('');
    setSelectedActiveSectionId('');
  };

  const onChangeEnabled = (enabled: boolean) => {
    setActiveRegion(prev => ({ ...prev, enabled }));
  };

  const onClickOk = () => {
    onOk(activeRegion);
  };

  if (!camera || !activeRegion) return null;

  return (
    <Container>
      <Sidebar
        camera={camera}
        sections={sections}
        selectedSectionId={selectedSectionId}
        selectedActiveSectionId={selectedActiveSectionId}
        activeRegion={activeRegion}
        onClickOk={onClickOk}
        onCancel={onCancel}
        onChangeSelectedSection={onChangeSelectedSection}
        onChangeSelectedActiveSection={onChangeSelectedActiveSection}
        onDeleteActiveSection={onDeleteActiveSection}
        onChangeEnabled={onChangeEnabled}
      />
      <EditorArea
        sections={sections}
        activeRegion={activeRegion}
        selectedActiveSectionId={selectedActiveSectionId}
        selectedSectionId={selectedSectionId}
        camera={camera}
        onAddNewActiveSection={onAddNewActiveSection}
      />
    </Container>
  );
}
