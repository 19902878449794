import { useProfile } from '@standard/portal';

const isAdmin = email => {
  return email.split('@')[1] === 'standard.ai';
};

const useAccount = () => {
  const profile = useProfile();

  return { ...profile, isAdmin: isAdmin(profile.email) };
};

export default useAccount;
