import { toast } from 'react-toastify';

import JobButton from './JobButton';
import { CamogramJob } from 'types/taskBased';
import { regressTask } from 'slices/taskBasedSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { CircularProgress } from '@material-ui/core';

type RegressButtonProps = {
  toTaskName: string;
};

export default function RegressTaskButton({ toTaskName }: RegressButtonProps) {
  const { dispatch, isLoading } = useAsyncDispatch();
  const onClick = async (job?: CamogramJob) => {
    if (job === undefined) throw new Error('Job is undefined on regress');

    if (
      window.confirm(
        'Did you save your work before regression? "Redo task" will remove the unsaved annotation and it cannot be undone.'
      )
    ) {
      await toast.promise(dispatch(regressTask({ job, toTaskName })), {
        pending: 'Regressing...',
        success: 'Job Regressed!',
        error: 'Could not regress.',
      });
    }
  };
  return (
    <JobButton
      style={{ fontSize: 9, height: 30, padding: '4px 4px' }}
      color="default"
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={25} /> : 'Redo Task'}
    </JobButton>
  );
}
