import { useParams } from 'react-router-dom';

import MainLayout from 'layouts/Main';
import TaskBasedEditorInitialDataLoader from '../TaskBasedEditorInitialDataLoader';
import ContentContainer from '../Content';
import Header from '../Header';
import Sidebar from '../Sidebar';
import SplitLayout from 'layouts/SplitLayout';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { clear } from 'slices/taskBasedSlice';
import { TaskType } from 'types/taskBased';
import { TaskBasedContextProvider } from '../contexts/TaskBasedContext';
import ContentSwitcherContainer from './ContentSwitcherContainer';

export default function TaskBasedReviewAnnotationsPage() {
  const { storeId, groupId } = useParams<{ storeId; groupId }>();
  const dispatch = useDispatch();

  const isTaskTypeEnable = useCallback((taskType: TaskType) => {
    return taskType !== TaskType.Completed;
  }, []);

  const isTaskDone = useCallback((taskType: TaskType) => {
    const completedTaskTypes = [TaskType.Completed];

    return completedTaskTypes.includes(taskType);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <TaskBasedContextProvider
      isTaskTypeEnable={isTaskTypeEnable}
      isTaskDone={isTaskDone}
    >
      <TaskBasedEditorInitialDataLoader
        storeId={storeId}
        groupId={groupId}
        isQa={true}
      >
        <MainLayout
          header={
            <Header
              containerStyle={{ color: 'white', backgroundColor: '#f5a623' }}
            />
          }
          aside={<Sidebar groupId={groupId} />}
        >
          <SplitLayout>
            <ContentContainer>
              <ContentSwitcherContainer />
            </ContentContainer>
          </SplitLayout>
        </MainLayout>
      </TaskBasedEditorInitialDataLoader>
    </TaskBasedContextProvider>
  );
}
