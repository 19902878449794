import { useDispatch } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import useAsyncCall from 'hooks/useAsyncCall';
import { AnyAction } from 'redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import { Modify } from 'types';
import { fetchCameras } from 'slices/cameraImagesSlice';
import Loader from 'components/Loader';
import { useAppSelector } from 'hooks';
import { fetchJobs } from 'slices/camogramJobsSlice';
import { fetchSkus } from 'actions';
import { fetchCameraJobForAnnotation } from 'slices/taskBasedSlice';
import { track } from 'utils/segmentAnalytics';
import { EVENT_TASK_BASED_LANDING_TASK } from 'common/constants';
import { FetchJobsApiProps } from 'utils/api/taskBasedEditor/fetchJobsApi';
import { useTaskBasedContext } from './contexts/TaskBasedContext';
import ErrorDisplayer from 'components/ErrorDisplayer';
import { CamogramJobBase } from 'types/taskBased';

type FetchInitialDataProps = Modify<
  FetchJobsApiProps,
  { groupId: string; storeId: string }
> & {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
};

const fetchInitialData = async ({
  storeId,
  groupId,
  isQa,
  dispatch,
}: FetchInitialDataProps) => {
  const jobsRes = await dispatch(fetchJobs({ storeId, groupId, isQa }));
  if (fetchJobs.rejected.match(jobsRes)) {
    throw new Error(jobsRes.error.message);
  }

  const { jobs } = jobsRes.payload as { jobs: CamogramJobBase[] };
  const [camerasRes, skusRes] = await Promise.all([
    dispatch(
      fetchCameras({
        storeId,
      })
    ),
    dispatch(fetchSkus({ storeId })),
  ]);

  if (fetchSkus.rejected.match(skusRes)) {
    throw new Error(skusRes.error.message);
  }
  if (fetchCameras.rejected.match(camerasRes)) {
    throw new Error(camerasRes.error.message);
  }

  return { jobs };
};

type JobLoaderProps = TaskBasedEditorInitialDataLoaderProps;

const LandingTracker: React.FC<JobLoaderProps> = ({
  storeId,
  groupId,
  isQa,
}) => {
  const selectedJob = useAppSelector(state => state.taskBased.selectedJob);

  const { id, currentTaskName, datetime, providerKey } = selectedJob ?? {};
  const { cameraId } = providerKey ?? {};
  useAsyncEffect(async () => {
    if (!currentTaskName) return;
    track(EVENT_TASK_BASED_LANDING_TASK, {
      storeId,
      groupId,
      cameraId,
      jobId: id,
      currentTaskName,
      camogramTimestamp: datetime,
      isQa,
    });
  }, [id, cameraId, groupId, currentTaskName]);

  return null;
};

type TaskBasedEditorInitialDataLoaderProps = {
  storeId: string;
  groupId: string;
  isQa: boolean;
};
const TaskBasedEditorInitialDataLoader: React.FC<TaskBasedEditorInitialDataLoaderProps> = ({
  storeId,
  groupId,
  isQa,
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState<string>();
  const { isTaskTypeEnable } = useTaskBasedContext();
  const { isReady, call } = useAsyncCall<{
    jobs: CamogramJobBase[];
  }>();

  const selectedJob = useAppSelector(state => state.taskBased.selectedJob);

  const onClickStoreHome = () => {
    history.push(`/stores/${storeId}`);
  };

  useAsyncEffect(async () => {
    try {
      const { jobs } = await call(
        fetchInitialData({ storeId, groupId, isQa, dispatch })
      );
      const enableCamerasSortedByCameraInteger = _.sortBy(jobs, job =>
        Number(job.providerKey.cameraId)
      ).filter(job => {
        return isTaskTypeEnable(job.currentTaskName);
      });

      const jobWithLowestCameraId = enableCamerasSortedByCameraInteger[0];

      if (jobs.length === 0) {
        setError(
          'There are no jobs in this group. All jobs may have already been completed.'
        );
      } else if (jobWithLowestCameraId === undefined) {
        if (isQa) {
          setError(
            'There are currently no cameras in this group to work on. All jobs may be finalized.'
          );
        } else {
          setError(
            'There are currently no cameras in this group to work on. All jobs may be in the QC queue.'
          );
        }
      } else {
        const version = jobs[0].version;
        setError(undefined);
        await dispatch(
          fetchCameraJobForAnnotation({
            storeId,
            groupId,
            providerKey: jobWithLowestCameraId.providerKey,
            version,
          })
        );
      }
    } catch (e) {
      setError(e.message);
      if (!e.isKnownError?.()) {
        throw e;
      }
    }
  }, [storeId, groupId, dispatch]);

  if (error)
    return (
      <ErrorDisplayer
        full
        error={error}
        actions={
          <Button color="primary" onClick={onClickStoreHome}>
            Store Home
          </Button>
        }
      />
    );
  if (!isReady || selectedJob === undefined)
    return <Loader full loading text="Loading Initial Data" />;
  return (
    <>
      <LandingTracker storeId={storeId} groupId={groupId} isQa={isQa} />
      {children}
    </>
  );
};

export default TaskBasedEditorInitialDataLoader;
