import { toPath } from './helpers';
import Text from './Text';
import Link from './Link';
import { Shelf } from 'types';
import { useLayout2DContext } from '.';

const getCentroid = (height: number) => (points: any[]) =>
  points.reduce(
    (center, point, i, all) => {
      return [
        center[0] + point.x / all.length,
        center[1] + (height - point.y) / all.length,
      ];
    },
    [0, 0]
  );

function ShelvesComponent({
  height,
  shelves,
  onClick,
}: {
  height: number;
  shelves: Shelf[];
  onClick?: any;
}) {
  const configuredShelves = shelves.map(shelf => ({
    id: shelf.id as string,
    path: toPath(height)(shelf.coordinates),
    center: getCentroid(height)(shelf.coordinates),
  }));

  const shelfLabels = configuredShelves.map(({ id, center }) => (
    <Text key={id} center={center}>
      {id}
    </Text>
  ));

  const shelfRectangles = configuredShelves.map(({ id, path }) => (
    <Link key={id} id={id} onClick={onClick}>
      <path d={path} fill="#ffffffaa" />
    </Link>
  ));

  return <>{[shelfRectangles, shelfLabels]}</>;
}

export default function Shelves() {
  const { height, layout } = useLayout2DContext();

  return <ShelvesComponent height={height} shelves={layout.shelves} />;
}
