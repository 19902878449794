import api from '..';
import { addNumericCamIdParam } from '../helpers';
import fetchJobsApi from './fetchJobsApi';
import { CamogramJobBase, TaskType } from 'types/taskBased';
type ReleaseGroupJobsApiProps = {
  storeId: string;
  groupId: string;
  jobFilter?: (job: CamogramJobBase) => boolean;
};

const releaseGroupJobsApi = async ({
  storeId,
  groupId,
  jobFilter = (_: CamogramJobBase) => true,
}: ReleaseGroupJobsApiProps) => {
  const jobs = await fetchJobsApi({ storeId, groupId });
  const jobIds = jobs
    .filter(({ currentTaskName }) => currentTaskName !== TaskType.Completed)
    .filter(jobFilter)
    .map(({ id }) => id);
  const res = await api.post(
    addNumericCamIdParam('task-based/generic/release-jobs'),
    {
      job_ids: jobIds,
    }
  );

  return res.data;
};

export const releaseQcJobsApi = async ({ storeId, groupId }) => {
  const jobFilter = (job: CamogramJobBase) =>
    job.hasProgressedToReviewAnnotations;
  return releaseGroupJobsApi({ storeId, groupId, jobFilter });
};

export default releaseGroupJobsApi;
