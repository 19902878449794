import { combineReducers } from 'redux';

import {
  fetchCamogramInitialData,
  fetchOmniCamogramInitialData,
} from 'actions';
import { RESET_CURRENT_CAMOGRAM } from 'actions/types';

const initialCamogramState = { cams: {} };
const CAMOGRAM_INITIAL_DATA = fetchCamogramInitialData.fulfilled().type;
const OMNI_CAMOGRAM_INITIAL_DATA = fetchOmniCamogramInitialData.fulfilled()
  .type;

const camogram = (state = initialCamogramState, { type, payload }) => {
  if (type === CAMOGRAM_INITIAL_DATA || type === OMNI_CAMOGRAM_INITIAL_DATA) {
    return payload.camogram;
  }

  return state;
};

// { "0": { src: "image's url" } }
const cameraImages = (state = {}, { type, payload }) => {
  if (type === CAMOGRAM_INITIAL_DATA || type === OMNI_CAMOGRAM_INITIAL_DATA) {
    return payload.cameraImages;
  }

  return state;
};

const reducer = combineReducers({ camogram, cameraImages });

const reducers = (state, action) => {
  if (action.type === RESET_CURRENT_CAMOGRAM) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return reducer(state, action);
};

export default reducers;
