import styled from 'styled-components';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ProviderKey } from 'types';
import { CamogramJobBase, TaskType } from 'types/taskBased';
import { useAppSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import CompleteTaskButton from '../components/CompleteTaskButton';
import { updateSelectedJobId } from 'slices/taskBasedSlice';
import { CheckCircle } from '@material-ui/icons';
import { COLORS } from 'common/constants';
import Flex from 'components/Flex';
import RegressTaskButton from '../components/RegressButton';
import { useTaskBasedContext } from '../contexts/TaskBasedContext';
import { exist } from 'utils';

const StyledCameraImage = styled.img`
  width: 100px;
  height: 50px;
  object-fit: contain;
`;

const TaskItemText = styled.div<{ current: boolean }>`
  font-weight: ${({ current }) => (current ? 'bold' : 'normal')};
  opacity: ${({ current }) => (current ? 1 : 0.5)};
  font-size: 14px;
  margin-bottom: ${({ current }) => (current ? '8px' : 0)};
`;

const TaskItemDescription = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-right: 4px;
  }
`;

const configureTaskText = (taskType: TaskType) => _.startCase(taskType);

enum JobStatus {
  Idle,
  Current,
  Done,
}

const configureSecondaryText = (taskType: TaskType): React.ReactNode => {
  switch (taskType) {
    case TaskType.SelectUnchangedPreviousLocations:
      return "Please check each items from previous annotation whether it has changed since the last one. If either the box or the item doesn’t match with the current, please select 'Changed'";
    case TaskType.SelectLocalizedPreviousLocations:
      return 'You’ll review whether you want to use the boxes that you selected “Changed“ in the previous step. Selecting “Use“ prevents you from drawing the box again from scratch.';
    case TaskType.RoutePreviousLocations:
      return (
        <div>
          Please route (or categorize/mark) each previous annotation
          appropriately. An annotation can be categorized as "Keep",
          "Reclassify", "Reshape", or "Discard".
          <ul>
            <li>Please choose "Keep" if nothing needs to be changed.</li>
            <li>
              Please choose "Reclassify" if the box is fix to the item, but
              still need to change the sku in Step 5.
            </li>
            <li>
              Please choose "Reshape" if the sku is not changed but the box
              needs to be modified in Step 2.
            </li>
            <li>
              Please choose "Discard" if you want to get rid of the annotation.
            </li>
          </ul>
        </div>
      );
    // return 'Please route (or categorize/mark) each previous annotation appropriately. An annotation can be categorized as "Keep", "Reclassify", "Reshape", or "Discard". Please choose "Keep" if nothing needs to be changed. Please choose "Reclassify" if the box is correct but the sku needs to be modified (in Step 5). Please choose "Reshape" if the sku is correct but the box needs to be modified (in Step 2). Please choose "Discard" if you want to get rid of the annotation entirely.';
    case TaskType.ReshapePreviousLocations:
      return 'Please reshape each boxes to fix the item in the image';
    case TaskType.SelectLocalizedProposedLocations:
      return "These are boxes for items the ML model identified as not covered by any boxes. You'll review those items whether you want to use the box. If you choose 'Use', you'll classify an SKU for the box in the 'Classify Locations' task.";
    case TaskType.CreateLocations:
      return 'You’ll draw a box for Skus that are not covered in the previous steps.';
    case TaskType.ClassifyLocations:
      return 'You’ll classify a sku for all empty boxes one by one. The boxes that you choose “Unchanged“ in step 1 are displayed in ZoomOut View as well, and you can see the item on hover cursor.';
  }
};

type JobItemProps = {
  taskType: TaskType;
  nested?: boolean;
  status: JobStatus;
  index: number;
  disabled?: boolean;
};
const JobItem = ({
  taskType,
  nested = false,
  status,
  index,
  disabled = false,
}: JobItemProps) => {
  const taskName = configureTaskText(taskType);
  const taskTitle = `${index}. ${taskName}`;
  const isCurrent = status === JobStatus.Current;
  const secondary = isCurrent ? configureSecondaryText(taskType) : null;

  return (
    <ListItem
      style={{
        paddingLeft: nested ? 56 : 16,
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: isCurrent ? 16 : 0,
        marginBottom: isCurrent ? 16 : 0,
        paddingRight: 8,
      }}
      dense
      disabled={disabled || status === JobStatus.Idle}
    >
      <Flex justifyContent="space-between" style={{ alignSelf: 'stretch' }}>
        <TaskItemText current={isCurrent}>{taskTitle}</TaskItemText>
        {!disabled && status === JobStatus.Done && (
          <ButtonsContainer>
            <RegressTaskButton toTaskName={taskType} />
            <CheckCircle style={{ color: COLORS.approved }} />
          </ButtonsContainer>
        )}
      </Flex>
      {!disabled && isCurrent && (
        <>
          <TaskItemDescription>{secondary}</TaskItemDescription>
          <CompleteTaskButton />
        </>
      )}
    </ListItem>
  );
};

const configureJobStatus = ({
  job,
  isDone,
  jobName,
}: {
  job: CamogramJobBase;
  isDone: boolean;
  jobName: string;
}): JobStatus => {
  if (isDone) return JobStatus.Done;
  if (job.currentTaskName === jobName) return JobStatus.Current;

  const jobIndex = job.workflowTasksInfo.findIndex(
    ({ name }) => name === jobName
  );
  const currentTaskIndex = job.workflowTasksInfo.findIndex(
    ({ name }) => name === job.currentTaskName
  );
  return jobIndex < currentTaskIndex ? JobStatus.Done : JobStatus.Idle;
};

type CameraJobListItemProps = {
  selected: boolean;
  onClick: () => void;
  job: CamogramJobBase;
  isLoading: boolean;
  cameraLabel: React.ReactNode;
};
function CameraJobListItem({
  onClick,
  selected,
  job,
  isLoading,
  cameraLabel,
}: CameraJobListItemProps) {
  const { isTaskDone, isTaskTypeEnable } = useTaskBasedContext();
  const isCameraDone = job ? isTaskDone(job.currentTaskName) : true;
  const disabled = job ? !isTaskTypeEnable(job.currentTaskName) : true;
  return (
    <>
      <ListItem
        disabled={disabled}
        dense
        button
        selected={selected}
        onClick={onClick}
      >
        <ListItemText primary={cameraLabel} />
        {isCameraDone && <CheckCircle style={{ color: COLORS.approved }} />}
      </ListItem>
      {job && !isLoading && selected && (
        <Collapse in={selected} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {job.workflowTasksInfo.map(({ name }, index) => (
              <JobItem
                key={name}
                nested
                taskType={name}
                status={configureJobStatus({
                  job,
                  jobName: name,
                  isDone: isTaskDone(job.currentTaskName),
                })}
                disabled={!isTaskTypeEnable(name)}
                index={index + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

const configureCameraLabelComponents = (providerKey: ProviderKey) => {
  const cameraLabel = `Cam ${providerKey.cameraId}`;

  const labels = [<div>{cameraLabel}</div>];

  if (exist(providerKey.shelfId)) {
    labels.push(<div>{providerKey.shelfId}</div>);
  }

  if (exist(providerKey.camogramSectionId)) {
    labels.push(<div>{`Section ${providerKey.camogramSectionId}`}</div>);
  }
  return labels;
};

type CameraJobListComponentProps = {
  jobs: CamogramJobBase[];
  selectedJobId: string;
  isLoading: boolean;
  onClickJob: (jobId: string) => void;
};
function CameraJobListComponent({
  jobs,
  isLoading,
  onClickJob,
  selectedJobId,
}: CameraJobListComponentProps) {
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      style={{ overflow: 'auto' }}
    >
      {jobs.map(job => (
        <CameraJobListItem
          key={job.id}
          onClick={() => onClickJob(job.id)}
          cameraLabel={configureCameraLabelComponents(job.providerKey)}
          selected={job.id === selectedJobId}
          isLoading={isLoading && job.id === selectedJobId}
          job={job}
        />
      ))}
    </List>
  );
}

type CameraJobListProps = {
  groupId: string;
};
export default function CameraJobList({ groupId }: CameraJobListProps) {
  const dispatch = useDispatch();

  const allJobs = useAppSelector(state => state.camogramJobs.listData);
  const groupJobs = _.sortBy(
    Object.values(allJobs).filter(job => job.groupId === groupId),
    job => Number(job.providerKey.cameraId)
  );
  const selectedJob = useAppSelector(state => state.taskBased.selectedJob);

  if (selectedJob === undefined) return null;

  const onClickJob = (jobId: string) => {
    if (
      window.confirm(
        'Are you sure? Switching camera will remove the unsaved annotation and it cannot be undone. Please make sure that you save your work in progress before this operation'
      )
    ) {
      const nextJob = groupJobs.find(job => job.id === jobId);
      if (nextJob === undefined)
        throw new Error(
          `Job ${jobId} not found when switching jobs via sidebar`
        );
      dispatch(updateSelectedJobId({ jobId: nextJob.id, ...nextJob }));
    }
  };

  return (
    <CameraJobListComponent
      jobs={groupJobs}
      onClickJob={onClickJob}
      selectedJobId={selectedJob.id}
      isLoading={false}
    />
  );
}
