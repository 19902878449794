import React from 'react';
import styled from 'styled-components';
import { Error as ErrorIcon } from '@material-ui/icons';

const Container = styled.div<{ full?: boolean }>`
  height: ${({ full }) => (full ? '100vh' : '100%')};
  width: ${({ full }) => (full ? '100vw' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  max-width: 800px;
  margin: 0px auto;
  text-align: center;
  font-weight: bold;
  > * {
    margin-bottom: 16px;
  }
`;

const ErrorContent = styled.div`
  overflow: auto;
  width: 100%;
  max-height: 500px;
`;

type Props = {
  error?: React.ReactNode;
  actions?: React.ReactNode;
  full?: boolean;
};

export default function ErrorDisplayer({
  error,
  actions,
  full = false,
}: Props) {
  if (error === undefined) return null;
  return (
    <Container full={full}>
      <ErrorIcon color="secondary" fontSize="large" />
      <ErrorContent>{error}</ErrorContent>
      {actions}
    </Container>
  );
}
