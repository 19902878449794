import { createContext, useContext } from 'react';
import { TaskType } from 'types/taskBased';

type State = {
  isTaskTypeEnable: (taskType: TaskType) => boolean;
  isTaskDone: (taskType: TaskType) => boolean;
};
const TaskBasedContext = createContext<State | undefined>(undefined);

export const TaskBasedContextProvider: React.FC<State> = ({
  children,
  ...props
}) => {
  return (
    <TaskBasedContext.Provider value={props}>
      {children}
    </TaskBasedContext.Provider>
  );
};

export const useTaskBasedContext = () => {
  const context = useContext(TaskBasedContext);

  if (context === undefined) {
    throw new Error(
      'useTaskBasedContext must be used within a TaskBasedContextProvider'
    );
  }

  return context;
};
