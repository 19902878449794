import useShortcut from 'hooks/useShortcut';
import { createContext, useContext, useState } from 'react';
import { Coordinate, CursorMode } from 'types';
import { convertPointsToRegion, toRectangle } from 'utils';

type State = {
  cursorMode: CursorMode;
  drawingPoints: number[][];
  cursorMousePos: number[];
  handleMouseDown: (props: HooksProps) => (event: any) => void;
  handleMouseMove: (event: any) => void;
  setCursorMode: React.Dispatch<React.SetStateAction<CursorMode>>;
  allPoints: number[][];
};

const DrawLocationContext = createContext<State | undefined>(undefined);
enum DrawType {
  Polygon,
  Rectangle,
}
type DrawLocationProviderProps = {
  defaultCursorMode?: CursorMode;
  drawType?: DrawType;
  pointsLimit?: number;
};
export const DrawLocationProvider: React.FC<DrawLocationProviderProps> = ({
  defaultCursorMode = CursorMode.Pointer,
  drawType = DrawType.Polygon,
  pointsLimit,
  children,
}) => {
  const [cursorMousePos, setCursorMousePos] = useState<number[]>([]);
  const [drawingPoints, setDrawingPoints] = useState<number[][]>([]);
  const [cursorMode, setCursorMode] = useState<CursorMode>(defaultCursorMode);
  const [isMouseOverStartPoint, setIsMouseOverStartPoint] = useState(false);

  const isFinished = drawingPoints.length === 0;
  const allPoints = isFinished
    ? drawingPoints
    : drawType === DrawType.Polygon
    ? [...drawingPoints, cursorMousePos]
    : toRectangle([...drawingPoints, cursorMousePos]);

  const handleMouseDown = ({
    width,
    height,
    angle,
    onAddRegion,
  }: HooksProps) => event => {
    // Handle only left click
    if (event.evt.button !== 0) return;

    if (cursorMode === CursorMode.Draw) {
      const mousePos = [event.evt.offsetX, event.evt.offsetY];
      setCursorMousePos(mousePos);
      setDrawingPoints([mousePos]);
      setCursorMode(CursorMode.Drawing);
      event.evt.stopPropagation();
      return;
    }

    if (cursorMode === CursorMode.Drawing) {
      const isExceedLimit = pointsLimit
        ? drawingPoints.length > pointsLimit - 2
        : false;
      const isFinishPolygon =
        drawType === DrawType.Polygon &&
        (isMouseOverStartPoint || isExceedLimit);
      const isFinishRectangle = drawType === DrawType.Rectangle;
      if (isFinishPolygon || isFinishRectangle) {
        setDrawingPoints([]);

        const region: Coordinate[] =
          drawType === DrawType.Polygon
            ? convertPointsToRegion(allPoints, width, height, angle)
            : convertPointsToRegion(
                toRectangle([...drawingPoints, cursorMousePos]),
                width,
                height,
                angle
              );
        setIsMouseOverStartPoint(false);
        setCursorMousePos([]);
        setCursorMode(defaultCursorMode);
        event.evt.stopPropagation();

        onAddRegion(region);
      } else {
        const mousePos = [event.evt.offsetX, event.evt.offsetY];
        setDrawingPoints([...drawingPoints, mousePos]);
      }
    }
  };

  const handleMouseMove = event => {
    if (cursorMode === CursorMode.Drawing) {
      const mousePos = [event.evt.offsetX, event.evt.offsetY];
      setCursorMousePos(mousePos);
    }
  };

  useShortcut(
    'escape',
    e => {
      setDrawingPoints([]);
      setIsMouseOverStartPoint(false);
      setCursorMousePos([]);
      setCursorMode(defaultCursorMode);
    },
    true
  );

  return (
    <DrawLocationContext.Provider
      value={{
        cursorMode,
        cursorMousePos,
        drawingPoints,
        handleMouseMove,
        handleMouseDown,
        allPoints,
        setCursorMode,
      }}
    >
      {children}
    </DrawLocationContext.Provider>
  );
};

type HooksProps = {
  width: number;
  height: number;
  angle: number;
  onAddRegion: (region: Coordinate[]) => void;
};
export const useDrawLocationContext = () => {
  const context = useContext(DrawLocationContext);

  if (context === undefined) {
    throw new Error(
      'useDrawLocationContext must be used within a DrawLocationProvider'
    );
  }

  return context;
};

export const useDrawHandlers = (props: HooksProps) => {
  const context = useDrawLocationContext();

  const handleMouseDown = context.handleMouseDown(props);

  return { handleMouseDown, handleMouseMove: context.handleMouseMove };
};
