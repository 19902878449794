import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { CamogramJobBase } from 'types/taskBased';

import { createAsyncThunk } from '@reduxjs/toolkit';

import fetchJobsApi, {
  FetchJobsApiProps,
} from 'utils/api/taskBasedEditor/fetchJobsApi';
import { completeTask, regressTask } from './taskBasedSlice';

export const fetchJobs = createAsyncThunk(
  'fetchJobs',
  async (props: FetchJobsApiProps) => {
    const jobs = await fetchJobsApi(props);

    return { jobs };
  }
);

type CamogramListJobs = {
  [cameraId: string]: CamogramJobBase;
};

export type CamogramJobsState = {
  listData: CamogramListJobs;
};

const initialState: CamogramJobsState = {
  listData: {},
};

const camogramJobsSlice = createSlice({
  name: 'camogramJobs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchJobs.fulfilled, (state, action) => {
        const { jobs } = action.payload;

        const newJobs = {};
        jobs.forEach(job => {
          newJobs[job.cameraImageId] = job;
        });

        state.listData = newJobs;
      })
      .addCase(completeTask.fulfilled, (state, action) => {
        const { newJob } = action.payload;
        const cameraId = newJob.cameraImageId;
        state.listData[cameraId] = newJob;
      })
      .addCase(regressTask.fulfilled, (state, action) => {
        const { newJob } = action.payload;
        const cameraId = newJob.cameraImageId;
        state.listData[cameraId] = newJob;
      });
  },
});

export default camogramJobsSlice;
