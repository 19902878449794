import api from '.';
import { addNumericCamIdParam } from './helpers';
import { configureListJob } from './taskBasedEditor/helpers';

type StartNextReviewAnnotationsApiProps = {
  userId: string;
};

export const postStartNextReviewAnnotationsTaskApi = async ({
  userId,
}: StartNextReviewAnnotationsApiProps) => {
  const response = await api.post(
    addNumericCamIdParam('/camogram_queue/get_next_qc_job_for_annotation'),
    {
      user_id: userId,
    }
  );
  const result = configureListJob(response.data.result);

  return result;
};
