import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 3rem;

  @media (max-width: 800px) {
    thead {
      display: none;
    }
  }
`;

export const Tbody = styled.tbody`
  border-top: solid 1px #cdcdcd;
`;

export const Row = styled.tr`
  background: #fff;
  margin: 0;
  line-height: 1.5;
  border-bottom: solid 1px #cdcdcd;

  @media (max-width: 800px) {
    border: 20px solid white;
    border-width: 5px 0 5px;
    display: block;
    padding: 5px;
  }
`;

export const Cell = styled.td<{ right?: boolean }>`
  padding: 8px 12px;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  word-wrap: break-word;
  max-width: 400px;

  span {
    vertical-align: middle;
  }

  @media (max-width: 800px) {
    display: block;
    text-align: left;
    padding: 4px 8px;
  }
`;

export const TableContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 16px;
  }
`;
