import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchStoresApi } from 'utils/api';

const fetchStores = createAsyncThunk('fetchStores', async () => {
  const stores = await fetchStoresApi();
  return { stores };
});

export default fetchStores;
