import { EditorDataSource } from 'types';
import api from '..';
import { addNumericCamIdParam } from '../helpers';

// From [ { image: "image's url", image_source: "image's key" }, { image: "image's url", image_source: "image's key" } ]
// To { "0": { src: "image's url" imageSource: 'image's key' }, "1": { src: "image's url", imageSource: 'image's key' } }
const configureCameraImages = images => {
  if (!images) return {};
  const byCamera = {};
  for (const image of images) {
    byCamera[image.provider_key.cam_id] = {
      src: image.image,
      imageSource: image.image_source,
      providerKey: image.provider_key,
      status: image.status,
      unknown: image.unknown,
    };
  }
  return byCamera;
};

const fetchHighResCameraImagesApi = async (storeId, datetime) => {
  let url = `/stores/${storeId}/generic_camogram/images?data_source=${EditorDataSource.StaticHighRes}`;
  if (datetime) {
    url = url + `&timestamp=${encodeURIComponent(datetime)}`;
  }
  const res = await api.get(addNumericCamIdParam(url));
  return configureCameraImages(res.data.results);
};

export default fetchHighResCameraImagesApi;
