import CamogramLocations from 'components/CamogramLocations';
import { CamogramLocation } from 'components/CamogramLocations/types';
import { useAppSelector } from 'hooks';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useDispatch } from 'react-redux';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';
import { useJob } from '../helpers';
import {
  Coordinate,
  CursorMode,
  HighresActiveSection,
  Location,
  Modify,
} from 'types';
import { DrawLocationProvider } from 'contexts/DrawLocationProvider';
import { setSelectedLocationId, updateLocation } from 'slices/taskBasedSlice';
import { COLORS } from 'common/constants';
import Flex from 'components/Flex';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';
import { setActiveLocation } from 'actions';
import CamogramTooltip from 'components/CamogramTooltip';
import ItemLabel, { ItemLabelInfo } from '../common/ItemLabel';
import { useParams } from 'react-router-dom';

const configureLocationsColor = ({
  status,
  isSelected,
  isExisting,
}: {
  status?: LocationReviewStatus;
  isSelected: boolean;
  isExisting: boolean;
}) => {
  if (isSelected) {
    return COLORS.rejected;
  } else if (isExisting) {
    return COLORS.init;
  } else {
    if (status === undefined)
      throw new Error(
        'status was undefined in configureLocationsColor in ReshapePreviousLocations. Please contact to the frontend person!'
      );
    switch (status) {
      case LocationReviewStatus.Reshaped:
        return COLORS.approved;
      case LocationReviewStatus.Init:
        return COLORS.reshape;
      default:
        throw new Error(
          `This status (${status}) must not happen in configureLocationsColor in ReshapePreviousLocations.`
        );
    }
  }
};

function ItemLabelContainer({ itemUuid }: { itemUuid: string }) {
  const { storeId = '' } = useParams<{ storeId: string }>();
  const itemInfo = useAppSelector(state => state.skus?.[storeId]?.[itemUuid]);

  if (!itemInfo) return null;

  return <ItemLabel itemInfo={itemInfo} />;
}

type ComponentProps = Modify<
  React.ComponentProps<typeof CamogramLocations>,
  { locations: LocationToReview[] }
> & {
  existingLocations: Location[];
  selectedLocationId?: string;
  selectedSkuInfo?: ItemLabelInfo;
  activeShelfs?: HighresActiveSection[];
  onChangeLocationId: (locationId: string) => void;
};

function ReshapeLocationsComponent({
  existingLocations,
  locations,
  selectedLocationId,
  selectedSkuInfo,
  activeShelfs,
  onChangeLocationId,
  ...others
}: ComponentProps) {
  const allLocations: CamogramLocation[] = [
    ...existingLocations.map(location => ({
      ...location,
      color: configureLocationsColor({
        isSelected: selectedLocationId === location.id,
        isExisting: true,
      }),
    })),
    ...locations.map(location => ({
      ...location,
      color: configureLocationsColor({
        isSelected: selectedLocationId === location.id,
        isExisting: false,
        status: location.status,
      }),
      editable: selectedLocationId === location.id,
    })),
  ];

  if (selectedLocationId === undefined) return <div>No Location</div>;
  const selectedSkuId = locations.find(({ id }) => id === selectedLocationId)
    ?.skuId;
  return (
    <Flex direction="column">
      <CamogramLocations
        {...others}
        locations={allLocations}
        activeShelfs={activeShelfs}
      />
      {selectedSkuId && <ItemLabelContainer itemUuid={selectedSkuId} />}
      <LocationHorizontalScrollList
        locations={locations}
        selectedLocationUuId={selectedLocationId}
        onChangeLocationUuid={onChangeLocationId}
      />
    </Flex>
  );
}
export default function ReshapeLocations() {
  const dispatch = useDispatch();
  const locationsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const existingLocations = useAppSelector(
    state => state.taskBased.existingLocations
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);
  const onClick = (locationId: string) => {
    if (!locationsToReview.map(({ id }) => id).includes(locationId)) return;
    dispatch(setSelectedLocationId({ locationId }));
    dispatch(setActiveLocation(null));
  };

  const onChange = ({
    locationId,
    region,
  }: {
    locationId: string;
    region: Coordinate[];
  }) => {
    dispatch(
      updateLocation({
        locationId,
        data: { region, status: LocationReviewStatus.Reshaped },
      })
    );
  };

  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };

  const onMouseEnter = (event: any, locationId: string) => {
    if (locationId === selectedLocationId) return;
    const location =
      locationsToReview.find(({ id }) => locationId === id) ??
      existingLocations.find(({ id }) => locationId === id);
    if (location === undefined)
      throw new Error('Location is undefined onMouseEnter');
    dispatch(
      setActiveLocation({ ...location, x: event.clientX, y: event.clientY })
    );
  };
  const onMouseLeave = (event: any, locationId?: string) => {
    if (locationId) {
      dispatch(setActiveLocation(null));
    }
  };

  return (
    <DrawLocationProvider
      defaultCursorMode={CursorMode.Pointer}
      pointsLimit={4}
    >
      <ReshapeLocationsComponent
        angle={image.angle ?? 0}
        imageSrc={jobContext.imageUrl}
        locations={locationsToReview}
        existingLocations={existingLocations}
        selectedLocationId={selectedLocationId}
        activeShelfs={image.meta?.activeShelfs}
        onClick={onClick}
        onChange={onChange}
        onChangeLocationId={onChangeLocationId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <CamogramTooltip />
    </DrawLocationProvider>
  );
}
