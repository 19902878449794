import api from '.';

const postSkuReportApi = async ({ itemUuid, itemName, description, image }) => {
  const form = new FormData();
  form.append('item_uuid', itemUuid);
  form.append('item_name', itemName);
  form.append('problem_text', description);
  if (image) {
    form.append('image', image, 'screenshot.jpg');
  }
  const stores = await api.post(`sku_hunting`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return stores.data.results;
};

export default postSkuReportApi;
