import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layer, Stage as StageRK } from 'react-konva';
import Polygon from 'components/Editor/Polygon';
import {
  useDrawHandlers,
  useDrawLocationContext,
} from 'contexts/DrawLocationProvider';
import {
  Coordinate,
  CursorMode,
  Dimensions,
  HighresActiveRegion,
  HighresActiveSection,
  HighresCamera,
  Section,
  Zoom,
} from 'types';
import { ActiveRegionEditorProvider, useActiveRegionEditor } from './context';
import { calcPolygonArea, convertRegionToPoints } from 'utils';

const Stage = styled(StageRK)`
  position: absolute;
  left: 0;
  top: 0;
  cursor: ${({ draw }) => (draw ? 'crosshair' : 'auto')};
`;

type ItemLocationsLayerProps = ItemLocationsLayerContainerProps & {
  allPoints: number[][];
  imageSize: Dimensions;
  zoom: Zoom;
  handleMouseDown: (event: any) => void;
  handleMouseMove: (event: any) => void;
  setCursorMode: (cursorMode: CursorMode) => void;
};

const ItemLocationsLayer = ({
  imageSize,
  zoom,
  camera,
  activeRegion,
  allPoints,
  selectedSection,
  selectedActiveSection,
  handleMouseDown,
  handleMouseMove,
  setCursorMode,
}: ItemLocationsLayerProps) => {
  const shouldDraw =
    selectedSection && allPoints !== undefined && allPoints.length > 0;
  const draw = Boolean(selectedSection);

  const { width, height } = imageSize;
  const { angle } = camera;

  const locations = activeRegion.activeSections?.map(shelf => {
    const coordinates = convertRegionToPoints(
      shelf.coordinates,
      width,
      height,
      angle
    );
    const selected =
      shelf.camogramSectionId === selectedActiveSection?.camogramSectionId;
    const color = selected ? '#ff0000' : '#9be931';

    return { ...shelf, coordinates, selected, color };
  });

  useEffect(() => {
    if (selectedSection) {
      setCursorMode(CursorMode.Draw);
    }
  }, [selectedSection]);

  return (
    <>
      <Stage
        width={width}
        height={height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        draw={draw}
      >
        <ActiveRegionEditorProvider zoom={zoom} imageSize={imageSize}>
          <Layer>
            {shouldDraw && (
              // @ts-ignore
              <Polygon
                points={allPoints}
                closed
                stroke="#9be931"
                strokeWidth={2}
                fill="#9be93155"
              />
            )}
            {locations &&
              locations.map(location => (
                <React.Fragment key={location.camogramSectionId}>
                  {/* @ts-ignore */}
                  <Polygon
                    points={location.coordinates}
                    closed
                    stroke={location.color}
                    strokeWidth={2}
                    fill={`${location.color}55`}
                  />
                </React.Fragment>
              ))}
          </Layer>
        </ActiveRegionEditorProvider>
      </Stage>
    </>
  );
};

type ItemLocationsLayerContainerProps = {
  activeRegion: HighresActiveRegion;
  camera: HighresCamera;
  selectedSection?: Section;
  selectedActiveSection?: HighresActiveSection;
  onAddNewActiveSection: (activeRegion: HighresActiveSection) => void;
};
function ItemLocationsLayerContainer(props: ItemLocationsLayerContainerProps) {
  const { imageSize, zoom } = useActiveRegionEditor();

  if (!imageSize) return null;

  const { camera, onAddNewActiveSection, selectedSection } = props;
  const { allPoints, setCursorMode } = useDrawLocationContext();
  const { width, height } = imageSize ?? { width: 0, height: 0 };
  const { angle } = camera;
  const onAddRegion = (region: Coordinate[]) => {
    const area = calcPolygonArea(region);

    if (area === 0) {
      alert('The box has zero area. Could you please draw it again?');
      return;
    }

    if (!selectedSection) return;

    const newActiveSection = {
      camogramSectionId: selectedSection.camogramSectionId,
      coordinates: region,
    };

    onAddNewActiveSection(newActiveSection);
  };

  const { handleMouseDown, handleMouseMove } = useDrawHandlers({
    width,
    height,
    angle,
    onAddRegion,
  });

  return (
    <ItemLocationsLayer
      allPoints={allPoints}
      imageSize={imageSize}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      setCursorMode={setCursorMode}
      zoom={zoom}
      {...props}
    />
  );
}

export default memo(ItemLocationsLayerContainer);
