import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';

const useResize = (props = {}) => {
  const [size, setSize] = useState({ width: null, height: null });
  const { ref } = useResizeObserver({
    ...props,
    onResize: size => requestAnimationFrame(() => setSize(size)),
  });

  return { ...size, ref };
};

export default useResize;
