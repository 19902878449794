import _ from 'lodash';
import { fetchSkuImage, fetchSkus } from 'actions';
import fetchSkuImages from 'actions/fetchSkuImages';
import { groupByUniqueKey } from 'utils';
const skus = (state = {}, { type, payload, meta }) => {
  if (type === fetchSkus.fulfilled().type) {
    const byId = {};
    payload.skus.forEach(sku => {
      byId[sku.uuid] = {
        ..._.pick(sku, [
          'barcode',
          'category',
          'name',
          'price',
          'proxy',
          'retired_at',
          'uuid',
        ]),
        // Sometimes there's first whitespace
        display_name: sku.display_name.trim(),
      };
    });
    return { ...state, [meta.arg.storeId]: byId };
  }

  if (type === fetchSkuImage.fulfilled().type) {
    const uuid = meta.arg.skuUuid;
    const storeId = meta.arg.storeId;
    const currentStoreSKus = state[storeId] ?? {};
    const currentSku = currentStoreSKus?.[uuid] ?? {};

    return {
      ...state,
      [storeId]: {
        ...currentStoreSKus,
        [uuid]: { ...currentSku, imageUrl: payload.imageUrl },
      },
    };
  }

  if (type === fetchSkuImages.fulfilled().type) {
    const storeId = meta.arg.storeId;
    const currentStoreSkus = state[storeId] ?? {};
    const skusWithImageUrl = payload.imageUrls.map(({ imageUrl, skuUuid }) => {
      const currentSku = currentStoreSkus?.[skuUuid] ?? {};

      return { ...currentSku, imageUrl, uuid: skuUuid };
    });
    const skusGroupedByUuid = groupByUniqueKey(skusWithImageUrl, 'uuid');

    return {
      ...state,
      [storeId]: {
        ...currentStoreSkus,
        ...skusGroupedByUuid,
      },
    };
  }

  return state;
};

export default skus;
