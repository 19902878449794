import reducers from './reducers';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { track } from 'utils/segmentAnalytics';
import { configureEditorType } from 'utils';
import { configureStore } from '@reduxjs/toolkit';

const configureParams = () => {
  const path = window.location.pathname;
  const strings = path.split('/');
  const storeIdIndex = strings.findIndex(element => element === 'stores');

  if (storeIdIndex === -1) return {};

  // It's assuming /stores/{store_id}/{editor_type}/{timestamp}
  const storeId = strings[storeIdIndex + 1];
  const camogramTimestamp = strings[storeIdIndex + 3];

  const editorType = configureEditorType(path);

  return { storeId, editorType, camogramTimestamp };
};

const configureAnalyticsState = (action, state) => {
  const params = configureParams();
  const camNum = state[params.editorType]?.present.camNum;
  const env = process.env.NODE_ENV;
  return { camNum, env, ...params };
};

const configurePayload = payload => {
  const locationId = payload?.locationId;
  const itemName = payload?.itemName;
  const itemUuid = payload?.itemUuid;

  return { locationId, itemName, itemUuid };
};

const configureMetaArg = arg => {
  const { storeId, camNum, datetime } = arg;

  return { storeId, camNum, camogramTimestamp: datetime };
};

const shouldExclude = action => {
  if (action.type.startsWith('fetchSkuImage')) return true;
  if (action.type.endsWith('pending')) return true;

  return false;
};

const tracker = store => next => action => {
  if (shouldExclude(action)) return next(action);
  const state = configureAnalyticsState(action, store.getState);
  const payload = configurePayload(action.payload);
  const meta = action.meta?.arg ? configureMetaArg(action.meta.arg) : {};
  track(action.type, { ...payload, ...state, ...meta });
  const result = next(action);
  return result;
};
const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    process.env.NODE_ENV === 'development'
      ? [thunk]
      : getDefaultMiddleware().concat(tracker),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
