import styled from 'styled-components';

import { TaskType } from 'types/taskBased';
import TaskBasedEditorContentDataLoader from './TaskBasedEditorContentDataLoader';
import CompareLocations from './CompareLocations';
import { ImageFilterProvider } from 'components/Editor/Header/ImageFilterSliders';
import Flex from 'components/Flex';
import SelectLocalizedPreviousLocations from './SelectLocalizedPreviousLocations';
import SelectLocalizedProposedLocations from './SelectLocalizedProposedLocations';
import CreateLocations from './CreateLocations';
import ClassifyItems from './ClassifyItems';
import TaskCompleted from './TaskCompleted';
import JobCompleted from './JobCompleted';
import ReviewAnnotations from './ReviewAnnotations';
import RoutePreviousLocations from './RoutePreviousLocations';
import ReshapeLocations from './ReshapePreviousLocations';

const Container = styled(Flex)`
  flex-direction: column;
`;

export enum TaskCompletionState {
  AllJobsDone = 'AllJobsDone',
  TaskDone = 'TaskDone',
}

export type TaskContentState = TaskType | TaskCompletionState;

type ContentSwitcherProps = {
  taskContentState: TaskContentState;
};

export function ContentSwitcher({ taskContentState }: ContentSwitcherProps) {
  switch (taskContentState) {
    case TaskType.SelectUnchangedPreviousLocations:
      return <CompareLocations />;
    case TaskType.SelectLocalizedPreviousLocations:
      return <SelectLocalizedPreviousLocations />;
    case TaskType.RoutePreviousLocations:
      return <RoutePreviousLocations />;
    case TaskType.ReshapePreviousLocations:
      return <ReshapeLocations />;
    case TaskType.SelectLocalizedProposedLocations:
      return <SelectLocalizedProposedLocations />;
    case TaskType.CreateLocations:
      return <CreateLocations />;
    case TaskType.ClassifyLocations:
      return <ClassifyItems />;
    case TaskType.ReviewAnnotations:
      return <ReviewAnnotations />;
    case TaskCompletionState.AllJobsDone:
      return <JobCompleted />;
    case TaskCompletionState.TaskDone:
      return <TaskCompleted />;
    default:
      throw new Error(
        `the taskContentState (${taskContentState}) is not supported in ContentSwitcher`
      );
  }
}

const ContentContainer: React.FC = ({ children }) => {
  return (
    <TaskBasedEditorContentDataLoader>
      <Container>
        <ImageFilterProvider>{children}</ImageFilterProvider>
      </Container>
    </TaskBasedEditorContentDataLoader>
  );
};

export default ContentContainer;
