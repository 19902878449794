import React, { useContext, useState } from 'react';
import { ZoomContext } from './Camogram';

const uvToPoint = ({ u, v }, imageWidth, imageHeight) => {
  return [u * imageWidth, v * imageHeight];
};

/**
 * Display a region as an SVG element. Depending on data passed in, show as a
 * circle, square, or polygon.
 * @param {object} props
 */
export default function PolygonOrPoint({
  region,
  imageWidth,
  imageHeight,
  stroke = 'lime',
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) {
  const { scale } = useContext(ZoomContext);
  const [hover, setHover] = useState(false);

  const mapFunc = uv => uvToPoint(uv, imageWidth, imageHeight);

  const points = region.map(mapFunc);

  const onInternalMouseEnter = e => {
    onMouseEnter(e);
    setHover(true);
  };

  const onInternalMouseLeave = e => {
    onMouseLeave(e);
    setHover(false);
  };

  const strokeWidth = hover ? 2 / Math.sqrt(scale) : 1 / Math.sqrt(scale);

  if (!points.length) {
    return null;
  }

  if (points.length === 1) {
    // This looks like dead code...
    const pointSize = 1;
    // return a dot
    const [x, y] = points[0];
    return (
      <rect
        x={x - pointSize / 2}
        y={y - pointSize / 2}
        width={pointSize}
        height={pointSize}
        onClick={onClick}
        onMouseEnter={onInternalMouseEnter}
        onMouseLeave={onInternalMouseLeave}
      />
    );
  }

  return (
    <polygon
      fill="transparent"
      stroke={stroke}
      points={points}
      scale={scale}
      strokeWidth={`${strokeWidth}px`}
      onClick={onClick}
      onContextMenu={onClick}
      onMouseEnter={onInternalMouseEnter}
      onMouseLeave={onInternalMouseLeave}
    />
  );
}
