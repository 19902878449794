import { useLocation } from 'react-router-dom';
import { configureEditorType } from 'utils';

const useEditorType = () => {
  const { pathname } = useLocation();
  const editorType = configureEditorType(pathname);
  return editorType;
};

export default useEditorType;
