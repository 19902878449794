import React from 'react';

import styled from 'styled-components';

const LoaderContainerFull = styled.div`
  width: 500px;
  margin: 40px auto;
  font-size: 30px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 0 10px 20px;
  background: #ffffff;

  > img {
    width: 50%;
  }
`;

const LoaderContainer = styled.div`
  width: 200px;
  margin: 10px auto;
  font-size: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px 10px;
  background: #ffffff;

  > img {
    width: 100%;
  }
`;

type LoaderProps = {
  loading?: boolean;
  text?: string;
  full?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  loading = false,
  text = 'Loading...',
  children,
  full = false,
}) => {
  if (loading) {
    const Wrapper = full ? LoaderContainerFull : LoaderContainer;

    return (
      <Wrapper>
        <img src="/loader-logo.svg" alt="" />
        <div>{text}</div>
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default Loader;
