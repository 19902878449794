import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { initVisible, showLayer, hideLayer } from 'actions';

const Box = styled.div`
  position: relative;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: ${p => (p.dim ? 0.3 : 1)};
  text-decoration: ${p => (p.dim ? 'line-through' : 'none')};
  user-select: none;
  &:hover {
    background: #ccc;
  }

  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 6px;
    border-radius: 3px;
    position: absolute;
    background: ${p => p.color || ''};
    top: 75%;
    margin-left: -10px;
    left: 50%;
  }
`;

const SquareBox = styled(Box)`
  padding: 0 8px;
`;

export const SquareToggle = ({ id, show = true, color, children }) => {
  const [visible, setVisible] = useLayerVisibility(id, show);
  const onClick = e => setVisible(!visible);
  return (
    <SquareBox dim={!visible} onClick={onClick} color={color}>
      {children}
    </SquareBox>
  );
};

export const useLayerVisibility = (id, def) => {
  const dispatch = useDispatch();
  const visibleState = useSelector(state => state.layers[id]);
  const visible = typeof visibleState === 'undefined' ? def : visibleState;

  // Put the value in Redux so that we only define the layer in one place:
  useEffect(() => {
    // Only if it was not defined previously
    if (typeof visibleState === 'undefined' && typeof def !== 'undefined') {
      dispatch(initVisible(id, def));
    }
  }, [id, visibleState, def, dispatch]);

  const setVisible = useCallback(
    show => (show ? dispatch(showLayer(id)) : dispatch(hideLayer(id))),
    [id, dispatch]
  );

  return [visible, setVisible];
};

export default function Layer({ id, component: Comp, render, children }) {
  const [visible] = useLayerVisibility(id);
  if (visible === false) return null;
  if (Comp) return <Comp />;
  if (render) return render();
  return children;
}
