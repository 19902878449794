import api from '..';
import { addNumericCamIdParam } from '../helpers';
import { configureJobForAnnotation } from './helpers';

type RegressJobApiProps = {
  jobId: string;
  taskName: string;
};

const regressJobApi = async ({ jobId, taskName }: RegressJobApiProps) => {
  const res = await api.post(
    addNumericCamIdParam('task-based/generic/regress-job'),
    {
      job_id: jobId,
      task_name: taskName,
      return_job_for_annotation: true,
    }
  );
  const job = configureJobForAnnotation([res.data.data])[0];
  return { job };
};

export default regressJobApi;
