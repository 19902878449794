import api from '.';

const fetchImageOptionsApi = async (storeId, camNum, datetime) => {
  const res = await api.get(
    `/stores/${storeId}/camera/${camNum}/camograms/${datetime}/images`
  );

  return res.data.results.map(cameraImage => ({
    src: cameraImage.image,
    imageSource: cameraImage.image_source,
  }));
};

export default fetchImageOptionsApi;
