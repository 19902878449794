import useResize from 'hooks/useResize';
import React, { createContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { uniq } from 'lodash';

import { setSelectedExtremeZoomShelf } from 'actions';
import Warning from 'components/Warning';
import ExtremeZoomImage from './ExtremeZoomImage';

const ExtremeZoomImagesContainer = styled.div`
  background-color: #eee;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 100px;
  z-index: 100;

  > * {
    margin-bottom: 1.2rem;
  }
`;

const ShelfListContainer = styled.div`
  padding: 5px 10px;
`;

export const ContainerWidthContext = createContext(0);
const ExtremeZoomImagesList = ({ children }) => {
  const { ref, width } = useResize();

  return (
    <ContainerWidthContext.Provider value={width}>
      <ExtremeZoomImagesContainer ref={ref}>
        {width && children}
      </ExtremeZoomImagesContainer>
    </ContainerWidthContext.Provider>
  );
};

const ExtremeZoomImagesComponent = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const extremeZoomImages = useSelector(state =>
    state.extremeZoom.extremeZoomImages.images.filter(
      extremeZoomImage => extremeZoomImage.storeId === storeId
    )
  );
  const selectedShelf = useSelector(
    state => state.extremeZoom.extremeZoomImages.selectedShelf
  );
  const shelfList = uniq(
    extremeZoomImages.map(
      extremeZoomImage =>
        `${extremeZoomImage.shelfId}_section_${extremeZoomImage.sectionId}`
    )
  );

  useEffect(() => {
    // Set selected shelf to first shelf if not set
    if (shelfList.length > 0 && !selectedShelf) {
      dispatch(setSelectedExtremeZoomShelf({ selectedShelf: shelfList[0] }));
    }
  }, [selectedShelf, shelfList, dispatch]);

  const getSelectedExtremeZoomImages = () => {
    if (!selectedShelf) {
      return [];
    }

    const [shelfId, sectionId] = selectedShelf.split('_section_');
    return extremeZoomImages.filter(
      extremeZoomImage =>
        extremeZoomImage.shelfId === shelfId &&
        extremeZoomImage.sectionId === sectionId
    );
  };

  const selectedExtremeZoomImages = getSelectedExtremeZoomImages();

  return (
    <>
      <ShelfListContainer>
        <label>
          <span>Shelf and Section:</span>
          <select
            onChange={event =>
              dispatch(
                setSelectedExtremeZoomShelf({
                  selectedShelf: event.target.value,
                })
              )
            }
            value={selectedShelf}
          >
            {shelfList.map(shelfKey => (
              <option key={shelfKey}>{shelfKey}</option>
            ))}
          </select>
        </label>
      </ShelfListContainer>
      <ExtremeZoomImagesList>
        {selectedExtremeZoomImages.length === 0 && (
          <Warning message="No Extreme Zoom Images" />
        )}
        {selectedExtremeZoomImages.map((extremeZoomImage, index) => (
          <ExtremeZoomImage
            extremeZoomImage={extremeZoomImage}
            key={index}
            itemNumber={(index + 1).toString()}
          />
        ))}
      </ExtremeZoomImagesList>
    </>
  );
};

export default ExtremeZoomImagesComponent;
