import { CameraState, EditorDataSource, ProviderKey } from 'types';
import { convertToCamelCaseKeys } from 'utils';
import api from '.';

const configureResponse = (responseItem: any): CameraState => {
  return convertToCamelCaseKeys(responseItem);
};

export const fetchCameraStatesApi = async () => {
  const response = await api.get('/camogram_queue/list_provider_states');
  const result = response.data.results.map(configureResponse) as CameraState[];

  return result;
};
