import api from '.';
import { addNumericCamIdParam } from './helpers';

// From { "cam_0": { image: "image's url", image_source: "image's key" }, "cam_1": { image: "image's url", image_source: "image's key" } }
// To { "0": { src: "image's url" imageSource: 'image's key' }, "1": { src: "image's url", imageSource: 'image's key' } }
const configureCameraImages = images => {
  if (!images) return {};
  const byCamera = {};
  Object.entries(images).forEach(([key, value]) => {
    const camNum = key.split('_')[1];
    byCamera[camNum] = {
      src: value.image,
      imageSource: value.image_source,
      status: value.status,
      unknown: value.unknown,
    };
  });
  return byCamera;
};

const fetchCameraImagesApi = async (storeId, datetime) => {
  const res = await api.get(
    addNumericCamIdParam(`/stores/${storeId}/camograms/${datetime}/images`)
  );
  return configureCameraImages(res.data);
};

export default fetchCameraImagesApi;
