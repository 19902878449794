import React from 'react';
import styled from 'styled-components';

const ToolbarContainer = styled.div`
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  background-color: #333;
  align-items: center;
`;

const Action = styled.div`
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #333;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease;

  &:hover {
    background: #fff;
    color: #000;
    transform: scale(1.05);
  }
`;

const Panel = ({ toolBar, onClose, children }) => {
  return (
    <div>
      <ToolbarContainer>
        {toolBar}
        {onClose && <Action onClick={onClose}>×</Action>}
      </ToolbarContainer>
      {children}
    </div>
  );
};

export default Panel;
