import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import styled from 'styled-components';
import moment from 'moment';

import DeleteTaskBasedCamogramButton from './DeleteTaskBasedCamogramButton';
import { formatDatetime } from 'utils/formatDatetime';

const Row = styled.tr`
  background: #fff;
  margin: 0;
  line-height: 1.5;
  border-bottom: solid 1px #cdcdcd;

  &:hover {
    background: #eee;
  }
`;

const Cell = styled.td`
  padding: 8px 12px;
  text-align: left;
  align-items: center;
  word-wrap: break-word;
  max-width: 400px;

  span {
    vertical-align: middle;
  }

  @media (max-width: 800px) {
    display: block;
    text-align: left;
    padding: 4px 8px;
  }
`;

const CellButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

type Props = {
  camogram: any;
  fetchCamograms: () => void;
  cameras: string[];
  buttonTitle: string;
  onClickContinueButton: () => void;
};

const TaskBasedCamogramItem = ({
  camogram,
  fetchCamograms,
  cameras,
  buttonTitle,
  onClickContinueButton,
}: Props) => {
  const { created_at, groupId, started_by } = camogram;

  return (
    <Row>
      <Cell>{formatDatetime(created_at)}</Cell>
      <Cell>{groupId}</Cell>
      <Cell>{started_by}</Cell>
      <Cell>{cameras.join(',')}</Cell>
      <Cell>
        <CellButtonsContainer>
          <>
            <DeleteTaskBasedCamogramButton
              groupId={groupId}
              fetchJobs={fetchCamograms}
            />
            <Button
              onClick={onClickContinueButton}
              variant="contained"
              size="small"
              color="primary"
            >
              <NavigateNext style={{ paddingRight: '6px' }} /> {buttonTitle}
            </Button>
          </>
        </CellButtonsContainer>
      </Cell>
    </Row>
  );
};

export default TaskBasedCamogramItem;
