import fuzzysort from 'fuzzysort';
import _ from 'lodash';

const filterSkus = (skus, { proxy, category }) => {
  if (proxy) {
    skus = skus.filter(sku => sku.proxy);
  }
  if (category) {
    skus = skus.filter(sku => sku.category === category);
  }

  return skus;
};

export const fuzzySearchSkus = (skus, searchText) => {
  if (!searchText) return skus;
  const result = fuzzysort.go(searchText, skus, {
    keys: ['display_name', 'name', 'barcode'],
    limit: 40,
    threshold: -100000,
    // Scoring function when multiple keys are used. Return max score of all keys.
    // Score exact substring matches higher than fuzzy matches.
    scoreFn: (keysResult): number => {
      let max = Number.MIN_SAFE_INTEGER;

      for (let i = 0; i < keysResult.length; ++i) {
        const result = keysResult[i];

        if (result === null) {
          continue;
        }

        let { score } = result;

        if (result.target.toLowerCase().includes(searchText.toLowerCase())) {
          score = Math.round(score / 200); // Increase score by factor of 200 for exact substring matches (Perfect score is 0)
        }

        if (score > max) {
          max = score;
        }
      }

      return max;
    },
  });

  return result.map(search => search.obj);
};

export const selectSkus = ({ searchText, filter, storeId }) => state => {
  const allSkus = state.skus[storeId]
    ? Object.values<any>(state.skus[storeId]).sort((a, b) =>
        (a.display_name ?? '').localeCompare(b.display_name)
      )
    : [];

  const filtered = filterSkus(allSkus, filter);
  return fuzzySearchSkus(filtered, searchText);
};

export const selectSkuIds = ({ searchText, filter, storeId }) => state => {
  const allSkus = state.skus[storeId]
    ? Object.values<any>(state.skus[storeId]).sort((a, b) =>
        (a.display_name ?? '').localeCompare(b.display_name)
      )
    : [];

  const filtered = filterSkus(allSkus, filter);
  const searched = fuzzySearchSkus(filtered, searchText);
  return searched.map(({ uuid }) => uuid);
};

export const selectCategories = ({ storeId }) => state =>
  _.uniq(
    Object.values<any>(state.skus[storeId])
      .map(sku => sku.category)
      .filter(Boolean)
  ).sort();

export const selectSelectedSku = ({ storeId, skuId }) => state => {
  if (!skuId) return null;
  const allSkus = state.skus[storeId]
    ? Object.values<any>(state.skus[storeId])
    : [];
  return allSkus.find(sku => skuId === sku.uuid);
};
