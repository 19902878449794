// Cursor Mode
export const POINTER = 'POINTER';
export const DRAW = 'DRAW';
export const DRAWING = 'DRAWING';

// Camogram Status
export const CAMOGRAM_STATUS_APPROVED = 'APPROVED';
export const CAMOGRAM_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const CAMOGRAM_STATUS_STARTED = 'STARTED';

export const DEFAULT_CAMERA_IMAGE_ASPECT_RATIO = 9 / 16;

export const ANNOTATION_TYPE_OMNI = '360';
export const ANNOTATION_TYPE_ORIGINAL = 'ORIGINAL';
export const ANNOTATION_TYPE_HIGH_RES = 'STATIC_HIGH_RES';
export const ANNOTATION_TYPE_TASK_BASED = 'ANNOTATION_TYPE_TASK_BASED';

// Task Based Camogram
export const EVENT_TASK_BASED_LANDING_TASK = 'EVENT_TASK_BASED_LANDING_TASK';
export const EVENT_TASK_BASED_START_CAMOGRAM =
  'EVENT_TASK_BASED_START_CAMOGRAM';
export const EVENT_TASK_BASED_COMPLETE_TASK = 'EVENT_TASK_BASED_COMPLETE_TASK';
export const EVENT_TASK_BASED_REGRESS_TASK = 'EVENT_TASK_BASED_REGRESS_TASK';
export const EVENT_TASK_BASED_REVIEW_LOCATION =
  'EVENT_TASK_BASED_REVIEW_LOCATION';
export const EVENT_TASK_BASED_UPDATE_CAMERA = 'EVENT_TASK_BASED_UPDATE_CAMERA';
export const EVENT_TASK_BASED_ADD_LOCATION = 'EVENT_TASK_BASED_ADD_LOCATION';
export const EVENT_TASK_BASED_UPDATE_LOCATION =
  'EVENT_TASK_BASED_UPDATE_LOCATION';
export const EVENT_TASK_BASED_DELETE_LOCATION =
  'EVENT_TASK_BASED_DELETE_LOCATION';
export const EVENT_TASK_BASED_SAVE = 'EVENT_TASK_BASED_SAVE';
export const EVENT_TASK_BASED_DISCARD = 'EVENT_TASK_BASED_DISCARD';
export const EVENT_TASK_BASED_UNLOCK_GROUP_JOBS =
  'EVENT_TASK_BASED_UNLOCK_GROUP_JOBS';
export const EVENT_TASK_BASED_CLASSIFY_ITEM = 'EVENT_TASK_BASED_CLASSIFY_ITEM';
export const EVENT_REPORT_SKU = 'EVENT_REPORT_SKU';
export const EVENT_START_NEXT_TASK = 'EVENT_START_NEXT_TASK';
export const EVENT_START_NEXT_QC_TASK = 'EVENT_START_NEXT_QC_TASK';

// Check-in
export const EVENT_CHECK_IN = 'EVENT_CHECK_IN';
export const EVENT_CHECK_OUT = 'EVENT_CHECK_OUT';

export const BOX_COLORS = {
  unknown: 'yellow',
  retired: 'orange',
  unsaved: '#FF00FF',
  current: '#afa',
  lowAccuracy: 'blue',
  yesterday: 'white',
  activeShelf: '#FF713355',
  flaggedForRedrawing: '#7700ff',
};

export const STORE_NAME_MAPPINGS = {
  cirk: 'Circle K',
  cmps: 'Compass',
  rdsx: 'Red Sox',
  scmt: 'Seicomart',
  stcg: 'Standard',
};

export const COLORS = {
  approved: '#46975d',
  rejected: '#f50057',
  unknown: '#FFCC00',
  selected: '#3a86ff',
  init: '#ffffff',
  reclassify: '#FFCC00',
  reshape: '#d8776b',
  flags: {
    isOccluded: '#863893',
    isGroup: '#2a9d8f',
    isUncertain: '#e9c46a',
    isEmpty: '#f4a261',
  },
};

export const LOCATION_ACCURACY_THRESHOLD = 0.7;
export const WORKFLOW_VERSION = 2;
export const WORKFLOW_NAME = 'camogram';
export const UNKNOWN_SKU_BARCODE = '999999999999';
export const HELP_PAGE_URL =
  'https://standardai.atlassian.net/servicedesk/customer/portal/12/article/345735640';
