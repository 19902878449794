import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { MapInteractionCSS } from 'react-map-interaction';
import moment from 'moment';
import { Button, Dialog } from '@material-ui/core';
import { DeleteForever, Save, Error as ErrorIcon } from '@material-ui/icons';

import StoreLayout from 'components/StoreLayout';
import AdminLayout from 'layouts/AdminLayout';
import fetchLayoutApi from 'utils/api/fetchStoreLayoutApi';
import useAsyncCall from 'hooks/useAsyncCall';
import {
  HighresCamera,
  HighresActiveRegion,
  Layout,
  Section,
  CameraPosition,
} from 'types';
import Loader from 'components/Loader';
import { fetchCamerasApi, fetchHighResCameraImagesApi } from 'utils/api';
import fetchHighresActiveRegionsApi from 'utils/api/fetchHighresActiveRegionsApi';
import ActiveRegionEditor from './ActiveRegionEditor';
import { HighresCameraSettingsProvider } from './context';
import postHighresActiveRegionsApi from 'utils/api/postHighresActiveRegionsApi';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: stretch;
`;

const LayoutContainer = styled.div`
  max-width: 100%;
  height: 100vh;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 8px;

  > * {
    margin-right: 8px;
  }
`;

const ErrorMessageContainer = styled.div`
  height: auto;
  padding: 32px;
  text-align: center;
  max-width: 400px;
  margin: auto;
`;

const configure4kCameras = (cameras: any, images: any) => {
  const { cameraExtrinsics, deviceSettings } = cameras;

  const highresCameraKeys = Object.keys(cameraExtrinsics).filter(camNum => {
    const settings = deviceSettings.find(
      (setting: any) => String(setting.cam) === String(camNum)
    );

    return settings !== undefined && settings.function === 'camogram';
  });

  const highresCamerasObject = _.pick(cameraExtrinsics, highresCameraKeys);

  const highresCameras = Object.entries(highresCamerasObject).map(
    ([key, value]) => ({
      id: key,
      ...value,
    })
  );

  const withImage = highresCameras.map(camera => {
    const setting = deviceSettings.find(
      (setting: any) => String(setting.cam) === String(camera.id)
    );

    const image = images[String(setting.cam)];

    return { ...camera, ...image };
  });

  return withImage;
};

const fetchInitialData = async (storeId: string) => {
  const datetime = moment().toISOString();
  const [layout, cameras, images, activeRegions] = await Promise.all([
    fetchLayoutApi({ storeId }),
    fetchCamerasApi(storeId),
    fetchHighResCameraImagesApi(storeId, datetime),
    fetchHighresActiveRegionsApi(storeId),
  ]);

  return {
    layout,
    cameras: configure4kCameras(cameras, images),
    activeRegions,
  };
};

const configureCameras = (
  cameras: CameraPosition[],
  activeRegions: HighresActiveRegion[]
) => {
  const withColor = cameras.map(camera => {
    const isSetup = activeRegions.some(
      region =>
        region.camNum === camera.id &&
        region.activeSections &&
        region.activeSections.length > 0
    );
    const color = isSetup ? '#06d6a0' : undefined;

    return { ...camera, color };
  });

  return withColor;
};

const configureShelfIdAndSectionId = (camogramSectionId: string) => {
  const items = camogramSectionId.split('_');
  const length = items.length;

  return [Number(items[length - 3]), Number(items[length - 1])];
};

const isEqualCamogramSectionIds = (sectionIdA: string, sectionIdB: string) => {
  if (sectionIdA === sectionIdB) return true;

  // The legacy ID is storeID_shelf_#_section_#
  // The new ID is shelf_#_section_#
  const [shelfNumA, sectionNumA] = configureShelfIdAndSectionId(sectionIdA);
  const [shelfNumB, sectionNumB] = configureShelfIdAndSectionId(sectionIdB);

  if (shelfNumA === shelfNumB && sectionNumA === sectionNumB) return true;

  return false;
};

const configureSections = (
  sections: Section[],
  activeRegions: HighresActiveRegion[]
) => {
  const withColor = sections.map(section => {
    const isSetup = activeRegions.some(region => {
      return region.activeSections?.some(activeSection =>
        isEqualCamogramSectionIds(
          activeSection.camogramSectionId,
          section.camogramSectionId
        )
      );
    });

    const color = isSetup ? 'green' : undefined;

    return { ...section, color };
  });

  return withColor;
};

const configureLayout = (
  layout: Layout,
  activeRegions: HighresActiveRegion[]
): Layout => {
  return {
    ...layout,
    sections: configureSections(layout.sections, activeRegions),
  };
};

export default function Admin4kCameraSettingsPage() {
  const { storeId } = useParams<{ storeId: string }>();
  const [selectedCameraId, setSelectedCameraId] = useState<string>();
  const [editing, setEditing] = useState(false);

  const { data, isLoading, call, setData } = useAsyncCall<{
    layout: Layout;
    cameras: HighresCamera[];
    activeRegions: HighresActiveRegion[];
  }>();

  const camera = data?.cameras.find(({ id }) => id === selectedCameraId);
  const activeRegion = data?.activeRegions.find(
    ({ camNum }) => camNum === selectedCameraId
  );
  const configuredLayout = data
    ? configureLayout(data.layout, data.activeRegions)
    : null;
  const sections = configuredLayout?.sections;

  const configuredCameras = data
    ? configureCameras(data.cameras, data.activeRegions)
    : null;

  const shouldDisplayLoader =
    !data || isLoading || !configuredLayout || !configuredCameras;

  const setActiveRegions: React.Dispatch<
    React.SetStateAction<HighresActiveRegion[]>
  > = prop => {
    if (!setData) throw new Error('setData is undefined');
    if (typeof prop === 'function') {
      setData(prev => {
        if (!prev) throw new Error('No prev data');
        return {
          ...prev,
          activeRegions: prop(prev.activeRegions),
        };
      });
    } else {
      setData(prev => {
        if (!prev) throw new Error('No prev data');
        return {
          ...prev,
          activeRegions: prop,
        };
      });
    }
  };

  const onClickCamera = (cameraId: string, e: any) => [
    setSelectedCameraId(cameraId),
  ];

  const onCloseDialog = () => {
    setSelectedCameraId(undefined);
  };

  const onOk = async (newActiveRegion: HighresActiveRegion) => {
    if (!data) return;
    const others = data.activeRegions.filter(
      ({ camNum }) => camNum !== selectedCameraId
    );

    setActiveRegions([...others, newActiveRegion]);
    setEditing(true);
    setSelectedCameraId(undefined);
  };

  const onCancel = () => {
    setSelectedCameraId(undefined);
  };

  const onSave = async () => {
    if (!data) return;
    const activeRegions = data.activeRegions;
    await toast.promise(
      postHighresActiveRegionsApi({ storeId, activeRegions }),
      {
        pending: 'Saving...',
        success: 'Active regions saved!',
        error: 'Could not save, please try again.',
      }
    );
    setEditing(false);
  };

  const onDiscard = async () => {
    await toast.promise(call(fetchInitialData(storeId)), {
      pending: 'Discarding...',
      success: 'Discarded!',
      error: 'Could not discard, please try again.',
    });
    setEditing(false);
  };

  useEffect(() => {
    call(fetchInitialData(storeId));
  }, [call, storeId]);

  return (
    <AdminLayout>
      {shouldDisplayLoader ? (
        <Loader text="Loading Layout" loading />
      ) : (
        <HighresCameraSettingsProvider
          cameras={data.cameras}
          activeRegions={data.activeRegions}
          layout={data.layout}
        >
          {data.activeRegions.length > 0 ? (
            <ContentContainer>
              <ButtonsContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                  disabled={!editing}
                  style={{ minWidth: '20px', padding: '6px 12px' }}
                  title="Save"
                  size="small"
                >
                  <Save aria-label="Save" />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!editing}
                  onClick={onDiscard}
                  style={{ minWidth: '20px', padding: '6px 12px' }}
                  title="Discard"
                >
                  <DeleteForever aria-label="Discard" />
                </Button>
              </ButtonsContainer>
              <MapInteractionCSS>
                <LayoutContainer>
                  <StoreLayout
                    layout={configuredLayout}
                    cameras={configuredCameras}
                    onCameraClick={onClickCamera}
                    selectedCameraId={selectedCameraId}
                  />
                </LayoutContainer>
              </MapInteractionCSS>
            </ContentContainer>
          ) : (
            <ErrorMessageContainer>
              <ErrorIcon color="secondary" />
              <div>
                The endpoint `/static_4k_active_regions` returned no
                `action_regions`. Please contact the camogram backend service
                owner in #team-camogram.
              </div>
            </ErrorMessageContainer>
          )}
          <Dialog
            fullWidth
            maxWidth="xl"
            open={Boolean(selectedCameraId)}
            onClose={onCloseDialog}
          >
            {camera && activeRegion && sections ? (
              <ActiveRegionEditor
                initialActiveRegion={activeRegion}
                camera={camera}
                sections={sections}
                onOk={onOk}
                onCancel={onCancel}
              />
            ) : (
              <ErrorMessageContainer>
                <ErrorIcon color="secondary" />
                <div>
                  There is no active regions data for this camera (all cameras
                  should have a default value). Please contact the camogram
                  backend service owner in #team-camogram.
                </div>
              </ErrorMessageContainer>
            )}
          </Dialog>
        </HighresCameraSettingsProvider>
      )}
    </AdminLayout>
  );
}
