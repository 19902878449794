import _ from 'lodash';
import { PtzConfig } from 'types';
import api from '.';

type PostPtzConfigApiRequest = {
  storeId: string;
  ptzConfig: PtzConfig;
};

const configureBody = (ptzConfig: PtzConfig) => {
  const configuredPtzList = _.pick(ptzConfig, [
    'shelf_id',
    'camogram_section_id',
    'cam_id',
    'store_layout_version_num',
    'pan_value',
    'tilt_value',
    'zoom_value',
    'camera_access_base_url',
    'last_updated_by',
  ]);

  // Trim last_updated_by to 20 characters
  configuredPtzList.last_updated_by = configuredPtzList?.last_updated_by?.substring(
    0,
    20
  );

  return {
    ptz_config_list: [configuredPtzList],
  };
};

export default async function postPtzConfigApi({
  storeId,
  ptzConfig,
}: PostPtzConfigApiRequest) {
  const res = await api.post(
    `/admin/stores/${storeId}/ptz_config`,
    configureBody(ptzConfig)
  );

  return res.data;
}
