import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Header = ({ storeId, datetime }) => {
  const store = useSelector(state => state.stores[storeId]);

  return (
    <HeaderContainer>
      {`${store?.name} (${storeId}) / ${moment(datetime).format('lll')}`}
    </HeaderContainer>
  );
};

export default Header;
