import { Button } from '@material-ui/core';
import CamogramLocations from 'components/CamogramLocations';
import Flex from 'components/Flex';
import { COLORS } from 'common/constants';
import { useAppSelector } from 'hooks';
import { useCameraImage } from 'pages/TaskBasedEditor/helpers';
import { useDispatch } from 'react-redux';
import { reviewLocation, setSelectedLocationId } from 'slices/taskBasedSlice';
import styled from 'styled-components';
import { LocationReviewStatus, LocationToReview } from 'types/taskBased';
import { useJob } from '../helpers';
import LocationHorizontalScrollList from '../LocationHorizontalScrollList';
import { HighresActiveSection, Location } from 'types';
import CamogramTooltip from 'components/CamogramTooltip';
import { setActiveLocation } from 'actions';
import useShortcut from 'hooks/useShortcut';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 8px;
  }
`;

const configureColor = (location: LocationToReview, selected: boolean) => {
  if (selected) {
    return COLORS.selected;
  } else if (location.status === LocationReviewStatus.Init) {
    return COLORS.init;
  } else if (location.status === LocationReviewStatus.UseLocation) {
    return COLORS.approved;
  } else if (location.status === LocationReviewStatus.UnuseLocation) {
    return COLORS.rejected;
  } else {
    const errorMessage = `This status must not happen in configureColor in SelectLocalizedPreviousLocation. location: ${JSON.stringify(
      location
    )}, selected: ${selected}`;
    throw new Error(errorMessage);
  }
};

type SelectLocalizedPreviousLocationsComponentProps = {
  locations: LocationToReview[];
  existingLocations: Location[];
  selectedLocationId?: string;
  imageSrc: string;
  imageAngle: number;
  activeShelfs?: HighresActiveSection[];
  onChangeLocationId: (locationId: string) => void;
  onClickUse: (locationId: string) => void;
  onClickUnUse: (locationId: string) => void;
  onMouseEnter: (e: any, locationId: string) => void;
  onMouseLeave: (e: any, locationId?: string) => void;
};
function SelectLocalizedPreviousLocationsComponent({
  locations,
  existingLocations,
  selectedLocationId,
  imageSrc,
  imageAngle,
  activeShelfs,
  onChangeLocationId,
  onClickUse,
  onClickUnUse,
  onMouseEnter,
  onMouseLeave,
}: SelectLocalizedPreviousLocationsComponentProps) {
  const selectedLocation = locations.find(
    ({ id }) => id === selectedLocationId
  );

  useShortcut('a', () => {
    if (selectedLocationId === undefined) return;
    onClickUnUse(selectedLocationId);
  });

  useShortcut('s', () => {
    if (selectedLocationId === undefined) return;
    onClickUse(selectedLocationId);
  });

  if (selectedLocationId === undefined || !selectedLocation)
    return <div>No Location</div>;
  const locationsToReview = locations.map(location => ({
    ...location,
    color: configureColor(location, location.id === selectedLocationId),
  }));
  const existingLocationsWithColor = existingLocations.map(location => ({
    ...location,
    color: COLORS.approved,
  }));
  const camogramLocations = existingLocationsWithColor.concat(
    locationsToReview
  );
  const onClickLocation = (locationId: string) => {
    const isExisting = existingLocations
      .map(({ id }) => id)
      .includes(locationId);
    if (isExisting) return;
    onChangeLocationId(locationId);
  };

  return (
    <Flex padding={16} direction="column">
      <CamogramLocations
        imageSrc={imageSrc}
        angle={imageAngle}
        locations={camogramLocations}
        activeShelfs={activeShelfs}
        onClick={onClickLocation}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <LocationHorizontalScrollList
        locations={locations}
        selectedLocationUuId={selectedLocationId}
        onChangeLocationUuid={onChangeLocationId}
      />
      <ButtonsContainer>
        <Button
          variant="contained"
          style={{ backgroundColor: COLORS.rejected, color: 'white' }}
          size="large"
          onClick={() => onClickUnUse(selectedLocationId)}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: COLORS.approved, color: 'white' }}
          size="large"
          onClick={() => onClickUse(selectedLocationId)}
        >
          Use
        </Button>
      </ButtonsContainer>
    </Flex>
  );
}

export default function SelectLocalizedPreviousLocations() {
  const dispatch = useDispatch();
  const locationsToReview = useAppSelector(
    state => state.taskBased.locationsToReview
  );
  const existingLocations = useAppSelector(
    state => state.taskBased.existingLocations
  );
  const selectedLocationId = useAppSelector(
    state => state.taskBased.selectedLocationId
  );

  const jobContext = useJob(job => job.context);
  const image = useCameraImage(image => image);
  const onClickUse = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.UseLocation },
      })
    );
  };
  const onClickUnUse = (locationId: string) => {
    dispatch(
      reviewLocation({
        locationUuid: locationId,
        data: { status: LocationReviewStatus.UnuseLocation },
      })
    );
  };

  const onChangeLocationId = (locationId: string) => {
    dispatch(setSelectedLocationId({ locationId }));
  };
  const onMouseEnter = (event: any, locationId: string) => {
    const location = [...existingLocations, ...locationsToReview].find(
      ({ id }) => locationId === id
    );
    if (location === undefined)
      throw new Error('Location is undefined onMouseEnter');
    dispatch(
      setActiveLocation({ ...location, x: event.clientX, y: event.clientY })
    );
  };
  const onMouseLeave = (event: any, locationId?: string) => {
    if (locationId) {
      dispatch(setActiveLocation(null));
    }
  };
  return (
    <Flex>
      <SelectLocalizedPreviousLocationsComponent
        locations={locationsToReview}
        existingLocations={existingLocations}
        selectedLocationId={selectedLocationId}
        imageSrc={jobContext.imageUrl}
        imageAngle={image.angle ?? 0}
        activeShelfs={image.meta?.activeShelfs}
        onChangeLocationId={onChangeLocationId}
        onClickUse={onClickUse}
        onClickUnUse={onClickUnUse}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <CamogramTooltip />
    </Flex>
  );
}
