import {
  fetchCamogramInitialData,
  fetchOmniCamogramInitialData,
  fetchStores,
} from 'actions';

const stores = (state = {}, { type, payload }) => {
  const CAMOGRAM_INITIAL_DATA = fetchCamogramInitialData.fulfilled().type;
  const OMNI_CAMOGRAM_INITIAL_DATA = fetchOmniCamogramInitialData.fulfilled()
    .type;
  const STORES = fetchStores.fulfilled().type;

  if (
    type === CAMOGRAM_INITIAL_DATA ||
    type === STORES ||
    type === OMNI_CAMOGRAM_INITIAL_DATA
  ) {
    const byStoreId = {};
    payload.stores.forEach(store => {
      byStoreId[store.id] = store;
    });
    return byStoreId;
  }
  return state;
};

export default stores;
