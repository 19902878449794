import { VisibilityOff } from '@material-ui/icons';
import styled from 'styled-components';

import SkuImage from 'components/SkuImage';

const IMAGE_SIZE = 40;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  :hover {
    background-color: lightgray;
  }
`;

const TitleContainer = styled.div`
  font-size: 12px;
`;

const ImagesContainer = styled.div`
  margin-top: 0px;
  display: flex;
`;

const ImageContainer = styled.div<{ index: number }>`
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  border-radius: 15px;
  border: solid 1px #000;
  overflow: hidden;
  margin-right: -16px;
`;

type LocationHorizontalScrollListCollapseProps = {
  title: string;
  visible: boolean;
  skuIds: string[];
  onClickVisibility: (visible: boolean) => void;
};

const LocationHorizontalScrollListCollapse: React.FC<LocationHorizontalScrollListCollapseProps> = ({
  title,
  visible,
  skuIds,
  onClickVisibility,
  children,
}) => {
  const onClickVisible = () => {
    onClickVisibility(!visible);
  };
  if (!visible) {
    return (
      <Container onClick={onClickVisible}>
        <TitleContainer>
          {title}
          <VisibilityOff fontSize="small" />
        </TitleContainer>
        <ImagesContainer>
          {skuIds.map((skuId, index) => (
            <ImageContainer index={index} key={skuId}>
              <SkuImage
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                uuid={skuId}
                alt={skuId}
              />
            </ImageContainer>
          ))}
        </ImagesContainer>
      </Container>
    );
  } else {
    return <>{children}</>;
  }
};

export default LocationHorizontalScrollListCollapse;
