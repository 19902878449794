import { useAppSelector } from 'hooks';
import { useParams } from 'react-router-dom';

const useStore = () => {
  const { storeId } = useParams<{ storeId: string }>();
  const store = useAppSelector(state => state.stores[storeId]);

  if (!store)
    throw new Error(
      'No store data. useStore must be used within StoresProvider'
    );

  return store;
};

export default useStore;
