import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ImageFilterContext } from 'components/Editor/Header/ImageFilterSliders';
import StyledImage from 'components/Editor/CameraImage/StyledImage';
import { Coordinate, Dimensions } from 'types';
import { calcIsRotated, calculateLocationPosition } from 'utils/position';
import { MapInteractionCSS } from 'react-map-interaction';
import { convertAngle } from 'utils';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #444;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  color: #ffffff;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
`;

const ImageContainer = styled.div<{ locationPosition: any }>`
  flex-grow: 1;
  transform: scale(
      ${({ locationPosition }) => locationPosition?.zoomScale || 1}
    )
    translate(
      ${({ locationPosition }) => locationPosition?.zoomTranslate?.x || 0}px,
      ${({ locationPosition }) => locationPosition?.zoomTranslate?.y || 0}px
    );
  transform-origin: top left;
  height: 100%;
`;

const configureClipPath = regions => {
  const arr = regions.map(({ u, v }) => `${u * 100}% ${v * 100}%`);
  // Need to close the polygon
  const result = arr.concat(arr[0]).join(',');
  return result;
};
const SelectedLocationHole = styled.div<{
  width: number;
  height: number;
  regions: any;
}>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  clip-path: ${({ regions }) =>
    `polygon(evenodd, 0 0, 100% 0, 100% 100%, 0% 100%, 0 0, ${configureClipPath(
      regions
    )})`};
  background-color: rgba(0, 0, 0, 0.55);
`;

type HighlightLocationProps = {
  region: Coordinate[];
  rotation: number;
  imageSrc: string;
  zoomIn?: boolean;
  angle?: number;
  label?: React.ReactNode;
};

export default function HighlightLocation({
  region: originalRegion,
  rotation,
  imageSrc,
  zoomIn = true,
  angle = 0,
  label,
}: HighlightLocationProps) {
  const imageWrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { imageFilter } = useContext(ImageFilterContext);
  const [imageDimensions, setImageDimensions] = useState<Dimensions>();
  const [containerDimensions, setContainerDimensions] = useState<Dimensions>();
  const isRotated = calcIsRotated(angle);

  const angledRegion = originalRegion.map(({ u, v }) =>
    convertAngle(u, v, angle)
  );
  useLayoutEffect(() => {
    if (imageWrapperRef.current) {
      setContainerDimensions({
        width: imageWrapperRef.current.offsetWidth,
        height: imageWrapperRef.current.offsetHeight,
      });
    }
  }, []);

  const onLoad = ({ target }) => {
    const width = isRotated ? target.naturalHeight : target.naturalWidth;
    const height = isRotated ? target.naturalWidth : target.naturalHeight;
    setImageDimensions({
      width,
      height,
    });
  };

  const locationPosition = calculateLocationPosition(
    angledRegion,
    rotation,
    imageDimensions,
    containerDimensions,
    zoomIn
  );

  return (
    <ItemWrapper>
      <ImageWrapper ref={imageWrapperRef}>
        <MapInteractionCSS>
          <ImageContainer locationPosition={locationPosition}>
            <StyledImage
              src={imageSrc}
              imageFilter={imageFilter}
              onLoad={onLoad}
            />
            {locationPosition && (
              <SelectedLocationHole
                width={locationPosition.imageDimensions.width}
                height={locationPosition.imageDimensions.height}
                regions={angledRegion}
              />
            )}
          </ImageContainer>
        </MapInteractionCSS>
      </ImageWrapper>
      {label}
    </ItemWrapper>
  );
}
