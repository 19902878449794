import { useState } from 'react';
import styled from 'styled-components';
import { MapInteractionCSS } from 'react-map-interaction';

import {
  HighresActiveRegion,
  HighresActiveSection,
  HighresCamera,
  Section,
} from 'types';
import ItemLocationsLayer from './ItemLocationsLayer';
import { ActiveRegionEditorProvider } from './context';

const ContentContainer = styled.div`
  min-width: 0;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const CameraImage = styled.img`
  object-fit: contain;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

type EditorAreaProps = {
  activeRegion: HighresActiveRegion;
  selectedActiveSectionId: string;
  selectedSectionId: string;
  camera: HighresCamera;
  sections: Section[];
  onAddNewActiveSection: (newActiveSection: HighresActiveSection) => void;
};

export default function EditorArea({
  sections,
  activeRegion,
  selectedActiveSectionId,
  selectedSectionId,
  camera,
  onAddNewActiveSection,
}: EditorAreaProps) {
  const [zoom, setZoom] = useState({
    scale: 0.5,
    translation: { x: 0, y: 0 },
  });
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  }>({ width: 1200, height: 600 });

  const selectedSection = sections.find(
    section => section.camogramSectionId === selectedSectionId
  );
  const selectedActiveSection = activeRegion.activeSections?.find(
    activeSection => activeSection.camogramSectionId === selectedActiveSectionId
  );

  const onLoad = ({ target }: any) => {
    const width = target.naturalWidth;
    const height = target.naturalHeight;
    setImageSize({ width, height });

    setZoom({ ...zoom, scale: 0.3 });
  };

  return (
    <ActiveRegionEditorProvider imageSize={imageSize} zoom={zoom}>
      <ContentContainer>
        <MapInteractionCSS
          minScale={0.1}
          maxScale={15}
          value={zoom}
          onChange={setZoom}
        >
          <CameraImage
            src={camera.src}
            onLoad={onLoad}
            width={imageSize.width}
            height={imageSize.height}
          />
          <ItemLocationsLayer
            selectedSection={selectedSection}
            selectedActiveSection={selectedActiveSection}
            activeRegion={activeRegion}
            camera={camera}
            onAddNewActiveSection={onAddNewActiveSection}
          />
        </MapInteractionCSS>
      </ContentContainer>
    </ActiveRegionEditorProvider>
  );
}
