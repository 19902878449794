import React, { useState } from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import useAsyncEffect from 'use-async-effect';
import { useDispatch } from 'react-redux';

import { CAMOGRAM_STATUS_APPROVED } from 'common/constants';
import fetchStoreCamogramsApi from 'utils/api/fetchStoreCamogramsApi';
import useAsyncCall from 'hooks/useAsyncCall';
import Loader from './Loader';

const CamogramsSelector = ({ storeId, datetime, onSelect }) => {
  const dispatch = useDispatch();
  const { call, isReady } = useAsyncCall();
  const [camograms, setCamograms] = useState([]);

  useAsyncEffect(async () => {
    const camograms = await call(fetchStoreCamogramsApi({ storeId }));
    const cleanedCamograms = camograms.filter(
      camogram => camogram.status === CAMOGRAM_STATUS_APPROVED
    );
    setCamograms(cleanedCamograms);
  }, [dispatch, storeId]);

  const onChangeCamogram = e => {
    const datetime = e.target.value;
    const selectedCamogram = camograms.find(
      camogram =>
        (camogram.rockset_created_at || camogram.created_at) === datetime
    );
    onSelect(selectedCamogram);
  };

  return (
    <Loader loading={!isReady} full={false}>
      <InputLabel>Camograms</InputLabel>
      <Select
        value={datetime ?? ''}
        onChange={onChangeCamogram}
        fullWidth
        displayEmpty
      >
        {camograms.map((camogram, index) => (
          <MenuItem
            key={index}
            value={camogram.rockset_created_at || camogram.created_at}
          >
            {camogram.rockset_created_at || camogram.created_at}
          </MenuItem>
        ))}
      </Select>
    </Loader>
  );
};

export default CamogramsSelector;
