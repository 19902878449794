import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchHighResCamogramInitialData,
  fetchCamogramInitialData,
  resetCurrentCamogram,
} from 'actions';
import Camograms from 'components/Camograms';
import Loader from 'components/Loader';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useLocation } from 'react-router-dom';
import {
  ANNOTATION_TYPE_OMNI,
  ANNOTATION_TYPE_HIGH_RES,
  ANNOTATION_TYPE_ORIGINAL,
} from 'common/constants';
const useAnnotationType = () => {
  const { pathname } = useLocation();
  if (pathname.includes('omni-camogram')) {
    return ANNOTATION_TYPE_OMNI;
  } else if (pathname.includes('highres-camogram')) {
    return ANNOTATION_TYPE_HIGH_RES;
  } else {
    return ANNOTATION_TYPE_ORIGINAL;
  }
};
const CamogramsContainer = () => {
  const { storeId, datetime } = useParams();
  const annotationType = useAnnotationType();

  return (
    <CamogramDetailComponent
      storeId={storeId}
      datetime={datetime}
      annotationType={annotationType}
    />
  );
};

export const CamogramDetailComponent = ({
  storeId,
  datetime,
  full = true,
  annotationType,
}) => {
  const dispatch = useDispatch();
  const cameras = useSelector(state =>
    Object.values(state.current.camogram.cams)
  );

  const { dispatch: dispatchAsync, isReady } = useAsyncDispatch();

  useEffect(() => {
    if (annotationType === ANNOTATION_TYPE_HIGH_RES) {
      dispatchAsync(fetchHighResCamogramInitialData({ storeId, datetime }));
    } else {
      dispatchAsync(fetchCamogramInitialData({ storeId, datetime }));
    }

    return () => dispatch(resetCurrentCamogram());
  }, [storeId, datetime, dispatchAsync, dispatch, annotationType]);

  return (
    <Loader full={full} loading={!isReady} text="Loading Camogram">
      {cameras.length > 0 ? (
        <Camograms cameras={cameras} />
      ) : (
        <div>No Camograms</div>
      )}
    </Loader>
  );
};

export default CamogramsContainer;
